import { ErrorIcon } from "../assets";

const ErrorField = ({ message }) => {
	return (
		<div className={`flex items-center h-[10px] w-full mt-2  shaking`}>
			<div className=" h-full mr-2">
				<img src={ErrorIcon} alt="Error" className=" h-full w-[10px]" />
			</div>
			<div className="h-full flex items-center">
				<p className="error-label text-[10px] font-GraphikRegular">{message}</p>
			</div>
		</div>
	);
};

export default ErrorField;
