import styled from "styled-components";

const basicColor = "#f28e14";
const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
	width: ${(props) => (props.isFull ? "100%" : "270px")};
	height: 50px;

	color: ${(props) => {
        const { isOption, isDisabled } = props;
        if (isOption && isDisabled) return "gray";
        if (isOption && !isDisabled) return basicColor;
        return "white";
    }};
	border-radius: 10px;
	text-align: center;
	font-family: "GraphikRegular";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
    transition: 500ms all ease;
	background: ${(props) => {
        const { isDisabled, isOption } = props;
        if (!isOption && !isDisabled) {
            return basicColor;
        }
        if (!isOption && isDisabled) {
            return "gray";
        }
        return "transparent";
    }};
	cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
    :active {
        transform: scale(1.1);
    }
`;

export default Button;
