import React, { useEffect, useState } from 'react'
import { useStoreBonusTime, useStoreTask, useStoreUser } from '../hooks/store';
import { useTimeTask } from '../hooks/useTimeTask';
import { BonusActions } from '../hooks/BonusActions';
import { sumPointsTotalBonusByTime } from '../helpers/bonusPointsOperations';
import imgs from '../assets/img';

let checkTime = 0
let idTaskBonus = -1

export const TaskClock = ({ isLoading = false }) => {
    const { tasks } = useStoreTask();
    const { questions } = useStoreUser()
    const { taskBonusReactive, numberTasksAnswered, addTotalNumberSecondOportunity } = useStoreBonusTime()
    const { setTimeBonusActivity, setUpdateNumberTaskAnswered, setUpdateNumberBonusSecondOpotunity } = BonusActions()
    const { timerView, playTimer, stopTimer, resetTimer } = useTimeTask()
    const { playTimer: playGlobalTimer, stopTimer: stopTimerGlobal, timerView: timerViewGlobal } = useTimeTask(true)

    const [circleProgressTime, setCircleProgressTime] = useState("0 250")
    const [circleColorTime, setCircleColorTime] = useState("#0577BE")
    const [bonusPoints, setBonusPoints] = useState(0)
    const [initExperencie, setinitExperencie] = useState(false)

    const [isSecondOprtunityState, setIsSecondOprtunityState] = useState(false)

    //* tiempo de la tarea
    const initTimeReactive = () => {
        const findReactivo = taskBonusReactive.findIndex((taskB) => !taskB.isUsed)
        if (findReactivo === -1) {
            return
        }
        playTimer()
        checkTime = 1
        idTaskBonus = findReactivo
    }

    //* segunda oportunidad
    useEffect(() => {
        const checkTaskId = tasks.length > 0 ? tasks[0].id : 0
        const findBonusQuestion = taskBonusReactive.findIndex((bonus) => bonus.finishSecondId === checkTaskId)


        if (isSecondOprtunityState) {
            if (findBonusQuestion === -1) {
                return
            }
            initTimeReactive()
            setIsSecondOprtunityState(false)
            setUpdateNumberTaskAnswered(numberTasksAnswered + 1)
            checkTime = 1
            return
        } else if (checkTime === 0 && idTaskBonus === -1 && ![0, 1].includes(checkTaskId)) {
            initTimeReactive()
            checkTime = 1
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks])


    const saveNormalQuestion = (responseQuestion = false, isSecond = false) => {
        if (isSecond) {
            if (!responseQuestion) {
                setIsSecondOprtunityState(true)
                setUpdateNumberBonusSecondOpotunity(addTotalNumberSecondOportunity + 1)
            }
        }
        setUpdateNumberTaskAnswered(numberTasksAnswered + 1)
        setTimeBonusActivity(idTaskBonus, timerView, responseQuestion)
        const bonusTimeTotal = sumPointsTotalBonusByTime(taskBonusReactive)
        setBonusPoints(bonusTimeTotal)
        idTaskBonus = -1
        checkTime = 0
        resetTimer()
    }

    //* si ya contesto
    useEffect(() => {
        if (checkTime === 0) {
            return
        }

        const taskBonusCheck = taskBonusReactive[idTaskBonus]

        if (!taskBonusCheck) {
            return
        }

        if (taskBonusCheck.isUsed) {
            checkTime = 0
            stopTimer()
            idTaskBonus = -1
            return
        }

        //* buscamos la pregunta que se contesto
        let findResponseQuestion = Object.values(questions).find((quest) => {
            return quest.taskId === taskBonusCheck.id
        })

        if (!findResponseQuestion) {
            return
        }

        let canSave = false
        if (taskBonusCheck.isSpecial) {

            if (taskBonusCheck.id === 13) {
                if (findResponseQuestion.answerLabel) {
                    canSave = true
                }
            }

            if (taskBonusCheck.id === 20) {
                if (findResponseQuestion.retro.times !== 0) {
                    canSave = true
                }
            }

            if (taskBonusCheck.id === 24) {
                if (findResponseQuestion.wasUsed) {
                    canSave = true
                }
            }

            //! las graficas del jefe se checan abajo y tambien la de credito de mujer

            if (!canSave) {
                return
            }
        }

        checkTime = 0
        stopTimer()
        const isSecondOportunity = taskBonusCheck.haveSecond
        const reponseQuestion = findResponseQuestion.isCorrect
        saveNormalQuestion(reponseQuestion, isSecondOportunity)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions])

    useEffect(() => {
        const checkTaskId = tasks.length > 0 ? tasks[0].id : 0
        const taskCheck = taskBonusReactive[idTaskBonus]
        if (!taskCheck) {
            return
        }

        if ([32, 35, 38, 41, 44].includes(taskCheck.id)) {
            if (taskCheck.finishId.includes(checkTaskId)) {
                const responseSlicePosition = questions.sliceQuestion.currentIndex
                const responseSlice = questions.sliceQuestion.presentation[responseSlicePosition]
                saveNormalQuestion(responseSlice.isCorrect, false)
            }
        }

        if (52 === taskCheck.id) {
            if (questions.womanQuestion.answerLabel) {
                saveNormalQuestion(questions.womanQuestion.isCorrect, false)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks])

    useEffect(() => {
        const checkTaskId = tasks.length > 0 ? tasks[0].id : 0
        if (checkTaskId === 2 && !initExperencie) {
            setinitExperencie(true)
        }
        if (checkTaskId === 59 && initExperencie) {
            stopTimerGlobal()
        }
        const bonusTimeTotal = sumPointsTotalBonusByTime(taskBonusReactive)
        setBonusPoints(bonusTimeTotal)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks])

    useEffect(() => {
        if (initExperencie) {
            playGlobalTimer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initExperencie])

    useEffect(() => {
        if (timerViewGlobal === "0:00") {
            setCircleProgressTime("0 250")
            setCircleColorTime("#0577BE")
            return
        }

        const [minutes, seconds] = timerViewGlobal.split(':');
        const elapsedTime = parseInt(minutes) * 60 + parseInt(seconds);

        const maxTimeByResponseInMinutes = 90 // 2h = 120
        const totalTime = maxTimeByResponseInMinutes * 60;
        const percentage = (elapsedTime / totalTime) * 100;
        // const percentage = (intMinutes * 100) / maxTimeByResponse

        const radio = 40;
        const circumference = 2 * Math.PI * radio;
        const lengthPercentage = (circumference * percentage) / 100;

        if (minutes <= 45) {
            setCircleColorTime("#4BB631")
        } else if (minutes >= 46 && minutes <= 74) {
            setCircleColorTime("#FEE14A")
        } else if (minutes >= 75) {
            setCircleColorTime("#FF0000")
        }
        // if (percentage <= 25) {
        //     setCircleColorTime("#4BB631")
        // } else if (percentage >= 26 && percentage <= 75) {
        //     setCircleColorTime("#FEE14A")
        // } else if (percentage >= 76) {
        //     setCircleColorTime("#FF0000")
        // }
        setCircleProgressTime(`${lengthPercentage} ${circumference}`)
    }, [timerViewGlobal])

    useEffect(() => {
        setinitExperencie(!isLoading)
        if (!isLoading) {
            initTimeReactive()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
        <div className={`h-full w-full relative ${isLoading && 'hidden'}`}>
            {/* <div className='absolute z-40 top-0 bg-green'>{timerView}</div> */}
            <div className={`h-full w-[75px] absolute z-10 -left-[64px] flex justify-center items-center ${bonusPoints === 0 && 'hidden'}`}>
                <div className='h-[75px] w-full relative'>
                    <div className='absolute z-10 h-full w-full'>
                        <img className='h-full w-full object-contain' src={imgs.iconWin} alt="win" />
                    </div>
                    <div className='absolute z-20 h-full w-full flex justify-center items-center pt-[22px] pr-1'>
                        <span className='text-[#E89B05] font-bold text-[10px]'>{bonusPoints}</span>
                    </div>
                </div>
            </div>
            {/* <div className='h-full w-full absolute z-10'>
                <svg width="100%" height="100%" viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="40" stroke="#ffffff4b" strokeWidth="8" fill="none" />
                    <circle
                        cx="50"
                        cy="50"
                        r="40"
                        stroke="#32B6D9"
                        strokeWidth="8"
                        fill="none"
                        // strokeDasharray={circleProgressTask}
                        strokeDasharray="0 250"
                        transform="rotate(90, 50, 50)"
                        id="progress"
                    />
                </svg>
            </div> */}
            <div className='w-[48px] h-[16px] bg-[#31B5D8] rounded-[5px] flex items-center justify-center absolute z-30 -bottom-[2px] left-1/2 -translate-x-1/2 '>
                <p className='text-white text-[12px] font-semibold'>{numberTasksAnswered}/{taskBonusReactive.length + addTotalNumberSecondOportunity}</p>
            </div>
            <div className='absolute h-full w-full z-10 overflow-hidden p-[10px]'>
                <div className='bg-white rounded-full h-full w-full'></div>
            </div>
            {/* p-[13px] */}
            <div className='absolute h-full w-full z-20 p-[8px]'>
                <div className='h-full w-full'>
                    <svg width="100%" height="100%" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="35" stroke="#31b4d857" strokeWidth="2" fill="none" />
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            stroke={circleColorTime}
                            strokeWidth="8"
                            fill="none"
                            strokeDasharray={circleProgressTime}
                            transform="rotate(90, 50, 50)"
                            id="progress"
                        />
                    </svg>
                </div>
            </div>
            <div className='absolute h-full w-full flex justify-center items-center z-30'>
                <p className='text-[#31B5D8] text-[14px] font-semibold'>{timerViewGlobal}</p>
            </div>
        </div>
    )
}
