/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useStoreComputer, useStoreTask, useStoreUser } from "../hooks/store";
import { setNotificationStatus } from "../store/slices/task";
import { initStatusQuestions } from "../types";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";

const TableContext = createContext({ isDoneAction: false, doAction: () => { }, changeAction: () => { } });

export const TableProvider = ({ children }) => {
    const [showNotification, setShowNotification] = useState(false);
    const dispatch = useDispatch();
    const { tasks } = useStoreTask();
    const { questions } = useStoreUser();
    const { selectedSection } = useStoreComputer();

    const handlerClickAction = useCallback(() => {
        const body = initStatusQuestions.isClient
            ? getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_modal_info_es_cliente")
            : getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_modal_info_no_es_cliente");
        dispatch(setNotificationStatus({ isActive: true, title: getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_modal_titulo"), body }));
        setShowNotification(true);
    }, [dispatch, tasks]);

    const changeMessage = (body) => {
        dispatch(
            setNotificationStatus({
                isActive: true,
                title: getJsonTextLanguageDisplay("tarea_7_modal_final_titulo"),
                body,
            })
        );
    };
    const getMessageRetro1 = (answer) => {
        if (initStatusQuestions.personRetro1.isWomanClient && answer) {
            changeMessage(getJsonTextLanguageDisplay("tarea_7_mensaje_promocion_autorizado", { "[replace1]": initStatusQuestions.personRetro1.name }));
            return;
        }
        if (initStatusQuestions.personRetro1.isWomanClient && !answer) {
            changeMessage(getJsonTextLanguageDisplay("tarea_7_mensaje_promocion_denegado", { "[replace1]": initStatusQuestions.personRetro1.name }));
            return;
        }
        if (!initStatusQuestions.personRetro1.isWomanClient && answer) {
            changeMessage(getJsonTextLanguageDisplay("tarea_7_mensaje_tasa_preferencial_autorizado", { "[replace1]": initStatusQuestions.personRetro1.name }));
            return;
        }
        if (!initStatusQuestions.personRetro1.isWomanClient && !answer) {
            changeMessage(getJsonTextLanguageDisplay("tarea_7_mensaje_tasa_preferencial_denegado", { "[replace1]": initStatusQuestions.personRetro1.name }));
            return;
        }
        if (initStatusQuestions.isClient && !answer) {
            changeMessage(getJsonTextLanguageDisplay("tarea_7_mensaje_es_cliente_1", { "[replace1]": initStatusQuestions.personRetro1.name }));
            return;
        }
    };

    useEffect(() => {
        if (!tasks) return;
        if (tasks.length === 0) return;
        if (selectedSection !== "Registro" && selectedSection !== "Documents" && selectedSection !== "Histograma") return;
        const task = tasks[0];
        if (task.id === 10 && questions.question.retro.answer !== null) {
            const {
                retro: { answer },
            } = questions.question;
            getMessageRetro1(answer);
        }
    }, [tasks, questions, selectedSection]);

    return <TableContext.Provider value={{ isDoneAction: showNotification, doAction: handlerClickAction }}>{children}</TableContext.Provider>;
};

export const useTableContext = () => {
    return useContext(TableContext);
};
