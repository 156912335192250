import {
    informationQuestion1,
    informationQuestion2,
    informationQuestion3Age,
    informationQuestion3Score,
    informmationRetro1Event,
    informmationRetro1_1Registered,
    informationQuestion5,
    informationQuestionMeeting,
    informationQuestions9,
    informationQuestionsCouple,
    informationRetro5,
    informationQuestionClientParticipate,
    informationQuestionClient,
    informationQuestionParticipate,
    retroConcepts,
    informationQuestion4,
    informationQuestionRetro4,
    informationQuestionsCoupleWomanCases,
    informationQuestions9MenCases,
    informationQuestionMeetingCases,
    informationQuestion5Cases,
} from "../data/retro";
import { sumPointsTotalBonusByTime } from "./bonusPointsOperations";
import { getJsonTextLanguageDisplay } from "./helpersLanguage";


const getClasification = (score = 0) => {
    if (score <= 20) return getJsonTextLanguageDisplay("reactivo_total_calificaion_incipiente")
    if (score > 20 && score <= 23) return getJsonTextLanguageDisplay("reactivo_total_calificaion_basico")
    if (score > 23 && score <= 27) return getJsonTextLanguageDisplay("reactivo_total_calificaion_solido")
    return getJsonTextLanguageDisplay("reactivo_total_calificaion_destacado")
}

export const getFormattedAnswer = (results) => {
    const newResults = [];
    if (!results) return []
    results.forEach(data => {
        let totalScore = 0;
        const { config, userId, bonusTime, codeLanguage = "es" } = data;
        const { hasParticipated, isClient, retroHasParticipated, age, score: scoreClient, typeBorrow, isRetroWoman, retroIsClient, casesReactive7And8, casesReactiveMeetingSlice, casesReactive5WorkMate } = config;
        newResults.push({ name: userId })
        newResults.push({ code: codeLanguage })
        data.responses.forEach(response => {

            let isCasesReactive7And8 = false
            if (casesReactive7And8) {
                isCasesReactive7And8 = true
            }

            let isCasesReactiveMeetingSlice = false
            if (casesReactiveMeetingSlice) {
                isCasesReactiveMeetingSlice = true
            }

            let isCaseReactive5FirstOportunity = false
            if (casesReactive5WorkMate) {
                isCaseReactive5FirstOportunity = true
            }

            // analisis
            const { isCorrect, questionName, retroQuestionName, retroStatus, answerLabel, score, retroScore } = response;
            if (questionName === 'Reactivo 9') return;
            if (questionName === "Reactivo 1") {
                const statusText = isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf")
                const nameQuestionR1 = getJsonTextLanguageDisplay("reactivo1_nombre_r1")
                if (isClient && hasParticipated) {
                    newResults.push({ numberReactive: "1", context: getJsonTextLanguageDisplay("reactivo1_es_cliente_participo_titulo_1"), answerLabel, status: statusText, score, question: nameQuestionR1, retro: informationQuestionClientParticipate[score][0], training: informationQuestionClientParticipate[score][1] })
                } else if (isClient && !hasParticipated) {
                    newResults.push({ numberReactive: "1", context: getJsonTextLanguageDisplay("reactivo1_es_cliente_no_participo_titulo_1"), answerLabel, status: statusText, score, question: nameQuestionR1, retro: informationQuestionClient[score][0], training: informationQuestionClient[score][1] })
                } else if (!isClient && !hasParticipated) {
                    newResults.push({ numberReactive: "1", context: getJsonTextLanguageDisplay("reactivo1_no_es_cliente_no_participo_titulo_1"), answerLabel, status: statusText, score, question: nameQuestionR1, retro: informationQuestion1[score][0], training: informationQuestion1[score][1] })
                } else {
                    newResults.push({ numberReactive: "1", context: getJsonTextLanguageDisplay("reactivo1_si_no_entra_a_ninguno_titulo_1"), answerLabel, status: statusText, score, question: nameQuestionR1, retro: informationQuestionParticipate[score][0], training: informationQuestionParticipate[score][1] })
                }
                const localScore = score;
                totalScore += localScore;
                if (isCorrect) return;
                if (retroStatus === "Sin retroalimentación") return;
                const { retroScore } = response;

                let isExistRetroWoman = false
                if (isRetroWoman !== undefined) {
                    isExistRetroWoman = isRetroWoman
                }

                const retroMessages = isExistRetroWoman ? informmationRetro1Event : informmationRetro1_1Registered;
                const retroMessageValue = isExistRetroWoman ? retroIsClient ? 1 : 0 : retroHasParticipated ? 1 : 0;
                const status = retroScore === 1 ? true : false;
                const retroScoreData = status ? 1 : 0;

                const retroType = `${retroMessageValue}${retroScoreData}`;
                totalScore += retroScoreData;
                const { retroLabel = "" } = response;

                let context = retroIsClient ? getJsonTextLanguageDisplay("reactivo1_1_es_retro_registro") : getJsonTextLanguageDisplay("reactivo1_1_es_retro_no_registro")
                if (isExistRetroWoman) {
                    if (retroHasParticipated) {
                        context = getJsonTextLanguageDisplay("reactivo1_1_es_retro_asistio")
                    } else {
                        context = getJsonTextLanguageDisplay("reactivo1_1_es_retro_no_asistio")
                    }
                }

                const nameQuestionR1_1 = getJsonTextLanguageDisplay("reactivo1_nombre_r1_1")
                newResults.push({
                    numberReactive: "1.1",
                    context: context,
                    answerLabel: retroLabel,
                    status: status ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                    score: retroScoreData,
                    question: nameQuestionR1_1,
                    retro: retroMessages[retroType][0],
                    training: retroMessages[retroType][1]
                })
                return;

            }
            if (questionName === "Reactivo 2") {
                const { table, answerLabel, score } = response;
                const nameQuestionR2 = getJsonTextLanguageDisplay("reactivo2_nombre_r2")
                const nameQuestionR2_1 = getJsonTextLanguageDisplay("reactivo2_nombre_r2_1")
                const nameTableName = getJsonTextLanguageDisplay("reactivo2_tabla_nombre", { "[replace1]": table })

                newResults.push(
                    {
                        numberReactive: "2",
                        context: nameTableName,
                        answerLabel: answerLabel.join(", "),
                        status: score === 3 ? getJsonTextLanguageDisplay("reactivo2_acierta_todas") : score === 0 ? getJsonTextLanguageDisplay("reactivo2_falla_todas") : getJsonTextLanguageDisplay("reactivo2_falla_al_menos_uno"),
                        score,
                        question: nameQuestionR2,
                        retro: informationQuestion2[score][0],
                        training: informationQuestion2[score][1]
                    });
                totalScore += score;
                if (isCorrect) return;
                if (retroStatus === "Sin retroalimentación") return
                if (response.retroLabel.length === 0) return;


                const { retroLabel, retroAnswerOptions } = response;
                const oldLabel = retroScore === 1 ? getJsonTextLanguageDisplay("reactivo2_acierta_todas") : retroScore === 0 ? getJsonTextLanguageDisplay("reactivo2_falla_todas") : getJsonTextLanguageDisplay("reactivo2_falla_al_menos_uno");
                newResults.push(
                    {
                        numberReactive: "2.1",
                        context: nameTableName,
                        answerLabel: retroLabel.join(", "),
                        status: retroAnswerOptions || oldLabel,
                        score: retroScore,
                        question: nameQuestionR2_1,
                        retro: retroConcepts[retroScore][0],
                        training: retroConcepts[retroScore][1]
                    })
                totalScore += retroScore;

                return;
            }
            if (questionName === "Reactivo 3") {
                const nameQuestionR3 = getJsonTextLanguageDisplay("reactivo3_nombre_r3")

                const ageMessage = age >= 18 ? getJsonTextLanguageDisplay("reactivo3_cliente_mayor_18") : getJsonTextLanguageDisplay("reactivo3_cliente_menor_18");
                const scoreMessage = 716 <= scoreClient && scoreClient <= 809 ? getJsonTextLanguageDisplay("reactivo3_score_entre_18") : getJsonTextLanguageDisplay("reactivo3_score_arriba_18");
                const retroMessages = isClient ? informationQuestion3Score : informationQuestion3Age;
                const retroMessageValue = isClient ? 716 <= scoreClient && scoreClient <= 809 ? 0 : 1 : age >= 18 ? 1 : 0;
                const retroType = `${retroMessageValue}${score}`;

                newResults.push(
                    {
                        numberReactive: "3",
                        context: typeBorrow === "edad" ? ageMessage : scoreMessage,
                        answerLabel: answerLabel,
                        status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                        score,
                        question: nameQuestionR3,
                        retro: retroMessages[retroType][0],
                        training: retroMessages[retroType][1]
                    });
                totalScore += score;

                return;

            }
            if (questionName === "Reactivo 4") {
                const { retroLabel, retroScore, retroFile, file } = response;
                const nameQuestionR4 = getJsonTextLanguageDisplay("reactivo4_nombre_r4")

                const type = isCorrect ? 2 : 0;

                const localScore = isCorrect ? 2 : 0;
                newResults.push(
                    {
                        numberReactive: "4",
                        context: getJsonTextLanguageDisplay("reactivo4_selecion_carpeta"),
                        answerLabel: answerLabel,
                        status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                        score: localScore,
                        question: nameQuestionR4,
                        retro: file ? file.studentRetro : informationQuestion4[type][0],
                        training: file ? file.teacherRetro : informationQuestion4[type][1]

                    }
                )
                totalScore += localScore;
                if (retroStatus === "Sin retroalimentación") return;
                if (retroQuestionName === "Sin retroalimentación") return;
                if (isCorrect) return;



                newResults.push(
                    {
                        numberReactive: "4.1",
                        context: getJsonTextLanguageDisplay("reactivo4_selecion_carpeta"),
                        answerLabel: retroLabel,
                        status: retroScore === 1 ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                        score: retroScore,
                        question: getJsonTextLanguageDisplay("reactivo4_nombre_r4_1"),
                        retro: retroFile ? retroFile.studentRetro : informationQuestionRetro4[retroScore][0],
                        training: retroFile ? retroFile.teacherRetro : informationQuestionRetro4[retroScore][1]

                    }
                )
                totalScore += retroScore
                return;
            }
            if (questionName === "Reactivo 5") {

                const { retroLabel, retroScore } = response;

                const nameQuestionR5 = getJsonTextLanguageDisplay("reactivo5_nombre_r5")


                if (isCaseReactive5FirstOportunity) {
                    // nuevo
                    const caseSelectWorkMate = casesReactive5WorkMate.workmate.caseWorkMateSelect
                    const isRetroCorrect = isCorrect ? "correct" : "incorrect"
                    const { retro: retroReact5, nivelComp: nivelCompReact5 } = informationQuestion5Cases[caseSelectWorkMate][isRetroCorrect]

                    newResults.push(
                        {
                            numberReactive: "5",
                            context: getJsonTextLanguageDisplay("reactivo5_mayor_probabilidad_cumplir"),
                            answerLabel: answerLabel,
                            status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                            score,
                            question: nameQuestionR5,
                            retro: retroReact5,
                            training: nivelCompReact5
                        }
                    )
                } else {
                    // antes
                    newResults.push(
                        {
                            numberReactive: "5",
                            context: getJsonTextLanguageDisplay("reactivo5_mayor_probabilidad_cumplir"),
                            answerLabel: answerLabel,
                            status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                            score,
                            question: nameQuestionR5,
                            retro: informationQuestion5[score][0],
                            training: informationQuestion5[score][1]
                        }
                    )
                }
                totalScore += score;


                if (retroStatus === "Sin retroalimentación") return
                if (isCorrect) return;


                newResults.push(
                    {
                        numberReactive: "5.1",
                        context: getJsonTextLanguageDisplay("reactivo5_menor_probabilidad_cumplir"),
                        answerLabel: retroLabel,
                        status: retroLabel === "Cliente 3" ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                        score: retroScore,
                        question: getJsonTextLanguageDisplay("reactivo5_nombre_r5_1"),
                        retro: informationRetro5[retroScore][0],
                        training: informationRetro5[retroScore][1]
                    }
                )
                totalScore += retroScore;
                return
            }
            if (questionName.includes("Reactivo 8")) {
                let newScore = isCorrect ? 2 : 0
                const localScore = isCorrect ? 2 : 0;

                const nameQuestionR8 = getJsonTextLanguageDisplay("reactivo8_nombre_r8")


                //* reactivo 8
                if (isCasesReactive7And8) {

                    const numberCaseMen7 = casesReactive7And8.men.numberCase
                    const whichCopyPdf = newScore === 2 ? "correct" : "incorrect"
                    const retroCopy = informationQuestions9MenCases[numberCaseMen7].retro[whichCopyPdf]
                    const trainingCopy = informationQuestions9MenCases[numberCaseMen7].nivelComp[whichCopyPdf]

                    newResults.push(
                        {
                            numberReactive: "8",
                            context: getJsonTextLanguageDisplay("reactivo8_contexto"),
                            answerLabel,
                            status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                            score: localScore,
                            question: nameQuestionR8,
                            retro: retroCopy,
                            training: trainingCopy
                        }
                    )
                    totalScore += newScore;
                    return
                }

                newResults.push(
                    {
                        numberReactive: "8",
                        context: getJsonTextLanguageDisplay("reactivo8_contexto"),
                        answerLabel,
                        status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                        score: localScore,
                        question: nameQuestionR8,
                        retro: informationQuestions9[newScore][0],
                        training: informationQuestions9[newScore][1]
                    }
                )
                totalScore += localScore;

                return
            }
            if (retroQuestionName === "Sin retroalimentación") {
                if (questionName.includes("Reactivo 6")) {

                    const numberR6 = response.questionName.split(" ")
                    const nameQuestionR6 = getJsonTextLanguageDisplay("reactivo6_nombre_r6", { "[replace1]": numberR6[1] })

                    if (isCasesReactiveMeetingSlice) {
                        const { sliceId } = response;

                        const casesBossSlice = {
                            "Reactivo 6.1": "slice1",
                            "Reactivo 6.2": "slice2",
                            "Reactivo 6.3": "slice3",
                            "Reactivo 6.4": "slice4",
                            "Reactivo 6.5": "slice5",
                        }

                        const numberCaseSlice = casesReactiveMeetingSlice[casesBossSlice[response.questionName]]
                        const messageRetro = informationQuestionMeetingCases[response.questionName][numberCaseSlice][sliceId]

                        newResults.push(
                            {
                                numberReactive: `${numberR6[1]}`,
                                context: getJsonTextLanguageDisplay("reactivo6_junta_jefe"),
                                answerLabel: answerLabel,
                                status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                                score: messageRetro.score,
                                question: nameQuestionR6,
                                retro: messageRetro.retro,
                                training: messageRetro.nivelComp
                            }
                        )
                        totalScore += messageRetro.score;
                        return
                    }

                    const { sliceId } = response;
                    const messageRetro = informationQuestionMeeting[response.questionName][sliceId];
                    newResults.push(
                        {
                            numberReactive: `${numberR6}`,
                            context: getJsonTextLanguageDisplay("reactivo6_junta_jefe"),
                            answerLabel: answerLabel,
                            status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                            score: messageRetro[0],
                            question: nameQuestionR6,
                            retro: messageRetro[1],
                            training: messageRetro[2]
                        }
                    )
                    totalScore += messageRetro[0];
                    return
                }

                //* reactivo 7
                const nameQuestionR7 = getJsonTextLanguageDisplay("reactivo7_nombre_r7")

                if (isCasesReactive7And8) {
                    const newScore = score ? score : isCorrect ? 2 : 0;

                    const numberCaseWoman7 = casesReactive7And8.woman.numberCase
                    const whichCopyPdf = newScore === 2 ? "correct" : "incorrect"
                    const retroCopy = informationQuestionsCoupleWomanCases[numberCaseWoman7].retro[whichCopyPdf]
                    const trainingCopy = informationQuestionsCoupleWomanCases[numberCaseWoman7].nivelComp[whichCopyPdf]

                    newResults.push(
                        {
                            numberReactive: "7",
                            context: getJsonTextLanguageDisplay("reactivo7_contexto"),
                            answerLabel: answerLabel,
                            status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                            score: newScore,
                            question: nameQuestionR7,
                            retro: retroCopy,
                            training: trainingCopy
                        }
                    )
                    totalScore += newScore;
                    return
                }

                const newScore = score ? score : isCorrect ? 2 : 0;
                newResults.push(
                    {
                        numberReactive: "7",
                        context: getJsonTextLanguageDisplay("reactivo7_contexto"),
                        answerLabel: answerLabel,
                        status: isCorrect ? getJsonTextLanguageDisplay("acierta_titulo_pdf") : getJsonTextLanguageDisplay("no_acierta_titulo_pdf"),
                        score: newScore,
                        question: nameQuestionR7,
                        retro: informationQuestionsCouple[newScore][0],
                        training: informationQuestionsCouple[newScore][1]
                    }
                )
                totalScore += newScore;

                return
            }



            if (isCorrect) return
            const localScore = isCorrect ? 2 : 0;
            newResults.push(
                {
                    context: "Promoción/Tarjeta",
                    answerLabel: answerLabel,

                    status: isCorrect ? "Acierta" : "Falla",
                    score: localScore,
                    question: questionName
                }
            )
            totalScore += localScore;

            newResults.push(
                {
                    context: "Autorización",
                    answerLabel: "Autorizar/Denegar",
                    status: retroStatus ? "Acierta" : "Falla",
                    score: retroScore,
                    question: retroQuestionName
                }
            )
            totalScore += retroScore;

            return

        })
        const newTotalPointsPercent = parseInt((totalScore * 100) / 29)
        // newResults.push({ identifierPdf: "total", total: "Total", score: totalScore, range: getClasification(totalScore) })
        newResults.push({ identifierPdf: "total", total: "", score: "", range: "" })
        //* nombres
        const getTotalBonus = sumPointsTotalBonusByTime(bonusTime)
        const sumBonus = getTotalBonus + newTotalPointsPercent
        newResults.push({
            identifierPdf: "columnExtraData",
            context: "Resultados.-",
            answerLabel: "Matricula:",
            status: "Bonus:",
            score: `Total:`,
            question: "",
            retro: getJsonTextLanguageDisplay("reactivo8_resultado_final"),
            training: "Estatus:"

        })
        //* final
        newResults.push({
            identifierPdf: "extraData",
            context: ``,
            answerLabel: `${userId}`,
            status: `${getTotalBonus}`,
            score: `${totalScore}`,
            question: "",
            retro: `${sumBonus}`,
            training: getClasification(totalScore)
        })
    });
    return { newResults, totalScore: 0 };
}