/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { getClientGrapth } from "../../../data/histogram";
import { initStatusQuestions } from "../../../types";
import DataHistogram from "./DataHistogram";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

const Histogram = () => {
    const { img, arrData } = getClientGrapth(initStatusQuestions.isClient);
    const items = useMemo(() => {
        return arrData.map((item) => {
            return <DataHistogram.Item key={item.title + item.data} title={item.title} value={item.data} />;
        });
    }, []);
    return (
        <div className="h-full w-full pt-[16px] flex  ">
            <div className="w-4/5 h-full ">
                <div className="w-full h-full flex flex-col  rounded-xl">
                    <div className="w-full h-[49px] flex items-center justify-center rounded-t-xl bg-white">
                        <h3 className="text-strong-blue font-GraphikBold text-[16px] xl:text-[20px]">
                            <b>
                                {initStatusQuestions.isClient ? getJsonTextLanguageDisplay("tarea_3_revisa_histograma_score_grafica_card_titulo") : getJsonTextLanguageDisplay("tarea_3_revisa_histograma_edad_grafica_card_titulo")}
                            </b>
                        </h3>
                    </div>
                    <div className="w-full grow rounded-b-xl bg-strong-blue">
                        <div className=" h-full flex justify-center items-center">
                            <img className="object-cover" src={img} alt="Graph of client data" />
                        </div>
                    </div>
                </div>
            </div>
            <DataHistogram>{items}</DataHistogram>
        </div>
    );
};

export default Histogram;
