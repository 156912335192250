import TableUserQuestion from "./TableUserQuestion";
import DocumentSection from "./DocumentSection";
import { Histogram } from "./task4";

const ClientSubSection = ({ subSection, taskId }) => {
	switch (subSection) {
		case "Documentación": {
			return <DocumentSection />;
		}
		case "Registro": {
			return <TableUserQuestion taskId={taskId} />;
		}
		case "Histograma": {
			return <Histogram />;
		}
		default:
			return <></>;
	}
};

export default ClientSubSection;
