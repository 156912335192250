/* eslint-disable react-hooks/exhaustive-deps */
import { Field, ErrorMessage } from "formik";
import ErrorField from "./ErrorField";
import { memo } from "react";

const TextFormField = ({ handleChange, value, placeholder, name }) => {
	return (
		<div className="mb-6 xl:mb-8 w-full h-8">
			<Field
				className="w-full h-[36px] rounded-[4px] p-[8px]"
				type="text"
				placeholder={placeholder}
				name={name}
				onChange={handleChange}
				value={value}
				autoComplete="none"
			/>
			<ErrorMessage key={value + name} name={name}>
				{(msg) => <ErrorField mustShake={value} message={msg} />}
			</ErrorMessage>
		</div>
	);
};

export default memo(TextFormField);
