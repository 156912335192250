
import { INIT_CAMERA_POSITION, STANDUP_CAMERA_POSITION, STANDUP_CAMERA_ROTATION, WORKMATE_NAME } from "./constanst";
import { Sound, correctTransparency, createTaskSprite, getBank, getWorkMate } from "./models";
import { soundNames } from "./models/Sound";
import { Animation, createLigth, setSize, startStructure } from "./structure";
import { loadingManager, path3D } from "./utils";

let _camera, _scene, _renderer, _animations, _labelRenderer, _actualSprite, _pointer, _controls, _sounds;

export class Introduction {

    constructor(container, closeLoading, task, actionTasks) {
        const { camera, renderer, scene, pointer, controls } = startStructure(container, INIT_CAMERA_POSITION, actionTasks);
        _controls = controls;
        this.container = container;
        this.isActive = false;
        loadingManager.onLoad = closeLoading;
        _renderer = renderer;
        _camera = camera;
        _scene = scene;
        _pointer = pointer;
        _animations = new Animation(scene, camera, renderer, [pointer]);
        this.listeners(container);
        this.init(task, actionTasks)
    }

    async init(actualTask, actionTasks) {
        const [bank, workmate] = await Promise.all([getBank(), getWorkMate()]);

        _scene.add(bank);
        _scene.add(workmate);
        _animations.appendElement(bank);
        _animations.appendElement(workmate)

        _scene.add(createLigth());
        this.addSpriteByTask(actualTask, false, actionTasks);
        correctTransparency(_scene);
        this.appendSound()
    }


    appendSound() {
        const sounds = [
            { name: 'office', path: `${path3D}music/office_background.mp3`, information: { volume: 0.2 } },
            { name: soundNames.dialogue, path: `${path3D}music/conversation.mp3`, information: { loop: false, autoPlay: false } },
            { name: soundNames.retro, path: `${path3D}music/feedback.mp3`, information: { loop: false, autoPlay: false } },
            { name: soundNames.task, path: `${path3D}music/Task.mp3`, information: { loop: false, autoPlay: false } }
        ]
        _sounds = new Sound(sounds)
    }
    start() {
        this.isActive = true;
        _animations.start();
    }
    stop() {
        this.isActive = false;
        _animations.stop();
    }


    enableMovement() {
        const { x, y, z } = STANDUP_CAMERA_POSITION;
        const { x: rx, y: ry, z: rz } = STANDUP_CAMERA_ROTATION;
        _camera.position.set(x, y, z);
        _camera.rotation.set(rx, ry, rz);
        _controls.name = "movment";
        _animations.appendElement(_controls);
    }
    createSpriteByTask(task, isSearchable) {
        if (_actualSprite) {
            _scene.remove(_actualSprite);
            _pointer.setAction = () => { }
        }
        if (task === null) return;
        if (!task) return;
        const { texture, name, position, isDoble } = task;
        const sprite = createTaskSprite(texture, name, position, isDoble, isSearchable);
        _actualSprite = sprite;

        return sprite;
    }


    cleanSpriteAction() {
        _scene.remove(_actualSprite);
        _pointer.setActive(false);
    }

    setGoingZone(task, callback) {
        _camera.tick = () => this.limitGoalZome(_camera, task.square, callback);
        _animations.appendElement(_camera);
    }

    limitGoalZome(object3D, zone, callback) {
        const { gtX, lsX, gtZ, lsZ } = zone;
        const { x, z } = object3D.position;
        if (x > gtX && x < lsX && z > gtZ && z < lsZ) {
            callback();
            this.removeAnimationElement(object3D.name);
            this.removeAnimationElement(_controls.name);
        }
    }

    sitDownUser() {
        const { x, y, z } = INIT_CAMERA_POSITION;
        _camera.position.set(x, y, z);
        _camera.rotation.set(0, 1.1615166172022264, 0);
    }


    getWorkmateArea() {
        return [WORKMATE_NAME, { minZ: -0.8, maxZ: 2.5 }]
    }

    createClickSprite(task, action, isSearchable = false) {
        const sprite = this.createSpriteByTask(task, isSearchable);
        _pointer.setActionTask(action);
        if (!sprite) return null;
        _scene.add(sprite)
    }

    moveUser(task, action) {
        this.enableMovement();
        const [objectName, limitPosition] = this.getWorkmateArea()
        const removeElement = () => this.removeHelpingIcon(action, objectName)
        this.createClickSprite(task, removeElement, true);
        const object3D = _scene.getObjectByName(objectName);
        const eliminateTick = () => this.removeAnimationElement(objectName);
        object3D.tick = () => this.limitZoneHelpingIcon(object3D, eliminateTick, limitPosition);
        _animations.appendElement(object3D);

    }
    addSpriteByTask(task, isSearchable = false) {
        if (task.type !== "click") return;
        const iconLabel = this.createSpriteByTask(task, isSearchable);
        if (!iconLabel) return;

        _scene.add(iconLabel);

    }
    createSearchableSprite(task, action) {
        const [objectName, limitPosition] = this.getWorkmateArea();
        const removeSprite = () => this.removeHelpingIcon(action, objectName);
        this.createClickSprite(task, removeSprite, true);

        return { limitPosition, objectName };
    }

    removeHelpingIcon(callback, objectName) {
        callback();
        _scene.remove(_scene.getObjectByName(objectName));
        _pointer.removeAction();
    }

    addZoneAnimation({ objectName, limitPosition }) {
        const object = _scene.getObjectByName(objectName);
        if (!object) return;
        const eliminateTick = () => this.removeAnimationElement(objectName);
        object.tick = () => this.limitZoneHelpingIcon(object, eliminateTick, limitPosition);
        _animations.appendElement(object);
    }
    removeAnimationElement(objectName = "") {
        _animations.removeElement(objectName);
    }
    limitZoneHelpingIcon(object3D, callbackEliminate, limitPosition) {
        const { minZ, maxZ, minX = 3.3 } = limitPosition;
        if (object3D.material.visible) return;
        if (_camera.position.z > minZ && _camera.position.z < maxZ && _camera.position.x > minX) {
            _pointer.setActive(true);
            object3D.material.visible = true;
            callbackEliminate();
            _pointer.setActive(true);

        }
    }

    listeners(container) {
        window.addEventListener("resize", () => {
            setSize(_camera, _renderer, container, _labelRenderer);
        });
    }
}