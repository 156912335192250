const useActiveConversation = (messages, index) => {
	let isActive = true;
	if (messages === null) isActive = false;
	else if (index === null) isActive = false;
	else if (messages.length === 0) isActive = false;
	else if (messages[index] === undefined) isActive = false;

	return { isVisible: isActive };
};

export default useActiveConversation;
