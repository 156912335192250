const Modal = ({ children }) => {
    return (
        <div
            className="w-full h-full absolute flex justify-center  items-center top-0 left-0 "
            style={{ zIndex: 3010, background: "rgba(0,0,0,0.6)" }}
        >
            {children}
        </div>
    );
};

export default Modal;
