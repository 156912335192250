import { useCallback, useEffect, useState } from "react";
import { useSaveAnswer, useUserActions } from '../../hooks';
import { usePDFAnswers } from '../../hooks/cms';
import { LogoEscuela } from "../../assets";
import { Button } from "../../components";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const Final = ({ isIntroduction = false }) => {
    const { printManyAdmin, createIntroduction } = usePDFAnswers();
    const { updateAnswers } = useSaveAnswer();
    const [pdfData, setPdfData] = useState([]);
    const { finishUserData } = useUserActions()
    const [isLoading, setIsLoading] = useState(false);

    const getExcelData = useCallback(async () => {
        const response = await updateAnswers();
        const { payload } = response
        setPdfData(payload.data);
    }, [updateAnswers])

    useEffect(() => {
        if (isIntroduction) return
        setIsLoading(true)
        getExcelData().finally(() => setIsLoading(false))
    }, [getExcelData, isIntroduction]);

    const closeIntroduction = async () => {
        await createIntroduction();
        window.location.href = 'https://itesm.co1.qualtrics.com/jfe/form/SV_bEmmELnpwM86id8';

    }

    const closeApp = async () => {

        try {
            if (isIntroduction) {
                closeIntroduction()
                return;
            }
            await printManyAdmin(pdfData)
            window.location.href = 'https://itesm.co1.qualtrics.com/jfe/form/SV_9pEcLQWdu9OeBls';
            finishUserData()
        } catch (error) {
            alert(error.message)
        }
    }

    return (
        <div className="w-full  h-full  flex justify-center items-center flex-col">
            <img src={LogoEscuela} alt="logo tec de monterrey" className="h-11 object-cover" />
            <div className="w-full flex flex-col items-center justify-center pt-8 pb-6">
                <h3 className="font-bold text-[24px] lg:text-[32px] text-white w-1/2 text-center mb-2 lg:mb-4">
                    {getJsonTextLanguageDisplay("despedida")}
                </h3>
                <p className="font-GraphikMedium text-[14px] lg:text-[18px] text-white">{getJsonTextLanguageDisplay("task_despedida")}</p>

            </div>
            {
                !isLoading && <Button onClick={closeApp}>
                    {getJsonTextLanguageDisplay("firmar_salida")}
                </Button>
            }

        </div >

    );
};

export default Final;
