import React, { useEffect, useState } from 'react'
import { useStoreLanguage } from '../hooks/store';
import { LanguageActions } from '../hooks/LanguageActions';
import { PrincipalLogo, WhiteCircle } from '../components';
import { logoInstitutefor } from '../assets';
import { useLocation } from 'react-router-dom';


const year = new Date().getFullYear()

export const PageSelecionLanguage = () => {
    const [isSelectLanguage, setIsSelectLanguage] = useState(false)
    const { languageEnable } = useStoreLanguage();
    const { updateCodeLanguage } = LanguageActions()

    const location = useLocation();

    // selectCodeLanguage, viene de redux
    const goToExperience = (code = languageEnable.es) => {
        if (["/introduction", "/introduction/"].includes(location.pathname)) {
            window.location.href = `/#/introduction/init?lang=${code}`;
        } else {
            window.location.href = `/#/init?lang=${code}`;
        }
        // window.open(`/#/init?lang=${code}`, '_blank');
        // window.close();

    }

    const selectLanguageAction = (code = languageEnable.es) => {
        if (isSelectLanguage) {
            return
        }
        setIsSelectLanguage(true)
        updateCodeLanguage(code)
        localStorage.setItem("activeLanguage", "recarga")
        setTimeout(() => {
            goToExperience(code)
        }, 900);
    }

    useEffect(() => {
        localStorage.removeItem("language")
        localStorage.removeItem("jsonTextLanguage")
        localStorage.removeItem("activeLanguage")
    }, [])


    return (
        <>
            <div className="w-full h-screen flex flex-col pt-[72px] xl:pt-[120px] md:pb-[12px]">
                <div className="flex justify-center">
                    <div className="w-[232px]">
                        <PrincipalLogo />
                    </div>
                    <div className="w-[246px] lg:w-[246px] h-[46px] ml-[40px]">
                        <img src={logoInstitutefor} alt="Tec de monterrey logo" className="w-full object-cover" />
                    </div>
                </div>
                <div className="flex flex-col grow justify-center items-center gap-10">
                    <div>
                        <h2 className="main-title text-center text-[32px] xl:text-[36px] font-GraphikBold ">
                            Selecciona tu idioma preferido
                        </h2>
                        <div className='text-[16px] text-white mt-2 flex justify-center items-center'>
                            <p>Select your preferred language</p>
                        </div>
                    </div>
                    <div className="w-full flex justify-center gap-12 home-button">
                        <ButtonLanguage
                            // img={Icons.icon_mexico}
                            disabled={isSelectLanguage}
                            action={() => {
                                selectLanguageAction(languageEnable.es)
                            }}>
                            Español
                        </ButtonLanguage>
                        <ButtonLanguage
                            // img={Icons.icon_usa}
                            disabled={isSelectLanguage}
                            action={() => {
                                selectLanguageAction(languageEnable.en)
                            }}>
                            English
                        </ButtonLanguage>
                    </div>
                </div>
                <div className="mt-auto self-center">
                    <p className="footer-home text-center font-GraphikRegular text-[14px] ">
                        Por: Dra. Gabriela Monforte García<br />
                        Traducción por: Dr. Jesús Ávila Martínez<br />
                        D.R. Instituto Tecnológico y de Estudios Superiores de Monterrey, México, {year}
                    </p>
                </div>
            </div>
            <WhiteCircle />
        </>
    )
}


const ButtonLanguage = ({ children, action, disabled = false, img = "" }) => {
    return (
        <>
            <div onClick={action} className={`h-[58px] w-[240px] ${disabled ? "bg-gray-light cursor-none" : "bg-organge-task cursor-pointer"}  rounded-[10px] py-2 px-8`}>
                <div className='w-full h-full flex items-center gap-6 justify-center'>
                    {/* <div className='h-[40px] w-[40px]overflow-hidden'>
                        <img className='h-full w-full object-contain' src={img} alt="icon" />
                    </div> */}
                    <p className='text-[18px] text-white font-semibold'>{children}</p>
                </div>
            </div>
        </>
    )
}