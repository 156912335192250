import { checkAllKeysForTranslationRepetition } from "./helperCheckKeyTranslate"

const graficas1Modulo5 = {
    graficas_1_1_caso_1_boton_selecionar_grafica_1: {
        es: "Seleccionar",
        en: "Select"
    },
    // caso 1 pregunta
    graficas_1_caso_1_grafica_gerente_card_contenido: {
        es: "Dígame cuál de las siguientes gráficas muestra la proporción de los clientes actuales que podrán ser usuarios de la nueva tarjeta debido a que no tienen un trabajo remunerado.",
        en: "Tell me which of the following graphs shows the proportion of current customers who may be eligible for the new card because they don't have paid employment."
    },
    graficas_1_caso_1_boton_plegable_grafica_1: {
        es: "¿Cuál de las siguientes gráficas muestra la proporción de los clientes actuales que podrán ser usuarios de la nueva tarjeta debido a que no tienen un trabajo remunerado? Selecciona la gráfica correcta.",
        en: "Which of the following graphs shows the proportion of current customers who may be eligible for the new card because they don't have paid employment? Select the correct graph."
    },
    // caso 2 preguntas
    graficas_1_caso_2_grafica_gerente_card_contenido: {
        es: "Dígame cuál de las siguientes gráficas es evidencia de que no hay una diferencia importante entre las proporciones de clientes hombres respecto a clientes mujeres que no tienen trabajo remunerado.",
        en: "Tell me which of the following graphs is evidence that there is no significant difference between the proportions of male customers compared to female customers who do not have paid work."
    },
    graficas_1_caso_2_boton_plegable_grafica_1: {
        es: "¿Cuál de las siguientes gráficas es evidencia de que no hay una diferencia importante entre las proporciones de clientes hombres respecto a clientes mujeres que no tienen trabajo remunerado? Selecciona la gráfica correcta.",
        en: "Which of the following graphs provides evidence that there is no significant difference between the proportions of male clients compared to female clients who do not have paid employment? Select the correct graph."
    },

    // GRAFICAS 1 2 3 CASO 1 Y 2
    graficas_1_1_caso_1_2_card_titulo_grafica_1: {
        es: "Tipo de actividad de los clientes actuales",
        en: "Type of activity of current clients"
    },
    graficas_1_2_caso_1_2_card_titulo_grafica_1: {
        es: "Género de los clientes actuales",
        en: "Gender of current clients"
    },
    graficas_1_3_caso_1_2_card_titulo_grafica_1: {
        es: "Clientes por género dado su tipo de actividad",
        en: "Clients by gender given their type of activity"
    },
    // final
    graficas_1_dialogo_jefe_final_1: {
        es: "Gracias. Continuemos con la siguiente pregunta.",
        en: "Thank you. Let's continue with the next question."
    },
}

const graficas2Modulo5 = {
    // caso 1 pregunta
    graficas_2_caso_1_grafica_gerente_card_contenido: {
        es: "Ahora dígame cuál es la proporción de clientes actuales que realizan sus compras de productos básicos en mercados y supermercados.",
        en: "Now tell me what is the proportion of current clients who make their purchases of basic products in markets and supermarkets."
    },
    graficas_2_caso_1_boton_plegable_grafica_2: {
        es: "¿Cuál es la proporción de clientes actuales que realizan sus compras de productos básicos en mercados y supermercados? Selecciona la gráfica correcta.",
        en: "What is the proportion of current clients who make their purchases of basic products in markets and supermarkets? Select the correct graph."
    },

    // caso 2 preguntas
    graficas_2_caso_2_grafica_gerente_card_contenido: {
        es: "Ahora dígame cuál es la proporción de clientes que compran en el supermercado comparando a México con CDMX.",
        en: "Now tell me what is the proportion of customers who shop at the supermarket comparing Mexico State with Mexico City."
    },
    graficas_2_caso_2_boton_plegable_grafica_2: {
        es: "¿Cuál es la proporción de clientes que compran en el supermercado comparando a México con CDMX? Selecciona la gráfica correcta.",
        en: "What is the proportion of customers who shop at the supermarket comparing Mexico State with Mexico City? Select the correct graph."
    },

    // GRAFICAS 1 2 3 CASO 1 Y 2
    graficas_2_1_caso_1_2_card_titulo_grafica_2: {
        es: "Proporción de clientes que realizan sus compras en los diferentes establecimientos",
        en: "Proportion of clients who make their purchases in different establishments"
    },
    graficas_2_2_caso_1_2_card_titulo_grafica_2: {
        es: "Cantidad de personas que realiza sus compras en cada establecimiento por sexo",
        en: "Number of people who make their purchases at each establishment by gender"
    },
    graficas_2_3_caso_1_2_card_titulo_grafica_2: {
        es: "Cantidad de personas que realiza sus compras en cada establecimiento por entidad",
        en: "Number of people who make their purchases at each establishment by state"
    },
    // final
    graficas_2_dialogo_jefe_final_1: {
        es: "Gracias. Continuemos con la siguiente pregunta.",
        en: "Thank you. Let's continue with the next question."
    },
}

const graficas3Modulo5 = {
    // caso 1 pregunta
    graficas_3_caso_1_grafica_gerente_card_contenido: {
        es: "Indíqueme la proporción de clientes actuales que realizan transacciones por cantidades menores a $1500 pesos al día",
        en: "Indicate the proportion of current customers who make transactions for amounts less than $1500 pesos per day."
    },
    graficas_3_caso_1_boton_plegable_grafica_3: {
        es: "¿Cuál es la proporción de clientes actuales que realizan transacciones por cantidades menores a $1,500 pesos al día? Selecciona la gráfica correcta.",
        en: "What is the proportion of current customers who make transactions for amounts less than $1,500 pesos per day? Select the correct graph."
    },

    // caso 2 preguntas
    graficas_3_caso_2_grafica_gerente_card_contenido: {
        es: "Indíqueme la proporción de clientes actuales que realizan tres y cuatro transacciones al día.",
        en: "Indicate the proportion of current customers who make three and four transactions per day."
    },
    graficas_3_caso_2_boton_plegable_grafica_3: {
        es: "¿Cuál es la proporción de clientes actuales que realizan tres y cuatro transacciones al día? Selecciona la gráfica correcta.",
        en: "What is the proportion of current customers who make three and four transactions per day? Select the correct graph."
    },

    // GRAFICAS 1 2 3 CASO 1 Y 2
    graficas_3_1_caso_1_2_card_titulo_grafica_3: {
        es: "Cantidad de clientes que realizan transacciones por cantidades menores a $1500 pesos por día.",
        en: "Number of customers who make transactions for amounts less than $1500 pesos per day."
    },
    graficas_3_2_caso_1_2_card_titulo_grafica_3: {
        es: "Cantidad de clientes que realizan desde 1 hasta más de 5 transacciones al día.",
        en: "Number of customers who make from 1 to more than 5 transactions per day."
    },
    graficas_3_3_caso_1_2_card_titulo_grafica_3: {
        es: "Cantidad de productos financieros utilizados por cliente.",
        en: "Number of financial products used per customer."
    },
    // final
    graficas_3_dialogo_jefe_final_1: {
        es: "Gracias. Continuemos con la siguiente pregunta.",
        en: "Thank you. Let's continue with the next question."
    },
}


const graficas4Modulo5 = {
    // caso 1 pregunta
    graficas_4_caso_1_grafica_gerente_card_contenido: {
        es: "Necesito evidencia de que los clientes que son candidatos para adquirir la nueva tarjeta tienen un historial crediticio favorable.",
        en: "I need evidence that customers who are candidates to acquire the new card have a favorable credit history."
    },
    graficas_4_caso_1_boton_plegable_grafica_4: {
        es: "El gerente necesita evidencia de que los clientes que son candidatos para adquirir la nueva tarjeta tienen un historial crediticio favorable.  Selecciona la descripción correcta.",
        en: "The manager needs evidence that customers who are candidates to acquire the new card have a favorable credit history. Select the correct description."
    },

    // caso 2 preguntas
    graficas_4_caso_2_grafica_gerente_card_contenido: {
        es: "Necesito conocer la proporción de clientes del BCJ que tienen un historial crediticio favorable.",
        en: "I need to know the proportion of Banco Creciendo Juntos customers who have a favorable credit history."
    },
    graficas_4_caso_2_boton_plegable_grafica_4: {
        es: "El gerente requiere conocer la proporción de clientes del BCJ que tienen un historial crediticio favorable. Selecciona la descripción correcta.",
        en: "The manager needs to know the proportion of Banco Creciendo Juntos customers who have a favorable credit history. Select the correct description."
    },

    // GRAFICAS 1 2 3 CASO 1 Y 2
    graficas_4_1_caso_1_2_card_titulo_grafica_4: {
        es: "Cantidad de clientes que compran en los diferentes establecimientos y su calificación crediticia.",
        en: "Number of customers shopping at different establishments and their credit score."
    },
    graficas_4_2_caso_1_2_card_titulo_grafica_4: {
        es: "Cantidad de clientes que compran en los diferentes establecimientos y su calificación crediticia.",
        en: "Number of customers shopping at different establishments and their credit score."
    },
    graficas_4_3_caso_1_2_card_titulo_grafica_4: {
        es: "Cantidad de clientes que compran en los diferentes establecimientos y su calificación crediticia.",
        en: "Number of customers shopping at different establishments and their credit score."
    },
    // final
    graficas_4_dialogo_jefe_final_1: {
        es: "Gracias. Continuemos con la siguiente pregunta.",
        en: "Thank you. Let's continue with the next question."
    },
}

const graficas5Modulo5 = {
    // caso 1 pregunta
    graficas_5_caso_1_grafica_gerente_card_contenido: {
        es: "Ahora me gustaría conocer la solvencia promedio más alta de los clientes regulares que son candidatos a la nueva tarjeta de crédito.",
        en: "Now I would like to know the highest average solvency of regular customers who are candidates for the new credit card."
    },
    graficas_5_caso_1_boton_plegable_grafica_4: {
        es: "El gerente requiere conocer la solvencia promedio más alta de los clientes regulares que son candidatos a la nueva tarjeta de crédito. Selecciona la descripción correcta.",
        en: "The manager needs to know the highest average solvency of regular customers who are candidates for the new credit card. Select the correct description."
    },

    // caso 2 preguntas
    graficas_5_caso_2_grafica_gerente_card_contenido: {
        es: "Ahora me gustaría conocer la solvencia promedio más alta de los clientes regulares del BCJ.",
        en: "Now, I would like to know the highest average solvency of regular customers of Banco Creciendo Juntos."
    },
    graficas_5_caso_2_boton_plegable_grafica_4: {
        es: "El gerente requiere conocer la solvencia promedio más alta de los clientes regulares del BCJ. Selecciona la descripción correcta.",
        en: "The manager needs to know the highest average solvency of regular customers of Banco Creciendo Juntos. Select the correct description."
    },

    // GRAFICAS 1 2 3 CASO 1 Y 2
    graficas_5_1_caso_1_2_card_titulo_grafica_4: {
        es: "Saldo vigente promedio por calificación crediticia vs donde compran ",
        en: "Average current balance by credit rating vs where they shop"
    },
    graficas_5_2_caso_1_2_card_titulo_grafica_4: {
        es: "Saldo vigente promedio por calificación crediticia vs donde compran ",
        en: "Average current balance by credit rating vs where they shop"
    },
    graficas_5_3_caso_1_2_card_titulo_grafica_4: {
        es: "Saldo vigente promedio por calificación crediticia vs donde compran ",
        en: "Average current balance by credit rating vs where they shop"
    },
    // final
    graficas_5_dialogo_jefe_final_1: {
        es: "Gracias. Hemos terminado nuestra junta sobre la propuesta de la nueva tarjeta.",
        en: "Thank you. We have finished our meeting on the proposal for the new card."
    },
}

export const todasLasGraficas = checkAllKeysForTranslationRepetition({
    graficas1Modulo5,
    graficas2Modulo5,
    graficas3Modulo5,
    graficas4Modulo5,
    graficas5Modulo5
})


