import { createPortal } from "react-dom";

const CustomModal = ({ children, isVisible, closeModal }) => {
    if (!isVisible) return <></>
    return createPortal(
        <div className="w-full h-full absolute top-0 flex justify-center items-center z-50 bg-[rgba(0,0,0,0.3)] " onClick={closeModal}>
            <div className="w-full max-w-[918px] h-fit " onClick={(event) => event.stopPropagation()}>
                {children}
            </div>,
        </div>,
        document.getElementById("modal-root")
    );
}

export default CustomModal;