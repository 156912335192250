import { textsToTranslate } from "../language";

export const getTextsByLanguageCode = (language = localStorage.getItem("language") || "es") => {
    let setTextsFromTheTranslation = {}
    Object.entries(textsToTranslate).forEach(([key, value]) => {
        setTextsFromTheTranslation = {
            ...setTextsFromTheTranslation,
            [key]: value[language]
        }
    })
    localStorage.setItem("language", language)
    localStorage.setItem("jsonTextLanguage", JSON.stringify(setTextsFromTheTranslation))
    return setTextsFromTheTranslation
}

const interfaceReplaceText = { "[replace1]": "c-0" }

const patternsToReplace = /\[replace\d\]/g;

const getTextOfSelectedLanguage = (jsonTextLanguage = [], selectedTextLocation = "", replaceText = interfaceReplaceText) => {
    const selectedText = jsonTextLanguage[selectedTextLocation];

    if (!selectedText) {
        return "";
    }

    const matchReplaceTextInTextSelect = selectedText.match(patternsToReplace);

    if (!matchReplaceTextInTextSelect) {
        return selectedText;
    }

    let newText = selectedText;

    matchReplaceTextInTextSelect.forEach(matchText => {
        const replacementText = replaceText[matchText];
        if (replacementText) {
            newText = newText.replace(matchText, replacementText);
        }
    });

    return newText;
}

const getLocalStorageJsonTextLanguage = () => {
    return JSON.parse(localStorage.getItem("jsonTextLanguage"))
}


export const getJsonTextLanguageDisplay = (selectedTextLocation = "", replaceText = interfaceReplaceText) => {
    const jsonTextLanguage = getLocalStorageJsonTextLanguage()
    if (!jsonTextLanguage) {
        return ""
    }

    return getTextOfSelectedLanguage(jsonTextLanguage, selectedTextLocation, replaceText)
}

