import { ComputerSectionCorrectAnswer, workmateRetroCorrectAnswer } from "../computer/state";
import { idTaskFirstQuestion, initStatusQuestions } from "../../../types";
import { doesJuanHaveLowScore, isJuanAdult } from "../../../data";
import { tableConceptsQuestion } from "../../../data/concepts";
import { meetingPresentationReact, menReact8, womanReact7, workmateReact5 } from "../task/helper";
import tasks from "../task/tasks";

const getAnswerClient = (type) => {
    switch (type) {
        case "11": {
            return 1;
        }
        case "10": {
            return 2;
        }
        case "00": {
            return 3;
        }
        case "01": {
            return 3;
        }
        default:
            new Error("Type case invalid");
    }
};


export const getInitState = () => {
    const { isClient, DoesParticipate, personRetro1 } = initStatusQuestions;
    const type = `${isClient ? 1 : 0}${DoesParticipate ? 1 : 0}`;
    let typeCase = type;
    let correctAnswer = getAnswerClient(type);
    const questionConceptRandom = tableConceptsQuestion;
    const notClientAge = isJuanAdult ? 1 : 0;
    const lendMoney = doesJuanHaveLowScore ? 1 : 0;
    const firstRetroCorrect = personRetro1.responseAnswer;
    return {
        information: null,
        character: null,
        firtsClient: {
            DoesParticipate: DoesParticipate === 0,
            isClient: isClient === 0,
        },
        concepts: questionConceptRandom.concepts,
        typeCase,
        questions: {
            question: {
                taskId: idTaskFirstQuestion,
                name: "Reactivo 1",
                answer: null,
                correctAnswer,
                isCorrect: false,
                retro: {
                    id: 1,
                    name: "Reactivo 1.1",
                    taskId: 10,
                    wasUsed: false,
                    correctAnswer: firstRetroCorrect,
                    answer: null,
                    type: personRetro1.isClient
                },
                message: {
                    "3": ["Respuesta correcta", "El estudiante pudo tomar decisiones identificando y aplicando los conceptos de población y muestra: Distingue a la población, que para el caso de estudio son los clientes registrados, de su complemento, que para el caso de estudio son los clientes no registrados. Además, reconoce a un evento transversal a la población, que para el caso de estudio es la muestra de individuos que participaron en el estudio de la nueva tarjeta electrónica."],
                    "2": ["El cliente además de pertenecer a la población de clientes registrados formó parte de la muestra de clientes para el estudio de la Nueva Tarjeta Electrónica, debe recibir de regalo el electrodoméstico. ", "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, o porque no reconocer el concepto de muestra, expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica. "],
                    "0": ["El cliente pertenecer a la población de clientes registrados y formó parte de la muestra de clientes para el estudio de la Nueva Tarjeta Electrónica, debe recibir de regalo el electrodoméstico. ", "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, y porque no reconocer el concepto de muestra expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica. "]
                }
            },
            questionConcept: {
                taskId: 13,
                name: "Reactivo 2",
                answer: null,
                correctAnswer: questionConceptRandom.correctAnswer,
                selectedConcept: [],
                isCorrect: false,
                retro: {
                    id: 2,
                    name: "Reactivo 2.2",
                    taskIdId: 13,
                    wasUsed: false,
                    times: 0,
                },
            },
            histograma: {
                taskId: 17,
                name: "Reactivo 3",
                answer: null,
                correctAnswer: isClient ? lendMoney : notClientAge, // option onse
                isCorrect: false,
            },
            meetingDocuments: {
                taskId: 20,
                name: "Reactivo 4",
                answer: null,
                correctAnswer: ComputerSectionCorrectAnswer,
                selected: null,
                isCorrect: false,
                retro: {
                    id: null,
                    name: "Reactivo 4.1",
                    wasUsed: false,
                    times: 0,
                },
            },
            workmateQuestion: {
                taskId: 24,
                name: "Reactivo 5",
                answer: null,
                // correctAnswer: workmateCorrectAnswer,
                correctAnswer: workmateReact5.reponseNumber,
                wasUsed: false,
                isCorrect: false,
                retro: { id: 3, name: "Reactivo 5.1", taskId: 10, wasUsed: false, correctAnswer: workmateRetroCorrectAnswer, answer: null },
            },
            sliceQuestion: {
                taskId: tasks.TaskSelectSlice.id,
                name: "Reactivo 6",
                currentIndex: 0,
                presentation: meetingPresentationReact.slices,
            },
            womanQuestion: {
                taskId: tasks.TaskFinalConversationWoman.id,
                name: "Reactivo 7",
                answer: null,
                // correctAnswer: false, // respuesta
                correctAnswer: womanReact7.reponseCorrectOpt,
                isCorrect: false,
            },
            manCreditQuestion: {
                taskId: tasks.TaskCreditMan.id,
                name: "Reactivo 8",
                answer: null,
                // correctAnswer: false,
                correctAnswer: menReact8.reponseCorrectOpt,
                isCorrect: false,
            },
            manNewCreditQuestion: {
                taskId: tasks.TaskNewCreditMan.id,
                name: "Reactivo 9",
                answer: null,
                correctAnswer: false,
                isCorrect: false,
            },
        },
        notificationUser: false,
        time: 0,
        answerId: null
    };
};
