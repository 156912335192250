import { Button } from "../../components";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";
import { useComputerActions } from "../../hooks";
import { Task_1_1, workmateComputer } from "../../store/slices/introduction/tasks";

const FooterButton = ({ taskInformation }) => {
    const { currentTask, section, openEmail, selectedEmail, selectedSection, selectedDate, changeTask } = taskInformation;
    const { selectComputerSection } = useComputerActions();
    if (!selectedSection && currentTask.section !== 'workmate') return <></>
    return (
        <>

            {
                currentTask.id === Task_1_1.id && section === 'Correo' && < div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            onClick={() => {
                                if (selectedEmail)
                                    return openEmail(null)
                                selectComputerSection(null);
                            }}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("tarea_goback")}
                        </Button>
                    </div>
                </div >
            }
            {
                currentTask.id === workmateComputer.id && < div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            onClick={() => changeTask()}
                            disabled={selectedDate == null}
                            isDisabled={selectedDate == null}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_boton")}
                        </Button>
                    </div>
                </div >
            }
        </>
    );
}

export default FooterButton;