

import { useDispatch } from 'react-redux'
import { setActiveModalWarnnigTime, setAddTotalNumberSecondOportunity, setBonusPoints, setBonusReagentTime, setNumberTasksAnswered, setTaskBonusSecondReactive } from '../store/slices/bonustime';

export const BonusActions = () => {
    const dispatch = useDispatch()

    const setTimeBonusActivity = (id, time, response) => {
        dispatch(setBonusReagentTime({ id, time, response }))
    }

    const setTimeBonusActivitySecond = (id, time, response) => {
        dispatch(setTaskBonusSecondReactive({ id, time, response }))
    }

    const setUpdateNumberBonusSecondOpotunity = (number) => {
        dispatch(setAddTotalNumberSecondOportunity(number))
    }

    const setUpdateNumberTaskAnswered = (number) => {
        dispatch(setNumberTasksAnswered(number))
    }

    const setActiveModalWarning = (value = false) => {
        dispatch(setActiveModalWarnnigTime(value))
    }

    const updateBonusPoints = (number = 0) => {
        dispatch(setBonusPoints(0))
    }

    return { setTimeBonusActivity, setActiveModalWarning, setUpdateNumberTaskAnswered, setUpdateNumberBonusSecondOpotunity, setTimeBonusActivitySecond, updateBonusPoints }
}
