export const modulo4Seccion3Companero = {
    tarea_1_companero_ayuda_escritorio_boton_plegable_1: {
        es: `"¡Tu compañero necesita ayuda! Dirígete a su oficina que se encuentra a tu lado derecho para ayudarle antes de tu junta."`,
        en: `"Your colleague needs help! Head to their office, which is located on your right, to assist them before your meeting."`
    },
    //? tarea 2
    tarea_2_revisa_computadora_companero_boton_plegable_1: {
        es: "Revisa la computadora de tu compañero.",
        en: "Check your colleague's computer."
    },
    tarea_2_revisa_computadora_companero_card_text_titulo: {
        es: "Ejecutivo",
        en: "Executive"
    },
    tarea_2_revisa_computadora_companero_card_text_contenido: {
        es: "Hola, tengo una duda: tres clientes me solicitan la apertura de una cuenta, pero solo puedo abrir una cuenta nueva. ¿Cuál cliente tiene mayor capacidad de pago?",
        en: "Hello, I have a question: three clients are requesting the opening of an account, but I can only open one new account. Which client has the highest payment capacity?"
    },
    //? tarea 3
    tarea_3_revisa_computadora_companero_boton_plegable_1: {
        es: "Revisa la computadora de tu compañero.",
        en: "Check your colleague's computer."
    },
    //? tarea 4 
    // caso 1
    tarea_4_casos_1_tabla_boton_plegable_1: {
        es: "Partiendo de que las variables gasto-promedio e ingreso-promedio se distribuyen de manera normal y son independientes, elige al cliente con mayor probabilidad de cumplir con sus pagos.",
        en: "Based on the assumption that the average spending and average income variables are normally distributed and independent, choose the client with the highest probability of meeting their payments."
    },
    tarea_4_casos_1_tabla_fila_1_col_1: {
        es: "Ingreso promedio",
        en: "Average income"
    },
    tarea_4_casos_1_tabla_fila_1_col_2: {
        es: "Gasto promedio",
        en: "Average spending"
    },
    tarea_4_casos_1_tabla_fila_2_col_1: {
        es: "Cliente",
        en: "Client"
    },
    tarea_4_casos_1_tabla_fila_2_col_2: {
        es: "Media",
        en: "Mean"
    },
    tarea_4_casos_1_tabla_fila_2_col_3: {
        es: "Desv. est",
        en: "Standard deviation"
    },
    tarea_4_casos_1_tabla_fila_2_col_4: {
        es: "Media",
        en: "Mean"
    },
    tarea_4_casos_1_tabla_fila_2_col_5: {
        es: "Desv. est",
        en: "Standard deviation"
    },
    tarea_4_casos_1_boton_1: {
        es: "Elegir",
        en: "Select"
    },
    // caso 2
    // segunda oportunidad
    tarea_4_segunda_oportunidad_contenido_texto_1: {
        es: "Y de los tres clientes, ¿cuál crees que sea el que tiene la probabilidad más alta de no cumplir con los pagos de su tarjeta de crédito en función de su balance ingreso / gasto?",
        en: "And of the three clients, which one do you think has the highest probability of not meeting their credit card payments based on their income-to-spending balance?"
    },
    tarea_4_segunda_oportunidad_boton_plegable_1: {
        es: "Partiendo de que las variables gasto-promedio e ingreso-promedio se distribuyen de manera normal y son independientes, elige al cliente con menor probabilidad de cumplir con sus pagos.",
        en: "Assuming that the average spending and average income variables are normally distributed and independent, choose the client with the lowest probability of meeting their payments."
    },
    tarea_4_segunda_oportunidad_boton_plegable_2: {
        // antes Has elegido la opción incorrecta. El cliente (n) no tenía la probabilidad más alta de no cumplir con los pagos de su tarjeta de crédito. Da click en ‘’X’’ para salir.
        es: "Partiendo de que las variables gasto-promedio e ingreso-promedio se distribuyen de manera normal y son independientes, elige al cliente con menor probabilidad de cumplir con sus pagos.",
        en: "Assuming that the average spending and average income variables are normally distributed and independent, choose the client with the lowest probability of meeting their payments."
    },
    tarea_4_segunda_oportunidad_boton: {
        es: "Elegir",
        en: "Select"
    },
    //? final
    companero_final_card_contenido: {
        es: "¡Gracias por ayudarme!",
        en: "Thank you for helping me!"
    },
}