import { useStoreTask } from "../../hooks/store";


const getHour = (taskId) => {
    if (taskId > -1 && taskId <= 11) {
        return "9:58";
    }
    if (taskId > 11 && taskId <= 15) {
        return "10:00";
    }
    if (taskId > 15 && taskId <= 27) {
        return "10:55";
    }
    if (taskId > 27 && taskId <= 42) {
        return "11:00";
    }
    return "11:55";
};
const Header = () => {
    const { tasks } = useStoreTask();
    const currenTask = tasks[0];

    return (
        <div className="flex w-full  items-end  justify-between ">
            <div className="flex items-center justify-end w-full   ">
                <div className=" flex justify-end">
                    <p className="avatar-text font-GraphikSemibold text-[32px]">{getHour(currenTask.id)}</p>
                </div>
            </div>
        </div>
    );
};

export default Header;
