import { animations, LogoEscuela } from "../assets";
const year = new Date().getFullYear()

const LoaderCms = () => {
    return (
        <div className="w-full h-full flex items-center  flex-col">
            <div className="w-[608px] h-[120px] p-1 mb-[103px] mt-40 bg-background-screen">
                <img src={LogoEscuela} alt="Logo con el nombre de la universidad" />
            </div>
            <div>
                <img className="w-20" src={animations.loading} alt="Puntos animados que aparecen mientras carga la página" />
            </div>
            <div className="mt-auto mb-8">
                <p className="text-white text-center">D.R. Instituto Tecnológico y de Estudios Superiores de Monterrey, México, {year}</p>
            </div >
        </div>
    );
}

export default LoaderCms;