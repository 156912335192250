import Stats from "three/examples/jsm/libs/stats.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { AmbientLight, Color, EquirectangularReflectionMapping, MathUtils, PerspectiveCamera, Scene, sRGBEncoding, WebGLRenderer } from "three";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { CameraMovement } from "./MyControl";
import PointerTask from "./PointerTask";
import Renderer from "./Renderer";
import Animation from "./Loop";
import { path3D } from "../utils";

const createScene = () => {
    const scene = new Scene();
    scene.background = new Color("#0f4172");
    return scene;
};

const addHDR = (scene) => {
    new RGBELoader()
        .setPath(`${path3D}models/ligths/`)
        .load('HDR_Banco.hdr', function (texture) {
            texture.mapping = EquirectangularReflectionMapping;
            scene.environment = texture;
        });
}

const addStats = (container) => {
    const stats = new Stats();
    stats.domElement.id = "stats";
    stats.domElement.style.position = "absolute";
    stats.domElement.style.bottom = "0px";
    stats.domElement.style.width = "50px";
    stats.domElement.style.zIndex = 100;
    container.appendChild(stats.domElement);
    stats.tick = () => stats.update();

    return stats;
};

const createLigth = () => {
    return new AmbientLight(0xffffff, 0.4);
};

const createRenderer = (container) => {
    const width = container.clientWidth;
    const height = container.clientHeight;
    const renderer = new WebGLRenderer({ antialias: true });
    renderer.localClippingEnabled = true;
    renderer.outputEncoding = sRGBEncoding;
    renderer.setSize(width, height);
    container.append(renderer.domElement);

    return renderer;
};

const createCamera = (width, height, initPosition) => {
    const camera = new PerspectiveCamera(60, width / height, 0.1, 60);
    const { x, y, z } = initPosition;
    camera.position.set(x, y, z);
    camera.rotateY(MathUtils.degToRad(90));
    camera.rotateX(MathUtils.degToRad(-22));

    return camera;
};
const createOrbitControls = (camera, renderDom) => {
    const controls = new OrbitControls(camera, renderDom);
    controls.update();

    return controls;
};

const createMyControls = (camera, children) => {
    return new CameraMovement(camera, children);
};

const setSize = (camera, renderer, container, labelRenderer) => {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    camera.matrixWorldNeedsUpdate = true;
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
    if (labelRenderer) {
        labelRenderer.setSize(container.innerWidth, container.innerHeight);
    }
};

const createPointer = (scene, camera, container, actionTask) => {
    return new PointerTask(scene, camera, container, actionTask);
};
const startStructure = (container, initCameraPosition, actionTask) => {
    const width = container.clientWidth;
    const height = container.clientHeight;
    const scene = createScene();
    addHDR(scene)
    const camera = createCamera(width, height, initCameraPosition);
    const renderer = createRenderer(container);
    const controls = createMyControls(camera, scene.children);
    const pointer = createPointer(scene, camera, container, actionTask);

    return { scene, camera, renderer, controls, pointer };
};

export { createScene, createRenderer, createCamera, setSize, Animation, createLigth, Renderer, addStats, startStructure, createOrbitControls };
