import { useEffect, useState } from "react";
import { Task_1_1 } from "../../store/slices/introduction/tasks";
import { verifyMailIsEmpty } from "../../helpers/mails";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const useLabel = (taskInformation) => {
    const [label, setLabel] = useState(null);
    const { currentTask, selectedSection, openedEmail, mails } = taskInformation;

    useEffect(() => {
        if (currentTask.id === Task_1_1.id) {
            const isComplete = verifyMailIsEmpty(mails);
            if (isComplete)
                return setLabel(getJsonTextLanguageDisplay("demo_inicio_experencia_task_4_1"));
        }
        if (currentTask.id === Task_1_1.id && selectedSection === "Correo") {
            return setLabel(getJsonTextLanguageDisplay("demo_inicio_experencia_task_3_1"));
        }
        setLabel(currentTask.label)
    }, [currentTask, selectedSection, openedEmail, mails]);

    return {
        label
    }
}

export default useLabel;