import { memo } from "react";
import { IconBell, IconDocumentsClient, IconHistogram, IconRegister } from "../../assets";
import { useStoreComputer, useStoreTask } from "../../hooks/store";
import TableUserQuestion from "./TableUserQuestion";
import ClientSubSection from "./ClientSubSection";
import { useComputerActions } from "../../hooks";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const ClientSection = () => {
    const { tasks } = useStoreTask();
    const { selectedSection } = useStoreComputer();
    const { selectComputerSection } = useComputerActions();
    const isInSubfolder = selectedSection === "Documentación" || selectedSection === "Registro" || selectedSection === "Histograma";
    const task = tasks[0];

    if (task.id === 6) return <TableUserQuestion taskId={task.id} />;
    return (
        <>
            {isInSubfolder && <ClientSubSection subSection={selectedSection} taskId={task.id} />}
            {!isInSubfolder && (
                <div className="w-full  flex justify-around items-center h-full">
                    <MemoItem
                        action={() => selectComputerSection("Documentación", true)}
                        subSection={task.subSection}
                        icon={IconDocumentsClient}
                        text={"Documentación"}
                        textNewIteam={getJsonTextLanguageDisplay("tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_card_documentacion_1")}
                    />
                    <MemoItem
                        action={() => selectComputerSection("Registro", true)}
                        subSection={task.subSection}
                        icon={IconRegister}
                        text={"Registro"}
                        textNewIteam={getJsonTextLanguageDisplay("tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_card_registro_1")}
                    />
                    <MemoItem
                        action={() => selectComputerSection("Histograma", true)}
                        subSection={task.subSection}
                        icon={IconHistogram}
                        text={"Histograma"}
                        textNewIteam={getJsonTextLanguageDisplay("tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_card_histograma_1")}
                    />
                </div>
            )}
        </>
    );
};

const Item = ({ action, icon, text, subSection, textNewIteam = "" }) => {
    const isRequired = text === subSection;
    return (
        <div
            className={` px-[10px] py-[24px] flex justify-center items-center relative  rounded-lg icon-hover-effect${!isRequired ? "-" : ""
                }  bg-strong-blue ${isRequired ? "opacity-100 cursor-pointer" : "opacity-60"}`}
            style={{ width: "28%" }}
            onClick={() => {
                if (!isRequired) return;
                action();
            }}
        >
            <div className="flex flex-col  w-[132px] h-[176px] lg:h-[202px]">
                <div className="  w-full  h-[132px] lg:h-[154px] mb-[24px]">
                    <div className="h-full  flex items-center justify-center">
                        <img src={icon} alt="It shows a part of the computer" className={` h-full`} style={{ objectFit: "contain" }} />
                    </div>
                </div>
                <div className="flex justify-center items-center  grow">
                    <p className="font-GraphikRegular text-[16px] lg:text-[24px] text-white ">{textNewIteam} </p>
                </div>
                {isRequired && (
                    <div className="w-[28px] h-[32px] absolute top-3 left-3">
                        <img src={IconBell} alt="Showing you must check this section" />
                    </div>
                )}
            </div>
        </div>
    );
};

const MemoItem = memo(Item);
export default ClientSection;
