import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addingExtraTasks, addingDialoguesBoss, addRetroDialoguesMeeting, setNotificationStatus } from "../store/slices/task";

const useTaskActions = () => {
    const dispatch = useDispatch();

    const appendOptionalTasks = useCallback(() => {
        dispatch(addingExtraTasks());
    }, [dispatch]);

    const appendDialoguesBoss = useCallback(
        (areCorrectMeetingFiles, taskId, label) => {
            dispatch(addingDialoguesBoss({ areCorrectMeetingFiles, taskId, label }));
        },
        [dispatch]
    );

    const appendDialoguesMeeting = useCallback(
        (taskMessages) => {
            dispatch(addRetroDialoguesMeeting({ messages: taskMessages.dialogue, taskId: taskMessages.taskId }));
        },
        [dispatch]
    );
    const setComputerMessage = useCallback(
        ({ title, body }) => {
            dispatch(setNotificationStatus({ isActive: true, title, body }));
        },
        [dispatch]
    );

    return { appendOptionalTasks, appendDialoguesBoss, appendDialoguesMeeting, setComputerMessage };
};

export default useTaskActions;
