import { useSelector } from "react-redux";

const useStore = () => {
    const userStore = useSelector((state) => state.user);
    const useStoreTask = useSelector((state) => state.task);

    return { userStore, useStoreTask };
};

export const useStoreBonusTime = () => {
    const state = useSelector((state) => state.bonusTime);

    return { ...state };
};

export const useStoreLanguage = () => {
    const state = useSelector((state) => state.language);

    return { ...state };
};

export const useStoreTask = () => {
    const state = useSelector((state) => state.task);

    return { ...state };
};
export const useStoreUser = () => {
    const state = useSelector((state) => state.user);

    return { ...state };
};
export const useStoreComputer = () => {
    const state = useSelector((state) => state.computer);

    return { ...state };
};
export const useStoreGeneral = () => {
    const state = useSelector((state) => state.general);

    return { ...state };
};

export default useStore;
