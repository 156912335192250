import { useComputerActions, useUserActions } from "../../../hooks";
import HOCSubDocuments from "./HOCSubDocuments";
import { Directory } from "../../../assets";
import ComputerItem from "../ComputerItem";
import SubDocuments from "./SubDocuments";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

const Documents = ({ subSection }) => {
    const { selectFile } = useUserActions();
    const { selectComputerSection } = useComputerActions();
    const handlerClick = (section) => {
        selectComputerSection(section, true);
    };

    return (
        <>
            {subSection && (
                <SubDocuments>
                    <HOCSubDocuments Component={SubDocuments.Item} selectFile={selectFile} subSection={subSection} />
                </SubDocuments>
            )}
            {!subSection && (
                <div className="w-full h-full flex flex-col justify-center items-center pt-[2px] ">
                    <div className="w-full h-8 flex justify-center items-end  ">
                        <h3 className="text-white font-GraphikSemibold text-[18px] xl:text-[22px]">{getJsonTextLanguageDisplay("tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_seleciona_carpeta")}</h3>
                    </div>
                    <div className="w-full  flex justify-evenly items-center mt-4">
                        <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_credito_hipotecario")} isSubfolder action={() => handlerClick("hipotecario")} icon={Directory} text="Crédito hipotecario" />
                        <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_credito_automotriz")} isSubfolder action={() => handlerClick("automotriz")} icon={Directory} text="Crédito automotriz" />
                        <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_tarjeta_credito")} isSubfolder action={() => handlerClick("credito")} icon={Directory} text="Tarjeta de crédito" />
                        <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_seguro_vida")} isSubfolder action={() => handlerClick("vida")} icon={Directory} text="Seguro de vida" />
                    </div>
                </div>
            )}
        </>
    );
};

export default Documents;
