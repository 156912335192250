import { useEffect, useState, useRef } from "react";
import { BonusActions } from "./BonusActions";

export const useTimeTask = (isVerificateHrs = false) => {
    const { setActiveModalWarning } = BonusActions()
    const [timerView, setTimerView] = useState('0:00');
    const secondsRef = useRef(0);
    const minutesRef = useRef(0);
    const timeoutId = useRef(null);

    const playTimer = () => {
        // 2h = 120
        // 1h 55m = 115

        if (isVerificateHrs) {
            if (minutesRef.current === 85) {
                setActiveModalWarning(true)
            }

            if (minutesRef.current === 90) {
                window.location.href = "/";
                stopTimer()
                return
            }
        }

        const chronometer = `${minutesRef.current}:${secondsRef.current < 10 ? '0' : ''}${secondsRef.current}`;
        setTimerView(chronometer);

        if (secondsRef.current >= 60) {
            minutesRef.current++;
            secondsRef.current = 0;
        } else {
            secondsRef.current++;
        }
        timeoutId.current = setTimeout(playTimer, 1000);
    }

    const stopTimer = () => {
        clearTimeout(timeoutId.current);
    }

    const resetTimer = () => {
        secondsRef.current = 0;
        minutesRef.current = 0
        setTimerView('0:00')
        clearTimeout(timeoutId.current);
    }

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId.current);
        }
    }, [])

    return {
        timerView,
        playTimer,
        stopTimer,
        resetTimer
    }
}
