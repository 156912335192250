import {
	useMobileOrientation,
	useDeviceSelectors,
	browserName,
	fullBrowserVersion,
	isAndroid,
	isBrowser,
	isChrome,
	isChromium,
	isOpera,
	isDesktop,
	isEdge,
	isFirefox,
	isIOS,
	isMacOs,
	isSafari,
} from "react-device-detect";
/**
 *
 * @description This hook returns information about the device whom is used to use the app and cointains information about browser,
 * brand, version, os, etc
 */
const useDevice = () => {
	const { isLandscape, isPortrait } = useMobileOrientation();
	const [selectors] = useDeviceSelectors(window.navigator.userAgent);
	const { isMobile } = selectors;
	return {
		isLandscape,
		isMobile,
		browserName,
		fullBrowserVersion,
		isAndroid,
		isBrowser,
		isChrome,
		isChromium,
		isOpera,
		isDesktop,
		isEdge,
		isFirefox,
		isIOS,
		isMacOs,
		isSafari,
		isPortrait,
	};
};

export default useDevice;
