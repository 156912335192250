import { createSlice } from "@reduxjs/toolkit";
import { initTaskBonusReactive, initTaskBonusReactiveSecondOportunity } from "./initState";


const initialState = {
    taskBonusReactive: initTaskBonusReactive,
    taskBonusSecondReactive: initTaskBonusReactiveSecondOportunity,
    numberTasksAnswered: 0,
    addTotalNumberSecondOportunity: 0,
    activeModalWarnnigTime: false,
    bonusPoints: 0,
};

const isBonusTimeSlice = createSlice({
    name: "bonustime",
    initialState,
    reducers: {
        setBonusReagentTime: (state, { payload }) => {
            state.taskBonusReactive[payload.id].finishTime = payload.time;
            state.taskBonusReactive[payload.id].response = payload.response;
            state.taskBonusReactive[payload.id].isUsed = true;
        },
        setNumberTasksAnswered: (state, { payload }) => {
            state.numberTasksAnswered = payload;
        },
        setAddTotalNumberSecondOportunity: (state, { payload }) => {
            state.addTotalNumberSecondOportunity = payload;
        },
        setActiveModalWarnnigTime: (state, { payload }) => {
            state.activeModalWarnnigTime = payload;
        },
        setTaskBonusSecondReactive: (state, { payload }) => {
            state.taskBonusSecondReactive[payload.id].finishTime = payload.time;
            state.taskBonusSecondReactive[payload.id].response = payload.response;
        },
        setBonusPoints: (state, { payload }) => {
            state.bonusPoints = payload.time;
        },
    }
})

export const { setBonusReagentTime, setNumberTasksAnswered, setActiveModalWarnnigTime, setTaskBonusSecondReactive, setAddTotalNumberSecondOportunity, setBonusPoints } = isBonusTimeSlice.actions;

export default isBonusTimeSlice.reducer;