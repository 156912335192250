import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";
import Button from "./Button";
import Modal from "./Modal";

const Notification = ({ title, body, action, textButton = getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_boton") }) => {
    return (
        <Modal>
            <div className="w-[440px] h-[220px] flex flex-col rounded-lg">
                <div className="text-center bg-strong-blue rounded-t-lg py-[7px] text-[24px] text-white">
                    <h4 className="notification-title text-2xl text-white font-GraphikMedium">{title.toUpperCase()}</h4>
                </div>
                <div className=" flex justify-center items-center flex-col h-full rounded-b-lg pt-[16px] pb-[32px] bg-white">
                    <div className="w-full h-fit  grow  mb-[16px] px-[18px]">
                        <p className="notification-body text-[16px] font-GraphikRegular text-center">{body}</p>
                    </div>
                    <div className=" flex items-end  justify-center w-full   h-[48px] ">
                        <Button onClick={action} className="rounded-full w-[192px] h-full ">
                            {textButton}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Notification;
