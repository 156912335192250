import labelExperiencia from './labelExperiencia.json'

import modulo0AdvertenciaTiempo from './modulo0AdvertenciaTiempo.json'
import modulo0GuiaNavegacion from './modulo0GuiaDeNavegacion.json'
import modulo0Inicio from './modulo0Inicio.json'

import { modulo1DecisionRecompesa } from './modulo1DecisionRecompesa'
import { modulo1ReactivoRetroMujeres } from './modulo1ReactivoRetroMujeres'
import { modulo1Seccion1RevisionDeCorreos } from './modulo1Seccion1RevisionDeCorreos'
import { modulo1Seccion2PrimeraInteraccion } from './modulo1Seccion2PrimeraInteraccion'
import { modulo1RevisarDatosClientes } from './modulo1RevisarDatosClientes'

import { modulo2Seccion1IngresarVistaTabla } from './modulo2Seccion1IngresarVistaTabla'
import { modulo2Seccion2SeleccionTabla } from './modulo2Seccion2SeleccionTabla'

import { modulo3TarjetaCredito } from './modulo3TarjetaCredito'

import { modulo4Seccion1RevisarAgenda } from './modulo4Seccion1RevisarAgenda'
import { modulo4Seccion2SelecionarDocumentos } from './modulo4Seccion2SelecionarDocumentos'
import { modulo4Seccion3Companero } from './modulo4Seccion3Companero'

import { modulo5EntregaDocumentacion } from './modulo5EntregaDocumentacion'
import { modulo5Finalizar } from './modulo5Finalizar'
import { todasLasGraficas } from './modulo5Graficas'

import { modulo6Pareja } from './modulo6Pareja';

import { reporteGeneral } from './reporte'

import { demoTraduction } from './demo'

import { checkAllKeysForTranslationRepetition } from './helperCheckKeyTranslate'


//* checar si no se repite alguno

export const textsToTranslate = checkAllKeysForTranslationRepetition({
    modulo0Inicio,
    modulo0GuiaNavegacion,
    modulo0AdvertenciaTiempo,
    labelExperiencia,
    modulo1Seccion1RevisionDeCorreos,
    modulo1Seccion2PrimeraInteraccion,
    modulo1RevisarDatosClientes,
    modulo1DecisionRecompesa,
    modulo1ReactivoRetroMujeres,
    modulo2Seccion1IngresarVistaTabla,
    modulo2Seccion2SeleccionTabla,
    modulo3TarjetaCredito,
    modulo4Seccion1RevisarAgenda,
    modulo4Seccion2SelecionarDocumentos,
    modulo4Seccion3Companero,
    modulo5EntregaDocumentacion,
    todasLasGraficas,
    modulo5Finalizar,
    modulo6Pareja,
    reporteGeneral,
    demoTraduction
})