import { Outlet } from "react-router-dom";

const Introduction = () => {
    return (
        <div className=" flex justify-center items-center w-min-[1024px] h-min-[600px] w-full h-screen ">
            <Outlet />
        </div>
    );
}

export default Introduction;