import { useEffect, useState } from "react";
import { Task_1_1 } from "../../store/slices/introduction/tasks";
import { verifyMailIsEmpty } from "../../helpers/mails";

const useCloseButton = ({ actualTask, emails = [], section, selectedEmail }) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (actualTask.id === Task_1_1.id) {
            // const value = emails.reduce((prev, current) => {
            //     if (!prev) return false;
            //     if (!current.isChecked) return false
            //     return true;
            // }, true);
            const value = verifyMailIsEmpty(emails);
            setIsVisible(value)
        }
    }, [actualTask, emails, section, selectedEmail])

    return { isVisible }
}

export default useCloseButton;