import { AnimationMixer, SpriteMaterial, Sprite, AudioListener, Audio } from "three";

import { audioLoader, loader, path3D, textureLoader } from "../utils";
import Sound from './Sound';
const workmateUrl = 'ejecutivo_v10.glb'
const characterUrls = ["CL_01_Hablar_escuchar.glb", "cliente 02_v11.glb", "cliente 03_v10.glb", workmateUrl, "jefe_v10.glb"];
const bankUrl = "models/Banco_iluminacion_26.glb";

const setModel = (data) => {
    const mesh = data.scene;
    const animations = data.animations[0];
    const mixer = new AnimationMixer(mesh);
    const action = mixer.clipAction(animations);
    action.play();
    mesh.tick = (delta) => mixer.update(delta);

    return mesh;
};

const setAnimation = (animationData, mesh) => {
    const { animations, index } = animationData;
    const animation = animations[index];
    const mixer = new AnimationMixer(mesh);
    const action = mixer.clipAction(animation);
    action.play();
    return (delta) => mixer.update(delta);
};

const setModelAsync = async (dataModel) => {
    const mesh = dataModel.scene;
    let index = 1;
    const name = dataModel.scene.children[0].name;
    if (name === "Cliente_02") {
        index = 2;
    }
    if (name === "Jefe" || name === "Cliente_03") index = 3;
    if (name === "Ejecutivo") index = 4;
    if (name === "Cliente_01") index = 0;
    const animation = setAnimation({ animations: dataModel.animations, index }, mesh);
    mesh.tick = animation;

    return await Promise.resolve(mesh);
};
const getBank = async () => {
    const data = await loader.loadAsync(`${path3D}${bankUrl}`);
    const bank = setModel(data);
    return bank;
};

const correctTransparency = (model, status = false) => {
    model.traverse((object) => {
        if (!object.isMesh) return;
        if (object.type !== "SkinnedMesh") return;
        object.frustumCulled = status;
    });
    model.matrixWorldNeedsUpdate = true;
};
const getCharacters = async () => {
    const charactersModelData = await Promise.all(characterUrls.map((url) => loader.loadAsync(`${path3D}models/clients/${url}`)));
    const [client1, client2, client3, workmate, boss] = await Promise.all(
        charactersModelData.map((characterModelData) => setModelAsync(characterModelData))
    );
    client1.name = "scene_client1";
    client2.name = "scene_client2";
    client3.name = "scene_client3";
    client2.position.z += 0.74;
    client3.position.z += 0.74;
    workmate.name = "scene_workmate";
    boss.name = "scene_boss";

    client2.visible = false;
    client3.visible = false;
    workmate.visible = true;
    boss.visible = false;
    return { characters: [client1, client2, client3, workmate, boss], models: charactersModelData };
};

export const getWorkMate = async () => {
    const workmateModelData = await loader.loadAsync(`${path3D}models/clients/${workmateUrl}`);
    const workmate = setModelAsync(workmateModelData);
    workmate.name = "scene_workmate";
    workmate.visible = true;

    return workmate
}

const createTaskSprite = (name, nameObj, position, isDoble, isSearchable = false) => {
    let scale = 0.06;
    if (isDoble) scale *= 5;
    const map = textureLoader.load(`${name}.png`);
    const material = new SpriteMaterial({ map: map, color: 0xffffff });
    material.visible = !isSearchable;
    const sprite = new Sprite(material);
    sprite.name = nameObj;
    sprite.scale.set(scale, scale, scale);
    const { x, y, z } = position;
    sprite.position.set(x, y, z);

    return sprite;
};


const createSound = (path, audioInformation) => {
    const { volume = 1, autoPlay = true } = audioInformation
    const audioListener = new AudioListener();
    const sound = new Audio(audioListener);
    audioLoader.load(path, function (buffer, _, __, error) {
        sound.setBuffer(buffer);
        sound.setLoop(true);
        sound.setVolume(volume);
        if (autoPlay)
            sound.play();
    });
    return { sound };
}
export { getBank, createTaskSprite, getCharacters, setAnimation, correctTransparency, createSound, Sound };
