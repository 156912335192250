export const modulo1RevisarDatosClientes = {
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_boton_plegable_tarea_1: {
        es: "Puedes ver las condiciones de las promociones dando click en el ícono de información, al terminar da click en “X” para salir.",
        en: "You can view the conditions of the promotions by clicking on the information icon, then click on “X” to exit."
    },
    //? columna 1
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_columna1_titulo: {
        es: "Datos de cliente actual",
        en: "Current client information"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_1: {
        es: "Nombre completo",
        en: "Full name"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_2: {
        es: "Juan Enrique Reyes González",
        en: "Juan Enrique Reyes González"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_3: {
        es: "Edad",
        en: "Age"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_4: {
        es: "[replace1] años",
        en: "[replace1] years"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_5: {
        es: "Estado",
        en: "State"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_6: {
        es: "Jalisco",
        en: "Jalisco"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_7: {
        es: "Registro",
        en: "Registration"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_8_registrado: {
        es: "Cliente registrado",
        en: "Registered client"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_8_no_registrado: {
        es: "Cliente no registrado",
        en: "Non-registered client"
    },
    //? columna 2
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_columna2_titulo: {
        es: "Lista de clientes",
        en: "List of clients"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_9: {
        es: "Nombre completo:",
        en: "Full name:"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_10: {
        es: "Edad:",
        en: "Age:"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_11: {
        es: `Estado:`,
        en: "State:"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_12: {
        es: `Estudio: `,
        en: "Survey:"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_13: {
        es: `Evento: `,
        en: "Event:"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_asistio: {
        es: "Asistió",
        en: "Attended"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_no_asistio: {
        es: "No asistió",
        en: "Did not attend"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_participo: {
        es: "Participó",
        en: "Participated"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_noparticipo: {
        es: "No Participó",
        en: "Did not participate"
    },
    //? botones
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_boton_actualizar: {
        es: "Actualizar",
        en: "Update"
    },
    tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_boton_registrar: {
        es: "Registrar",
        en: "Register"
    },
    //? modal icon
    tarea_1_ver_datos_cliente_caso_1_modal_icon_ayuda_interfaz2D_modal_titulo_1: {
        es: "Información",
        en: "Information"
    },
    tarea_1_ver_datos_cliente_caso_1_modal_icon_ayuda_interfaz2D_modal_titulo_2: {
        es: "Ayuda",
        en: "Help"
    },
    tarea_1_ver_datos_cliente_caso_1_modal_icon_ayuda_interfaz2D_modal_info: {
        es: `<b> Recuerda que: </b>
        <br />
        1) Los clientes registrados que participaron en la encuesta de la Nueva Tarjeta
        Electrónica recibirán de regalo un electrodoméstico. <br />
        2) Los clientes registrados que no participaron en la encuesta de la Nueva
        Tarjeta Electrónica recibirán de regalo una tarjeta por $200 pesos. <br />
        <b>**Los clientes no registrados se registrarán para que puedan participar en
            futuras promociones.</b> 
        `,
        en: `<b> Remember that: </b>
        <br />
        1) Registered clients who participated in the New Electronic Card survey will receive a home appliance as a gift. <br />
        2) Registered clients who did not participate in the New Electronic Card survey will receive a $200 pesos gift card. <br />
        <b>**Non-registered clients will be registered so they can participate in future promotions.</b> 
        `,
    },
    //? modal aviso actualizacion
    tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_modal_titulo: {
        es: "AVISO",
        en: "NOTICE"
    },
    tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_modal_info_es_cliente: {
        es: "¡El registro de Juan Enrique Reyes González ha sido actualizado con éxito!",
        en: "Juan Enrique Reyes González's registration has been successfully updated!"
    },
    tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_modal_info_no_es_cliente: {
        es: "¡Juan Enrique Reyes González ha sido registrado con éxito!",
        en: "The registration of Juan Enrique Reyes González has been successfully completed!"
    },
    tarea_1_ver_datos_cliente_caso_1_modal_aviso_actualizacion_interfaz2D_boton: {
        es: "Entendido",
        en: "Understood"
    },
    tarea_1_revisar_correo_pendiente_1: {
        es: "Revisa el correo pendiente.",
        en: "Check the pending email."
    },
    // Da click en ‘’X’’ para salir.
    // tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton_plegable_tarea_2
}