import styled from "styled-components";

const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
	width: 270px;
	height: 50px;
	background: transparent;
	border-radius: 10px;
	text-align: center;

	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	/* f2f2 */
	border: 3px solid #9a9a9a;
	color: #9a9a9a;
`;

export default Button;
