import { memo } from "react";
import { Schedule, ScheduleEn } from "../../../assets";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

const Agenda = () => {
    return (
        <div className="w-full h-full flex flex-col pt-[11px]">
            <div className="w-full rounded-xl ">
                <div className="w-full h-12 flex items-center bg-white text-strong-blue rounded-t-xl py-1 px-10">
                    <h3>{getJsonTextLanguageDisplay("tarea_2_recordatorio_reunion_agenda_interfaz2D_card_titulo")}</h3>
                </div>
            </div>
            <div className="w-full grow flex justify-center items-center px-10 py-[18px] rounded-b-xl bg-strong-blue ">
                <img src={localStorage.getItem("language") === "es" ? Schedule : ScheduleEn} alt="Agenda. there is a schedule" />
            </div>
        </div>
    );
};

export default memo(Agenda);
