import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { teacherHeaders } from "../../data/headers";
import { useExcelAnswers } from "../../hooks";
import { Icons } from "../../assets";

const CSVIcon = ({ results = [] }) => {
    const { excelData, getAnswers } = useExcelAnswers();
    useEffect(() => {
        getAnswers(results)
    }, [results, getAnswers])

    const [result] = results;
    return (
        <CSVLink data={excelData} headers={teacherHeaders} filename={`respuestas_${result.userId}.csv`}>
            <div className='w-full flex justify-center items-center cursor-pointer active:scale-105' >
                <img className='w-5' src={Icons.document} alt="info" />
            </div>
        </CSVLink>

    );
}

export default CSVIcon;