import { Vector2, Raycaster } from "three";

let _scene, _camera, _element, _container, _action;
const _pointer = new Vector2();
const _raycaster = new Raycaster();

export default class Pointer {
    constructor(scene, camera, container) {
        _scene = scene;
        _camera = camera;
        _container = container;
        this.listeners();
    }

    onPointMoveEvent(event) {
        _pointer.x = (event.clientX / _container.clientWidth) * 2 - 1;
        _pointer.y = -(event.clientY / _container.clientHeight) * 2 + 1;
    }

    tick() {
        _raycaster.setFromCamera(_pointer, _camera);
        let intersects = _raycaster.intersectObjects(_scene.children);
        intersects = intersects.filter((element) => element.object.isSprite);
        if (intersects.length === 0 && !_element) return;
        if (intersects.length > 0 && _element) return;
        if (intersects.length === 0 && _element) {
            const element = _scene.getObjectByName(_element.name);
            if (!element) return;
            element.material.color.copy(_element.color);
            _element = null;
            return;
        }
        if (intersects.length > 0 && !_element) {
            for (let i = 0; i < intersects.length; i++) {
                _element = {
                    name: intersects[i].object.name,
                    color: intersects[i].object.material.color.clone(),
                };
                intersects[i].object.material.color.set("#13A6E0");
                break;
            }
        }
    }

    getElement() {
        return _element;
    }
    addClickAction(action) {
        _action = action;
        _container.addEventListener("click", _action);
    }
    updateClickAction(newAction) {
        _container.removeEventListener("click", _action);
        if (!newAction) return;
        this.addClickAction(() => newAction());
    }

    removeAction() {
        _element = null;
        _container.removeEventListener("click", _action);
    }

    listeners() {
        _container.addEventListener("pointermove", this.onPointMoveEvent);
    }
}
