import { memo } from "react";
import { useStoreComputer } from "../../hooks/store";
import { useGetSection } from "../../hooks/computer";
import ClientSection from "./ClientSection";
import { Agenda, Documents } from "./task5";
import { WorkMateTask } from "../computer";
import ComputerHome from "./ComputerHome";
import MailSection from "./MailSection";
import { Meeting } from "./task7";
import Final from "./Final";

const Body = ({ currentTask }) => {
    const { selectedSection } = useStoreComputer();
    const [section, subSection, subDirectories] = useGetSection(selectedSection);
    if (!currentTask) return;
    if (currentTask.meeting) return <Meeting />;
    if (currentTask.person || currentTask.isAlsoComputer) return <WorkMateTask />;
    if (currentTask.section === "Final") return <Final />;
    switch (section) {
        case "Documentos": {
            return <Documents subSection={subSection} directories={subDirectories} />;
        }
        case "Correo": {
            return <MailSection />;
        }
        case "Clientes": {
            return <ClientSection />;
        }
        case "Agenda": {
            return <Agenda />;
        }

        default: {
            return <ComputerHome />;
        }
    }
};

export default memo(Body);
