import { Graphs } from "../../../assets";
import { juanInformation } from "../../../data";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";
import { getRandomNumber } from "../../../utils";

const talk5 = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("usuario_card_texto_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("juan_no_cliente_card_texto_1", { "[replace1]": juanInformation.age }),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("usuario_no_cliente_card_texto_2"),
    },
];
const getTalkTask4 = (isClient) => {
    const talk = [...talk5];
    if (isClient) {
        talk[1].message = getJsonTextLanguageDisplay("juan_es_cliente_card_texto_1", { "[replace1]": juanInformation.score });
        talk[2].message = getJsonTextLanguageDisplay("usuario_es_cliente_card_texto_2");
    }
    return talk;
};

export { getTalkTask4 };




const shuffleArray = (slices) => {
    return [...slices].sort(function () {
        return Math.random() - 0.5;
    });
}

const getAnswerSlice = (slices) => {
    return slices.findIndex(slice => slice.isCorrect) + 1
}

// const slicesGroupOne = shuffleArray([
//     {
//         id: 1,
//         title: "Tipo de actividad de los clientes actuales.",
//         graph: Graphs.ClientCake,
//         dialoguesBoss: ["Gracias. Continuemos con la siguiente pregunta."],
//         isCorrect: true,
//     },
//     {
//         id: 2,
//         title: "Clientes por tipo de actividad y ejecutivo de cuenta",
//         graph: Graphs.WorkClients,
//         dialoguesBoss: ["Gracias. Continuemos con la siguiente pregunta."],
//     },
//     {
//         id: 3,
//         title: "Clientes por género dado su tipo de actividad.",
//         graph: Graphs.GenderClients,
//         dialoguesBoss: ["Gracias. Continuemos con la siguiente pregunta."],
//     },
// ]);

// const sliceOne = {
//     correctAnswer: getAnswerSlice(slicesGroupOne),
//     answer: 1,
//     isCorrect: false,
//     slices: slicesGroupOne
// };

//* grupo 1 ---
const slicesGroupOne_One = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_1_1_caso_1_2_card_titulo_grafica_1"),
        // graph: Graphs.ClientCake,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo9_Grafica1 : Graphs.Reactivo9_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_1_dialogo_jefe_final_1")],
        isCorrect: true,
    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_1_2_caso_1_2_card_titulo_grafica_1"),
        // graph: Graphs.WorkClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo9_Grafica2 : Graphs.Reactivo9_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_1_dialogo_jefe_final_1")],
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_1_3_caso_1_2_card_titulo_grafica_1"),
        // graph: Graphs.GenderClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo9_Grafica3 : Graphs.Reactivo9_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_1_dialogo_jefe_final_1")],
    },
]);
const slicesGroupOne_Two = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_1_1_caso_1_2_card_titulo_grafica_1"),
        // graph: Graphs.ClientCake,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo9_Grafica1 : Graphs.Reactivo9_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_1_dialogo_jefe_final_1")],
    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_1_2_caso_1_2_card_titulo_grafica_1"),
        // graph: Graphs.WorkClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo9_Grafica2 : Graphs.Reactivo9_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_1_dialogo_jefe_final_1")],
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_1_3_caso_1_2_card_titulo_grafica_1"),
        // graph: Graphs.GenderClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo9_Grafica3 : Graphs.Reactivo9_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_1_dialogo_jefe_final_1")],
        isCorrect: true,
    },
]);
//* grupo 1 ---

//* grupo 2 ---
const slicesGroupTwo_One = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_2_1_caso_1_2_card_titulo_grafica_2"),
        // graph: Graphs.SuperMarketCake,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo10_Grafica1 : Graphs.Reactivo10_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_2_dialogo_jefe_final_1")],
        isCorrect: true

    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_2_2_caso_1_2_card_titulo_grafica_2"),
        // graph: Graphs.GenderSlice2,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo10_Grafica2 : Graphs.Reactivo10_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_2_dialogo_jefe_final_1")],
        retroMessage: ""
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_2_3_caso_1_2_card_titulo_grafica_2"),
        // graph: Graphs.State,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo10_Grafica3 : Graphs.Reactivo10_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_2_dialogo_jefe_final_1")],
    },
])
const slicesGroupTwo_Two = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_2_1_caso_1_2_card_titulo_grafica_2"),
        // graph: Graphs.SuperMarketCake,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo10_Grafica1 : Graphs.Reactivo10_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_2_dialogo_jefe_final_1")],
    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_2_2_caso_1_2_card_titulo_grafica_2"),
        // graph: Graphs.GenderSlice2,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo10_Grafica2 : Graphs.Reactivo10_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_2_dialogo_jefe_final_1")],
        retroMessage: "",
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_2_3_caso_1_2_card_titulo_grafica_2"),
        // graph: Graphs.State,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo10_Grafica3 : Graphs.Reactivo10_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_2_dialogo_jefe_final_1")],
        isCorrect: true
    },
])
//* grupo 2 ---

//* grupo 3 ---
const slicesGroupThree_One = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_3_1_caso_1_2_card_titulo_grafica_3"),
        // graph: Graphs.Transaction,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo11_Grafica1 : Graphs.Reactivo11_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_3_dialogo_jefe_final_1")],
        isCorrect: true
    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_3_2_caso_1_2_card_titulo_grafica_3"),
        // graph: Graphs.TransactionPerDay,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo11_Grafica2 : Graphs.Reactivo11_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_3_dialogo_jefe_final_1")],

    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_3_3_caso_1_2_card_titulo_grafica_3"),
        // graph: Graphs.Products,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo11_Grafica3 : Graphs.Reactivo11_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_3_dialogo_jefe_final_1")],

    },
]);

const slicesGroupThree_Two = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_3_1_caso_1_2_card_titulo_grafica_3"),
        // graph: Graphs.Transaction,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo11_Grafica1 : Graphs.Reactivo11_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_3_dialogo_jefe_final_1")],
    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_3_2_caso_1_2_card_titulo_grafica_3"),
        // graph: Graphs.TransactionPerDay,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo11_Grafica2 : Graphs.Reactivo11_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_3_dialogo_jefe_final_1")],
        isCorrect: true
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_3_3_caso_1_2_card_titulo_grafica_3"),
        // graph: Graphs.Products,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo11_Grafica3 : Graphs.Reactivo11_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_3_dialogo_jefe_final_1")],

    },
]);
//* grupo 3 ---

//* grupo 4 ---
const slicesGroupFour_One = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_4_1_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.A4SuperMarket,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo12_Grafica1 : Graphs.Reactivo12_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_4_dialogo_jefe_final_1")],
        isCorrect: true

    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_4_2_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.B4SuperMarket,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo12_Grafica2 : Graphs.Reactivo12_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_4_dialogo_jefe_final_1")],
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_4_3_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.C4SuperMarket,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo12_Grafica3 : Graphs.Reactivo12_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_4_dialogo_jefe_final_1")],
    },
])

const slicesGroupFour_Two = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_4_1_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.A4SuperMarket,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo12_Grafica1 : Graphs.Reactivo12_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_4_dialogo_jefe_final_1")],


    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_4_2_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.B4SuperMarket,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo12_Grafica2 : Graphs.Reactivo12_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_4_dialogo_jefe_final_1")],
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_4_3_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.C4SuperMarket,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo12_Grafica3 : Graphs.Reactivo12_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_4_dialogo_jefe_final_1")],
        isCorrect: true
    },
])

//* grupo 4 ---

//* grupo 5 ---
const slicesGroupFive_One = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_5_1_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.A5RegularClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo13_Grafica1 : Graphs.Reactivo13_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_5_dialogo_jefe_final_1")],
        isCorrect: true

    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_5_2_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.B5RegularClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo13_Grafica2 : Graphs.Reactivo13_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_5_dialogo_jefe_final_1")],
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_5_3_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.C5Morosos,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo13_Grafica3 : Graphs.Reactivo13_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_5_dialogo_jefe_final_1")],
    },
])

const slicesGroupFive_Two = shuffleArray([
    {
        id: 1,
        title: getJsonTextLanguageDisplay("graficas_5_1_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.A5RegularClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo13_Grafica1 : Graphs.Reactivo13_Grafica1En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_5_dialogo_jefe_final_1")],


    },
    {
        id: 2,
        title: getJsonTextLanguageDisplay("graficas_5_2_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.B5RegularClients,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo13_Grafica2 : Graphs.Reactivo13_Grafica2En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_5_dialogo_jefe_final_1")],
        isCorrect: true
    },
    {
        id: 3,
        title: getJsonTextLanguageDisplay("graficas_5_3_caso_1_2_card_titulo_grafica_4"),
        // graph: Graphs.C5Morosos,
        graph: localStorage.getItem("language") === "es" ? Graphs.Reactivo13_Grafica3 : Graphs.Reactivo13_Grafica3En,
        dialoguesBoss: [getJsonTextLanguageDisplay("graficas_5_dialogo_jefe_final_1")],
    },
])
//* grupo 5 ---

const casesMetingBoss = {
    sliceOne: {
        1: {
            questionBoss: getJsonTextLanguageDisplay("graficas_1_caso_1_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_1_caso_1_boton_plegable_grafica_1"),
            slices: slicesGroupOne_One
        },
        2: {
            questionBoss: getJsonTextLanguageDisplay("graficas_1_caso_2_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_1_caso_2_boton_plegable_grafica_1"),
            slices: slicesGroupOne_Two
        }
    },
    sliceTwo: {
        1: {
            questionBoss: getJsonTextLanguageDisplay("graficas_2_caso_1_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_2_caso_1_boton_plegable_grafica_2"),
            slices: slicesGroupTwo_One
        },
        2: {
            questionBoss: getJsonTextLanguageDisplay("graficas_2_caso_2_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_2_caso_2_boton_plegable_grafica_2"),
            slices: slicesGroupTwo_Two
        }
    },
    sliceThree: {
        1: {
            questionBoss: getJsonTextLanguageDisplay("graficas_3_caso_1_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_3_caso_1_boton_plegable_grafica_3"),
            slices: slicesGroupThree_One
        },
        2: {
            questionBoss: getJsonTextLanguageDisplay("graficas_3_caso_2_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_3_caso_2_boton_plegable_grafica_3"),
            slices: slicesGroupThree_Two
        }
    },
    sliceFour: {
        1: {
            questionBoss: getJsonTextLanguageDisplay("graficas_4_caso_1_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_4_caso_1_boton_plegable_grafica_4"),
            slices: slicesGroupFour_One
        },
        2: {
            questionBoss: getJsonTextLanguageDisplay("graficas_4_caso_2_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_4_caso_2_boton_plegable_grafica_4"),
            slices: slicesGroupFour_Two
        }
    },
    sliceFive: {
        1: {
            questionBoss: getJsonTextLanguageDisplay("graficas_5_caso_1_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_5_caso_1_boton_plegable_grafica_4"),
            slices: slicesGroupFive_One
        },
        2: {
            questionBoss: getJsonTextLanguageDisplay("graficas_5_caso_2_grafica_gerente_card_contenido"),
            titleBoss: getJsonTextLanguageDisplay("graficas_5_caso_2_boton_plegable_grafica_4"),
            slices: slicesGroupFive_Two
        }
    }
}

// export const meetingPresentation = [sliceOne, sliceTwo, sliceThree, sliceFour, sliceFift];
const meetingPresentationRandom = () => {
    const casesBossRandom = {
        slice1: getRandomNumber(1, 3),
        slice2: getRandomNumber(1, 3),
        slice3: getRandomNumber(1, 3),
        slice4: getRandomNumber(1, 3),
        slice5: getRandomNumber(1, 3),
    }
    const nameJson = {
        slice1: "sliceOne",
        slice2: "sliceTwo",
        slice3: "sliceThree",
        slice4: "sliceFour",
        slice5: "sliceFive",
    }
    let copysDialogBoss = {
        slice1: "",
        slice2: "",
        slice3: "",
        slice4: "",
        slice5: "",
    }
    let copysTitleDialogBoss = {
        slice1: "",
        slice2: "",
        slice3: "",
        slice4: "",
        slice5: "",
    }
    let arraySlices = []
    for (const key in casesBossRandom) {
        if (Object.hasOwnProperty.call(casesBossRandom, key)) {
            let baseSlide = {
                correctAnswer: [],
                answer: 1,
                isCorrect: false,
                slices: []
            }
            let sliceArray = []
            let copySelect = ""
            let copyQuesionTitle = ""
            const element = casesBossRandom[key];
            const name = nameJson[key]
            const sliceSelect = casesMetingBoss[name]

            if ([1, 2].includes(element)) {
                const { slices, questionBoss, titleBoss } = sliceSelect[element]
                sliceArray = slices
                copySelect = questionBoss
                copyQuesionTitle = titleBoss
            } else {
                const { slices, questionBoss, titleBoss } = sliceSelect[1]
                sliceArray = slices
                copySelect = questionBoss
                copyQuesionTitle = titleBoss
            }
            baseSlide.correctAnswer = getAnswerSlice(sliceArray)
            baseSlide.slices = sliceArray
            copysDialogBoss[key] = copySelect
            copysTitleDialogBoss[key] = copyQuesionTitle
            arraySlices.push(baseSlide)
        }
    }
    return {
        slices: arraySlices,
        casesType: casesBossRandom,
        copysDialogBoss,
        copysTitleDialogBoss
    }
}

export const meetingPresentationReact = meetingPresentationRandom()

const generateNuberRandom = (min = 0, max = 1) => {
    // Genera un número decimal entre 0 (inclusive) y 1 (exclusive)
    const randomDecimal = Math.random();

    // Ajusta el valor al rango deseado y redondea al entero más cercano
    const randomNumber = Math.round(randomDecimal * (max - min) + min);

    return randomNumber;
}


const randomDataCases = {
    shoppingForLessWoman: generateNuberRandom(500, 2500),
    averageMonthlyBalanceWoman: generateNuberRandom(10000, 20000),
    averageMonthlyBalanceMen: generateNuberRandom(10000, 20000)
}


//! Esta basado en las respuestas TaskOfferingWoman
// { label: "Ofrecer la nueva tarjeta de crédito.", value: true },
// { label: "No ofrecer la nueva tarjeta de crédito.", value: false },
const rangeConditionWomanReactive14 = [
    {
        id: 1,
        shoppingForLess: {
            min: 1500,
            max: 2500
        },
        averageMonthlyBalance: {
            min: 10000,
            max: 20000
        },
        reponseCorrect: false
    },
    {
        id: 2,
        shoppingForLess: {
            min: 500,
            max: 1499
        },
        averageMonthlyBalance: {
            min: 15000,
            max: 20000
        },
        reponseCorrect: true
    },
    {
        id: 3,
        shoppingForLess: {
            min: 500,
            max: 1499
        },
        averageMonthlyBalance: {
            min: 10000,
            max: 14999
        },
        reponseCorrect: false
    }
]

const getCasesWomanReactive7 = () => {
    let womanSelect = 1
    let isCorrectShoppingForLessCaseOne = false
    let reponseCorrectOpt = false
    const { shoppingForLessWoman, averageMonthlyBalanceWoman } = randomDataCases
    rangeConditionWomanReactive14.forEach(({ shoppingForLess, averageMonthlyBalance, reponseCorrect, id }) => {
        if (isCorrectShoppingForLessCaseOne) {
            return
        }

        if (shoppingForLessWoman > shoppingForLess.min && shoppingForLessWoman < shoppingForLess.max) {
            isCorrectShoppingForLessCaseOne = id === 1
        }

        if (averageMonthlyBalanceWoman > averageMonthlyBalance.min && averageMonthlyBalanceWoman < averageMonthlyBalance.max) {
            womanSelect = id
            reponseCorrectOpt = reponseCorrect
        }
    })
    return {
        numberCase: womanSelect,
        reponseCorrectOpt,
        shoppingForLess: shoppingForLessWoman.toLocaleString(),
        averageMonthlyBalance: averageMonthlyBalanceWoman.toLocaleString(),
    }
}


//! Esta basado en las respuestas TaskCreditMan
// { label: "Aumentar límite de crédito.", value: true },
// { label: "Ofrecer la nueva tarjeta de crédito.", value: false },
const rangeConditionMenReactive14 = [
    {
        id: 1,
        averageMonthlyBalance: {
            min: 15000,
            max: 20000
        },
        reponseCorrect: false
    },
    {
        id: 2,
        averageMonthlyBalance: {
            min: 10000,
            max: 14999
        },
        reponseCorrect: true
    },
]

const getCasesMenReactive8 = () => {
    let menSelect = 1
    let reponseCorrectOpt = false
    const { averageMonthlyBalanceMen } = randomDataCases
    rangeConditionMenReactive14.forEach(({ averageMonthlyBalance, reponseCorrect, id }) => {
        if (averageMonthlyBalanceMen > averageMonthlyBalance.min && averageMonthlyBalanceMen < averageMonthlyBalance.max) {
            menSelect = id
            reponseCorrectOpt = reponseCorrect
        }
    })

    return {
        numberCase: menSelect,
        reponseCorrectOpt,
        averageMonthlyBalance: averageMonthlyBalanceMen.toLocaleString(),
    }
}

export const womanReact7 = getCasesWomanReactive7()
export const menReact8 = getCasesMenReactive8()

const caseWorkMate = [
    {
        id: 1,
        averageWeeklyIncome: {
            min: 4000,
            max: 4579
        },
        reponseCorrect: 1
    },
    {
        id: 2,
        averageWeeklyIncome: {
            min: 4580,
            max: 5000
        },
        reponseCorrect: 2
    },
]

const setCaseWorkMate = () => {
    const priceRandom = generateNuberRandom(4000, 5000);
    const priceRandomFormatted = priceRandom.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let caseWorkMateSelect = 1;
    let reponseNumber = 1;

    for (const { averageWeeklyIncome, reponseCorrect, id } of caseWorkMate) {
        if (priceRandom > averageWeeklyIncome.min && priceRandom < averageWeeklyIncome.max) {
            caseWorkMateSelect = id;
            reponseNumber = reponseCorrect;
            break;
        }
    }

    return { reponseNumber, caseWorkMateSelect, priceRandom, priceRandomFormatted };
}

export const workmateReact5 = setCaseWorkMate()
