import { useMemo } from "react";
import { ComputerSections } from "../../store/slices/computer/state";

const useGetSection = (selectedSection) => {
	return useMemo(() => {
		let section = null;
		let subSection = null;
		let subDirectories = null;
		for (const key in ComputerSections) {
			const element = ComputerSections[key];
			if (selectedSection === element.name) {
				section = element.name;
				subSection = null;
				break;
			}
			if (element.directories === null) continue;
			const directories = element.directories;
			const subFolder = directories.find((item) => item === selectedSection);
			if (!subFolder) continue;
			section = element.name;
			subSection = subFolder;
			subDirectories = element.subDirectories;
		}

		return [section, subSection, subDirectories];
	}, [selectedSection]);
};

export default useGetSection;
