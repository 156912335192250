/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel } from "react-responsive-carousel";
import { useState, useEffect, useMemo, memo, useCallback } from "react";
import { Avatar, HeaderName, DialogueWithOption, Dialogue } from "../Conversation";
import { useActiveTalk, useArrow } from "../../hooks/conversation";
import { useStoreTask, useStoreUser } from "../../hooks/store";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const INIT_INDEX = 0;
const END_CONVERSATION = null;

const Conversation = () => {
    const { tasks } = useStoreTask();
    const { questions } = useStoreUser();
    const [index, setIndex] = useState(null);
    const [dialogues, setDialogue] = useState(null);
    const { nextFuntion } = useArrow(dialogues, index);
    const { isVisible } = useActiveTalk(dialogues, index);
    const doesHaveTask = tasks.length > 0;
    const currenTask = tasks[0];
    const task = doesHaveTask ? currenTask : { id: null };

    useEffect(() => {
        if (!doesHaveTask && dialogues === END_CONVERSATION) return;
        if (!doesHaveTask && dialogues !== END_CONVERSATION) {
            setDialogue(END_CONVERSATION);
            setIndex(null);

            return;
        }
        if (currenTask.type !== "talk" && dialogues === END_CONVERSATION) return;
        if (currenTask.type !== "talk" && dialogues !== END_CONVERSATION) {
            setDialogue(null);
            setIndex(null);
            return;
        }
        setDialogue(currenTask.messages);
    }, [tasks]);

    useEffect(() => {
        if (dialogues !== END_CONVERSATION && index === null) setIndex(INIT_INDEX);
    }, [dialogues]);

    useEffect(() => {
        setIndex(INIT_INDEX);
    }, [task.id]);
    const selectOption = useCallback(() => {
        if (dialogues[index].hasOptions) {
            setIndex(index + 1);
        }
    }, [dialogues, index])
    const conversationDialogues = useMemo(() => {
        if (!dialogues) return [];
        return dialogues.map((dialogue, index) => {
            if (dialogue.hasOptions)
                return <DialogueWithOption key={dialogue.id + index} correctAnswer={questions[dialogue.index].correctAnswer} item={dialogue} selectOption={selectOption} />;
            return <Dialogue key={index} message={dialogue.message} />;
        });
    }, [dialogues, questions, tasks]);


    if (!isVisible) return;
    const isOptionItem = dialogues[index].hasOptions;

    return (
        <div className="w-full h-[120px] absolute z-50 bottom-[32px] pr-[76px]  px-[132px] lg:px-[15%] ">
            <div className=" relative w-full h-full flex flex-col " style={{ zIndex: 200 }}>
                <Avatar isMine={dialogues[index].isMine} person={dialogues[index].person} />
                <HeaderName isMine={dialogues[index].isMine} isOptionItem={isOptionItem} person={dialogues[index].person} />
                <div className={`mt-[16px] pl-[76px] h-full flex justify-between  rounded-lg relative bg-white `}>
                    <Carousel
                        autoFocus
                        showStatus={false}
                        showThumbs={false}
                        selectedItem={index}
                        showIndicators={false}
                        renderArrowPrev={() => null}
                        renderArrowNext={nextFuntion}
                        className="w-full text-left h-full "
                        onChange={(value) => setIndex(value)}
                    >
                        {conversationDialogues}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default memo(Conversation);
