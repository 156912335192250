const ClienUserInfo = ({ title = "", value = "" }) => {
	return (
		<div className="h-[180px] xl:h-[78px] rounded-br-2xl ">
			<p className="item-table-own-client text-[11px] xl:text-[16px] font-GraphikSemibold text-computer">
				<b>{title}:</b>
			</p>
			<p className="item-table-own-client text-[11px] xl:text-[16px] font-GraphikRegular text-computer">{value}</p>
		</div>
	);
};

export default ClienUserInfo;
