import { Euler, Raycaster, Vector3 } from "three";

const validKeys = ["w", "s", "a", "d"];
const directionValue = new Vector3();
const rayCasterDirection = new Vector3();
const rotationValue = new Euler(0, 0, 0, "YXZ");
const rayCaster = new Raycaster();
rayCaster.far = 1;
const _PI_2 = Math.PI / 1.2;
let isActiveRotation = false;
let isActivePosition = true;

export class CameraMovement {
    #camera;
    #movementKeys;
    #velocityWalking;
    #velocityLooking;
    #children;
    constructor(camera, sceneChildren) {
        this.#camera = camera;
        this.#movementKeys = new Map();
        this.#movementKeys.set("w", { direction: 1, value: false, isFordware: true });
        this.#movementKeys.set("s", { direction: -1, value: false, isFordware: true });
        this.#movementKeys.set("a", { direction: -1, value: false, isFordware: false });
        this.#movementKeys.set("d", { direction: 1, value: false, isFordware: false });
        this.#velocityWalking = 1.2;
        this.#velocityLooking = 1.0;
        this.#children = sceneChildren;
        rayCaster.camera = camera;
        this.handleDirection = this.handleDirection.bind(this);
        this.handlerPointerEvent = this.handlerPointerEvent.bind(this);
        this.listeners();
    }

    getValidKeyFormat(key) {
        return String(key).toLowerCase();
    }
    getIsValidKey(key) {
        return validKeys.includes(key);
    }
    handleDirection(keyPressEvent) {
        const { key, type } = keyPressEvent;
        const value = type === "keyup" ? false : true;
        const formattedKey = this.getValidKeyFormat(key);
        const isValid = this.getIsValidKey(formattedKey);
        if (!isValid) return;
        const element = this.#movementKeys.get(formattedKey);
        this.#movementKeys.set(formattedKey, { ...element, value });
    }

    moveCameraForward(multiplyScalar) {
        this.#camera.getWorldDirection(directionValue);
        directionValue.y = 0;
        directionValue.normalize();
        directionValue.multiplyScalar(multiplyScalar);
    }
    moveCameraSide(multiplyScalar) {
        this.#camera.getWorldDirection(directionValue);
        directionValue.y = 0;
        directionValue.normalize();
        directionValue.cross(this.#camera.up);
        directionValue.multiplyScalar(multiplyScalar);
    }
    moveCamera(delta) {
        if (!isActivePosition) return;
        const movementVelocity = delta * this.#velocityWalking;
        for (const [, values] of this.#movementKeys.entries()) {
            const { direction, value, isFordware } = values;
            if (!value) continue;

            const isValid = this.verifyMovement(direction, isFordware);
            if (!isValid) continue;
            if (isFordware) this.moveCameraForward(direction * movementVelocity);
            else this.moveCameraSide(direction * movementVelocity);
            this.#camera.position.add(directionValue);
        }
    }

    handlerPointerEvent(event) {
        if (!isActiveRotation) return;
        const movementX = Number(event.movementX || event.mozMovementX || event.webkitMovementX || 0);
        const movementY = Number(event.movementY || event.mozMovementY || event.webkitMovementY || 0);
        const distanceRotation = 0.0017;
        rotationValue.setFromQuaternion(this.#camera.quaternion);
        rotationValue.y -= -movementX * distanceRotation * this.#velocityLooking;
        rotationValue.x -= -movementY * distanceRotation * this.#velocityLooking;
        rotationValue.x = Math.max(_PI_2 - Math.PI, Math.min(_PI_2, rotationValue.x));
        this.#camera.quaternion.setFromEuler(rotationValue);
    }

    verifyMovement(scalar, isFordware) {
        if (!this.#children) return;
        this.#camera.getWorldDirection(rayCasterDirection);
        rayCasterDirection.y = 0;
        rayCasterDirection.normalize();
        if (!isFordware) rayCasterDirection.cross(this.#camera.up);
        rayCasterDirection.multiplyScalar(scalar);
        rayCaster.set(this.#camera.position, rayCasterDirection);
        let intersects = rayCaster.intersectObjects(this.#children);
        return intersects.length === 0;
    }
    tick(delta) {
        this.moveCamera(delta);
    }
    listeners() {
        window.addEventListener("keydown", this.handleDirection);
        window.addEventListener("keyup", this.handleDirection);
        window.addEventListener("pointerdown", () => (isActiveRotation = true));
        window.addEventListener("pointerup", () => (isActiveRotation = false));
        window.addEventListener("mousemove", (event) => this.handlerPointerEvent(event));
    }
}
