import { memo } from "react";

const DataHistogram = ({ children }) => {
    return <div className="grow  h-full pl-[42px] pr-6 flex flex-col items-center overflow-y-scroll  ">{children}</div>;
};

const Item = ({ title, value }) => {
    return (
        <div className="h-20 w-4/5 mb-6 rounded-xl bg-organge-task ">
            <div className="w-full h-10 flex justify-center items-center rounded-t-xl bg-white">
                <h4 className="text-computer font-GraphikMedium text-[14px] xl:text-[16px]">{title}:</h4>
            </div>
            <div className="h-10 bg-strong-blue flex justify-center items-center rounded-b-xl">
                <p className="text-white font-GraphikRegular text-[14px] xl:text-[16px]">{value}</p>
            </div>
        </div>
    );
};

DataHistogram.Item = memo(Item);

export default DataHistogram;
