import { isJuanAdult, userList, userListWomen } from "../data";
import { getRandomBoolean } from "../utils";

const hasBenefit = getRandomBoolean();
const DoesParticipateRetro = getRandomBoolean();
const isCreditRetro = getRandomBoolean(); // if this value is 1 must be another option

export const getPersonRetro1 = (isCredit, hasBenefit) => {
    if (isCredit && hasBenefit) {
        const woman1 = { ...userListWomen[1] };
        return woman1;
    }
    if (isCredit && !hasBenefit) {
        const woman2 = { ...userListWomen[6] };
        return woman2;
    }
    if (!isCredit && hasBenefit) {
        const man1 = { ...userList[8] };
        man1.isTarget = true;
        return man1;
    }

    const man2 = { ...userList[5] };
    man2.isTarget = true;
    return man2;
};

export const womanTableInformation = {
    name: "Esther Lozano Muñoz",
    city: "CDMX",
    age: 36,
    job: "Temporal",
};
export const initStatusQuestions = {
    isClient: isJuanAdult ? getRandomBoolean() : false,
    DoesParticipate: getRandomBoolean(),
    hasBenefit,
    personRetro1: getPersonRetro1(isCreditRetro, DoesParticipateRetro),
};

export const getTypeCase = () => {
    const { isClient, DoesParticipate } = initStatusQuestions;
    return `${isClient ? 1 : 0}${DoesParticipate ? 1 : 0}`;
};

export const getTypeHistogram = () => {
    const { isClient } = initStatusQuestions;
    return isClient ? "isClient" : "isNotClient";
};

export const idTaskFirstQuestion = 7;
export const talkWithBossTaskId = 29;
export const taskSliceMeeting1 = 33;
export const taskSliceMeeting2 = 36;
export const taskSliceMeeting3 = 39;
export const taskSliceMeeting4 = 42;
export const taskSliceMeeting5 = 45;

export const selectSliceIds = {
    slice1: 32,
    slice2: 35,
    slice3: 38,
    slice4: 41,
    slice5: 44,
};
export const slicesTaskIds = [selectSliceIds.slice1, selectSliceIds.slice2, selectSliceIds.slice3, selectSliceIds.slice4, selectSliceIds.slice5];
