import { BankBCJ } from "../../assets";
import { useIntroduction } from "../../hooks";
import { useStoreComputer } from "../../hooks/store";
import FooterButton from "./FooterButton";

const Footer = ({ currentTask }) => {
    const { openEmail, state, changeTask } = useIntroduction()
    const { selectedEmail, selectedDate } = state;
    const { selectedSection, sectionRequired } = useStoreComputer();
    if (!currentTask) return <></>
    return (
        <div className="  w-full h-full flex items-center  justify-between   ">
            <div className="w-[106px] flex items-center h-full">
                <img src={BankBCJ} alt="TMY" />
            </div>
            <FooterButton taskInformation={{ currentTask, section: sectionRequired, openEmail, selectedEmail, selectedSection, selectedDate, changeTask }} />
        </div>
    );
}

export default Footer;