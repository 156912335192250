const Menu = ({ children = [] }) => {
    if (children.length > 2) {
        throw new Error("Menu can only have two children")
    }
    return (
        <div className="w-52 h-full flex flex-col  justify-between pt-9 pb-6 bg-background-screen">
            <div  >
                {children[0]}
            </div>
            <div>
                {children[1]}
            </div>
        </div>
    )
}

export default Menu;