import { createSound } from ".";


export const soundNames = {
    task: "task",
    dialogue: "dialogue",
    retro: "retro",
}

let _sounds = [];
export default class OfficeSound {
    constructor(soundsInformation = []) {
        soundsInformation.forEach(soundInformation => {
            const { name, path, information } = soundInformation;
            const sound = createSound(path, information)
            sound.name = name;
            _sounds.push(sound);
        })
    }

    getSound(name = '') {
        return _sounds.find(sound => sound.name === name)
    }

    playSound(name) {
        const sound = this.getSound(name)
        if (!sound) return
        sound.sound.play();
        sound.sound.setLoop(false);
    }

}