import { memo } from "react";
import { useMemo } from "react";
import { useStoreUser } from "../../../hooks/store";
import { ComputerSections } from "../../../store/slices/computer/state";

const HOCSubDocuments = ({ Component, subSection, selectFile }) => {
	const {
		questions: { meetingDocuments },
	} = useStoreUser();
	const subDirectories = useMemo(() => {
		if (!subSection) return <></>;
		const principalSection = ComputerSections.Documents.subDirectories.find((item) => item.directory === subSection);
		if (!principalSection) return <></>;
		return principalSection.subDirectories.map((item) => {
			return (
				<Component
					key={item.id}
					answer={{ selected: meetingDocuments.selected, isCorrect: meetingDocuments.isCorrect }}
					item={item}
					selectFile={selectFile}
					times={meetingDocuments.retro.times}
				/>
			);
		});
	}, [meetingDocuments, selectFile, subSection]);

	return <>{subDirectories}</>;
};

export default memo(HOCSubDocuments);
