const SymbolGuide = ({ title, items, isPrincipal, img1, img2, hasCouple, text, isLast }) => {
	const [item1, item2] = items;
	return (
		<div
			className={`w-1/2 flex flex-col h-full   items-center justify-center   ${
				isPrincipal ? "border-r-white border-r-2 pr-[48px]" : "pl-[48px]"
			} `}
		>
			<div className=" w-full h-fit">
				<h2
					className={`mb-[14px] title-carousell mt-1  ${
						isLast ? "text-left" : "text-center"
					} font-GraphikMedium text-[16px] lg:text-[20px] leading-6  `}
				>
					{title}
				</h2>
				{text && <p className="text-left guide-instructions mb-[15px] font-GraphikRegular text-[16px] ">{text}</p>}
			</div>
			<div className=" w-full  h-full flex  justify-self-center items-center justify-around    ">
				<div className={`flex flex-col items-center ${hasCouple ? "" : "w-full"}  `}>
					{img1}
					{item1.text && <p className="item-carousell-text font-GraphikRegular text-[16px] grow">{item1.text}</p>}
				</div>
				{items.length > 1 && (
					<div className={`flex flex-col items-center  ${hasCouple ? "" : "w-full"}  `}>
						{img2}
						{item2.text && <p className="item-carousell-text font-GraphikRegular text-[16px]  grow">{item2.text}</p>}
					</div>
				)}
			</div>
		</div>
	);
};

export default SymbolGuide;
