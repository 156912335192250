import { useStoreComputer } from "../../../hooks/store";
import { useComputerActions } from "../../../hooks";
import { Modal } from "../../../components";
import { useSelectGlossary } from "../../../hooks/computer";
import { useEffect } from "react";

const Glossary = ({ currentTask }) => {
    const { isGlossaryActive } = useStoreComputer();
    const { changeGlossaryStatus } = useComputerActions();
    const closeModal = () => changeGlossaryStatus(false)

    const Body = useSelectGlossary(closeModal, { title: currentTask.glossaryTitle, type: currentTask.glossaryType, message: currentTask.message })
    useEffect(() => {
        changeGlossaryStatus(false);
    }, [changeGlossaryStatus, currentTask.id])
    if (!isGlossaryActive) return;
    return (
        <Modal>
            <div className="w-full h-full flex flex-col justify-center">
                {Body}
            </div>
        </Modal>
    );
};

export default Glossary;
