/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { App } from "../3D/app";

const useInitApp = (statusGuide, changeTask, finishLoading, currentTask, isActive, isInstructionsActive, hasInstructionsBeenShown) => {
	const refApp = useRef();
	useEffect(() => {
		if (statusGuide === "waiting") return;
		const container = document.getElementById("container");
		const app = new App(container, changeTask, finishLoading, currentTask);
		refApp.current = app;
		app.start();
	}, [statusGuide]);

	useEffect(() => {
		if (statusGuide === "waiting") return;
		if (!refApp.current) return;
		if (currentTask.id === 21) return;
		refApp.current.setActiveComputer(!isActive);
	}, [isActive]);

	useEffect(() => {
		if (refApp.current !== undefined && isInstructionsActive) {
			refApp.current.stop();
			return;
		}
		if (!refApp.current) return;
		if (!refApp.current.isActive) refApp.current.start();
	}, [isInstructionsActive, hasInstructionsBeenShown]);

	return refApp.current;
};

export default useInitApp;
