export const modulo5Finalizar = {
    sala_juntas_card_contenido_1: {
        es: "Muy bien, la información que me mostró fue suficiente para considerar la promoción de la nueva tarjeta. Ahora, puede ofrecer la nueva tarjeta a los clientes.",
        en: "Very well, the information you presented was sufficient to consider promoting the new card. Now, you can offer the new card to the customers.",
    },
    sala_juntas_card_contenido_2: {
        es: "La promoción aplicará para clientes que: tengan un contrato laboral temporal o trabajo no renumerado, un valor promedio de compras menor a $1,500 pesos y realizar sus compras en mercados o supermercados.",
        en: "The promotion will apply to customers who: have a temporary employment contract or unpaid work, an average purchase value of less than $1,500 pesos, and make their purchases at markets or supermarkets.",
    },
    sala_juntas_card_contenido_3: {
        es: "Los clientes con al menos una de las características anteriores deberán cumplir con: ser clientes solventes (tener un estatus regular) y tener saldo promedio mensual superior a $15,000 pesos.",
        en: "Customers with at least one of the above characteristics must meet the following criteria: be creditworthy customers (have a regular status) and have a monthly average balance exceeding $15,000 pesos.",
    },
    sala_juntas_card_contenido_4: {
        es: "Estas serán las 5 características que buscaremos en los clientes para ofrecer la promoción de la nueva tarjeta de nuestro banco, ¿entendido?",
        en: "These will be the 5 characteristics we will look for in customers to offer the promotion of our bank's new card, understood?",
    },
    sala_juntas_card_contenido_5: {
        es: "De acuerdo, cuente con ello.",
        en: "Agreed, count on it.",
    },
    sala_juntas_card_boton_plegable_1: {
        es: "Dirígete a tu oficina para atender a tus clientes.",
        en: "Head to your office to attend to your clients.",
    },
}