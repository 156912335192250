import { useEffect, useRef } from "react";
import { useStoreComputer } from "../../hooks/store";

const Message = () => {
    const bodyRef = useRef();
    const { selectedMail } = useStoreComputer();

    useEffect(() => {
        if (bodyRef.current) {
            bodyRef.current.innerText = "";
            bodyRef.current.insertAdjacentHTML("beforeend", selectedMail.body);
        }
    }, [selectedMail.body]);

    if (selectedMail === null) return;
    const { person, title, icon } = selectedMail;
    return (
        <div className="  w-full px-[14px] lg:px-[14px] ">
            <div className="w-full flex items-center bg-strong-blue px-[30px] rounded-t-xl direct-mail-title  font-GraphikMedium text-[18px] xl:text-[20px] py-[7px] 2xl:py-[15px]">
                <p>{title}</p>
            </div>
            <div className="grow  rounded-b-xl px-[15px] py-[15px] xl:px-[26px] lg:pb-[30px] xl:pb-[48px] bg-white">
                <div className="flex items-center pb-[10px] xl:pb-[15px] 2xl:pb-[26px]">
                    <div className="w-[64px]  mr-[16px]">
                        <img src={icon} alt="Person who sent the message" />
                    </div>
                    <p className="w-full direct-mail-person font-GraphikMedium text-[16px]  xl:text-[18px] text-strong-blue ">
                        {person.name} | {person.position}
                    </p>
                </div>
                <div>
                    <p ref={bodyRef} className="direct-mail-body font-GraphikRegular text-[16px]  xl:text-[18px] text-strong-blue "></p>
                </div>
            </div>
        </div>
    );
};

export default Message;
