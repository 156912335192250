import { createSlice } from "@reduxjs/toolkit";
import { getTextsByLanguageCode } from "../../../helpers/helpersLanguage";

const initialState = {
    languageEnable: {
        es: "es",
        en: "en"
    },
    selectCodeLanguage: "",
    languageTexts: getTextsByLanguageCode()
};

const isLanguageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setSelectCodeLanguage: (state, { payload }) => {
            state.selectCodeLanguage = payload;
        },
        setLanguageTexts: (state, { payload }) => {
            state.languageTexts = payload;
        },
    }
})

export const { setSelectCodeLanguage, setLanguageTexts } = isLanguageSlice.actions;

export default isLanguageSlice.reducer;