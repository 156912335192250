/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef, useEffect, useState, memo, useCallback } from "react";
import { useStoreComputer, useStoreGeneral, useStoreTask, useStoreUser } from "../hooks/store";
import { useChangeTask, useGeneralActions, useInitApp, useUserActions } from "../hooks";
import { Label, ModelButtons, Conversation, Computer } from "../models/Task";
import { tasks as allTasks } from '../store/slices/task/state';
import { setLoading } from "../store/slices/general";
import { useLabelComputer } from "../hooks/computer";
import detailTasks from '../store/slices/task/tasks';
import { Loader } from "../components";
import { Curtain } from "../models/Container3D";
import Guide from "./Guide";
import { TaskClock } from "./TaskClock";
import { ModalWarnningTime } from "./ModalWarnningTime";



function Container3D() {
    const refApp = useRef();
    const timerRef = useRef()
    const timerValue = useRef(0)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tasks } = useStoreTask();
    const isFirstTask = useRef(true);
    const { changeTask } = useChangeTask();
    const { saveTimeExperience } = useUserActions();
    const { questions, information } = useStoreUser();
    const { changeMovementStatus } = useGeneralActions();
    const [statusGuide, setStatusGuide] = useState("waiting");
    const refLastTask = useRef(tasks[0] ? tasks[0].type : null);
    const [isCurtainVisible, setIsCurtainVisible] = useState(false);
    const { isActive, selectedSection, sectionRequired } = useStoreComputer();
    const { isInstructionsActive, hasInstructionsBeenShown, loading } = useStoreGeneral();
    const { label, hasRetro } = useLabelComputer(tasks[0] || null, selectedSection, sectionRequired, questions);
    const existMoreTask = tasks.length > 0;


    useEffect(() => {
        if (!tasks) return;
        if (tasks.length === 0) return;
        const currentTask = tasks[0];
        if (allTasks.TaskFinal.id === currentTask.id) {
            saveTimeExperience(timerValue.current)
        }
        timerRef.current = setInterval(() => {

            timerValue.current += 1;
        }, 1000);
        if (tasks.fin)
            return () => {
                if (timerRef.current) {
                    clearInterval(timerRef.current)
                }
            }
    }, [tasks])
    const finishLoading = useCallback(() => {
        dispatch(setLoading(false));
    }, []);

    refApp.current = useInitApp(statusGuide, changeTask, finishLoading, tasks[0], isActive, isInstructionsActive, hasInstructionsBeenShown);

    useEffect(() => {
        if (isFirstTask.current) {
            isFirstTask.current = false;
            return;
        }
        if (!existMoreTask) return;
        const currentTask = tasks[0];
        const type = `${refLastTask.current}*${currentTask.type}`;
        switch (type) {
            case "computer*click": {
                refApp.current.addSpriteTask(currentTask, () => {
                    setIsCurtainVisible(true);
                    setTimeout(() => {
                        setIsCurtainVisible(false);
                        refApp.current.moveClient();
                        changeTask();
                    }, 300);
                });
                break;
            }
            case "going-zone*click": {
                refApp.current.addSpriteTask(currentTask, () => {
                    setIsCurtainVisible(true);

                    setTimeout(() => {
                        setIsCurtainVisible(false);
                        refApp.current.callingCouple();
                        changeTask();
                    }, 300);
                });
                break;
            }
            case "talk*click": {
                refApp.current.addSpriteTask(currentTask, () => changeTask());
                break;
            }
            case "computer*talk": {
                refApp.current.addSpriteTask(null, () => { });
                break;
            }
            case "computer*stand-up": {
                changeMovementStatus(true);
                refApp.current.standUp(currentTask, () => changeTask());
                break;
            }
            case "talk*stand-up": {
                refApp.current.setWorkWorkmate();
                refApp.current.standUp(currentTask, () => changeTask(), true);
                refApp.current.playTask();

                break;
            }
            case "talk*going-zone": {
                refApp.current.setGoingZone(currentTask, () => {
                    setIsCurtainVisible(true);
                    changeMovementStatus(false);
                    refApp.current.removeWorkers();
                    refApp.current.playTask();

                    setTimeout(() => {
                        setIsCurtainVisible(false);
                        refApp.current.showCouple();
                        refApp.current.sitDownUser();
                        changeTask();
                    }, 600);
                });
                break;
            }
            default:
                break;
        }

        if (!existMoreTask) {
            refLastTask.current = null;
            return;
        }
        if (refLastTask.current !== tasks[0].type) refLastTask.current = tasks[0].type;
    }, [tasks.length]);

    useEffect(() => {
        if (!existMoreTask) return;
        if (!refApp.current) return;
        const currentTask = tasks[0];
        const isQuestion = currentTask.optionalMessages ? (currentTask.wasAnswer ? false : true) : false;
        if (currentTask.id === detailTasks.TaskBreakFast.id) {
            setIsCurtainVisible(true);
            setTimeout(() => {
                refApp.current.showCouple(false);
                setIsCurtainVisible(false);
            }, 600);
        }
        if (!currentTask.person) return;

        if (currentTask.type === "talk" && !isQuestion) {
            refApp.current.setClient1Animation("speaking", currentTask.person);
        } else {
            refApp.current.setClient1Animation("listening", currentTask.person);
        }

    }, [tasks]);

    useEffect(() => {
        if (!existMoreTask) return;
        if (!refApp.current) return;
        const currentTask = tasks[0];
        if (currentTask.type === 'talk') {
            refApp.current.playConversation();
        }
        if (hasRetro) {
            refApp.current.playRetro();
        }
    }, [tasks.length, hasRetro])

    if (!existMoreTask) return <></>;

    if (information === null) {
        navigate("/")
    }

    return (
        <main id="container" className="h-screen  w-full">
            <Curtain isVisible={isCurtainVisible} />
            <ModelButtons />
            <ModalWarnningTime />
            <div className="fixed top-[52px] right-[47px] z-[60] w-[84px] h-[84px]">
                <TaskClock isLoading={loading} />
            </div>
            {existMoreTask && tasks[0].label && label !== null && (
                <div className={`absolute z-[60] top-[52px] max-w-[70%]  `} style={{ zIndex: 10000000 }}>
                    <Label task={label} hasRetro={false} />
                </div>
            )}
            <Guide closeGuide={() => setStatusGuide("starting")} />
            <Loader isLoading={loading} />
            <Computer />
            <Conversation />
            {/* <TestingHelper /> */}
        </main>
    );
}

export default memo(Container3D);
