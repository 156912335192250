import { Close } from "../assets";

const InfoModal = ({ title, children, close }) => {
    return (
        <>
            <div className="w-full relative bg-strong-blue rounded-t-xl py-[6px]  text-center text-[20px] text-white ">
                <h2 className="text-white font-GraphikSemibold text-2xl">{title}</h2>
                <div onClick={close} className="absolute flex items-center h-full  top-0 right-6 cursor-pointer">
                    <img alt="close glossary" src={Close} className="w-6 h-6" />
                </div>
            </div>
            <div className="w-full  bg-white overflow-scroll rounded-b-xl">
                {children}
            </div>
        </>
    );
}


export default InfoModal;