import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { Button, CustomSelect, TextFormField, WhiteCircle, ErrorField, PrincipalLogo } from "../components";
import { validationLogin } from "../form/register";
import { useUserActions } from "../hooks";
import { startAttempt } from "../api";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";



const campus = [
    "Monterrey",
    "Laguna",
    "Saltillo",
    "Ciudad de México",
    "Estado de México",
    "Santa Fe",
    "Aguascalientes",
    "Chihuahua",
    "Guadalajara",
    "León",
    "Morelia",
    "Sinaloa",
    "Sonora Norte",
    "Cuernavaca",
    "Hidalgo",
    "Puebla",
    "Querétaro",
    "San Luis Potosí",
    "Tampico",
    "Toluca",
    "Chiapas",
    "Ciudad Juárez",
    "Ciudad Obregón",
    "Irapuato",
    "Veracruz",
    "Zacatecas",

];
const year = new Date().getFullYear();
const LIMIT_ATTEMPS_CODE = 401;
// const INVALID_CRN_CODE = 402;

const Register = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { setLogin } = useUserActions();
    const onSubmit = async (formData) => {
        setIsLoading(true);
        try {
            const { id, name, crn, campus } = formData;
            const { payload } = await startAttempt({ campus, crn, id, name });
            const { answerId } = payload;
            setLogin({ ...formData, answerId });
            navigate("/home/character");
        } catch (error) {
            const { status } = error;
            const checkErrorMsg = error?.response?.data?.message
            if (status === LIMIT_ATTEMPS_CODE) {
                alert("Ha superado el máximo de intentos")
            } else if (checkErrorMsg === 'INVALID_CRN_CODE') {
                alert("CRN incorrecto");
            } else if (checkErrorMsg === 'COMPLETA_EXPERENCIA') {
                alert("Ya completaste la experencia");
            } else {
                alert("Intentar más tarde");
                // navigate("/");
            }
            // navigate("/");
        } finally {
            setIsLoading(false)
        }
    };
    return (
        <Fragment>
            <div className=" w-full  h-screen flex flex-col ">
                <div className=" pt-[72px] xl:pt-[120px] pb-[42px] xl:pb-[57px]">
                    <PrincipalLogo />
                </div>
                <div className={`w-full grow  flex  items-center flex-col  overflow-scroll `}>
                    <div className="w-full  grow  flex flex-col justify-center   ">
                        <div className="lg:w-[402px] lg:self-center mb-2 xl:mb-8">
                            <h3 className="label-register  text-left font-GraphikMedium text-[16px] xl:text-[20px]">{getJsonTextLanguageDisplay("registro_desktop_interfaz2D_recuadro_de_texto_1")}</h3>
                        </div>
                        <Formik
                            initialValues={{
                                id: "",
                                name: "",
                                crn: "",
                                campus: "",
                            }}
                            validationSchema={validationLogin}
                            onSubmit={onSubmit}
                        >
                            {({ handleSubmit, handleChange, values, isValid, isSubmitting, status }) => (
                                <Form
                                    className=" flex flex-col home-button mb-4 justify-center w-full lg:w-[402px] lg:mb-[40px] lg:self-center"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="xl:mb-5">
                                        <TextFormField
                                            isSubmitting={isSubmitting}
                                            handleChange={handleChange}
                                            name="name"
                                            placeholder={getJsonTextLanguageDisplay("registro_desktop_interfaz2D_placeholder_ingreso_nombre")}
                                            value={values.name}
                                        />
                                        <TextFormField
                                            isSubmitting={isSubmitting}
                                            isValid={isValid}
                                            handleChange={handleChange}
                                            name="id"
                                            placeholder={getJsonTextLanguageDisplay("registro_desktop_interfaz2D_placeholder_ingreso_matricula")}
                                            value={values.id}
                                        />
                                        <TextFormField
                                            isSubmitting={isSubmitting}
                                            isValid={isValid}
                                            handleChange={handleChange}
                                            name="crn"
                                            placeholder={getJsonTextLanguageDisplay("registro_desktop_interfaz2D_placeholder_ingreso_crn")}
                                            value={values.crn}
                                        />
                                    </div>
                                    <h3 className="label-register mb-[24px]">{getJsonTextLanguageDisplay("registro_desktop_interfaz2D_recuadro_de_texto_2")}</h3>
                                    <div className="mb-6 xl:mb-8 w-full xl:h-[56px] ">
                                        <CustomSelect
                                            id="campus"
                                            name={"campus"}
                                            placeholder={getJsonTextLanguageDisplay("registro_desktop_interfaz2D_placeholder_ingreso_lista")}
                                            data={campus}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage name="campus">{(msg) => <ErrorField message={msg} />}</ErrorMessage>
                                    </div>

                                    <div className="mt-auto  w-full flex justify-center  button-content ">
                                        <Button disabled={isLoading} >{getJsonTextLanguageDisplay("registro_desktop_interfaz2D_boton")}</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="mt-auto  mb-[12px] lg:mb-[0px] w-full  ">
                            <p className="text-center footer-home">
                                {getJsonTextLanguageDisplay("registro_desktop_interfaz2D_recuadro_de_texto_3", { "[replace1]": year })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <WhiteCircle />
        </Fragment>
    );
};

export default Register;
