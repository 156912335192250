import React from 'react'
import { useStoreBonusTime } from '../hooks/store'
import { BonusActions } from '../hooks/BonusActions'
import imgs from '../assets/img'
import { getJsonTextLanguageDisplay } from '../helpers/helpersLanguage'

export const ModalWarnningTime = () => {
    const { activeModalWarnnigTime } = useStoreBonusTime()
    const { setActiveModalWarning } = BonusActions()

    if (!activeModalWarnnigTime) {
        return <></>
    }
    return (
        <div className="fixed z-[100] w-full h-full bg-[#00000092] flex justify-center items-center">
            <div className='h-[544px] w-[1080px] bg-white rounded-[66px] border-[#31B5D8] border-[8px] px-[107px] py-[80px]'>
                <div className='h-full w-full flex flex-col justify-center items-center'>
                    <div className='h-[96px] w-[96px]'>
                        <img className='h-full w-full object-contain' src={imgs.peligro} alt="peligro" />
                    </div>
                    <div className='h-[calc(100%-96px-58px)] w-full'>
                        <div className='h-[80px] w-full text-center' >
                            <span className='text-[#B64D34] font-bold text-[50px]'>{getJsonTextLanguageDisplay("mensaje_de_advertencia_interfaz2d_recuadro_de_texto_1")}</span>
                        </div>
                        <div className='h-[calc(100%-80px)] w-full text-center flex justify-center items-center' >
                            <p className='text-[24px]' dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("mensaje_de_advertencia_interfaz2d_recuadro_de_texto_2") }}></p>
                        </div>
                    </div>
                    <div className='h-[58px] w-full flex justify-center items-center'>
                        <div onClick={() => {
                            setActiveModalWarning(false)
                        }} className='w-[224px] h-full flex justify-center items-center bg-[#F28E14] rounded-[62px]'>
                            <span className='text-white text-[24px] font-light'>{getJsonTextLanguageDisplay("mensaje_de_advertencia_interfaz2d_boton")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
