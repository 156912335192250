export const getErrorMessage = (error) => {
    const { response } = error;
    const { status, data } = response;
    if (status >= 500 || !data.payload) {
        return "Error de sistema, intente más tarde"
    }

    const { payload: { errors } } = data;

    if (errors) {
        const errorData = errors[0];
        return `${errorData.msg}`
    }

    return data.message ? data.message : error.message



}