import { useDispatch } from 'react-redux'
import { setLanguageTexts, setSelectCodeLanguage } from '../store/slices/language'
import { getTextsByLanguageCode } from '../helpers/helpersLanguage'

export const LanguageActions = () => {
    const dispatch = useDispatch()

    const updateCodeLanguage = (code = "") => {
        if (code === null) {
            window.location.href = "/";
            return
        }
        dispatch(setSelectCodeLanguage(code))
        const getTextsLanguageByCode = getTextsByLanguageCode(code)
        dispatch(setLanguageTexts(getTextsLanguageByCode))
    }

    return { updateCodeLanguage }
}
