import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useComputerActions, useUserActions } from "../../hooks";
import { setNotificationStatus } from "../../store/slices/task";
import { useStoreUser } from "../../hooks/store";
import { Concepts } from "./task3";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const DocumentSection = () => {
    const {
        concepts,
        questions: { questionConcept },
    } = useStoreUser();
    const dispatch = useDispatch();
    const { selectConceptId } = useUserActions();
    const { changeGlossaryStatus } = useComputerActions();
    const { retro, selectedConcept } = questionConcept;
    const hasFinished = questionConcept.isCorrect || retro.times > 1;
    const handlerClick = useCallback(
        (id) => {
            const isSelected = questionConcept.selectedConcept.find((item) => item === id);
            if (questionConcept.selectedConcept.length === 8 && !isSelected) {
                dispatch(setNotificationStatus({ isActive: true, title: getJsonTextLanguageDisplay("modal_aviso_interfaz2D_modal_titulo"), body: getJsonTextLanguageDisplay("modal_aviso_interfaz2D_modal_info") }));
                return;
            }
            selectConceptId(id);
        },
        [dispatch, questionConcept.selectedConcept, selectConceptId]
    );
    const items = concepts.map((elem, index, concepts) => (
        <Concepts.Item
            id={elem.id}
            key={elem.id}
            index={index}
            title={elem.title}
            field={elem.field}
            value={elem.value}
            hasFinished={hasFinished}
            concepts={selectedConcept}
            handlerClick={handlerClick}
            isTheFirstElement={index === 0}
            isTheLastElement={index === concepts.length - 1}
        />
    ));
    return (
        <Concepts
            itemsSelected={questionConcept.selectedConcept.length}
            changeGlossaryStatus={changeGlossaryStatus}
            attemps={retro.times}
            hasFinished={hasFinished}
        >
            {items}
        </Concepts>
    );
};

export default DocumentSection;
