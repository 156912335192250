import { Fragment, useEffect, useState } from "react";
import { InstructionCarousell } from "../models";
import { Button, WhiteCircle } from "../components";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";


const instructions = [
    getJsonTextLanguageDisplay("demo_home_guia_introduccion_titulo_1"),
    getJsonTextLanguageDisplay("demo_home_guia_introduccion_titulo_2"),
    getJsonTextLanguageDisplay("demo_home_guia_introduccion_titulo_3"),
    getJsonTextLanguageDisplay("demo_home_guia_introduccion_titulo_4")
];

const IntroductionGuide = ({ status, close }) => {
    const [slice, setSlice] = useState(0);

    useEffect(() => {
        return () => {
            setSlice(0)
        }
    }, [status])
    const buttonText = slice === instructions.length - 1 ? getJsonTextLanguageDisplay("demo_home_guia_introduccion_button_1") : getJsonTextLanguageDisplay("demo_home_guia_introduccion_button_2");
    if (status === "running") return <></>
    return (
        <Fragment>
            <div className="w-full h-full absolute top-0 flex justify-center lg:px-[78px] overflow-scroll bg-computer " style={{ zIndex: 306 }}>
                <div className="w-full flex flex-col h-full">
                    <div className="h-[135px] flex items-end w-full px-[87px] xl:px-[174px]">
                        <div>
                            <div className="h-[26px] guide-title  mb-[24px] font-GraphikBold text-[32px] xl:text-[36px] ">
                                <h2>{getJsonTextLanguageDisplay("demo_home_guia_introduccion_1")}</h2>
                            </div>
                            <div className="h-[24px] guide-subtitle text-[24px] font-GraphikMedium ">
                                <p>
                                    {slice + 1}. {instructions[slice]}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" w-full grow flex items-center xl:px-[87px]">
                        <InstructionCarousell changeSlice={(item) => setSlice(item)} />
                    </div>
                    <div className="w-full h-fit lg:h-[156px] flex justify-end pb-0 px-[87px] xl:px-[174px] ">
                        {slice === instructions.length - 1 && (
                            <Button onClick={close} className="h-[48px]">
                                {buttonText}
                            </Button>
                        )}
                        {/* {!(buttonText === 'Saltar') &&
                            <Button onClick={close} className="h-[48px]">
                                {buttonText}
                            </Button>
                        } */}
                    </div>
                </div>
            </div>

            <WhiteCircle />
        </Fragment>
    );
}

export default IntroductionGuide;