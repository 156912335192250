import { getJsonTextLanguageDisplay } from "./helpersLanguage"

const studentBody = [
    [
        { text: getJsonTextLanguageDisplay("table_header_matricula"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_reactivos"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_valores_entrada"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_respuesta"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_estatus"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_puntos"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_retroalimentacion"), style: "header" },

    ],
]
export const adminBody = [
    [
        { text: getJsonTextLanguageDisplay("table_header_matricula"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_reactivos"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_valores_entrada"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_respuesta"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_estatus"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_puntos"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_retroalimentacion"), style: "header" },
        { text: getJsonTextLanguageDisplay("table_header_nivel_competencia"), style: "header" }
    ],
]

export const adminStructureTable = {
    content: [
        {
            style: 'tableExample',
            table: {
                widths: [43, 43, 50, 50, 30, 35, "*", "*"],
                body: adminBody
            }
        },
    ],
    styles: {
        tableExample: {
            margin: [0, 0, 0, 0]
        },
        header: {
            fontSize: 7,
            bold: true,
            margin: [0, 0, 0, 0],
            alignment: "center"
        },
        values: {
            fontSize: 5,
            alignment: "center"

        }
    },
}

export const studentStructureTable = {
    content: [
        {
            style: 'tableExample',
            table: {
                widths: [43, 43, 50, 50, 30, 35, "*"],
                body: studentBody
            }
        },
    ],
    styles: {
        tableExample: {
            margin: [0, 0, 0, 0]
        },
        header: {
            fontSize: 7,
            bold: true,
            margin: [0, 0, 0, 0],
            alignment: "center"
        },
        values: {
            fontSize: 5,
            alignment: "center"

        }
    },
}

export const tableStructure = {
    content: [
        {
            style: 'tableExample',
            table: {
                widths: [43, 43, 50, 50, 30, 35, "*", "*"],
                body: studentBody
            }
        },
    ],
    styles: {
        tableExample: {
            margin: [0, 0, 0, 0]
        },
        header: {
            fontSize: 7,
            bold: true,
            margin: [0, 0, 0, 0],
            alignment: "center"
        },
        values: {
            fontSize: 5,
            alignment: "center"

        }
    },
}



// playground requires you to assign document definition to a variable called dd

export const introductionStructure = {
    content: [
        {
            text: getJsonTextLanguageDisplay("demo_inicio_experencia_pdf_1"),
            style: 'header'
        },

    ],
    styles: {
        header: {
            fontSize: 18,
            bold: true
        },
        subheader: {
            fontSize: 15,
            bold: true
        },
        quote: {
            italics: true
        },
        small: {
            fontSize: 8
        }
    }

}

