import { useState } from "react";
import { useEffect, useRef } from "react";
import { useStoreGeneral } from "../../hooks/store";
import { CloseArrowLabel } from "../../assets";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const Label = ({ task, hasRetro }) => {
    const messageRef = useRef();
    const { loading } = useStoreGeneral();
    const [isHidden, setIsHidden] = useState(false);
    const [hiddenRetro, setHidderRetro] = useState(false);
    const idRef = useRef(null);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.innerText = "";
            messageRef.current.insertAdjacentHTML("beforeend", task);
        }
    });

    useEffect(() => {
        if (hasRetro && !hiddenRetro) {
            clearTimeout(idRef.current)
            idRef.current = setTimeout(() => {
                setHidderRetro(true);
            }, 7800);
            return;
        } else {
            clearTimeout(idRef.current)
        }
        if (hiddenRetro) setHidderRetro(false);
    }, [hasRetro, hiddenRetro, task, isHidden]);
    if (loading) return;
    return (
        <div
            className={`flex  ${hiddenRetro ? "opacity-0" : "opacity-100"}   ${"justify-between  rounded-r-lg"
                } items-center  ${isHidden ? "  opacity-80" : "w-full"} ${hasRetro ? "bg-strong-retro" : "bg-strong-label"} `}
            style={{ transition: "opacity 700ms", zIndex: 50000 }}
        >
            <div className={` relative py-[8px]   px-[16px]`}>
                <p className="h-full px-[10px] xl:px-[40px] font-GraphikSemibold text-[16px]  text-white">
                    <b>{hasRetro ? getJsonTextLanguageDisplay("label_retroalimentacion") : getJsonTextLanguageDisplay("label_tarea")}</b>
                </p>
                <div
                    onClick={() => setIsHidden(!isHidden)}
                    className="absolute right-0 w-[24px] h-[24px] "
                    style={{ top: "calc(50% - 12px)", zIndex: 50000 }}
                >
                    <img src={CloseArrowLabel} alt="Close task label" style={{ transform: isHidden ? "rotate(180deg)" : "rotate(0deg)" }} />
                </div>
            </div>
            {!isHidden &&
                <div
                    className={`h-full ${hasRetro ? "bg-strong-blue" : "bg-task-color"} px-[14px] py-[8px]  xl:px-[40px]  `}
                    style={{ height: "100%", zIndex: 50000 }}
                >
                    <p ref={messageRef} className="font-GraphikMedium text-[16px] " style={{ color: "white" }}></p>
                </div>
            }
        </div>
    );
};

export default Label;
