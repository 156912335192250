import { createSlice } from "@reduxjs/toolkit";
import { getTypeCase, getTypeHistogram, idTaskFirstQuestion } from "../../../types";
import { correctFilesBossDialogues, inCorrectFilesBossDialogues } from "./talk1";
import { tasks, optionalTask } from "./state";

const {
    Task_1,
    Task_1_1,
    Task_2,
    Task_2_1,
    Task_2_2,
    Task_2_3,
    Task_2_4,
    Task_3_1,
    Task_3_2,
    Task_3_3,
    Task_4,
    Task_4_1,
    Task_4_2,
    Task_4_3,
    Task_5,
    Task_5_1,
    Task_5_2,
    Task_6,
    Task_6_1,
    Task_6_2,
    Task_6_3,
    TaskThankingWorkMate,
    TaskGoingtoBoss,
    TaskConversationBoss,
    TaskOpenPresentation,
    TaskStartingMeeting,
    TaskSelectSlice,
    TaskRetroSlice,
    TaskStartingMeeting2,
    TaskSelectSlice2,
    TaskRetroSlice2,
    TaskStartingMeeting3,
    TaskSelectSlice3,
    TaskRetroSlice3,
    TaskStartingMeeting4,
    TaskSelectSlice4,
    TaskRetroSlice4,
    TaskStartingMeeting5,
    TaskSelectSlice5,
    TaskRetroSlice5,
    TaskFinishMeetingConversation,
    TaskGoBackOffice,
    TaskCallCouple,
    TaskWomanConversation,
    TaskRegister,
    TaskOfferingWoman,
    TaskManConversation,
    TasksRegisterMan,
    TaskCreditMan,
    TaskBreakFast,
    TaskFinal,
} = tasks;
const { Task_Optional_1, Task_Optional_2, Task_Optional_3, Task_Optional_6, Task_Optional_6_1 } = optionalTask;

const initialState = {
    tasks: [
        Task_1,
        Task_1_1,
        Task_2,
        Task_2_1,
        Task_2_2,
        Task_2_3,
        Task_2_4,
        Task_3_1,
        Task_3_2,
        Task_3_3,
        Task_4,
        Task_4_1,
        Task_4_2,
        Task_4_3,
        Task_5,
        Task_5_1,
        Task_5_2,
        Task_6,
        Task_6_1,
        Task_6_2,
        Task_6_3,
        TaskThankingWorkMate,
        TaskGoingtoBoss,
        TaskConversationBoss,
        TaskOpenPresentation,
        TaskStartingMeeting,
        TaskSelectSlice,
        TaskRetroSlice,
        TaskStartingMeeting2,
        TaskSelectSlice2,
        TaskRetroSlice2,
        TaskStartingMeeting3,
        TaskSelectSlice3,
        TaskRetroSlice3,
        TaskStartingMeeting4,
        TaskSelectSlice4,
        TaskRetroSlice4,
        TaskStartingMeeting5,
        TaskSelectSlice5,
        TaskRetroSlice5,
        TaskFinishMeetingConversation,
        TaskGoBackOffice,
        TaskCallCouple,
        TaskWomanConversation,
        Task_1,
        TaskRegister,
        TaskOfferingWoman,
        TaskManConversation,
        Task_1,
        TasksRegisterMan,
        TaskCreditMan,
        TaskBreakFast,
        TaskFinal,
    ],
    optionalTasks: [
        { idTask: idTaskFirstQuestion, extraTasks: [Task_Optional_1, Task_Optional_2, Task_Optional_3] },
        { idTask: 24, extraTasks: [Task_Optional_6, Task_Optional_6_1] },
    ],
    notification: { isActive: false, title: "", body: "", textButton: null },
};

export const taskSlice = createSlice({
    name: "task",
    initialState,
    reducers: {
        popTask: (state) => {
            return void state.tasks.shift();
        },
        setNotificationStatus: (state, action) => {
            const { payload } = action;
            if (payload.isActive) {
                state.notification = payload;
            } else {
                state.notification = {
                    body: "",
                    isActive: false,
                    title: "",
                };
            }
        },
        addingOptionalMessages: (state, action) => {
            const {
                payload: { isCorrect, value },
            } = action;
            let type = "";
            switch (state.tasks[0].id) {
                case idTaskFirstQuestion: {
                    type = getTypeCase();
                    break;
                }
                case 17: {
                    type = getTypeHistogram();
                    break;
                }
                case tasks.TaskOfferingWoman.id: {
                    type = "answer";
                    break;
                }
                default: {
                    type = "answer";
                }
            }

            state.tasks[0].wasAnswer = true;
            const optionalMessages = state.tasks[0].optionalMessages[type];
            let addingEmailMessages = [];
            if (state.tasks[0].id === idTaskFirstQuestion) {
                addingEmailMessages = isCorrect ? optionalMessages.correct : optionalMessages.incorrect[value]
            } else {
                addingEmailMessages = isCorrect ? optionalMessages.correct : optionalMessages.incorrect;
            }

            state.tasks[0].messages = state.tasks[0].messages.concat(addingEmailMessages);
            if (!isCorrect) {
                state.tasks[0].label = optionalMessages.retro;
                state.tasks[0].hasRetro = true;
                const { addingTasks = false } = state.tasks[0];
                if (addingTasks) taskSlice.caseReducers.addingExtraTasks(state);
            } else {
                state.tasks[0].label = null;
            }
        },

        addingExtraTasks: (state) => {
            if (state.optionalTasks.length === 0) return;
            if (state.tasks.length === 0) return;
            const { id: idTask } = state.tasks[0];
            for (let index = 0; index < state.optionalTasks.length; index++) {
                const element = state.optionalTasks[index];
                if (element.idTask === idTask) {
                    state.tasks = [state.tasks[0]].concat(element.extraTasks).concat(state.tasks.slice(1));
                }
            }
        },

        addingDialoguesBoss: (state, action) => {
            const {
                payload: { areCorrectMeetingFiles, taskId },
            } = action;
            const conversationBossTaskIndex = state.tasks.findIndex((task) => task.id === taskId);
            if (conversationBossTaskIndex < 0) throw new Error("Task not found");
            const conversationBossTask = state.tasks[conversationBossTaskIndex].messages;
            if (areCorrectMeetingFiles) {
                state.tasks[conversationBossTaskIndex].messages = [...conversationBossTask, ...correctFilesBossDialogues];
            } else {
                state.tasks[conversationBossTaskIndex].messages = [...conversationBossTask, ...inCorrectFilesBossDialogues];
            }
        },
        addRetroDialoguesMeeting: (state, action) => {
            const { payload } = action;
            const { messages, taskId } = payload;
            let taskWasFound = false;
            for (let index = 0; index < state.tasks.length; index++) {
                const task = state.tasks[index];
                if (task.id === taskId) {
                    task.messages = [...messages];
                    taskWasFound = true;
                    break;
                }
            }
            if (!taskWasFound) throw new Error("Task not found");
        },
    },
});

export const { popTask, setNotificationStatus, addingOptionalMessages, addingExtraTasks, addingDialoguesBoss, addRetroDialoguesMeeting } =
    taskSlice.actions;

export default taskSlice.reducer;
