export const modulo1Seccion1RevisionDeCorreos = {
    tarea_1_revisa_tu_computadora_na_escritorio_ambiente3D_boton_plegable_tarea_1: {
        es: "Revisa tu computadora.",
        en: "Check your computer."
    },
    //? tarea 2
    tarea_2_revisa_tu_correo_na_menu_interfaz2D_boton_plegable_tarea_1: {
        es: "Revisa tu correo.",
        en: "Check your email."
    },
    tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_documentos_1: {
        es: "Documentos",
        en: "Documents"
    },
    tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_correo_1: {
        es: "Correo",
        en: "Email"
    },
    tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_clientes_1: {
        es: "Clientes",
        en: "Clients"
    },
    tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_agenda_1: {
        es: "Agenda",
        en: "Agenda"
    },
    //? tarea 3 pantalla menu correos
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton_plegable_tarea_1: {
        es: `Revisa los correos pendientes.`,
        en: "Check pending emails."
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton_plegable_tarea_2: {
        es: `Da click en "x" para salir.`,
        en: `Click on 'X' to exit.`
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton: {
        es: `Regresar`,
        en: "Back"
    },
    //? tarea 3 pantalla correo 1
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_nombre: {
        es: `Lic. Miguel López`,
        en: "Mr. Miguel López"
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_posicion: {
        es: `Gerente General. Banco Creciendo Juntos`,
        en: "General Manager. Banco Creciendo Juntos"
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_2: {
        es: `Junta con el Gerente General. Banco Creciendo Juntos`,
        en: "Meeting with the General Manager. Banco Creciendo Juntos"
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_correo_1_interfaz2D_card_info_2: {
        es: `El Lic. Miguel López, Gerente Gral. del “Banco Creciendo Juntos” te invita a una junta a las <b class="strong-text font-GraphikBold">11:00 a.m.</b> en la sala de juntas para revisar la propuesta de la nueva tarjeta de crédito del Banco Creciendo Juntos. <b class="strong-text font-GraphikBold">Recuerda llevar los documentos necesarios para revisar la propuesta.</b> La puntualidad es importante.`,
        en: `Mr. Miguel López, General Manager of Banco Creciendo Juntos, invites you to a meeting at <b class="strong-text font-GraphikBold"> 11:00 a.m.</b> in the boardroom to review the proposal for the new credit card of Banco Creciendo Juntos. <b class="strong-text font-GraphikBold"> Remember to bring the necessary documents to review the proposal. </b>  Punctuality is important.`
    },
    //? tarea 3 pantalla correo 2
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_nombre: {
        es: `Administración General`,
        en: "General Management"
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_posicion: {
        es: `Banco Creciendo Juntos`,
        en: "Banco Creciendo Juntos"
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_2: {
        es: `Promociones para clientes registrados.`,
        en: "Promotions for registered clients."
    },
    tarea_3_revisa_los_correos_pendientes_na_pantalla_correo_2_interfaz2D_card_info_2: {
        es: `<b class="strong-text font-GraphikBold">1)</b> Los clientes registrados que participaron en la encuesta de la Nueva Tarjeta Electrónica recibirán de regalo un electrodoméstico. <br>
    
        <b class="strong-text font-GraphikBold">2)</b> Los clientes registrados que no participaron en la encuesta de la Nueva Tarjeta Electrónica recibirán de regalo una tarjeta por $200 pesos. <br>
        <b class="strong-text font-GraphikBold">**</b>Los clientes no registrados se registrarán para que puedan participar en futuras promociones.
        `,
        en: `<b class="strong-text font-GraphikBold">1)</b> Registered clients who participated in the New Electronic Card survey will receive a home appliance as a gift. <br>
    
        <b class="strong-text font-GraphikBold">2)</b> Registered clients who did not participate in the New Electronic Card survey will receive a $200 pesos gift card. <br>
        <b class="strong-text font-GraphikBold">**</b>Non-registered clients will be registered so they can participate in future promotions.
        `,
    },
}