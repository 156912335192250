export const modulo1Seccion2PrimeraInteraccion = {
    //? tarea 1
    tarea_1_conversacion_cliente_na_escritorio_ambiente3D_boton_plegable_tarea_1: {
        es: "Tienes un cliente en espera, habla con él.",
        en: "You have a client waiting, speak with him."
    },
    tarea_1_conversacion_cliente_na_juan_ambiente3D_card_texto_1: {
        es: "¡Buenos días! He venido a solicitar una tarjeta de crédito.",
        en: "Good morning! I've come to apply for a credit card."
    },
    tarea_1_conversacion_cliente_na_usuario_ambiente3D_card_texto_1: {
        es: "Claro, ¿me podrá facilitar su nombre completo y sus datos de referencia?",
        en: "Of course, could you provide me with your full name and reference details?"
    },
    tarea_1_conversacion_cliente_na_juan_ambiente3D_card_texto_2: {
        es: "Sí, mi nombre es Juan Enrique Reyes González y soy del estado de Jalisco...",
        en: "Yes, my name is Juan Enrique Reyes González and I'm from the state of Jalisco..."
    },
    tarea_1_conversacion_cliente_na_usuario_ambiente3D_card_texto_2: {
        es: "Muchas gracias, verificaré su información en el sistema.",
        en: "Thank you very much, I will verify your information in the system."
    },
    //? tarea 2
    tarea_2_revisa_la_computadora_na_escritorio_ambiente3D_boton_plegable_tarea_1: {
        es: "Revisa tu computadora.",
        en: "Check your computer."
    },
    //? tarea 3
    tarea_3_ingresa_seccion_clientes_na_menu_boton_plegable_tarea_1: {
        es: "Ingresa a la sección de clientes.",
        en: "Enter the clients section."
    },
}