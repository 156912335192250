export const modulo1DecisionRecompesa = {
    tarea_1_base_promocion_boton_plegable_tarea_1: {
        es: "Con base en las promociones para clientes registrados decide que recompensa ofrecerás al cliente.",
        en: "Based on the promotions for registered clients, decide which reward to offer the client."
    },
    tarea_1_base_promocion_card_contenido_opc_1: {
        es: "Entregar electrodoméstico.",
        en: "Deliver home appliance."
    },
    tarea_1_base_promocion_card_contenido_opc_2: {
        es: "Entregar tarjeta de $200 pesos.",
        en: "Deliver $200 pesos gift card."
    },
    tarea_1_base_promocion_card_contenido_opc_3: {
        es: "Participará en futuras promociones.",
        en: "Participate in future promotions."
    },
    tarea_1_base_promocion_text_opcion_1: {
        es: "Opción",
        en: "Option"
    },
    // dialogos
    // opc1
    tarea_1_base_promocion_opc_1_usuario_1: {
        es: "¡Felicidades! Por su colaboración y participación le entregaremos de regalo un electrodoméstico.",
        en: "Congratulations! For your collaboration and participation, we will give you a home appliance as a gift."
    },
    tarea_1_base_promocion_opc_1_juan_1: {
        es: "¡Muchas gracias! Mas tarde pasaré a recoger el electrodoméstico",
        en: "Thank you very much! I'll come later to pick up the home appliance."
    },
    // opc2
    tarea_1_base_promocion_opc_2_usuario_1: {
        es: "¡Gracias! Por su colaboración le entregaremos una tarjeta de regalo por $200 pesos.",
        en: "Thank you! For your collaboration, we will give you a $200 pesos gift card."
    },
    tarea_1_base_promocion_opc_2_juan_1: {
        es: "¡Muchas gracias! Mas tarde pasaré a recoger la tarjeta de promoción.",
        en: "Thank you very much! I'll come later to pick up the gift card."
    },
    // opc3
    tarea_1_base_promocion_opc_3_usuario_1: {
        es: "Ya lo registré, así podrá participar en las promociones para clientes registrados.",
        en: "I've already registered you, so you can participate in promotions for registered clients."
    },
    tarea_1_base_promocion_opc_3_juan_1: {
        es: "¡Muchas gracias! Espero pronto participar en sus promociones.",
        en: "Thank you very much! I look forward to participating in your promotions soon."
    },
}