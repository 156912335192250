export const modulo1ReactivoRetroMujeres = {
    tarea_1_revisar_computadora_boton_plegable_1: {
        es: "Revisa tu computadora. ¡Tienes un correo importante!",
        en: "Check your computer. You have an important email!"
    },
    tarea_1_revisar_computadora_boton_plegable_2: {
        es: "Revisa tu correo.",
        en: "Check your email."
    },
    tarea_1_revisar_computadora_correo_name: {
        es: "Administración General",
        en: "General Management"
    },
    tarea_1_revisar_computadora_correo_posicion: {
        es: "Banco Creciendo Juntos",
        en: "Banco Creciendo Juntos"
    },
    // es hombre
    tarea_1_revisar_computadora_correo_title: {
        es: "Nueva tarjeta preferente. Banco Creciendo Juntos.",
        en: "New preferred card. Banco Creciendo Juntos."
    },
    tarea_4_lee_correo_card_info_hombre: {
        es: `
        Se ofrecerá a la <b class="strong-text font-GraphikBold">población de clientes registrados,</b> un crédito con tasa 
        preferencial 5% menor a la tasa de interés que se ofrece para todo público. Se 
        deberá revisar si el cliente <b class="strong-text font-GraphikBold">[replace1]</b> con <b class="strong-text font-GraphikBold">[replace2] años de edad</b> y con domicilio en <b class="strong-text font-GraphikBold">[replace3],</b> es apto para recibir la tasa preferencial.
        `,
        en: `
        A preferential rate credit will be offered to the population of registered clients, 5% lower than the interest rate offered to the general public. 
        It should be checked whether the client [replace1] aged [replace2] and residing in [replace3] is eligible to receive the preferential rate.
        `
    },
    // es mujer
    tarea_1_revisar_computadora_correo_title_2: {
        es: "Nueva promoción para clientes registrados. Banco Creciendo Juntos",
        en: "New promotion for registered clients. Banco Creciendo Juntos."
    },
    tarea_4_lee_correo_card_info_mujer: {
        es: `
            Se otorgará a la muestra de mujeres jóvenes <b class="strong-text font-GraphikBold">que han asistido a la feria de 
            promoción</b> la tarjeta preferente “Banco Creciendo Juntos cree en ti". Se deberá 
            revisar si la cliente <b class="strong-text font-GraphikBold">[replace1]</b> con
            <b class="strong-text font-GraphikBold">[replace2] años de edad</b> y con domicilio en <b class="strong-text font-GraphikBold">[replace3]</b>, es apta para recibir la nueva promoción.
        `,
        en: `
            The preferred card "Banco Creciendo Juntos believes in you" will be awarded to the sample of young women who have attended the promotion fair. 
            It should be checked whether the client [replace1] aged [replace2] and residing in [replace3] is eligible to receive the new promotion.
        `,

    },
    // boton plegable
    tarea_4_lee_correo_con_atencion_boton_plegable_1: {
        es: "Lee el correo con atención. Cuando termines, da click en ‘’Regresar’’",
        en: "Read the email carefully. When you're done, click on ‘’Back’’."
    },
    tarea_5_seccion_clientes_boton_plegable_1: {
        es: "Dirígete a la sección de clientes.",
        en: "Head to the clients section."
    },
    tarea_6_seccion_registro_boton_plegable_1: {
        es: "Ingresa a la sección de registro.",
        en: "Enter the registration section."
    },
    tarea_7_ver_cliente_boton_plegable_1: {
        es: "Puedes ver las condiciones de las promociones dando click en el ícono de información, al terminar da click en “X” para salir.",
        en: "You can view the conditions of the promotions by clicking on the information icon, then click on “X” to exit."
    },

    // los nobres de los correos 2 y 3
    // tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_nombre
    // tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_nombre

    //modal ayuda
    // hombre
    modal_modal_info_ayuda_requisitos_titulo: {
        es: "Requisitos",
        en: "Requirements"
    },
    modal_modal_info_ayuda_titulo: {
        es: "Ayuda",
        en: "Help"
    },
    modal_modal_info_ayuda_info: {
        es: ` Analiza si el cliente [replace1] es apto para recibir una tasa
        preferencial. Recuerda que se ofrecerá a la población de clientes registrados
        un crédito con tasa preferencial 5% menor a la tasa de interés que se ofrece
        para todo público.`,
        en: `Analyze if the client [replace1] is eligible to receive a preferential rate. 
        Remember that a preferential rate credit, 5% lower than the interest rate offered to the general public, 
        will be offered to the population of registered clients.
        `
    },
    // mujer
    modal_modal_info_ayuda_titulo_mujer: {
        es: "Ayuda",
        en: "Help"
    },
    modal_modal_info_ayuda_info_mujer: {
        es: `Analiza si  el cliente [replace1] es   apta   para   recibir   la   nueva
        promoción. Recuerda que se otorgará la tarjeta preferente “Banco Creciendo Juntos cree en ti” a la muestra de mujeres que han asistido a la feria de promoción.`,
        en: `Analyze if the client [replace1] is eligible to receive the new promotion. 
        Remember that the preferred card "Banco Creciendo Juntos believes in you" will be awarded to the sample of women who attended the promotion fair.`
    },
    //botones
    tarea_7_ver_info_botones_1: {
        es: "Denegar",
        en: "Deny"
    },
    tarea_7_ver_info_botones_2: {
        es: "Autorizar",
        en: "Authorize"
    },

    tarea_7_ver_info_pnatlla_cliente_interfaz2D_boton_plegable_1: {
        es: `Puedes ver las condiciones de las promociones dando click en el ícono de información, al terminar da click en “X” para salir.`,
        en: `You can view the conditions of the promotions by clicking on the information icon, then click on "X" to exit.`
    },
    // modal de finales
    tarea_7_modal_final_titulo: {
        es: "AVISO",
        en: "NOTICE"
    },
    //? respuesta diferentes por retro
    // promocion
    // autorizado
    tarea_7_mensaje_promocion_autorizado: {
        es: `¡Se ha autorizado la tarjeta de promoción a [replace1] con éxito!`,
        en: "The promotion card for [replace1] has been successfully authorized!"
    },
    // denegado
    tarea_7_mensaje_promocion_denegado: {
        es: `Se ha denegado la tarjeta de promoción a [replace1]!`,
        en: "The promotion card for [replace1] has been successfully denied!"
    },
    // tasa preferencial
    // autorizado
    tarea_7_mensaje_tasa_preferencial_autorizado: {
        es: `¡Se ha autorizado la tasa preferencial a [replace1] con éxito!`,
        en: "The preferential rate for [replace1] has been successfully authorized!"
    },
    // denegado
    tarea_7_mensaje_tasa_preferencial_denegado: {
        es: `¡Se ha denegado la tasa preferencial a [replace1]!`,
        en: "The preferential rate for [replace1] has been successfully denied."
    },
    // es cliente
    tarea_7_mensaje_es_cliente_1: {
        es: `Se ha denegado el crédito de promoción a [replace1]!`,
        en: "The preferential rate for [replace1] has been successfully denied."
    },
    // salir
    tarea_8_salir_boton_plegable_1: {
        es: `Da click en ‘’X’’ para salir.`,
        en: `Click on 'X' to exit.`
    },


}