const CustomSelect = ({ id, name, data, onChange, placeholder = "Seleccionar" }) => {
	return (
		<select
			id={id}
			name={name}
			placeholder={placeholder}
			onChange={onChange}
			className="w-full  h-[36px] rounded-[4px] p-[4px] "
			defaultValue="default"
		>
			<option value="default" disabled>
				{placeholder}
			</option>

			{data.map((item, index) => (
				<Item key={item + index} value={item} label={item} />
			))}
		</select>
	);
};

export default CustomSelect;

const Item = ({ value, label }) => {
	return <option value={value}>{label}</option>;
};
