import { DatePicker, Table, Tooltip } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFilter, usePDFAnswers, useResults } from '../../hooks/cms/';
import { ButtonCms, CustomModal } from '../../components';
import { deleteEntry } from '../../api';
import ActionIcon from './ActionIcon';
import { Icons } from '../../assets';
import CSVIcon from './CSVIcon';
import PDFIcon from './PDFIcon';
import Detail from './Detail';

const { RangePicker } = DatePicker;



const TableStudents = () => {
    const dateRef = useRef(null);
    const { getColumnSearchProps } = useFilter();
    const { printManyAdmin } = usePDFAnswers();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { results, updateResults, filter, reset } = useResults();

    const closeDetailModal = useCallback(() => {
        setSelectedUser(null);
    }, [])

    useEffect(() => {
        updateResults();
    }, [updateResults])

    const columns = [

        {
            title: 'Nombre de usuario',
            width: 200,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            align: "center",

            ...getColumnSearchProps('name'),

        }, {
            title: 'Matrícula',
            width: 100,
            dataIndex: 'userId',
            key: 'userId',
            fixed: 'left',
            align: "center",

            ...getColumnSearchProps('userId'),
        }, {
            title: 'CRN',
            dataIndex: 'crn',
            key: '1',
            width: 150,
            align: "center",

            ...getColumnSearchProps('crn'),
        },
        {
            title: 'Campus',
            dataIndex: 'campus',
            key: '2',
            width: 150,
            defaultSortOrder: 'descend',
            align: "center",

            sorter: (a, b) => a.campus.charCodeAt(0) - b.campus.charCodeAt(0)
        },
        {
            title: 'Creado el:',
            dataIndex: 'createdAt',
            width: 150,
            defaultSortOrder: 'descend',
            align: "center",
            render: (props) => {
                const dateFormat = new Date(props)
                return (
                    <>{dateFormat.getDate()}/{dateFormat.getMonth() + 1}/{dateFormat.getFullYear()}</>
                )
            }
        },
        {
            title: 'Estado',
            dataIndex: 'responses',
            width: 150,
            defaultSortOrder: 'descend',
            align: "center",
            render: (props) => {
                return (
                    <>{props?.length > 0 ? "Concluido" : "No concluido"}</>
                )
            }
        },
        {
            title: 'Detalles',
            dataIndex: 'details',
            key: '3',
            align: "center",

            width: 50,
            render: (_, answer) => (
                <div className='w-full flex justify-center items-center cursor-pointer active:scale-105'>
                    <img onClick={() => setSelectedUser(answer)} className='w-5' src={Icons.info} alt="info" />
                </div>
            ),
        },
        {
            title: 'Descargables',
            dataIndex: 'downloads',
            key: '3',
            width: 50,
            fixed: true,
            align: "center",

            children: [
                {
                    title: 'pdf',
                    dataIndex: 'pdf',
                    key: '4',
                    width: 50,
                    align: "center",
                    render: (_, results) => {
                        return <PDFIcon results={[results]} />
                    }
                },
                {
                    title: 'excel',
                    dataIndex: 'excel',
                    key: '5',
                    width: 50,
                    align: "center",
                    render: (_, results) => <CSVIcon results={[results]} />
                }
            ]
        },
        {
            title: 'Eliminar',
            dataIndex: 'delete',
            key: '6',
            width: 50,
            fixed: true,
            align: "center",

            render: (_, answer) => {
                return (<div className='w-full flex justify-center items-center active:scale-105'>
                    <img onClick={async () => {
                        await deleteEntry(answer._id)
                        await updateResults();

                    }} className='h-4  cursor-pointer' src={Icons.remove} alt="info" />
                </div>)
            }
        },
    ];

    const selectedResults = useMemo(() => {
        return results.filter(result => {
            return selectedRowKeys.find(id => id === result._id);
        })
    }, [selectedRowKeys, results])

    const cleanRows = useCallback(() => {
        setSelectedRowKeys([]);
    }, [])
    return (
        <div className='w-full h-full flex flex-col  '>
            <div className="w-full h-14  pr-[70px]" >
                <div className="w-full h-full rounded-xl px-4 py-3 flex justify-between bg-white  ">
                    <div className='flex gap-4'>
                        <RangePicker onChange={(data) => {
                            if (!data) return reset()
                            dateRef.current = data;
                        }} style={{ width: 248 }} />
                    </div>
                    <div className='flex gap-4'>
                        <ButtonCms onClick={() => {
                            filter(dateRef.current)
                        }}>Buscar</ButtonCms>
                    </div>
                </div>
            </div >
            <div className='w-full grow pt-[22px] flex gap-5  overflow-hidden'>
                <div className='grow h-full rounded-xl  p-6 flex flex-col  bg-white'>
                    <h3 className='text-lg text-background-screen font-GraphikBold mb-6'>Tabla</h3>
                    <div className='grow overflow-scroll '>
                        <Table
                            bordered
                            className='border-1'
                            rowSelection={{

                                type: "checkbox",
                                onChange: (keys) => {
                                    setSelectedRowKeys(keys)
                                },
                                setSelectedRowKeys: selectedRowKeys

                            }}
                            size='small'
                            columns={columns}
                            dataSource={results}

                        />
                    </div>
                </div>
                <div className='w-[50px] h-full flex flex-col gap-6 '>
                    <ActionIcon icon={Icons.exportExcel} title="Exportar .csv" answers={selectedResults} cleanRows={cleanRows} />
                    <Tooltip onClick={() => printManyAdmin(selectedResults)} placement="left" title={"Exportar .pdf"} className='text-black' >
                        <img src={Icons.exportPdf} alt="exporting excel" className='w-full object-contain mx-auto cursor-pointer' />
                    </Tooltip>
                </div>
            </div>
            <CustomModal isVisible={selectedUser} closeModal={closeDetailModal} >
                <Detail information={selectedUser} />
            </CustomModal>
        </div>
    );
}


export default TableStudents;

