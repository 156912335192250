import { useCallback, useEffect, useState } from "react";
import optionalTasks from "../../store/slices/task/optionalTasks";
import tasks from "../../store/slices/task/tasks";
import { initStatusQuestions } from "../../types";
import { useStoreComputer } from "../store";
import useGetSection from "./useGetSection";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const getInit = (task) => {
    if (task === undefined || task === null) return "Sin tarea";
    if (task.label === undefined) return "Sin tarea";
    return task.label;
};

const useLabelComputer = (task, selectedSection, sectionRequired, questions) => {
    const { selectedMail } = useStoreComputer();
    const [hasRetro, setHasRetro] = useState(false);
    const [label, setLabel] = useState(getInit(task));
    const [task20ShowedRetro, setTask20ShowedRetro] = useState(false);
    const [section, subSection, subDirectories] = useGetSection(selectedSection);
    const { question, questionConcept, meetingDocuments, workmateQuestion, histograma } = questions;
    const setCloseLabel = useCallback(() => {
        setLabel(getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton_plegable_tarea_2"));
    }, []);

    useEffect(() => {
        setHasRetro(hasRetro => {
            if (hasRetro) setHasRetro(false);
        })
    }, [task.id])
    useEffect(() => {
        setLabel(getInit(task));
    }, [task]);
    useEffect(() => {

        const mustDoAnAction = sectionRequired !== null;
        if (task.id === tasks.Task_1_1.id && selectedSection === "Correo" && mustDoAnAction) {
            setLabel(getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton_plegable_tarea_1"));
            return;
        }
        if (task.id === tasks.Task_1_1.id && selectedSection === "Correo" && !mustDoAnAction) {
            setCloseLabel();
            return;
        }
        if (task.id === tasks.Task_2_3.id && selectedSection === "Clientes" && mustDoAnAction) {
            setLabel(getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_boton_plegable_tarea_1"))
            return;
        }
        if (task.id === tasks.Task_2_3.id && selectedSection === "Clientes" && !mustDoAnAction) {
            setCloseLabel();
            return;
        }

        if (task.id === optionalTasks.Task_Optional_2.id && selectedSection === "Correo") {
            setLabel(getJsonTextLanguageDisplay("tarea_1_revisar_correo_pendiente_1"));
            return;
        }

        if (task.id === optionalTasks.Task_Optional_3.id && selectedSection === "Clientes" && sectionRequired && !subSection) {
            setLabel(getJsonTextLanguageDisplay("tarea_6_seccion_registro_boton_plegable_1"));
            return;
        }

        if (task.id === optionalTasks.Task_Optional_3.id && selectedSection === "Registro" && sectionRequired && !initStatusQuestions.personRetro1.isWomanClient) {
            // setLabel(`Revisa los requisitos dando click en el icono de “información” para tomar 
            // una decisión. Al finalizar da click en “X” para salir.`);
            setLabel(getJsonTextLanguageDisplay("tarea_7_ver_info_pnatlla_cliente_interfaz2D_boton_plegable_1"));
            return;
        }
        if (task.id === optionalTasks.Task_Optional_3.id && selectedSection === "Registro" && sectionRequired) {
            setLabel(getJsonTextLanguageDisplay("tarea_7_ver_cliente_boton_plegable_1"));
            // setLabel(`Revisa los requisitos dando click en el icono de “información” para tomar 
            // una decisión. Al finalizar da click en “X” para salir.`);

            return;
        }
        if (task.id === optionalTasks.Task_Optional_3.id && selectedSection === "Correo" && !selectedMail) {
            setLabel(getJsonTextLanguageDisplay("tarea_5_seccion_clientes_boton_plegable_1"));
            return
        }
        if (task.id === optionalTasks.Task_Optional_3.id && selectedSection === "Correo") {
            setLabel(getJsonTextLanguageDisplay("tarea_4_lee_correo_con_atencion_boton_plegable_1"));
            return;
        }
        if (task.id === optionalTasks.Task_Optional_3.id && selectedSection === "Registro" && !sectionRequired) {
            setLabel(getJsonTextLanguageDisplay("tarea_8_salir_boton_plegable_1"));
            return;
        }

        if (task.id === tasks.Task_3_3.id && selectedSection === "Documentación" && (questionConcept.isCorrect ||
            questionConcept.retro.wasUsed === true)) {
            setLabel(getJsonTextLanguageDisplay("tabla_interfaz2D_boton_plegable_tarea_1"));
            return;
        }

        if (
            task.id === tasks.Task_3_3.id &&
            selectedSection === "Documentación" &&
            !questionConcept.isCorrect &&
            questionConcept.retro.times < 2 &&
            questionConcept.retro.times > 0
        ) {
            setLabel(getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_segunda_oportunidad_interfaz2D_boton_plegable_tarea_1"));
            return;
        }
        if (task.id === tasks.Task_3_3.id && selectedSection === "Documentación" && mustDoAnAction) {
            setLabel(getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_boton_plegable_tarea_1"));
            return;
        }
        if (task.id === tasks.Task_3_3.id && selectedSection === "Clientes" && mustDoAnAction) {
            setLabel(getJsonTextLanguageDisplay("tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_boton_plegable_tarea_1"));
            return;
        }
        if (task.id === tasks.Task_3_3.id && selectedSection === "Documentación" && questionConcept.isCorrect) {
            setLabel(`Da click en ‘’X’’ para salir.`);
            return;
        }

        if (task.id === tasks.Task_4_2.id && selectedSection === "Clientes") {
            setLabel(getJsonTextLanguageDisplay("tarea_2_ingresa_seccion_menu_boton_plegable_2"));
            return;
        }
        if (task.id === tasks.Task_4_2.id && selectedSection === "Histograma" && !initStatusQuestions.isClient) {
            setLabel(getJsonTextLanguageDisplay("tarea_3_revisa_histograma_edad_boton_plegable_1"));
            return;
        }
        if (task.id === tasks.Task_4_2.id && selectedSection === "Histograma" && initStatusQuestions.isClient) {
            setLabel(getJsonTextLanguageDisplay("tarea_3_revisa_histograma_score_boton_plegable_1"));
            return;
        }
        if (task.id === tasks.Task_4_3.id && histograma.answer !== null) {
            setLabel(null);
            return;
        }




        if (task.id === 19 && selectedSection === "Agenda") {
            setLabel(getJsonTextLanguageDisplay("tarea_2_recordatorio_reunion_agenda_ambiente3D_boton_plegable_tarea_1"));
            return;
        }
        if (task.id === tasks.Task_5_2.id && (meetingDocuments.isCorrect || meetingDocuments.retro.times > 1)) {
            setLabel(getJsonTextLanguageDisplay("tarea_4_click_salir_menu_documento_interfaz2D_boton_plegable_1"));
            return;
        }
        if (task.id === tasks.Task_5_2.id && section === "Documentos" && meetingDocuments.retro.times > 0 && !meetingDocuments.isCorrect && !task20ShowedRetro) {
            setLabel(getJsonTextLanguageDisplay("tarea_4_segunda_oprotunidad_menu_documento_interfaz2D_boton_plegable_1"));

            return;
        }
        if (task.id === tasks.Task_5_2.id && section === "Documentos" && meetingDocuments.retro.times > 0 && !meetingDocuments.isCorrect && !task20ShowedRetro) {
            setTask20ShowedRetro((value) => {
                if (!value && meetingDocuments.retro.times > 0 && !meetingDocuments.isCorrect) {
                    return true
                }
                return value
            })
        }
        if (task.id === tasks.Task_5_2.id && section === "Documentos" && meetingDocuments.isCorrect) {
            setLabel(
                "¡Listo! Da click en ‘’X’’ para salir y dirígete a la sala de juntas. Recuerda que puedes consultar la guía para recordar como trasladarte."
            );
            return;
        }

        if (task.id === tasks.Task_5_2.id && section === "Documentos" && selectedSection === section) {
            setLabel(getJsonTextLanguageDisplay("tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_boton_plegable_1"));

            return;
        }
        if (task.id === tasks.Task_5_2.id && section === "Documentos" && selectedSection) {
            setLabel(getJsonTextLanguageDisplay("tarea_3_elegir_documentos_hipotecario_interfaz2D_boton_plegable_1"));
            return;
        }
        if (task.id === 26) {
            const { retro } = workmateQuestion;
            const { correctAnswer, answer } = retro;
            if (!answer) return;
            if (correctAnswer !== answer)
                setLabel(getJsonTextLanguageDisplay("tarea_4_segunda_oportunidad_boton_plegable_2"));
            return;
        }
        if (task.id === tasks.TaskRegister.id && selectedSection === "Registro") {
            setLabel(getJsonTextLanguageDisplay("tarea_mujer_revisar"));
            return;
        }

        if (task.id === tasks.TaskRegister.id && selectedSection) {
            setLabel(getJsonTextLanguageDisplay("boton_flotantes_falta_ingresar"));
            return;
        }
        if (task.id === tasks.TasksRegisterMan.id && selectedSection === 'Registro') {
            setLabel(getJsonTextLanguageDisplay("task_revisar_pedro"));
            return;
        }
        if (task.id === tasks.TasksRegisterMan.id && selectedSection) {
            setLabel(getJsonTextLanguageDisplay("tarea_registro_hombre"));
            return;
        }


    }, [task, selectedSection, sectionRequired, question, questionConcept, meetingDocuments, subDirectories, subSection, selectedMail, section, setCloseLabel, workmateQuestion, histograma.answer, task20ShowedRetro]);

    //Used to define if ti has retro
    useEffect(() => {

        if (task.id === tasks.Task_5_2.id) {
            const { retro } = meetingDocuments;

            if (task20ShowedRetro) {
                setHasRetro(false);
                return;
            }
            if (meetingDocuments.isCorrect) {
                setHasRetro(false);
                return;
            }
            if (!meetingDocuments.isCorrect && retro.times > 0) {
                setHasRetro(true);
                return;
            }
        }

    }, [task, questionConcept, questionConcept.retro, questionConcept.isCorrect, questions.question.retro, meetingDocuments, question.retro, task20ShowedRetro]);

    return { label, hasRetro };
};

export default useLabelComputer;
