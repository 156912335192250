export const checkAllKeysForTranslationRepetition = (allImports = {}) => {
    const keyRepeats = {};
    Object.values(allImports).forEach((impt) => {
        Object.entries(impt).forEach(([key, values]) => {
            if (!keyRepeats[key]) {
                keyRepeats[key] = values;
            } else {
                console.log('repeated key:', key);
            }
        });
    });
    return keyRepeats;
};
