export const modulo2Seccion2SeleccionTabla = {
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_boton_plegable_tarea_1: {
        es: "Encuentra y selecciona las 8 columnas con las escalas de medición incorrectas, da click en la columna equivocada y verifica.",
        en: "Find and select the 8 columns with incorrect measurement scales, click on the wrong column, and verify."
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_boton_verificar: {
        es: "Verificar",
        en: "Verify"
    },
    //? nombre fila 1
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal: {
        es: "Nominal",
        en: "Nominal"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal: {
        es: "Ordinal",
        en: "Ordinal"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_intervalo: {
        es: "Intervalo",
        en: "Interval"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_razon: {
        es: "Razón",
        en: "Ratio"
    },
    //? nombre fila 2
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_1: {
        es: "Cust_id",
        en: "Cust_id"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_2: {
        es: "Cve_emp",
        en: "Cve_emp"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_3: {
        es: "Cve_ sexo",
        en: "Gender"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_4: {
        es: "Cve_actividad",
        en: "Cve_actividad"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_5: {
        es: "Tot_txn_en_6_mes",
        en: "Tot_txn_en_6_mes"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_6: {
        es: "Estado",
        en: "Estado"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_7: {
        es: "Imp_Txn",
        en: "Imp_Txn"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_8: {
        es: "Sdo_vigente_vista",
        en: "Sdo_vigente_vista"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_9: {
        es: "Num_prod_cte",
        en: "Num_prod_cte"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_10: {
        es: "Calificación_cte",
        en: "Calificación_cte"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_11: {
        es: "Score Cred",
        en: "Score Cred"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_12: {
        es: "Est_compra",
        en: "Est_compra"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_13: {
        es: "Edad_años",
        en: "Edad_años"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_14: {
        es: "Etapa_vida",
        en: "Etapa_vida"
    },
    //? nombre fila 3
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_1: {
        es: "Cte regular",
        en: "Cte regular"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_2: {
        es: "Mercado",
        en: "Market"
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_3: {
        es: "Adultos maduros",
        en: "Mature Adults"
    },
    // ? tabla titulo arriba
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_primera_oportunidad_interfaz2D_intrucciones_selecion_1: {
        es: "Encuentra y selecciona las [replace1] columnas con las escalas de medición incorrectas, da click en la columna equivocada y verifica.",
        en: "Find and select the [replace1] columns with incorrect measurement scales, click on the wrong column, and verify."
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_primera_oportunidad_interfaz2D_intrucciones_selecion_2: {
        es: "Encuentra la columna con la escala de medición incorrecta.",
        en: "Find the column with the incorrect measurement scale."
    },
    tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_segunda_oportunidad_interfaz2D_boton_plegable_tarea_1: {
        es: "Vuelve a seleccionar las columnas con las escalas de medición incorrectas, utilizando el glosario y verifica.",
        en: "Select again the columns with incorrect measurement scales, using the glossary and verify."
    },
    //? modal 
    modal_glosario_interfaz2D_modal_titulo: {
        es: "Glosario",
        en: "Glossary"
    },
    modal_glosario_interfaz2D_modal_escala: {
        es: `<b className="font-GraphikSemibold">Escala nominal:</b> propia de variables cualitativas que no pueden ordenarse de menor
        a mayor.`,
        en: `<b className="font-GraphikSemibold">Nominal Scale:</b> characteristic of qualitative variables that cannot be ordered from least to greatest.`
    },
    modal_glosario_interfaz2D_modal_ordinal: {
        es: ` <b className="font-GraphikSemibold">Escala ordinal:</b> propia de variables cualitativas que pueden ordenarse de menor a
        mayor.`,
        en: ` <b className="font-GraphikSemibold">Ordinal Scale:</b> characteristic of qualitative variables that can be ordered from least to greatest.`
    },
    modal_glosario_interfaz2D_modal_intervalo: {
        es: ` <b className="font-GraphikSemibold">Escala de intervalo:</b> propia de variables cuantitativas donde el cero forma parte de
        la escala.`,
        en: ` <b className="font-GraphikSemibold">Interval Scale:</b> characteristic of quantitative variables where zero is part of the scale.`
    },
    modal_glosario_interfaz2D_modal_razon: {
        es: ` <b className="font-GraphikSemibold">Escala de razón:</b> propia de variables cuantitativas donde el cero representa
        ausencia.`,
        en: ` <b className="font-GraphikSemibold">Ratio Scale:</b> characteristic of quantitative variables where zero represents absence.`
    },
    modal_glosario_interfaz2D_modal_variable_cualitativa: {
        es: ` <b className="font-GraphikSemibold">Variable cualitativa:</b> no puede medirse con un número o su valor depende de la
        percepción del observador.`,
        en: `<b className="font-GraphikSemibold">Qualitative Variable:</b> cannot be measured with a number or its value depends on the observer's perception.`
    },
    modal_glosario_interfaz2D_modal_variable_cuantitativa: {
        es: `<b className="font-GraphikSemibold">Variable cuantitativa:</b> se mide con un número en todos los casos y su valor es
        intrínseco al objeto que se mide.`,
        en: `<b className="font-GraphikSemibold">Quantitative Variable:</b> measured with a number in all cases, and its value is intrinsic to the object being measured.`
    },
    //? modal aviso 8 caracteres
    modal_aviso_interfaz2D_modal_titulo: {
        es: "AVISO",
        en: "NOTICE",
    },
    modal_aviso_interfaz2D_modal_info: {
        es: "Máximo 8 elementos",
        en: "Maximum 8 elements",
    },
    modal_aviso_interfaz2D_modal_boton: {
        es: "Entendido",
        en: "Understood",
    },
    tabla_interfaz2D_boton_plegable_tarea_1: {
        es: `Da click en "X" para salir.`,
        en: `Click on "X" to exit.`,
    },
    // tabla
}