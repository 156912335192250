import { Carousel } from "react-responsive-carousel";
import { useEffect, useMemo, useState } from "react";
import { Avatar, Dialogue, HeaderName } from "../Conversation";
import { useArrow } from "../../hooks/introductionApp";

const Conversation = ({ currentTask, changeTask }) => {
    const [dialogue, setDialogue] = useState([]);
    const [index, setIndex] = useState(0);
    const { nextFuntion } = useArrow(dialogue, index, changeTask);



    useEffect(() => {
        const { type, messages } = currentTask;
        if (type === 'talk') return setDialogue(messages);
        setDialogue([]);
    }, [currentTask]);



    const conversationDialogue = useMemo(() => {
        return dialogue.map((message, index) => <Dialogue key={index} message={message.message} />)
    }, [dialogue]);

    if (dialogue.length === 0) return;

    return <div className="w-full h-[120px] absolute bottom-[32px] pr-[76px]  px-[132px] lg:px-[15%] ">
        <div className=" relative w-full h-full flex flex-col " style={{ zIndex: 200 }}>
            <Avatar isMine={dialogue[index].isMine} person={dialogue[index].person} />
            <HeaderName isMine={dialogue[index].isMine} isOptionItem={false} person={dialogue[index].person} />
            <div className={`mt-[16px] pl-[76px] h-full flex justify-between  rounded-lg relative bg-white `}>
                <Carousel
                    autoFocus
                    showStatus={false}
                    showThumbs={false}
                    selectedItem={index}
                    showIndicators={false}
                    renderArrowPrev={() => null}
                    renderArrowNext={nextFuntion}
                    className="w-full text-left h-full "
                    onChange={(value) => setIndex(value)}
                >
                    {conversationDialogue}
                </Carousel>
            </div>
        </div>
    </div>
}

export default Conversation;