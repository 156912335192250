import { getToken } from "../helpers/loginToken";
import { api } from "./config";


export const sendAnswers = async ({ answers, time, answerId, config, bonusTime = [] }) => {
    const { data } = await api.put('/answers', {
        answers,
        time,
        answerId,
        config,
        bonusTime
    })

    return data;
}


export const verifyLogin = async (email, password) => {
    const { data } = await api.put('/authentication', {
        email,
        password
    });

    return data;
}


export const getAllAnswer = async () => {
    const token = getToken();
    const { data } = await api.get("/answers", {
        headers: {
            "x-token": token
        }
    })
    return data;
}

export const deleteEntry = async (id) => {
    await api.delete(`/answers/${id}`)
}

export const startAttempt = async ({ id, name, crn, campus }) => {
    const response = await api.post("/answers/start", {
        userId: id,
        name,
        crn,
        campus,
        codeLanguage: localStorage.getItem("language") || "es"
    });

    const { data } = response;
    return data;
}

export const getAnswerById = async (id) => {
    const { data } = await api.get(`/answers/${id}`);

    return data;
}