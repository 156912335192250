import { idTaskFirstQuestion, initStatusQuestions } from "../../../types";
import { IconAdmin, Icons } from "../../../assets";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

const mail1 = {
    id: 1,
    person: {
        name: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_nombre"),
        position: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_posicion"),
    },
    icon: Icons.Boss,
    title: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_1_2"),
    body: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_correo_1_interfaz2D_card_info_2"),
    isChecked: false,
};

const mail2 = {
    id: 2,
    person: {
        name: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_nombre"),
        position: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_posicion"),
    },
    icon: IconAdmin,
    title: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_correo_2_2"),
    body: getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_correo_2_interfaz2D_card_info_2"),
    isChecked: false,
};

const extraMailNewCard = {
    id: 3,
    person: {
        name: getJsonTextLanguageDisplay("tarea_1_revisar_computadora_correo_name"),
        position: getJsonTextLanguageDisplay("tarea_1_revisar_computadora_correo_posicion"),
    },
    icon: IconAdmin,
    title: getJsonTextLanguageDisplay("tarea_1_revisar_computadora_correo_title"),
    isChecked: false,
};
const getBodyMessage = (isCard, person) => {
    const { name, age, city } = person;
    if (isCard) {
        return getJsonTextLanguageDisplay("tarea_4_lee_correo_card_info_mujer", { "[replace1]": name, "[replace2]": age, "[replace3]": city });
    }
    return getJsonTextLanguageDisplay("tarea_4_lee_correo_card_info_hombre", { "[replace1]": name, "[replace2]": age, "[replace3]": city });
};

const getTitleMessage = (isCard) => {
    if (isCard) return getJsonTextLanguageDisplay("tarea_1_revisar_computadora_correo_title")
    return getJsonTextLanguageDisplay("tarea_1_revisar_computadora_correo_title_2")
}

const buildMessage = (isCard, person) => {
    const title = getTitleMessage(isCard);
    const body = getBodyMessage(isCard, person);
    extraMailNewCard.body = body;
    extraMailNewCard.title = title;
    return extraMailNewCard;
};

const extraMails = [
    {
        idTask: idTaskFirstQuestion,
        mails: [buildMessage(initStatusQuestions.personRetro1.isWomanClient, initStatusQuestions.personRetro1)],
    },
];
export const initMesssages = [mail1, mail2];
export const initExtraMails = extraMails;
export const ComputerSectionCorrectAnswer = 7;
export const workmateCorrectAnswer = 1;
export const workmateRetroCorrectAnswer = 3;
const studentRetro = getJsonTextLanguageDisplay("reactivo4_student_retroalientacion_multiple");

const teacherRetro = getJsonTextLanguageDisplay("reactivo4_student_nive_competencia_multiple");
export const ComputerSections = {
    Documents: {
        name: "Documentos",
        directories: ["hipotecario", "automotriz", "credito", "vida"],
        subDirectories: [
            {
                directory: "hipotecario",
                subDirectories: [
                    { id: 1, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_hipotecario_interfaz2D_card_1"), studentRetro, teacherRetro },
                    { id: 2, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_hipotecario_interfaz2D_card_2"), studentRetro, teacherRetro },
                    { id: 3, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_hipotecario_interfaz2D_card_3"), studentRetro, teacherRetro },
                ],

            },
            {
                directory: "automotriz",
                subDirectories: [
                    { id: 4, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_automotriz_interfaz2D_card_1"), studentRetro, teacherRetro },
                    { id: 5, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_automotriz_interfaz2D_card_2"), studentRetro, teacherRetro },
                    { id: 6, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_automotriz_interfaz2D_card_3"), studentRetro, teacherRetro },
                ],

            },
            {
                directory: "credito",
                subDirectories: [
                    {
                        id: ComputerSectionCorrectAnswer,
                        label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_1"),
                        studentRetro: getJsonTextLanguageDisplay("reactivo4_analisis_grafico_numerico_retro"),
                        teacherRetro: getJsonTextLanguageDisplay("reactivo4_analisis_grafico_numerico_nivel")
                    },
                    {
                        id: 8, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_2"),
                        studentRetro: getJsonTextLanguageDisplay("reactivo4_analisis_distribucional_retro"),
                        teacherRetro: getJsonTextLanguageDisplay("reactivo4_analisis_distribucional_nivel")

                    },
                    {
                        id: 9, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_3"),
                        studentRetro: getJsonTextLanguageDisplay("reactivo4_analisis_inferencial_retro"),
                        teacherRetro: getJsonTextLanguageDisplay("reactivo4_analisis_inferencial_nivel")
                    },
                ],
            },
            {
                directory: "vida",
                subDirectories: [
                    { id: 10, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_1"), studentRetro, teacherRetro },
                    { id: 11, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_2"), studentRetro, teacherRetro },
                    { id: 12, label: getJsonTextLanguageDisplay("tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_3"), studentRetro, teacherRetro },
                ],
            },
        ],
    },
    Email: {
        name: "Correo",
        directories: null,
    },
    Clients: { name: "Clientes", directories: ["Documentación", "Registro", "Histograma"] },
    Agenda: { name: "Agenda", directories: null },
};
