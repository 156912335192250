import { BellNotification } from "../../components";

const MailBox = ({ children }) => {
    return <div className="w-full h-full flex justify-center flex-col">{children}</div>;
}

const MailItem = ({ id, person, title, isChecked, openMail }) => {

    const { nameMail, positionMail } = person;
    return (
        <div onClick={() => openMail(id)} className="  rounded-lg  flex  cursor-pointer  mb-4 bg-white">
            <div className="w-[27px] h-full py-2 rounded-l-lg " style={{ background: "#EFB445" }}></div>
            <div className=" flex grow items-center justify-between pl-[20px] py-2  xl:py-[14px] px-[15px] xl:px-[35px]">
                <div className="flex flex-col  grow ">
                    <p className="w-full mail-header font-GraphikSemibold text-[16px] xl:text-[20px] text-task-color">
                        {nameMail} | {positionMail}
                    </p>
                    <p className="mail-title font-GraphikRegular text-[16px] xl:text-[20px] ">{title}</p>
                </div>
                <BellNotification isVisible={!isChecked} />
            </div>
        </div>
    );
};

MailBox.Message = MailItem;

export default MailBox;