const operationBono = (time = 2, pointsWin = 3) => {
    return (4 - time / 2) * 0.12 * pointsWin
}

export const sumPointsTotalBonusByTime = (tasks = []) => {
    let total = 0

    tasks.forEach((task) => {
        const { response, finishTime, pointsGet } = task
        if (!response) {
            return
        }

        const splitTime = finishTime.split(':');
        const timeFloatString = `${splitTime[0]}.${splitTime[1]}`
        const parseFloatTime = parseFloat(timeFloatString)
        if ((parseFloatTime >= 1 && parseFloatTime <= 3)) {
            const getPointsWin = operationBono(parseFloatTime, pointsGet)
            total += getPointsWin
            total = parseFloat(total.toFixed(2))
        }
    })
    return total
}