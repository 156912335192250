import Pointer from "./Pointer";

let _isActive = true;
export default class PointerTask extends Pointer {
    constructor(scene, camera, container, actionTask) {
        super(scene, camera, container);
        this.createListener(actionTask);
    }

    createListener(actionTask) {
        this.addClickAction(() => {
            const element = this.getElement();

            if (element && _isActive) {
                actionTask();
                _isActive = false;
            }
        });
    }

    setActionTask(action) {
        this.updateClickAction(() => {
            const element = this.getElement();
            if (element && _isActive) action();
        });
    }

    setActive(status) {
        _isActive = status;
    }
}
