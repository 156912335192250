import { useMemo, useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import { getNextOrangeArrow, getPrevOrangeArrow } from "../../../utils";
import { useStoreTask, useStoreUser } from "../../../hooks/store";
import { useUserActions } from "../../../hooks";
import MeetingGraph from "./MeetingGraph";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Meeting = () => {
    const {
        questions: { sliceQuestion },
    } = useStoreUser();
    const carouselRef = useRef();
    const renderRef = useRef(0);
    const { tasks } = useStoreTask();

    const { selectSliceMeeting, changeCollectionMeeting } = useUserActions();
    const { currentIndex: currentSlice, presentation } = sliceQuestion;
    const currentTask = tasks[0];
    const isConversationTask = currentTask.type === "talk";
    const Slices = useMemo(() => {
        let indexCollection = currentSlice;
        if (!presentation[currentSlice]) {
            indexCollection -= 1;
        }
        return presentation[indexCollection].slices.map((slice) => {
            return <MeetingGraph key={slice.id + slice.title} header={slice.title} graph={slice.graph} />;
        });
    }, [currentSlice, presentation]);

    useEffect(() => {
        if (carouselRef.current && currentTask.isQuestion && renderRef.current !== 0) {
            changeCollectionMeeting();
        }
        if (carouselRef.current && currentTask.isQuestion) {
            carouselRef.current.selectItem({ ...carouselRef.current.state, selectedItem: 0 });
        }
        renderRef.current++;
    }, [changeCollectionMeeting, currentTask.isQuestion, tasks]);

    const moveSlice = (index = 0) => {
        const sliceId = presentation[currentSlice].slices[index].id;
        selectSliceMeeting(sliceId, index + 1)
    }

    useEffect(() => {
        moveSlice(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlice])


    return (
        <div className="w-full h-11/12 pt-5" style={{ zIndex: 90000 }}>
            <Carousel
                ref={carouselRef}
                showThumbs={false}
                showStatus={false}
                onChange={(index) => {
                    moveSlice(index)
                }}
                renderIndicator={(event, isSelected) => getIndicator(event, isSelected, isConversationTask)}
                renderArrowNext={(onClickHandler, hasNext, labe) => getNextOrangeArrow(onClickHandler, hasNext, labe, isConversationTask)}
                renderArrowPrev={(onClickHandler, hasPrev, labe) => getPrevOrangeArrow(onClickHandler, hasPrev, labe, isConversationTask)}
            >
                {Slices}
            </Carousel>
        </div>
    );
};

export default Meeting;

const getIndicator = (changeSlice, isSelected, isConversationTask) => {
    if (isConversationTask) return;
    const isDisabled = isConversationTask
        ? " cursor-not-allowed"
        : `cursor-pointer hover:opacity-100 ${!isSelected && "opacity-50"} transition-opacity duration-300`;
    return (
        <span
            onClick={(event) => {
                if (isConversationTask) return;
                changeSlice(event);
            }}
            className={`text-organge-task text-8xl ${isDisabled} ${!isSelected && "opacity-50"}`}
        >
            .
        </span>
    );
};
