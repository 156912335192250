const Curtain = ({ isVisible }) => {
	return (
		<div
			className={`w-screen h-screen absolute top-0 bg-black flex items-center justify-center ${!isVisible && "hidden"}`}
			style={{ zIndex: 100000, transition: "all 500ms" }}
		></div>
	);
};

export default Curtain;
