import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";
import { talk8 } from "./talk1";

const Task_Optional_1 = {
    id: 8,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_revisar_computadora_boton_plegable_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};

const Task_Optional_2 = {
    id: 9,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_1_revisar_computadora_boton_plegable_2"),
    section: "Correo",
    action: "change-section",
};

const Task_Optional_3 = {
    id: 10,
    type: "computer",
    label: "Dirígete a registro.",
    section: "Clientes",
    subSection: "Registro",
    glossaryType: "concepts"
};
const Task_Optional_6 = {
    id: 25,
    type: "talk",
    label: null,
    messages: talk8,
    action: "finish",
    isAlsoComputer: true,
    person: "workmate",
};
const Task_Optional_6_1 = {
    id: 26,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_4_segunda_oportunidad_boton_plegable_1"),
    section: "Clientes",
    isAlsoComputer: true,
};

const optionalTasks = {
    Task_Optional_1,
    Task_Optional_2,
    Task_Optional_3,
    Task_Optional_6,
    Task_Optional_6_1,
};

export default optionalTasks;
