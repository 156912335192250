import { EventDispatcher } from "three";
import { Animation, createLigth, setSize, startStructure } from "./structure";
import { correctTransparency, createTaskSprite, getBank, getCharacters, setAnimation, Sound } from "./models";
import { loadingManager, path3D } from "./utils";
import { soundNames } from "./models/Sound";
import { CHAIR_C2_POSITION, CHAIR_C3_POSITION, INIT_CAMERA_POSITION, BOSS_NAME, CHAIR_POSITION, STANDUP_CAMERA_POSITION, STANDUP_CAMERA_ROTATION, USER_NAME, WORKMATE_NAME } from "./constanst";



let _camera, _scene, _renderer, _animations, _labelRenderer, _actualSprite, _pointer, _controls, _characterModels, _sounds;
export class App extends EventDispatcher {
    constructor(container, actionTask, closeLoading, actualTask) {
        super();

        const { camera, renderer, scene, pointer, controls } = startStructure(container, INIT_CAMERA_POSITION, actionTask);
        _controls = controls;
        this.container = container;
        this.isActive = false;
        loadingManager.onLoad = closeLoading;
        _renderer = renderer;
        _camera = camera;
        _scene = scene;
        scene.getObjectByName();
        _pointer = pointer;
        _animations = new Animation(scene, camera, renderer, [pointer]);
        this.listeners(container);
        this.init(actualTask);
    }

    async init(actualTask) {
        const [bank, modelCharacterData] = await Promise.all([getBank(), getCharacters()]);
        const { characters, models } = modelCharacterData;
        _characterModels = models;
        _animations.appendElement(bank);
        characters.forEach((character) => {
            _scene.add(character);
            _animations.appendElement(character);
        });
        const label = this.createSpriteByTask(actualTask);
        _scene.add(bank);
        _scene.add(createLigth());
        _scene.add(label);
        correctTransparency(_scene);
        const sounds = [
            { name: 'office', path: `${path3D}music/office_background.mp3`, information: { volume: 0.2 } },
            { name: soundNames.dialogue, path: `${path3D}music/conversation.mp3`, information: { loop: false, autoPlay: false } },
            { name: soundNames.retro, path: `${path3D}music/feedback.mp3`, information: { loop: false, autoPlay: false } },
            { name: soundNames.task, path: `${path3D}music/Task.mp3`, information: { loop: false, autoPlay: false } }
        ]
        _sounds = new Sound(sounds)
    }

    playConversation() {
        _sounds.playSound('dialogue');
    }
    playRetro() {
        // _sounds.playSound('retro');
    }
    playTask() {
        _sounds.playSound('task');
    }

    createSpriteByTask(task, isSearchable = false) {
        if (_actualSprite) _scene.remove(_actualSprite);
        if (task === null) return;
        const { texture, name, position, isDoble } = task;
        const sprite = createTaskSprite(texture, name, position, isDoble, isSearchable);
        _actualSprite = sprite;

        return sprite;
    }

    addSpriteTask(task, action, isSearchable = false) {
        const sprite = this.createSpriteByTask(task, isSearchable);
        _pointer.setActionTask(action);
        if (!sprite) return null;
        _scene.add(sprite);
    }

    removeHelpingIcon(callback, objectName) {
        callback();
        _scene.remove(_scene.getObjectByName(objectName));
        _pointer.removeAction();
    }

    setUserUp() {
        const { x, y, z } = STANDUP_CAMERA_POSITION;
        const { x: rx, y: ry, z: rz } = STANDUP_CAMERA_ROTATION;
        _camera.position.set(x, y, z);
        _camera.rotation.set(rx, ry, rz);
        _controls.name = "movment";
        _animations.appendElement(_controls);
    }

    removeAnimationElement(objectName = "") {
        _animations.removeElement(objectName);
    }

    limitZoneHelpingIcon(object3D, callbackEliminate, limitPosition) {
        const { minZ, maxZ, minX = 3.3 } = limitPosition;
        if (object3D.material.visible) return;
        if (_camera.position.z > minZ && _camera.position.z < maxZ && _camera.position.x > minX) {
            _pointer.setActive(true);
            object3D.material.visible = true;
            callbackEliminate();
        }
    }

    getHelpingIconInformation(isBoss) {
        return isBoss ? [BOSS_NAME, { minZ: -5.8, maxZ: -0.8 }] : [WORKMATE_NAME, { minZ: -0.8, maxZ: 2.5 }];
    }
    setVisibleElement(object3D, isVisible) {
        object3D.visible = isVisible;
        if (!isVisible) {
            _animations.removeElement(object3D.name);
            correctTransparency(object3D, true);
        }
    }
    getCorrectName(name) {
        if (name === 'click-workmate') return 'scene_workmate'
        return name
    }
    standUp(task, action, toBoss) {
        const [objectName, limitPosition] = this.getHelpingIconInformation(toBoss);
        const removeElement = () => this.removeHelpingIcon(action, objectName);
        this.addSpriteTask(task, removeElement, true);
        _pointer.setActive(false);
        if (!toBoss) {
            this.setVisibleElement(this.getPrincipalUser(), false);
            this.setVisibleElement(this.getWorkMate(), true);
            this.setUserUp();
        } else {
            this.setVisibleElement(this.getBoss(), true);
        }

        const object3D = _scene.getObjectByName(objectName);
        const eliminateTick = () => this.removeAnimationElement(objectName);
        object3D.tick = () => this.limitZoneHelpingIcon(object3D, eliminateTick, limitPosition);
        _animations.appendElement(object3D);

    }
    sitDownUser() {
        const { x, y, z } = INIT_CAMERA_POSITION;
        _camera.position.set(x, y, z);
        _camera.rotation.set(0, 1.1615166172022264, 0);
    }
    limitGoalZome(object3D, zone, callback) {
        const { gtX, lsX, gtZ, lsZ } = zone;
        const { x, z } = object3D.position;
        if (x > gtX && x < lsX && z > gtZ && z < lsZ) {
            callback();
            this.removeAnimationElement(object3D.name);
            this.removeAnimationElement(_controls.name);
        }
    }
    removeWorkers() {
        const status = false;
        const boss = this.getBoss();
        const workmate = this.getWorkMate();
        const person1 = _scene.getObjectByName("scene_client1");
        this.setVisibleElement(boss, status);
        this.setVisibleElement(workmate, status);
        this.setVisibleElement(person1, status);
        _scene.remove(boss);
        _scene.remove(workmate);
        _scene.remove(person1);
    }
    setGoingZone(task, callback) {
        _camera.tick = () => this.limitGoalZome(_camera, task.square, callback);
        _animations.appendElement(_camera);
    }

    setPositionClient1(object3D) {
        object3D.position.set(CHAIR_POSITION.px, object3D.position.y, CHAIR_POSITION.pz);
        object3D.rotation.set(CHAIR_POSITION.rx, CHAIR_POSITION.ry, CHAIR_POSITION.rz);
    }
    setPositionCouple(object3D, object3D2) {
        object3D.position.set(CHAIR_C2_POSITION.px, object3D.position.y, CHAIR_C2_POSITION.pz);
        object3D.rotation.set(CHAIR_C2_POSITION.rx, CHAIR_C2_POSITION.ry, CHAIR_C2_POSITION.rz);
        object3D2.position.set(CHAIR_C3_POSITION.px, object3D2.position.y, CHAIR_C3_POSITION.pz);
        object3D2.rotation.set(CHAIR_C3_POSITION.rx, CHAIR_C3_POSITION.ry, CHAIR_C3_POSITION.rz);
    }
    moveClient() {
        const user = _scene.getObjectByName(USER_NAME);
        if (!user) return;
        this.setPositionClient1(user);
        this.createSpriteByTask(null);
        _pointer.removeAction();
    }
    callingCouple() {
        const [client2, client3] = this.getCoupleClient();
        if (!client2 || !client3) return;
        this.setPositionCouple(client2, client3);
        this.createSpriteByTask(null);
        _pointer.removeAction();
    }

    getCorrectClient(person) {
        switch (person) {
            case "client1": {
                return this.getPrincipalUser();
            }
            case "workmate": {
                return this.getWorkMate();
            }
            case "client2": {
                return this.getClient2();
            }
            case "client3": {
                return this.getClient3();
            }
            case "boss": {
                return this.getBoss();
            }
            default:
        }
    }
    getConversationAnimation(person) {
        switch (person) {
            case "client1": {
                return [1, 0, "Cliente_01"];
            }
            case "workmate": {
                return [3, 2, "Ejecutivo"];
            }
            case "client2": {
                return [1, 0, "Cliente_02"];
            }
            case "client3": {
                return [2, 1, "Cliente_03"];
            }
            case "boss": {
                return [2, 1, "Jefe"];//4 sitdown
            }
            default:
        }
    }

    setClient1Animation(typeAnimation, person) {
        let indexAnimation = 0;
        if (typeAnimation === "listening") indexAnimation = 1;
        const user = this.getCorrectClient(person);
        const animationsIndexes = this.getConversationAnimation(person);
        if (!user) return;
        if (!user.visible) return;
        _animations.removeElement(user.name);
        const modelData = _characterModels.find((model) => {
            return model.scene.children[0].name === animationsIndexes[2];
        });
        if (!modelData) return;
        const animation = setAnimation({ animations: modelData.animations, index: animationsIndexes[indexAnimation] }, user);
        user.tick = animation;
        _animations.appendElement(user);
    }

    setWorkWorkmate() {
        const defaultAnimation = 4;
        const workmateName = "workmate";
        const workmate = this.getCorrectClient(workmateName);
        workmate.name = "scene_workmate"
        if (!workmate) throw new Error("workmate does not exist");
        const animationsIndexes = this.getConversationAnimation(workmateName);
        _animations.removeElement(workmate.name);

        const modelData = _characterModels.find((model) => {
            return model.scene.children[0].name === animationsIndexes[2]
        });
        if (!modelData) throw new Error("mdoel data does not exist");
        const body = { animations: modelData.animations, index: defaultAnimation };
        const animation = setAnimation(body, workmate);
        workmate.tick = animation;
        _animations.appendElement(workmate);
    }
    showCouple(visible = true) {
        const [client2, client3] = this.getCoupleClient();
        this.setVisibleElement(client2, visible);
        this.setVisibleElement(client3, visible);
    }


    getPrincipalUser() {
        return _scene.getObjectByName(USER_NAME);
    }
    getWorkMate() {
        return _scene.getObjectByName("scene_workmate");
    }
    getBoss() {
        return _scene.getObjectByName("scene_boss");
    }
    getClient2() {
        return _scene.getObjectByName("scene_client2");
    }
    getClient3() {
        return _scene.getObjectByName("scene_client3");
    }

    getCoupleClient() {
        const client2 = this.getClient2();
        const client3 = this.getClient3();
        return [client2, client3];
    }

    setActiveComputer(status) {
        _pointer.setActive(status);
    }

    start() {
        this.isActive = true;
        _animations.start();
    }

    stop() {
        this.isActive = false;
        _animations.stop();
    }

    listeners(container) {
        window.addEventListener("resize", () => {
            setSize(_camera, _renderer, container, _labelRenderer);
        });
    }
}
