import { checkAllKeysForTranslationRepetition } from "./helperCheckKeyTranslate"

const tableNamePdf = {
    table_header_matricula: {
        es: "Matrícula del alumno",
        en: "Student Enrollment"
    },
    table_header_reactivos: {
        es: "Reactivos",
        en: "Reagents"
    },
    table_header_valores_entrada: {
        // es: "Valores de entrada",
        // en: "Input Values"
        es: "",
        en: ""
    },
    table_header_respuesta: {
        es: "Respuesta",
        en: "Answer"
    },
    table_header_estatus: {
        es: "Estatus",
        en: "Status"
    },
    table_header_puntos: {
        es: "Puntos",
        en: "Points"
    },
    table_header_retroalimentacion: {
        es: "Retroalimentación",
        en: "Feedback"
    },
    table_header_nivel_competencia: {
        es: "Nivel en la competencia",
        en: "Competence Level"
    },
}

const tablaValoresEntrada = {
    acierta_titulo_pdf: {
        es: "Acierta",
        en: "Hits"
    },
    no_acierta_titulo_pdf: {
        es: "Falla",
        en: "Fails"
    },
    // r1
    reactivo1_nombre_r1: {
        es: "Reactivo 1",
        en: "Reagent 1"
    },
    reactivo1_es_cliente_participo_titulo_1: {
        es: "Cliente registrado sí contestó la encuesta",
        en: "Registered client answered the survey"
    },
    reactivo1_es_cliente_no_participo_titulo_1: {
        es: "Cliente registrado no contestó la encuesta",
        en: "Registered client did not answer the survey"
    },
    reactivo1_no_es_cliente_no_participo_titulo_1: {
        es: "Cliente no registrado no contestó la encuesta",
        en: "Unregistered client did not answer the survey"
    },
    reactivo1_si_no_entra_a_ninguno_titulo_1: {
        es: "Cliente no registrado sí contestó la encuesta",
        en: "Unregistered client answered the survey"
    },
    //1.1
    reactivo1_nombre_r1_1: {
        es: "Reactivo 1.1 ",
        en: "Reagent 1.1"
    },
    reactivo1_1_es_retro_registro: {
        es: "Esta registrado",
        en: "Is registered"
    },
    reactivo1_1_es_retro_no_registro: {
        es: "No se encuentra registrado",
        en: "Is not registered"
    },
    reactivo1_1_es_retro_asistio: {
        es: "Si asistió al evento",
        en: "Attended the event"
    },
    reactivo1_1_es_retro_no_asistio: {
        es: "No asistió al evento",
        en: "Did not attend the event"
    },
    // 2
    reactivo2_nombre_r2: {
        es: "Reactivo 2",
        en: "Reagent 2"
    },
    reactivo2_tabla_nombre: {
        es: `Tabla [replace1]`,
        en: `Table [replace1]`
    },
    reactivo2_acierta_todas: {
        es: `Acierta todas`,
        en: `Hits all`
    },
    reactivo2_falla_todas: {
        es: `Falla en todas`,
        en: `Fails all`
    },
    reactivo2_falla_al_menos_uno: {
        es: `Falla en almenos una`,
        en: `Fails at least one`
    },
    reactivo2_falla_por_lo_menos_uno: {
        es: `Falla en por lo menos una`,
        en: `Fails at least one`
    },
    //2.1
    reactivo2_nombre_r2_1: {
        es: "Reactivo 2 Segunda oportunidad",
        en: "Reagent 2 Second chance"
    },
    // 3
    reactivo3_nombre_r3: {
        es: "Reactivo 3 ",
        en: "Reagent 3"
    },
    reactivo3_cliente_mayor_18: {
        es: "Cliente mayor a 18",
        en: "Client older than 18"
    },
    reactivo3_cliente_menor_18: {
        es: "Cliente menor a 18",
        en: "Client younger than 18"
    },
    reactivo3_score_entre_18: {
        es: "Score crediticio entre 716 y 809 puntos.",
        en: "Credit score between 716 and 809 points."
    },
    reactivo3_score_arriba_18: {
        es: "Score crediticio arriba de 809 puntos.",
        en: "Credit score above 809 points."
    },
    // 4
    reactivo4_nombre_r4: {
        es: "Reactivo 4 ",
        en: "Reagent 4"
    },
    reactivo4_selecion_carpeta: {
        es: "Selecciona una carpeta",
        en: "Select a folder"
    },
    // 4.1
    reactivo4_nombre_r4_1: {
        es: "Reactivo 4.1 Segunda oportunidad",
        en: "Reagent 4.1 Second chance"
    },
    // 5
    reactivo5_nombre_r5: {
        es: "Reactivo 5 ",
        en: "Reagent 5"
    },
    reactivo5_mayor_probabilidad_cumplir: {
        es: "Mayor probabilidad de cumplir con sus pagos",
        en: "Higher probability of meeting payments"
    },
    // 5.1
    reactivo5_nombre_r5_1: {
        es: "Reactivo 5 Segunda oportunidad",
        en: "Reagent 5 Second chance"
    },
    reactivo5_menor_probabilidad_cumplir: {
        es: "Menor probabilidad de cumplir con sus pagos ",
        en: "Lower probability of meeting payments"
    },
    // 6
    reactivo6_nombre_r6: {
        es: "Reactivo [replace1]",
        en: "Reagent [replace1]"
    },
    reactivo6_junta_jefe: {
        es: "Junta con el jefe",
        en: "Meeting with the boss"
    },
    // 7
    reactivo7_nombre_r7: {
        es: "Reactivo 7",
        en: "Reagent 7"
    },
    reactivo7_contexto: {
        es: "Junta con pareja (Mujer)",
        en: "Meeting with partner (Woman)"
    },
    // 8
    reactivo8_nombre_r8: {
        es: "Reactivo 8",
        en: "Reagent 8"
    },
    reactivo8_contexto: {
        es: "Junta con pareja (Hombre)",
        en: "Meeting with partner (Man)"
    },
    //Total 
    reactivo_total_calificaion_incipiente: {
        es: "Incipiente",
        en: "Incipient"
    },
    reactivo_total_calificaion_basico: {
        es: "Básico",
        en: "Basic"
    },
    reactivo_total_calificaion_solido: {
        es: "Sólido",
        en: "Solid"
    },
    reactivo_total_calificaion_destacado: {
        es: "Destacado",
        en: "Outstanding"
    },
    //bonus time
    reactivo8_resultado_final: {
        es: "Resultado final: [replace1]",
        en: "Final result: [replace1]"
    },
}

const reactivo1 = {
    //? Cliente registrado sí contestó la encuesta
    reactivo_1_cliente_registrado_si_contesto_encuesta_entregar_electrodoméstico_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_cliente_registrado_si_contesto_encuesta_entregar_electrodoméstico_nivel: {
        es: "El estudiante pudo tomar decisiones identificando y aplicando los conceptos de población y muestra: Distingue a la población, que para el caso de estudio son los clientes registrados. Además, reconoce a un evento transversal a la población, que para el caso de estudio es la muestra de individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was able to make decisions by identifying and applying the concepts of population and sample: He distinguishes the population, which in the case of study are the registered clients. Additionally, he recognizes an event transversal to the population, which in the case of study is the sample of individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_registrado_si_contesto_encuesta_entrega_tarjeta_200_retro: {
        es: "El cliente además de pertenecer a la población de clientes registrados formó parte de la muestra de clientes para el estudio de la Nueva Tarjeta Electrónica, debe recibir de regalo el electrodoméstico.",
        en: "The client, in addition to being part of the population of registered customers, was part of the sample of customers for the study of the New Electronic Card, so they should receive the household appliance."
    },
    reactivo_1_cliente_registrado_si_contesto_encuesta_entrega_tarjeta_200_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, o porque no reconocer el concepto de muestra, expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, or because they did not recognize the concept of sample, expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_registrado_si_contesto_encuesta_participa_futuras_promociones_retro: {
        es: "El cliente pertenecer a la población de clientes registrados y formó parte de la muestra de clientes para el estudio de la Nueva Tarjeta Electrónica, debe recibir de regalo el electrodoméstico.",
        en: "The client belongs to the population of registered customers and was part of the sample of customers for the study of the New Electronic Card, so they should receive the household appliance."
    },
    reactivo_1_cliente_registrado_si_contesto_encuesta_participa_futuras_promociones_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, y porque no reconocer el concepto de muestra expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, and because they did not recognize the concept of sample expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    //? Cliente registrado no contestó la encuesta
    reactivo_1_cliente_registrado_no_contesto_encuesta_entregar_electrodoméstico_retro: {
        es: "El cliente pertenece a la población de clientes registrados, pero no formó parte de la muestra de clientes para la Nueva Tarjeta Electrónica por eso debe recibir la tarjeta por $200 pesos.",
        en: "The client belongs to the population of registered customers, but did not participate in the sample of customers for the New Electronic Card, so they should receive the card for $200 pesos."
    },
    reactivo_1_cliente_registrado_no_contesto_encuesta_entregar_electrodoméstico_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, o porque no reconocer el concepto de muestra, expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, or because they did not recognize the concept of sample, expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_registrado_no_contesto_encuesta_entrega_tarjeta_200_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_cliente_registrado_no_contesto_encuesta_entrega_tarjeta_200_nivel: {
        es: "El estudiante pudo tomar decisiones identificando y aplicando los conceptos de población y muestra: Distingue a la población, que para el caso de estudio son los clientes registrados.  Además, reconoce a un evento transversal a la población, que para el caso de estudio es la muestra de individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was able to make decisions by identifying and applying the concepts of population and sample: He distinguishes the population, which in the case of study are the registered clients. Additionally, he recognizes an event transversal to the population, which in the case of study is the sample of individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_registrado_no_contesto_encuesta_participa_futuras_promociones_retro: {
        es: "El cliente pertenecer a la población de clientes registrados y no formó parte de la muestra de clientes para el estudio de la Nueva Tarjeta Electrónica, debe recibir la tarjeta por $200 pesos.",
        en: "The client belongs to the population of registered customers and did not participate in the sample of customers for the study of the New Electronic Card, so they should receive the card for $200 pesos."
    },
    reactivo_1_cliente_registrado_no_contesto_encuesta_participa_futuras_promociones_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, y porque no reconocer el concepto de muestra expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, and because they did not recognize the concept of sample expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    //? Cliente no registrado sí contestó la encuesta
    reactivo_1_cliente_no_registrado_si_contesto_encuesta_entregar_electrodoméstico_retro: {
        es: "El cliente, aunque participó en el estudio, no pertenecer a la población de clientes registrados, solo se le registra para que tenga la oportunidad de participar en futuras promociones.",
        en: "The client, although participated in the study, does not belong to the population of registered customers, they are only registered to have the opportunity to participate in future promotions."
    },
    reactivo_1_cliente_no_registrado_si_contesto_encuesta_entregar_electrodoméstico_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, o porque no reconocer el concepto de muestra, expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, or because they did not recognize the concept of sample, expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_no_registrado_si_contesto_encuesta_entrega_tarjeta_200_retro: {
        es: "El cliente, no pertenecer a la población de clientes registrados, solo se le registra para que tenga la oportunidad de participar en futuras promociones.",
        en: "The client does not belong to the population of registered customers, they are only registered to have the opportunity to participate in future promotions."
    },
    reactivo_1_cliente_no_registrado_si_contesto_encuesta_entrega_tarjeta_200_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, y porque no reconocer el concepto de muestra expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, and because they did not recognize the concept of sample expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_no_registrado_si_contesto_encuesta_participa_futuras_promociones_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_cliente_no_registrado_si_contesto_encuesta_participa_futuras_promociones_nivel: {
        es: "El estudiante pudo tomar decisiones identificando y aplicando los conceptos de población y muestra: Distingue a la población, que para el caso de estudio son los clientes registrados. Además, reconoce a un evento transversal a la población, que para el caso de estudio es la muestra de individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was able to make decisions by identifying and applying the concepts of population and sample: He distinguishes the population, which in the case of study are the registered clients. Additionally, he recognizes an event transversal to the population, which in the case of study is the sample of individuals who participated in the study of the new electronic card."
    },
    //? Cliente no registrado no contestó la encuesta
    reactivo_1_cliente_no_registrado_no_contesto_encuesta_entregar_electrodoméstico_retro: {
        es: "El cliente no pertenecer a la población de clientes registrados además tampoco participó en el estudio, solo se le registra para que tenga la oportunidad de participar en futuras promociones.",
        en: "The client does not belong to the population of registered customers and also did not participate in the study, they are only registered to have the opportunity to participate in future promotions."
    },
    reactivo_1_cliente_no_registrado_no_contesto_encuesta_entregar_electrodoméstico_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, y porque no reconocer el concepto de muestra expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, and because they did not recognize the concept of sample expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_no_registrado_no_contesto_encuesta_entrega_tarjeta_200_retro: {
        es: "El cliente no pertenecer a la población de clientes registrados, solo se le registra para que tenga la oportunidad de participar en futuras promociones.",
        en: "The client does not belong to the population of registered customers, they are only registered to have the opportunity to participate in future promotions."
    },
    reactivo_1_cliente_no_registrado_no_contesto_encuesta_entrega_tarjeta_200_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando los conceptos de población o muestra: Cometió error porque no reconocer el concepto de población, que para el caso de estudio son los clientes registrados, o porque no reconocer el concepto de muestra, expresado en el caso de estudio como el evento transversal a la población que representa a los individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was unable to make decisions by identifying and applying the concepts of population or sample: Made an error because they did not recognize the concept of population, which in this case study are the registered customers, or because they did not recognize the concept of sample, expressed in the case study as the event that cuts across the population representing the individuals who participated in the study of the new electronic card."
    },
    reactivo_1_cliente_no_registrado_no_contesto_encuesta_participa_futuras_promociones_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_cliente_no_registrado_no_contesto_encuesta_participa_futuras_promociones_nivel: {
        es: "El estudiante pudo tomar decisiones identificando y aplicando los conceptos de población y muestra: Distingue a la población, que para el caso de estudio son los clientes registrados. Además, reconoce a un evento transversal a la población, que para el caso de estudio es la muestra de individuos que participaron en el estudio de la nueva tarjeta electrónica.",
        en: "The student was able to make decisions by identifying and applying the concepts of population and sample: He distinguishes the population, which in the case of study are the registered clients. Additionally, he recognizes an event transversal to the population, which in the case of study is the sample of individuals who participated in the study of the new electronic card."
    },
}

const reactivo1_1 = {
    //? Si asistió al evento
    reactivo_1_1_si_asistio_evento_autoriza_promocion_feria_mujer_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_1_si_asistio_evento_autoriza_promocion_feria_mujer_nivel: {
        es: "El estudiante puedo tomar decisiones identificando y aplicando el concepto de muestra: Reconoció a la muestra de mujeres jóvenes que han asistido a la feria de promoción la tarjeta preferente “Banco Creciendo Juntos cree en ti.",
        en: `The student was able to make decisions by identifying and applying the concept of a sample: He recognized the sample of young women who attended the promotion fair for the "Banco Creciendo Juntos believes in you" preferred card.`
    },
    reactivo_1_1_si_asistio_evento_denegar_promocion_feria_mujer_retro: {
        es: "La cliente sí formó parte de la muestra de mujeres que asistieron a la feria de promoción por ello lo correcto es ofrecer la tarjeta preferente Banco Creciendo Juntos cree en ti.",
        en: "The customer did participate in the sample of women who attended the promotional fair, so the correct action is to offer the Banco Creciendo Juntos preferred card."
    },
    reactivo_1_1_si_asistio_evento_denegar_promocion_feria_mujer_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando el concepto de muestra: Cometió error porque no reconocer el concepto de muestra expresado en el caso de estudio como el grupo de mujeres jóvenes que han asistido a la feria de promoción la tarjeta preferente “Banco Creciendo Juntos cree en ti.",
        en: `The student was unable to make decisions by identifying and applying the concept of a sample: He made an error because he did not recognize the concept of a sample expressed in the case study as the group of young women who attended the promotion fair for the "Banco Creciendo Juntos believes in you" preferred card.`
    },
    //? No asistió al evento
    reactivo_1_1_no_asistio_evento_autoriza_promocion_feria_mujer_retro: {
        es: "La cliente no formó parte de la muestra de mujeres que asistieron a la feria de promoción por ello no es correcto ofrecerle la tarjeta preferente Banco Creciendo Juntos cree en ti.",
        en: "The customer did not participate in the sample of women who attended the promotional fair, so it is not correct to offer them the Banco Creciendo Juntos preferred card."
    },
    reactivo_1_1_no_asistio_evento_autoriza_promocion_feria_mujer_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando el concepto de muestra: Cometió error porque no reconocer el concepto de muestra expresado en el caso de estudio como el grupo de mujeres jóvenes que han asistido a la feria de promoción la tarjeta preferente “Banco Creciendo Juntos cree en ti.",
        en: `The student was unable to make decisions by identifying and applying the concept of a sample: He made an error because he did not recognize the concept of a sample expressed in the case study as the group of young women who attended the promotion fair for the "Banco Creciendo Juntos believes in you" preferred card.`
    },
    reactivo_1_1_no_asistio_evento_denegar_promocion_feria_mujer_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_1_no_asistio_evento_denegar_promocion_feria_mujer_nivel: {
        es: "El estudiante puedo tomar decisiones identificando y aplicando el concepto de muestra: Reconoció a la muestra de mujeres jóvenes que han asistido a la feria de promoción la tarjeta preferente “Banco Creciendo Juntos cree en ti.",
        en: `The student was able to make decisions by identifying and applying the concept of a sample: He recognized the sample of young women who attended the promotion fair for the "Banco Creciendo Juntos believes in you" preferred card.`
    },
    //? Cliente registrado
    reactivo_1_1_cliente_registrado_autorizar_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_1_cliente_registrado_autorizar_nivel: {
        es: "El estudiante puedo tomar decisiones identificando y aplicando el concepto de población: Reconoció a la población de clientes registrados que pueden gozar de una tasa preferencial 5% menor a la tasa que se ofrece para todo público.",
        en: "The student was able to make decisions by identifying and applying the concept of population: He recognized the population of registered customers who can enjoy a preferential rate 5% lower than the rate offered to the general public."
    },
    reactivo_1_1_cliente_registrado_denegar_retro: {
        es: "El cliente sí pertenece a la población de clientes registrados por lo que lo correcto es ofrecer la promoción de crédito con tasa preferencial 5% menor a la tasa que se ofrece para todo público.",
        en: "The client does belong to the population of registered customers, so the correct action is to offer the credit promotion with a preferential rate 5% lower than the rate offered to the general public."
    },
    reactivo_1_1_cliente_registrado_denegar_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando el concepto de población: Cometió error al reconocer el concepto de población expresado como clientes registrados que pueden gozar de una tasa preferencial 5% menor a la tasa que se ofrece para todo público.",
        en: "The student was unable to make decisions by identifying and applying the concept of population: He made a mistake in recognizing the concept of population expressed as registered customers who can enjoy a preferential rate 5% lower than the rate offered to the general public."
    },
    //? Cliente no registrado
    reactivo_1_1_cliente_no_registrado_autorizar_retro: {
        es: "El cliente no pertenece a la población de clientes registrados por lo que lo correcto es no ofrecer la promoción de crédito con tasa preferencial 5% menor a la tasa que se ofrece para todo público.",
        en: "The client does not belong to the population of registered customers, so the correct action is not to offer the credit promotion with a preferential rate 5% lower than the rate offered to the general public."
    },
    reactivo_1_1_cliente_no_registrado_autorizar_nivel: {
        es: "El estudiante no pudo tomar decisiones identificando y aplicando el concepto de población: Cometió error al reconocer el concepto de población expresado como clientes registrados que pueden gozar de una tasa preferencial 5% menor a la tasa que se ofrece para todo público.",
        en: "The student was unable to make decisions by identifying and applying the concept of population: He made a mistake in recognizing the concept of population expressed as registered customers who can enjoy a preferential rate 5% lower than the rate offered to the general public."
    },
    reactivo_1_1_cliente_no_registrado_denegar_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_1_1_cliente_no_registrado_denegar_nivel: {
        es: "El estudiante puedo tomar decisiones identificando y aplicando el concepto de población: Reconoció a la población de clientes registrados que pueden gozar de una tasa preferencial 5% menor a la tasa que se ofrece para todo público.",
        en: "The student was able to make decisions by identifying and applying the concept of population: He recognized the population of registered customers who can enjoy a preferential rate 5% lower than the rate offered to the general public."
    },
}

const reactivo2 = {
    //? tabla 1 acierta en todas
    reactivo_2_tabla1_acierta_todas_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_2_tabla1_acierta_todas_nivel: {
        es: "El estudiante reconoció correctamente los errores en la designación de variables y reconoció correctamente los errores en la designación de escalas de medición.",
        en: "The student correctly recognized errors in the designation of variables and correctly recognized errors in the designation of measurement scales."
    },
    //? tabla 1 falla en una
    reactivo_2_tabla1_falla_en_una_retro: {
        es: "Confundes al menos un tipo de variable y/o sus escalas de medición te sugiero que revises este tema",
        en: "You confuse at least one type of variable and/or its measurement scales, I suggest you review this topic thoroughly."
    },
    reactivo_2_tabla1_falla_en_una_nivel: {
        es: "El estudiante cometió al menos un error reconociendo los errores en la designación de variables o cometió al menos un error reconociendo los errores en la designación de escalas de medición.",
        en: "The student made at least one mistake recognizing errors in the designation of variables or made at least one mistake recognizing errors in the designation of measurement scales."
    },
    //? tabla 1 no acierta 
    reactivo_2_tabla1_no_acierta_todas_retro: {
        es: "Tienes confusión en la identificación de los tipos de variables y de sus escalas de medición es necesario revisar con detalle este tema.",
        en: "You have confusion in identifying the types of variables and their measurement scales, it is necessary to review this topic in detail."
    },
    reactivo_2_tabla1_no_acierta_todas_nivel: {
        es: "El estudiante cometió al menos un error reconociendo los errores en la designación de variables y cometió al menos un error reconociendo los errores en la designación de escalas de medición.",
        en: "The student made at least one mistake recognizing errors in the designation of variables and made at least one mistake recognizing errors in the designation of measurement scales."
    },
}

const reactivo2_1 = {
    //? tabla 1 acierta en todas
    reactivo_2_1_tabla1_acierta_todas_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_2_1_tabla1_acierta_todas_nivel: {
        es: "El estudiante ya pudo reconocer correctamente los errores en la designación de variables y reconoció correctamente los errores en la designación de escalas de medición.",
        en: "The student has already been able to correctly recognize errors in the designation of variables and correctly recognized errors in the designation of measurement scales."
    },
    //? tabla 1 falla en una
    reactivo_2_1_tabla1_falla_en_una_retro: {
        es: "Te recomiendo que revises detalladamente los conceptos de tipos de variables y escalas de medición.",
        en: "I recommend that you carefully review the concepts of types of variables and measurement scales."
    },
    reactivo_2_1_tabla1_falla_en_una_nivel: {
        es: "El estudiante requiere una revisión detallada de los conceptos sobre tipos de variables y escalas de medición.",
        en: "The student requires a detailed review of the concepts of variable types and measurement scales."
    },
}

const reactivo3 = {
    //? cliente mayor
    reactivo3_cliente_menor_18_ofrece_tarjeta_credito_retro: {
        es: "Dado que la variable edad del cliente se distribuye de manera normal, el percentil 2.5 equivale al resultado de la media menos dos veces la desviación estándar, por lo tanto, dado que el cliente es menor a 18 años debe otorgársele la tarjeta de débito con aval.",
        en: "Given that the client's age variable is normally distributed, the 2.5th percentile is equivalent to the mean minus two times the standard deviation. Therefore, since the client is younger than 18 years old, they should be offered the debit card with a guarantor."
    },
    reactivo3_cliente_menor_18_ofrece_tarjeta_credito_nivel: {
        es: "El estudiante no reconoce alguno de los siguientes conceptos: la normalidad de la distribución de frecuencias, la regla empírica que define la relación entre la media y la desviación estándar de la variable en cuestión para aproximarse a los valores de ciertos percentiles predeterminados y el concepto de percentil que para el caso de estudio ese valor es el punto crítico para la toma de decisiones.",
        en: "The student does not recognize some of the following concepts: the normality of the frequency distribution, the empirical rule that defines the relationship between the mean and the standard deviation of the variable in question to approximate the values of certain predetermined percentiles, and the concept of percentile, which in the case of study represents the critical value for decision-making."
    },
    reactivo3_cliente_menor_18_ofrece_cuenta_ahorro_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo3_cliente_menor_18_ofrece_cuenta_ahorro_nivel: {
        es: "El estudiante es capaz de tomar la decisión correcta al reconocer que la distribución de frecuencias de los datos es normal; por tanto, puede obtener, utilizando la regla empírica, los valores del percentil indicado en el caso de estudio el cual representa el valor crítico para la toma de decisiones",
        en: "The student is able to make the correct decision by recognizing that the frequency distribution of the data is normal; therefore, they can obtain, using the empirical rule, the values of the percentile indicated in the case study, which represents the critical value for decision-making."
    },
    //? cliente menor
    reactivo3_cliente_mayor_18_ofrece_tarjeta_credito_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo3_cliente_mayor_18_ofrece_tarjeta_credito_nivel: {
        es: "El estudiante es capaz de tomar la decisión correcta al reconocer que la distribución de frecuencias de los datos es normal; por tanto, puede obtener, utilizando la regla empírica, los valores del percentil indicado en el caso de estudio el cual representa el valor crítico para la toma de decisiones.",
        en: "The student is able to make the correct decision by recognizing that the frequency distribution of the data is normal; therefore, they can obtain, using the empirical rule, the values of the percentile indicated in the case study, which represents the critical value for decision-making."
    },
    reactivo3_cliente_mayor_18_ofrece_cuenta_ahorro_retro: {
        es: "Dado que la variable edad del cliente se distribuye de manera normal, el percentil 2.5 equivale al resultado de la media menos dos veces la desviación estándar, por lo tanto, dado que el cliente es mayor a 18 años debe otorgársele la tarjeta de crédito.",
        en: "Given that the client's age variable is normally distributed, the 2.5th percentile is equivalent to the mean minus two times the standard deviation. Therefore, since the client is older than 18 years old, they should be offered the credit card."
    },
    reactivo3_cliente_mayor_18_ofrece_cuenta_ahorro_nivel: {
        es: "El estudiante no reconoce alguno de los siguientes conceptos: la normalidad de la distribución de frecuencias, la regla empírica que define la relación entre la media y la desviación estándar de la variable en cuestión para aproximarse a los valores de ciertos percentiles predeterminados y el concepto de percentil que para el caso de estudio ese valor es el punto crítico para la toma de decisiones.",
        en: "The student does not recognize some of the following concepts: the normality of the frequency distribution, the empirical rule that defines the relationship between the mean and the standard deviation of the variable in question to approximate the values of certain predetermined percentiles, and the concept of percentile, which in the case of study represents the critical value for decision-making."
    },
    //? score entre
    reactivo3_score_entre_ofrece_prestamo_1000_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo3_score_entre_ofrece_prestamo_1000_nivel: {
        es: "El estudiante es capaz de tomar la decisión correcta al reconocer que la distribución de frecuencias de los datos es normal; por tanto, puede obtener, utilizando la regla empírica, los valores del percentil indicado en el caso de estudio el cual representa el valor crítico para la toma de decisiones.",
        en: "The student is able to make the correct decision by recognizing that the frequency distribution of the data is normal; therefore, they can obtain, using the empirical rule, the values of the percentile indicated in the case study, which represents the critical value for decision-making."
    },
    reactivo3_score_entre_ofrece_prestamo_5000_retro: {
        es: "Dado que la variable score crediticio se distribuye de manera normal, el percentil 84 es el resultado de la media más la desviación estándar y el percentil 97.5 es el resultado de la media más dos veces la desviación estándar, por lo tanto, dado que el cliente tiene un score crediticio abajo del percentil 97.5, el préstamo que deben ofrecerle es por $100,000 pesos.",
        en: "Given that the credit score variable is normally distributed, the 84th percentile is the result of the mean plus the standard deviation, and the 97.5th percentile is the result of the mean plus two times the standard deviation. Therefore, since the client has a credit score below the 97.5th percentile, the loan they should be offered is for $100,000 pesos."
    },
    reactivo3_score_entre_ofrece_prestamo_5000_nivel: {
        es: "El estudiante no reconoce alguno de los siguientes conceptos: la normalidad de la distribución de frecuencias, la regla empírica que define la relación entre la media y la desviación estándar de la variable en cuestión para aproximarse a los valores de ciertos percentiles predeterminados y el concepto de percentil que para el caso de estudio ese valor es el punto crítico para la toma de decisiones.",
        en: "The student does not recognize some of the following concepts: the normality of the frequency distribution, the empirical rule that defines the relationship between the mean and the standard deviation of the variable in question to approximate the values of certain predetermined percentiles, and the concept of percentile, which in the case of study represents the critical value for decision-making."
    },
    //? score mayor
    reactivo3_score_mayor_ofrece_prestamo_1000_retro: {
        es: "Dado que la variable score crediticio se distribuye de manera normal, el percentil 84 es el resultado de la media más la desviación estándar y el percentil 97.5 es el resultado de la media más dos veces la desviación estándar, por lo tanto, dado que el cliente tiene un score crediticio arriba del percentil 97.5, el préstamo que deben ofrecerle es por $500,000 pesos.",
        en: "Given that the credit score variable is normally distributed, the 84th percentile is the result of the mean plus the standard deviation, and the 97.5th percentile is the result of the mean plus two times the standard deviation. Therefore, since the client has a credit score above the 97.5th percentile, the loan they should be offered is for $500,000 pesos."
    },
    reactivo3_score_mayor_ofrece_prestamo_1000_nivel: {
        es: "El estudiante no reconoce alguno de los siguientes conceptos: la normalidad de la distribución de frecuencias, la regla empírica que define la relación entre la media y la desviación estándar de la variable en cuestión para aproximarse a los valores de ciertos percentiles predeterminados y el concepto de percentil que para el caso de estudio ese valor es el punto crítico para la toma de decisiones.",
        en: "The student does not recognize some of the following concepts: the normality of the frequency distribution, the empirical rule that defines the relationship between the mean and the standard deviation of the variable in question to approximate the values of certain predetermined percentiles, and the concept of percentile, which in the case of study represents the critical value for decision-making."
    },
    reactivo3_score_mayor_ofrece_prestamo_5000_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo3_score_mayor_ofrece_prestamo_5000_nivel: {
        es: "El estudiante es capaz de tomar la decisión correcta al reconocer que la distribución de frecuencias de los datos es normal; por tanto, puede obtener, utilizando la regla empírica, los valores del percentil indicado en el caso de estudio el cual representa el valor crítico para la toma de decisiones.",
        en: "The student is able to make the correct decision by recognizing that the frequency distribution of the data is normal; therefore, they can obtain, using the empirical rule, the values of the percentile indicated in the case study, which represents the critical value for decision-making."
    },
}


const reactivo4 = {

    //! es ocupa solo una vez
    //? const studentRetro =  es el retroalimentacion de
    //? const teacherRetro =  es el nivel de competencia de
    // Crédito hipotecario con letras de crédito
    // Mutuo hipotecario endosable.
    // Mutuo hipotecaria no endosable.
    // Crédito convencional automotriz
    // Crédito inteligente
    // Crédito leasing o arrendamiento.
    // Seguro de vida temporal.
    // Seguro de ahorros para casos de supervivencia o jubilación.
    // Seguro de vida entera.
    // análisis distribucional

    reactivo4_student_retroalientacion_multiple: {
        es: "El tema de la junta es la posibilidad de lanzar una nueva tarjeta de crédito con beneficios especiales, así como se indicó en el laboratorio de práctica",
        en: "The topic of the meeting is the possibility of launching a new credit card with special benefits, as indicated in the practice lab."
    },
    reactivo4_student_nive_competencia_multiple: {
        es: "El estudiante no comprendió el caso de estudio",
        en: "The student did not understand the case study."
    },
    // -- --

    reactivo4_analisis_distribucional_retro: {
        es: "Este tema se refiere a las distribuciones de probabilidad de los estadísticos como X barra, S y P (distribuciones de muestreo). La información que se debe mostrar al Gerente es el análisis descriptivo de los datos disponibles, poblacionales y muestrales, usando métodos gráficos y medidas numéricas como herramientas de análisis.",
        en: "This topic refers to the probability distributions of statistics such as X bar, S, and P (sampling distributions). The information that should be presented to the Manager is the descriptive analysis of the available data, both population-level and sample-level, using graphical methods and numerical measures as analytical tools."
    },
    reactivo4_analisis_distribucional_nivel: {
        es: "El estudiante no comprende el caso de estudio y/o no distingue cuales son las herramientas que se emplean en el análisis descriptivo.",
        en: "The student does not understand the case study and/or does not distinguish which tools are used in descriptive analysis."
    },
    // análisis inferencial 
    reactivo4_analisis_inferencial_retro: {
        es: "El análisis inferencial se realiza utilizando estimaciones por intervalo y pruebas de hipótesis para estimar a la población a partir de los estadísticos obtenidos de la muestra. Para el caso de estudio se dispone de información tanto poblacional como muestral, la información que se le mostrará al Gerente es al análisis descriptivo usando métodos gráficos y medidas numéricas como herramientas de análisis.",
        en: "Inferential analysis is performed using interval estimates and hypothesis testing to estimate the population based on the statistics obtained from the sample. For the case study, both population-level and sample-level information is available, and the information that will be shown to the Manager is the descriptive analysis using graphical methods and numerical measures as analytical tools."
    },
    reactivo4_analisis_inferencial_nivel: {
        es: "El estudiante no comprende el caso de estudio y/o no distingue cuales son las herramientas que se emplean en el análisis descriptivo.",
        en: "The student does not understand the case study and/or does not distinguish which tools are used in descriptive analysis."
    },
    // análisis gráfico y numérico
    reactivo4_analisis_grafico_numerico_retro: {
        es: "Selección correcta",
        en: "Correct answer"
    },
    reactivo4_analisis_grafico_numerico_nivel: {
        es: "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas.",
        en: "The student understands the case study because they chose the correct folder and understand that the descriptive analysis of customer information is performed using graphical and numerical tools."
    },
};

const reactivo5 = {
    // ? Mayor probabilidad de cumplir con sus pagos. 4000 - 4579
    reactivo5_mayor_cuplir_pagos_4000_cliente_1_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer",
    },
    reactivo5_mayor_cuplir_pagos_4000_cliente_1_nivel: {
        es: "El estudiante fue capaz de tomar una decisión correcta a partir de realizar el comparativo entre los tres clientes para las variables ingreso promedio y gasto promedio, entendiendo la condición de que ambas variables son normales e independientes, contrastando los percentiles establecidos por la regla empírica lo que le permitió observar cuál de los clientes tiene la mayor probabilidad de tener un ingreso superior al gasto.",
        en: "The student was able to make a correct decision by comparing the three customers for the variables of average income and average expenditure, understanding the condition that both variables are normal and independent, contrasting the percentiles established by the empirical rule which allowed them to observe which of the customers is most likely to have income higher than expenditure.",
    },
    reactivo5_mayor_cuplir_pagos_4000_cliente_2_retro: {
        es: "La respuesta correcta es el cliente 1 debido a que es el único que no tiene déficit en ninguno de los límites del 95% de la regla empírica para una distribución normal, lo que aumenta la probabilidad de cumplir con el pago de la tarjeta de crédito",
        en: "The correct answer is customer 1 because they are the only one who does not have deficits in any of the limits of the 95% of the empirical rule for a normal distribution, which increases the probability of being able to pay the credit card.",
    },
    reactivo5_mayor_cuplir_pagos_4000_cliente_2_nivel: {
        es: "El estudiante no tomó una decisión adecuada debido a que no comprendió o no aplicó el siguiente razonamiento: La relación entre la distribución normal y la regla empírica ya que, a partir de la regla empírica, que establece los percentiles para determinadas probabilidades, podría estimar los valores de las variables para evaluar hasta qué valor de probabilidad el ingreso sería superior al gasto, considerando la independencia entre las variables",
        en: "The student did not make an appropriate decision because they did not understand or apply the following reasoning: The relationship between the normal distribution and the empirical rule, since, based on the empirical rule, which establishes percentiles for certain probabilities, they could estimate the values of the variables to evaluate up to which probability value the income would be higher than the expenditure, considering the independence between the variables.",
    },
    //? Mayor probabilidad de cumplir con sus pagos. 4580 - 5000
    reactivo5_mayor_cuplir_pagos_4580_cliente_2_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer",
    },
    reactivo5_mayor_cuplir_pagos_4580_cliente_2_nivel: {
        es: "El estudiante fue capaz de tomar una decisión correcta a partir de realizar el comparativo entre los tres clientes para las variables ingreso promedio y gasto promedio, entendiendo la condición de que ambas variables son normales e independientes, contrastando los percentiles establecidos por la regla empírica lo que le permitió observar cuál de los clientes tiene la mayor probabilidad de tener un ingreso superior al gasto.",
        en: "The student was able to make a correct decision by comparing the three customers for the variables of average income and average expenditure, understanding the condition that both variables are normal and independent, contrasting the percentiles established by the empirical rule which allowed them to observe which of the customers is most likely to have income higher than expenditure.",
    },
    reactivo5_mayor_cuplir_pagos_4580_cliente_3_retro: {
        es: "La respuesta correcta es el cliente 2 debido a que, en ambos límites del intervalo para el 95% de la regla empírica cuando la variable aleatoria es normal, los ingresos son iguales o mayores a los gastos y el superávit es mayor a todos los casos, lo que aumenta la probabilidad de cumplir con el pago de la tarjeta de crédito",
        en: "The correct answer is customer 2 because, in both limits of the interval for the 95% of the empirical rule when the random variable is normal, the income is equal to or greater than the expenses, and the surplus is greater than in all cases, which increases the probability of being able to pay the credit card.",
    },
    reactivo5_mayor_cuplir_pagos_4580_cliente_3_nivel: {
        es: "El estudiante no tomó una decisión adecuada debido a que no comprendió o no aplicó el siguiente razonamiento: La relación entre la distribución normal y la regla empírica ya que, a partir de la regla empírica, que establece los percentiles para determinadas probabilidades, podría estimar los valores de las variables para evaluar hasta qué valor de probabilidad el ingreso sería superior al gasto, considerando la independencia entre las variables",
        en: "The student did not make an appropriate decision because they did not understand or apply the following reasoning: The relationship between the normal distribution and the empirical rule, since, based on the empirical rule, which establishes percentiles for certain probabilities, they could estimate the values of the variables to evaluate up to which probability value the income would be higher than the expenditure, considering the independence between the variables.",
    },
}

const reactivo5_5 = {
    reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_1_2_retro: {
        es: "La respuesta correcta es el cliente 3 debido a que es el único que muestra una probabilidad menor al 84% de tener un ingreso semanal mayor al gato semanal lo que incrementa la probabilidad de que no pague la deuda de la tarjeta de crédito.",
        en: "The correct answer is customer 3 because they are the only one who shows a probability lower than 84% of having a weekly income greater than the weekly expenditure, which increases the probability of not paying the credit card debt."
    },
    reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_1_2_nivel: {
        es: "El estudiante, en una segunda oportunidad, no tomó una decisión adecuada debido a que no comprendió o no aplicó alguno de los siguientes razonamientos: La relación entre la distribución normal y la regla empírica ya que, a partir de la regla empírica, que establece los percentiles para determinadas probabilidades, podría estimar los valores de las variables para evaluar hasta qué valor de probabilidad el ingreso sería superior al gasto, considerando la independencia entre las variables.",
        en: "The student, on a second attempt, did not make an appropriate decision because they did not understand or apply one of the following reasoning: The relationship between the normal distribution and the empirical rule, since, based on the empirical rule, which establishes percentiles for certain probabilities, they could estimate the values of the variables to evaluate up to which probability value the income would be higher than the expenditure, considering the independence between the variables."
    },
    reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_3_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_3_nivel: {
        es: "El estudiante, en una segunda oportunidad, fue capaz de tomar una decisión correcta a partir de realizar el comparativo entre los tres clientes para las variables ingreso promedio y gasto promedio, entendiendo la condición de que ambas variables son normales e independientes, contrastando los percentiles establecidos por la regla empírica lo que le permitió observar cuál de los clientes tiene la menor probabilidad de tener un ingreso superior al gasto.",
        en: "The student, on a second attempt, was able to make a correct decision by comparing the three clients for the variables of average income and average expenditure, understanding the condition that both variables are normal and independent, contrasting the percentiles established by the empirical rule which allowed them to observe which of the clients has the lowest probability of having income higher than expenditure."
    },
}

const reactivo6_1 = {
    //? caso 1
    reactivo_6_1_caso_1_tipo_actividad_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_1_caso_1_tipo_actividad_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta eligiendo la gráfica de pastel cuando se solicita el valor de la probabilidad marginal de la variable analizada o la gráfica de barras cuando se solicita la probabilidad condicional entre variables",
        en: "The student was able to make the correct decision by choosing the pie chart when the value of the marginal probability of the analyzed variable is requested or the bar chart when the conditional probability between variables is requested."
    },
    reactivo_6_1_caso_1_genero_clientes_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no pide la proporción del género de los clientes.",
        en: "Although the interpretation of this graph is correct, the Manager does not ask for the gender proportion of the customers."
    },
    reactivo_6_1_caso_1_genero_clientes_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió de manera incorrecta la gráfica la gráfica de pastel que muestra el valor de la probabilidad marginal de la variable analizada o la gráfica de barras que la probabilidad condicional entre variables.",
        en: "The student did not make the appropriate decision because they incorrectly chose either the pie chart showing the value of the marginal probability of the analyzed variable or the bar chart showing the conditional probability between variables."
    },
    reactivo_6_1_caso_1_clientes_genero_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no pide la proporción condicional de no tener trabajo remunerado dado su género",
        en: "Although the interpretation of this graph is correct, the Manager does not ask for the conditional proportion of not having paid work given their gender."
    },
    reactivo_6_1_caso_1_clientes_genero_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió de manera incorrecta la gráfica la gráfica de pastel que muestra el valor de la probabilidad marginal de la variable analizada o la gráfica de barras que la probabilidad condicional entre variables.",
        en: "The student did not make the appropriate decision because they incorrectly chose either the pie chart showing the value of the marginal probability of the analyzed variable or the bar chart showing the conditional probability between variables."
    },
    //? caso 2
    reactivo_6_1_caso_2_tipo_actividad_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no pide la proporción de actividades laborales de los clientes",
        en: "Although the interpretation of this graph is correct, the Manager does not ask for the proportion of job activities of the customers."
    },
    reactivo_6_1_caso_2_tipo_actividad_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió de manera incorrecta la gráfica la gráfica de pastel que muestra el valor de la probabilidad marginal de la variable analizada o la gráfica de barras que la probabilidad condicional entre variables.",
        en: "The student did not make the appropriate decision because they incorrectly chose either the pie chart showing the value of the marginal probability of the analyzed variable or the bar chart showing the conditional probability between variables."
    },
    reactivo_6_1_caso_2_genero_clientes_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no pide la proporción del género de los clientes.",
        en: "Although the interpretation of this graph is correct, the Manager does not ask for the gender proportion of the customers."
    },
    reactivo_6_1_caso_2_genero_clientes_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió de manera incorrecta la gráfica la gráfica de pastel que muestra el valor de la probabilidad marginal de la variable analizada o la gráfica de barras que la probabilidad condicional entre variables.",
        en: "The student did not make the appropriate decision because they incorrectly chose either the pie chart showing the value of the marginal probability of the analyzed variable or the bar chart showing the conditional probability between variables."
    },
    reactivo_6_1_caso_2_clientes_genero_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_1_caso_2_clientes_genero_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta eligiendo la gráfica de pastel cuando se solicita el valor de la probabilidad marginal de la variable analizada o la gráfica de barras cuando se solicita la probabilidad condicional entre variables",
        en: "The student was able to make the correct decision by choosing the pie chart when the value of the marginal probability of the analyzed variable is requested or the bar chart when the conditional probability between variables is requested."
    },
}

const reactivo6_2 = {
    //? caso 1
    reactivo_6_2_caso_1_proporcion_clientes_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_2_caso_1_proporcion_clientes_nivel: {
        es: "El estudiante fue capaz de tomar decisiones empleando la gráfica correcta. Para el caso de la gráfica de pastel, que refleja el valor de la probabilidad marginal de la variable analizada y distingue adecuadamente entre las gráficas de barras que muestran relaciones condicionales entre variables.",
        en: "The student was able to make decisions using the correct graph. For the case of the pie chart, which reflects the value of the marginal probability of the analyzed variable, and appropriately distinguishes between the bar charts that show conditional relationships between variables."
    },
    reactivo_6_2_caso_1_por_sexo_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no solicita la proporción de hombres de los clientes que compran en mercados y supermercados.",
        en: "Although the interpretation of this graph is correct, the Manager does not request the proportion of male customers who shop in markets and supermarkets."
    },
    reactivo_6_2_caso_1_por_sexo_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable o eligió la gráfica de pastel que muestra la proporción marginal.",
        en: "The student did not make the appropriate decision because they chose a graph that showed a conditional proportion of the variable of interest with respect to another variable or chose the pie chart showing the marginal proportion."
    },
    reactivo_6_2_caso_1_por_entidad_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no solicita la comparación entre las fracciones de clientes que compran en el supermercado entre las dos entidades.",
        en: "Although the interpretation of this graph is correct, the Manager does not request the comparison between the fractions of customers who shop at the supermarket between the two entities."
    },
    reactivo_6_2_caso_1_por_entidad_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable o eligió la gráfica de pastel que muestra la proporción marginal.",
        en: "The student did not make the appropriate decision because they chose a graph that showed a conditional proportion of the variable of interest with respect to another variable or chose the pie chart showing the marginal proportion."
    },
    //? caso 2
    reactivo_6_2_caso_2_proporcion_clientes_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no solicita la proporción de clientes que hacen sus compras por establecimiento.",
        en: "Although the interpretation of this graph is correct, the Manager does not request the proportion of customers who make their purchases by establishment."
    },
    reactivo_6_2_caso_2_proporcion_clientes_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable o eligió la gráfica de pastel que muestra la proporción marginal.",
        en: "The student did not make the appropriate decision because they chose a graph that showed a conditional proportion of the variable of interest with respect to another variable or chose the pie chart showing the marginal proportion."
    },
    reactivo_6_2_caso_2_por_sexo_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no solicita la proporción de hombres de los clientes que compran en mercados y supermercados.",
        en: "Although the interpretation of this graph is correct, the Manager does not request the proportion of male customers who shop in markets and supermarkets."
    },
    reactivo_6_2_caso_2_por_sexo_nivel: {
        es: "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable o eligió la gráfica de pastel que muestra la proporción marginal.",
        en: "The student did not make the appropriate decision because they chose a graph that showed a conditional proportion of the variable of interest with respect to another variable or chose the pie chart showing the marginal proportion."
    },
    reactivo_6_2_caso_2_por_entidad_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_2_caso_2_por_entidad_nivel: {
        es: "El estudiante fue capaz de tomar decisiones empleando la gráfica correcta. Para el caso de la gráfica de pastel, que refleja el valor de la probabilidad marginal de la variable analizada y distingue adecuadamente entre las gráficas de barras que muestran relaciones condicionales entre variables.",
        en: "The student was able to make decisions using the correct graph. For the case of the pie chart, which reflects the value of the marginal probability of the analyzed variable, and appropriately distinguishes between the bar charts that show conditional relationships between variables."
    },
}


const reactivo6_3 = {
    //? caso 1
    reactivo_6_3_caso_1_menor_1500_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_3_caso_1_menor_1500_nivel: {
        es: "El estudiante tomó la decisión correcta porque fue capaz de distinguir en el contexto del problema a las variables que el gerente le pedía analizar para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros",
        en: "The student made the correct decision because he was able to distinguish the variables that the manager asked him to analyze in the context of the problem to choose the candidate customers for the new credit card that the bank intends to incorporate into its financial product portfolio."
    },
    reactivo_6_3_caso_1_5_transaccion_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no está interesado en la cantidad de transacciones al día que realizan los clientes.",
        en: "Although the interpretation of this graph is correct, the Manager is not interested in the number of transactions per day that customers make."
    },
    reactivo_6_3_caso_1_5_transaccion_nivel: {
        es: "El estudiante no tomó la decisión correcta porque no fue capaz de distinguir en el contexto del problema a las variables que el gerente le pedía analizar para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros.",
        en: "The student did not make the correct decision because he was unable to distinguish the variables that the manager asked him to analyze in the context of the problem to choose the candidate customers for the new credit card that the bank intends to incorporate into its financial product portfolio."
    },
    reactivo_6_3_caso_1_productos_financieros_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no está interesado en la cantidad de productos financieros que utilizan los clientes",
        en: "Although the interpretation of this graph is correct, the Manager is not interested in the number of financial products used by customers."
    },
    reactivo_6_3_caso_1_productos_financieros_nivel: {
        es: "El estudiante no tomó la decisión correcta porque no fue capaz de distinguir en el contexto del problema a las variables que el gerente le pedía analizar para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros",
        en: "The student did not make the correct decision because he was unable to distinguish the variables that the manager asked him to analyze in the context of the problem to choose the candidate customers for the new credit card that the bank intends to incorporate into its financial product portfolio."
    },
    //? caso 2
    reactivo_6_3_caso_2_menor_1500_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no está interesado en el monto de las transacciones al día que realizan los clientes.",
        en: "Although the interpretation of this graph is correct, the Manager is not interested in the amount of daily transactions made by customers."
    },
    reactivo_6_3_caso_2_menor_1500_nivel: {
        es: "El estudiante no tomó la decisión correcta porque no fue capaz de distinguir en el contexto del problema a las variables que el gerente le pedía analizar para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros",
        en: "The student did not make the correct decision because he was unable to distinguish the variables that the manager asked him to analyze in the context of the problem to choose the candidate customers for the new credit card that the bank intends to incorporate into its financial product portfolio."
    },
    reactivo_6_3_caso_2_5_transaccion_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_3_caso_2_5_transaccion_nivel: {
        es: "El estudiante tomó la decisión correcta porque fue capaz de distinguir en el contexto del problema a las variables que el gerente le pedía analizar para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros",
        en: "The student made the correct decision because he was able to distinguish the variables that the manager asked him to analyze in the context of the problem to choose the candidate customers for the new credit card that the bank intends to incorporate into its financial product portfolio."
    },
    reactivo_6_3_caso_2_productos_financieros_retro: {
        es: "Aunque la interpretación de esta gráfica es correcta, el Gerente no está interesado en la cantidad de productos financieros que utilizan los clientes.",
        en: "Although the interpretation of this graph is correct, the Manager is not interested in the number of financial products used by customers."
    },
    reactivo_6_3_caso_2_productos_financieros_nivel: {
        es: "El estudiante no tomó la decisión correcta porque no fue capaz de distinguir en el contexto del problema a las variables que el gerente le pedía analizar para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros",
        en: "The student did not make the correct decision because he was unable to distinguish the variables that the manager asked him to analyze in the context of the problem to choose the candidate customers for the new credit card that the bank intends to incorporate into its financial product portfolio."
    },
}

const reactivo6_4 = {
    //? caso 1
    reactivo_6_4_caso_1_correcto_1_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_4_caso_1_correcto_1_nivel: {
        es: "El estudiante tomó la decisión correcta al reconocer en el contexto del problema las relaciones correctas identificando las probabilidades condicional o marginal correcta que el gerente solicitó",
        en: "The student made the correct decision by identifying the correct relationships in the problem context, recognizing the correct conditional or marginal probabilities that the manager requested."
    },
    reactivo_6_4_caso_1_falla_1_retro: {
        es: "Este resultado indica la fracción de clientes que compran en mercados y supermercados del total de clientes regulares, lo que el Gerente necesita saber es la probabilidad de ser cliente regular dado que compra en mercados y supermercados",
        en: "This result indicates the fraction of customers who shop at markets and supermarkets out of the total regular customers, what the Manager needs to know is the probability of being a regular customer given that they shop at markets and supermarkets."
    },
    reactivo_6_4_caso_1_falla_1_nivel: {
        es: "El estudiante no tomó la decisión correcta debido a que no eligió la probabilidad condicionada o probabilidad marginal que el gerente solicitó.",
        en: "The student did not make the correct decision because he did not choose the conditional probability or marginal probability that the manager requested."
    },
    reactivo_6_4_caso_1_falla_2_retro: {
        es: "Este resultado indica la fracción de clientes regulares del total de clientes, es una probabilidad marginal, lo que el Gerente necesita saber es la probabilidad de ser cliente regular dado que compra en mercados y supermercados.",
        en: "This result indicates the fraction of regular customers out of the total customers, it is a marginal probability, what the Manager needs to know is the conditional probability of being a regular customer given that they shop at markets and supermarkets."
    },
    reactivo_6_4_caso_1_falla_2_nivel: {
        es: "El estudiante no tomó la decisión correcta debido a que no eligió la probabilidad condicionada o probabilidad marginal que el gerente solicitó.",
        en: "The student did not make the correct decision because he did not choose the conditional probability or marginal probability that the manager requested."
    },
    //? caso 2
    reactivo_6_4_caso_2_falla_1_retro: {
        es: "Este resultado indica la fracción de clientes regulares del total de clientes que compran en mercados y supermercados, es una probabilidad condicional, lo que el Gerente necesita saber es la probabilidad marginal de ser cliente regular.",
        en: "This result indicates the fraction of regular customers out of the total customers who shop at markets and supermarkets, it is a conditional probability, what the Manager needs to know is the marginal probability of being a regular customer."
    },
    reactivo_6_4_caso_2_falla_1_nivel: {
        es: "El estudiante no tomó la decisión correcta debido a que no eligió la probabilidad condicionada o probabilidad marginal que el gerente solicitó.",
        en: "The student did not make the correct decision because he did not choose the conditional probability or marginal probability that the manager requested."
    },
    reactivo_6_4_caso_2_falla_2_retro: {
        es: "Este resultado indica la fracción de clientes que compran en mercados y supermercados del total de clientes regulares, lo que el Gerente necesita saber es la probabilidad marginal de ser cliente regular.",
        en: "This result indicates the fraction of customers who shop at markets and supermarkets out of the total regular customers, what the Manager needs to know is the marginal probability of being a regular customer."
    },
    reactivo_6_4_caso_2_falla_2_nivel: {
        es: "El estudiante no tomó la decisión correcta debido a que no eligió la probabilidad condicionada o probabilidad marginal que el gerente solicitó.",
        en: "The student did not make the correct decision because he did not choose the conditional probability or marginal probability that the manager requested."
    },
    reactivo_6_4_caso_2_correcto_1_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_4_caso_2_correcto_1_nivel: {
        es: "El estudiante tomó la decisión correcta al reconocer en el contexto del problema las relaciones correctas identificando las probabilidades condicional o marginal correcta que el gerente solicitó",
        en: "The student made the correct decision by identifying the correct relationships in the problem context, recognizing the correct conditional or marginal probabilities that the manager requested."
    },
}

const reactivo6_5 = {
    //? caso 1
    reactivo_6_5_caso_1_correcto_1_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_5_caso_1_correcto_1_nivel: {
        es: "El estudiante tomo la decisión correcta, al entender y tener claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago.",
        en: "The student made the correct decision by understanding and being clear about the characteristics that clients must meet to be chosen based on their socio-demographic conditions and their ability to pay."
    },
    reactivo_6_5_caso_1_falla_1_retro: {
        es: "Aunque esta interpretación de la gráfica es correcta, los clientes que compran en otros establecimientos no son clientes que estén considerados en la promoción de la nueva tarjeta.",
        en: "Although this interpretation of the graph is correct, customers who shop at other establishments are not included in the promotion of the new card."
    },
    reactivo_6_5_caso_1_falla_1_nivel: {
        es: "El estudiante no tomo la decisión correcta dando evidencia de no entender claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago.",
        en: "The student did not make the correct decision, showing evidence of not clearly understanding the characteristics that clients must meet to be chosen based on their socio-demographic conditions and their ability to pay."
    },
    reactivo_6_5_caso_1_falla_2_retro: {
        es: "Esta interpretación de la gráfica es correcta; sin embargo, aunque los clientes morosos tienen un saldo promedio alto prácticamente en el límite requerido, una condición es que los clientes candidatos a la tarjeta sean regulares.",
        en: "This interpretation of the graph is correct; however, although delinquent customers have a high average balance, almost at the required limit, one condition is that card candidates must be regular customers."
    },
    reactivo_6_5_caso_1_falla_2_nivel: {
        es: "El estudiante no tomo la decisión correcta dando evidencia de no entender claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago.",
        en: "The student did not make the correct decision, showing evidence of not clearly understanding the characteristics that clients must meet to be chosen based on their socio-demographic conditions and their ability to pay."
    },
    //? caso 2
    reactivo_6_5_caso_2_falla_1_retro: {
        es: "Aunque esta interpretación de la gráfica es correcta, el gerente se refiere a los clientes del banco sin especificar que sean candidatos a la nueva tarjeta de crédito.",
        en: "Although this interpretation of the graph is correct, the manager refers to bank customers without specifying that they are candidates for the new credit card."
    },
    reactivo_6_5_caso_2_falla_1_nivel: {
        es: "El estudiante no tomo la decisión correcta dando evidencia de no entender claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago",
        en: "The student did not make the correct decision, showing evidence of not clearly understanding the characteristics that clients must meet to be chosen based on their socio-demographic conditions and their ability to pay."
    },
    reactivo_6_5_caso_2_correcto_1_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_6_5_caso_2_correcto_1_nivel: {
        es: "El estudiante tomo la decisión correcta, al entender y tener claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago",
        en: "The student made the correct decision by understanding and being clear about the characteristics that clients must meet to be chosen based on their socio-demographic conditions and their ability to pay."
    },
    reactivo_6_5_caso_2_falla_2_retro: {
        es: "Esta interpretación de la gráfica es correcta; sin embargo, aunque los clientes morosos tienen un saldo promedio alto prácticamente en el límite requerido, una condición es que los clientes candidatos a la tarjeta sean regulares",
        en: "This interpretation of the graph is correct; however, although delinquent customers have a high average balance, almost at the required limit, one condition is that card candidates must be regular customers."
    },
    reactivo_6_5_caso_2_falla_2_nivel: {
        es: "El estudiante no tomo la decisión correcta dando evidencia de no entender claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago",
        en: "The student did not make the correct decision, showing evidence of not clearly understanding the characteristics that clients must meet to be chosen based on their socio-demographic conditions and their ability to pay."
    },
}

const reactivo_7_1 = {
    reactivo_7_1_caso_1_ofrece_retro: {
        es: "Aunque Esther Lozano cumpliera con ser cliente regular y un saldo promedio mayor a $15,000, no cumple con ninguna de las características definidas para los candidatos a la nueva tarjeta de crédito. Por tanto, no es candidata para la nueva tarjeta de crédito",
        en: "Even if Esther Lozano meets the criteria of being a regular customer and having an average balance greater than $15,000, she does not meet any of the defined characteristics for candidates for the new credit card. Therefore, she is not eligible for the new credit card."
    },
    reactivo_7_1_caso_1_ofrece_nivel: {
        es: "El estudiante no fue capaz de tomar la decisión correcta, ofreció la nueva tarjeta de crédito a la cliente que no cumple con las condiciones definidas para los usuarios de la nueva tarjeta de crédito.",
        en: "The student was unable to make the correct decision; they offered the new credit card to the client who does not meet the defined conditions for users of the new credit card."
    },
    reactivo_7_1_caso_1_no_ofrece_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_7_1_caso_1_no_ofrece_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta al no ofrecer la tarjeta a la cliente ya que, aunque es un cliente regular y con capacidad de pago no cumple con ninguna de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito.",
        en: "The student made the correct decision by not offering the card to the client since, although she is a regular customer with the ability to pay, she does not meet any of the conditions defined by the bank for users of the new credit card."
    },
}

const reactivo_7_2 = {
    reactivo_7_2_caso_1_ofrece_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_7_2_caso_1_ofrece_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta al ofrecer la nueva tarjeta a la cliente ya que, es cliente regular y con capacidad de pago y cumple con al menos una de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito.",
        en: "The student made the correct decision by offering the new card to the client since she is a regular customer with the ability to pay and meets at least one of the conditions defined by the bank for users of the new credit card."
    },
    reactivo_7_2_caso_1_no_ofrece_retro: {
        es: "Esther Lozano cumple al menos una de las condiciones que la convierten en candidata a la nueva tarjeta de crédito porque sus compras son por menos de $1,500 pesos y también cumple con ser cliente regular y tener saldo mensual promedio igual o mayor a $15,000 pesos, sí debes ofrecerle la nueva tarjeta de crédito.",
        en: "Esther Lozano meets at least one of the conditions that make her a candidate for the new credit card because her purchases are less than $1,500 pesos, and she also meets the criteria of being a regular customer with a monthly average balance equal to or greater than $15,000 pesos. Therefore, you should offer her the new credit card."
    },
    reactivo_7_2_caso_1_no_ofrece_nivel: {
        es: "El estudiante no fue capaz de tomar la decisión correcta. No ofreció la nueva tarjeta de crédito a la cliente que sí cumple con las condiciones definidas para los usuarios de la nueva tarjeta de crédito.",
        en: "The student was unable to make the correct decision. They did not offer the new credit card to the client who does meet the conditions defined for users of the new credit card."
    },
}

const reactivo_7_3 = {
    reactivo_7_3_caso_1_ofrece_retro: {
        es: "Esther Lozano cumple con la característica de compras por menos de $1,500 pesos y aunque es cliente regular no cumple con tener un saldo promedio mayor a $15,000. Por tanto, no es candidata para la nueva tarjeta de crédito.",
        en: "Esther Lozano meets the condition of purchases less than $1,500 pesos, but although she is a regular customer, she does not meet the requirement of having an average balance greater than $15,000. Therefore, she is not eligible for the new credit card."
    },
    reactivo_7_3_caso_1_ofrece_nivel: {
        es: "El estudiante no fue capaz de tomar la decisión correcta, ofreció la nueva tarjeta de crédito a la cliente que no cumple con las condiciones definidas para los usuarios de la nueva tarjeta de crédito.",
        en: "The student was unable to make the correct decision; they offered the new credit card to the client who does not meet the conditions defined for users of the new credit card."
    },
    reactivo_7_3_caso_1_no_ofrece_retro: {
        es: "Respuesta correcta.",
        en: "Correct answer"
    },
    reactivo_7_3_caso_1_no_ofrece_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta al no ofrecer la tarjeta a la cliente ya que, aunque cumple con de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito no tiene la capacidad de pago.",
        en: "The student made the correct decision by not offering the card to the client since, although she meets one of the conditions defined by the bank for users of the new credit card, she does not have the ability to pay."
    },
}


const reactivo_8_1 = {
    reactivo_8_1_caso_1_ofrece_retro: {
        es: "Pedro Hernández tiene trabajo temporal y compra en mercados o supermercados, cumpliendo dos de las tres condiciones señaladas, además cumple con la condición de ser cliente regular y tener saldo promedio mayor a $15,000 pesos. Sí debes ofrecerle la nueva tarjeta de crédito.",
        en: "Pedro Hernández has temporary employment and shops at markets or supermarkets, meeting two of the three specified conditions. Additionally, he meets the condition of being a regular customer and having an average balance greater than $15,000 pesos. You should offer him the new credit card."
    },
    reactivo_8_1_caso_1_ofrece_nivel: {
        es: "El estudiante no fue capaz de tomar la decisión correcta, no ofreció la nueva tarjeta de crédito al cliente que cumple con dos de las tres condiciones definidas para los usuarios de la nueva tarjeta de crédito además de ser un cliente regular y con capacidad de pago.",
        en: "The student was unable to make the correct decision; they did not offer the new credit card to the client who meets two of the three conditions defined for users of the new credit card, in addition to being a regular customer and having the ability to pay."
    },
    reactivo_8_1_caso_1_no_ofrece_retro: {
        es: "Selección correcta.",
        en: "Correct answer"
    },
    reactivo_8_1_caso_1_no_ofrece_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta al ofrecer la tarjeta al cliente ya que cumple con dos de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito además de ser un cliente regular y con capacidad de pago.",
        en: "The student made the correct decision by offering the card to the client since they meet two of the conditions defined by the bank for users of the new credit card, in addition to being a regular customer and having the ability to pay."
    },
}

const reactivo_8_2 = {
    reactivo_8_2_caso_1_ofrece_retro: {
        es: "Selección correcta.",
        en: "Correct answer"
    },
    reactivo_8_2_caso_1_ofrece_nivel: {
        es: "El estudiante fue capaz de tomar la decisión correcta al no ofrecer la tarjeta al cliente ya que, aunque cumple con dos de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito, su capacidad de pago es menor a la esperada.",
        en: "The student made the correct decision by not offering the card to the client since, although they meet two of the conditions defined by the bank for users of the new credit card, their ability to pay is lower than expected."
    },
    reactivo_8_2_caso_1_no_ofrece_retro: {
        es: "Pedro Hernández tiene trabajo temporal y comprar en mercados o supermercados, por lo que cumple con al menos una de las condiciones señaladas, pero no cumple con el saldo promedio mayor a $15,000 pesos. Por lo que no es candidato a la nueva tarjeta de crédito.",
        en: "Pedro Hernández has temporary employment and shops at markets or supermarkets, so he meets at least one of the specified conditions, but he does not meet the requirement of having an average balance greater than $15,000 pesos. Therefore, he is not eligible for the new credit card."
    },
    reactivo_8_2_caso_1_no_ofrece_nivel: {
        es: "El estudiante no fue capaz de tomar la decisión correcta. Ofreció la nueva tarjeta de crédito al cliente que, aumque cumple con dos de las tres condiciones definidas para los usuarios de la nueva tarjeta de crédito, su capacidad de pago es menor a la esperada.",
        en: "The student was unable to make the correct decision. They offered the new credit card to the client who, although meets two of the three conditions defined for users of the new credit card, their ability to pay is lower than expected."
    },
}


export const reporteGeneral = checkAllKeysForTranslationRepetition({
    reactivo1,
    reactivo1_1,
    reactivo2,
    reactivo2_1,
    reactivo3,
    reactivo4,
    reactivo5,
    reactivo5_5,
    reactivo6_1,
    reactivo6_2,
    reactivo6_3,
    reactivo6_4,
    reactivo6_5,
    reactivo_7_1,
    reactivo_7_2,
    reactivo_7_3,
    reactivo_8_1,
    reactivo_8_2,
    tablaValoresEntrada,
    tableNamePdf
})