import { Card, Space } from 'antd';

const Detail = ({ information }) => {
    const { name, userId, crn, campus, time = 0, responses = [] } = information;
    const results = responses.reduce((prev, current) => {
        const { isCorrect, retroStatus } = current;
        if (isCorrect === 'Correcto') {
            return prev + 3;
        } else if (retroStatus === "Correcto") {
            return prev + 1;
        }
        return prev
    }, 0);
    const mins = Number((parseInt(time) / 60)).toFixed(2);

    return <div className="w-full h-full">
        <Space direction="vertical" className='w-full h-full'>
            <Card title="Detalles" >
                <div className='w-full flex justify-center flex-wrap '>
                    <div className='w-1/3 flex flex-col items-start justify-center  mx-auto mb-4'>
                        <p className='text-left mb-2'>Nombre de alumno: </p>
                        <p className='text-left'>{name}</p>
                    </div>
                    <div className='w-1/3 flex flex-col items-start justify-center  mx-auto mb-4'>
                        <p className='text-left mb-2'>Matrícula: </p>
                        <p className='text-left'>{userId}</p>
                    </div>
                    <div className='w-1/3 flex flex-col items-start justify-center  mx-auto mb-4'>
                        <p className='text-left mb-2'>CRN: </p>
                        <p className='text-left'>{crn}</p>
                    </div>

                    <div className='w-1/3 flex flex-col items-start justify-center  mx-auto mb-4'>
                        <p className='text-left mb-2'>Duración total de examen: </p>
                        <p className='text-left'>{mins} min</p>
                    </div>
                    <div className='w-1/3 flex flex-col items-start justify-center  mx-auto mb-4'>
                        <p className='text-left mb-2'>Resultados: </p>
                        <p className='text-left'>{results}</p>
                    </div>
                    <div className='w-1/3 flex flex-col items-start justify-center  mx-auto mb-4'>
                        <p className='text-left mb-2'>Campus: </p>
                        <p className='text-left'>{campus}</p>
                    </div>
                </div>
            </Card>

        </Space>
    </div>
}

export default Detail;