import { useNavigate } from "react-router-dom";
import { Button, LogoIntroduction, WhiteCircle } from "../components";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";
import { useEffect, useState } from "react";

const year = new Date().getFullYear()

const MainTraining = () => {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const typeName = localStorage.getItem("activeLanguage") || "no"
        if (typeName === "recarga") {
            localStorage.setItem("activeLanguage", "activo")
            window.location.reload(true);
        } else {
            setIsLoading(false)
        }
    }, [])

    if (isLoading) {
        return <></>
    }


    return (
        <div className="w-full h-screen flex flex-col pt-[72px] xl:pt-[120px] md:pb-[12px]">
            <LogoIntroduction />
            <div className="flex flex-col grow justify-center items-center ">
                <div>
                    <h2 className="main-title text-center text-[32px] xl:text-[36px] font-GraphikBold ">
                        {getJsonTextLanguageDisplay("demo_home_1")}
                    </h2>
                    <p className="label text-center  mt-[16px] text-[18px] font-GraphikRegular mb-16  ">
                        {getJsonTextLanguageDisplay("demo_home_2")}
                    </p>
                </div>
                <div className="w-full flex justify-center home-button">
                    <Button onClick={() => navigate(`/introduction/character`)}>{getJsonTextLanguageDisplay("demo_home_button")}</Button>
                </div>
            </div>
            <div className="mt-auto  self-center">
                <p className="footer-home text-center font-GraphikRegular text-[14px] ">
                    {getJsonTextLanguageDisplay("demo_home_3")} <br />
                    {getJsonTextLanguageDisplay("demo_home_4", { "[replace1]": year })}
                </p>
            </div>
            <WhiteCircle />
        </div>
    );
}

export default MainTraining;