import { memo } from "react";
import { useRef, useEffect } from "react";

const Dialogue = ({ message }) => {
	const messsageRef = useRef();
	useEffect(() => {
		messsageRef.current.innerText = "";
		messsageRef.current.insertAdjacentHTML("beforeend", message);
	});
	return (
		<div className="pt-9 pl-6 pr-16" style={{ zIndex: 10000 }}>
			<p ref={messsageRef} className="text-left text-dialogue text-[16px] xl:text-[18px] font-GraphikRegular"></p>
		</div>
	);
};

export default memo(Dialogue);
