import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const useAuthentication = () => {
	const [loading, setLoading] = useState(true);
	const { information } = useSelector((state) => state.user);
	useEffect(() => {
		if (information === null) window.location.href = window.location.origin + "/home";
		setLoading(false);
	}, [information]);
	return { isConnected: !loading };
};

export default useAuthentication;
