import { Icons } from "../../../assets";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";
import { WORKMATE_INDEX } from "../task/talk1";

const workMate = {
    name: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_2_name"),
    icon: Icons.WorkMate,
    index: WORKMATE_INDEX,
};

export const workmateOne = [
    {
        isMine: false,
        person: workMate,
        message: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_2_msg_1"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_2_msg_2"),
    },
    {
        isMine: false,
        person: workMate,
        message: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_2_msg_3"),
    },
];

export const workmateTwo = [

    {
        isMine: true,
        message: getJsonTextLanguageDisplay("demo_inicio_experencia_task_final_msg_1"),
    },
    {
        isMine: false,
        person: workMate,
        message: getJsonTextLanguageDisplay("demo_inicio_experencia_task_final_msg_2"),
    },
];