import { AvatarWomen, ProfileClient } from "../../assets";
import { useStoreUser } from "../../hooks/store";

const getAvatar = ({ isMine, character }, person) => {
    if (isMine) return character;
    if (!person) return ProfileClient;
    return person.icon;
};

const Avatar = ({ isMine, person }) => {
    const { character } = useStoreUser()
    const avatar = getAvatar({ isMine, character: character ? character.img : AvatarWomen }, person);

    return (
        <img
            className=" absolute  h-full left-[-56px] top-0 rounded-full avatar-icon"
            src={avatar}
            alt="Client of the bank or user"
            style={{ zIndex: 200 }}
        />
    );
};

export default Avatar;
