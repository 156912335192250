import { useComputerActions, useUserActions } from "../../../hooks";
import { useStoreUser } from "../../../hooks/store";
import { Back, FileAction } from "../../../assets";
import { memo } from "react";

const SubDocuments = ({ children }) => {
    const { selectFile } = useUserActions();
    const { selectComputerSection } = useComputerActions();
    const {
        questions: { meetingDocuments },
    } = useStoreUser();
    const isActiveTaks = !meetingDocuments.isCorrect && meetingDocuments.retro.times < 2;

    return (
        <div className="w-full h-full flex flex-col pt-[2px]">
            <div className="w-full h-10 flex justify-end pr-[14px] ">
                <img
                    src={Back}
                    alt="Arrow allows to go back screen"
                    className={`w-8 h-8 ${isActiveTaks ? "cursor-pointer" : "cursor-not-allowed"}  `}
                    onClick={() => {
                        if (!isActiveTaks) return;
                        selectComputerSection("Documentos");
                        selectFile(null);
                    }}
                />
            </div>
            <div className="w-full grow flex items-center justify-evenly ">{children}</div>
        </div>
    );
};

const Item = ({ answer, times, item, selectFile }) => {
    const { selected, isCorrect } = answer;
    const isActiveTaks = !isCorrect && times < 2;
    const isSelected = selected ? item.id === selected.id : false;

    const animations = !isSelected && isActiveTaks && "hover:bg-blue-table-histogram  icon-hover-effect";

    return (
        <div
            className={`  flex justify-center items-center relative  rounded-lg  ${isSelected ? "bg-task-color" : "bg-strong-blue"} ${animations} ${isActiveTaks ? "cursor-pointer" : "cursor-not-allowed"
                }`}
            style={{ width: "31%" }}
            onClick={() => {
                if (!isActiveTaks) return;
                selectFile(item);
            }}
        >
            <div className="flex flex-col items-center  w-full h-full lg:h-[262px]">
                <div className="  w-full flex justify-center items-end  h-[132px]  ">
                    <div className="flex items-center justify-center h-[112px]">
                        <img src={FileAction} alt="It shows a part of the computer" className={` h-full`} style={{ objectFit: "contain" }} />
                    </div>
                </div>
                <div className="flex justify-center items-center  grow">
                    <p className="icon-text text-center font-GraphikRegular text-[20px] lg:text-[24px] text-white px-6 ">{item.label}</p>
                </div>
            </div>
        </div>
    );
};

SubDocuments.Item = memo(Item);

export default SubDocuments;
