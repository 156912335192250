import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getFormattedAnswer } from "../../helpers/answerStructure";
import { adminStructureTable, studentStructureTable, introductionStructure } from "../../helpers/pdf";
import { generateStructurePdfAndExcel } from "./fileStructure";
pdfMake.vfs = pdfFonts.pdfMake.vfs;




const getStudentColum = (row = []) => {
    const newRow = []
    if (row[0].text === "Total") {
        row[0].text = `${row[0].text}: ${row[1].text}`
        newRow[0] = "";
        newRow[1] = ""
        newRow[2] = ""
        newRow[3] = ""
        newRow[4] = row[0]
        newRow[5] = row[2]
        newRow[6] = ""
        return newRow;
    }
    newRow[0] = row[0]
    newRow[1] = row[5]
    newRow[2] = row[1];
    newRow[3] = row[2];
    newRow[4] = row[3];
    newRow[5] = row[4];
    newRow[6] = row[6];
    return newRow
}
const getPrintColumn = (row = []) => {
    const newRow = []
    if (row[0].text === "Total") {
        row[0].text = `${row[0].text}: ${row[1].text}`
        newRow[0] = "";
        newRow[1] = ""
        newRow[2] = ""
        newRow[3] = ""
        newRow[4] = ""
        newRow[5] = row[0]
        newRow[6] = row[2]
        newRow[7] = ""
        return newRow;
    }
    newRow[0] = " ";
    newRow[1] = row[4]
    newRow[2] = row[0];
    newRow[3] = row[1];
    newRow[4] = row[2];
    newRow[5] = row[3];
    newRow[6] = row[5]
    newRow[7] = row[6]
    return newRow
}




const usePDFAnswers = () => {
    const getResponsesRow = (responses, sizeRow) => {
        const result = []

        responses.forEach((information) => {
            const informationEntries = Object.entries(information);
            const row = [];
            if (informationEntries.length === sizeRow) row.push({ text: ' ', style: 'values' });
            for (let index = 0; index < sizeRow; index++) {
                const element = informationEntries[index];
                if (index === 0 && !element) {
                    row.push({ text: ' ', style: 'values' });
                    continue;
                }
                if (!element) {
                    row.push({ text: ' ', style: 'values' });
                    continue;
                }
                const [, value] = element;
                row.push({ text: value, style: 'values', index });
            }
            if (informationEntries.length === 1) {
                result.push(row);
                return;
            }
            result.push(getPrintColumn(row));
        });
        return result
    }

    const getAdminFormat = (responses = [], score) => {
        const sizeRow = 8;
        return getResponsesRow(responses, sizeRow)
    }
    const formattedData = (responses = []) => {
        const result = []
        const sizeRow = 7

        responses.forEach((information) => {

            const informationEntries = Object.entries(information);
            const row = [];
            if (informationEntries.length === sizeRow) row.push({ text: ' ', style: 'values' });
            for (let index = 0; index < sizeRow; index++) {
                const element = informationEntries[index];
                if (index === 0 && !element) {
                    row.push({ text: ' ', style: 'values' });
                    continue;
                }
                if (!element) {
                    row.push({ text: ' ', style: 'values' });
                    continue;
                }
                const [, value] = element;
                row.push({ text: value, style: 'values', index });
            }
            if (informationEntries.length === 1) {
                result.push(row);
                return;
            }
            result.push(getStudentColum(row));
        });


        return result;
    }

    const printOne = async (response, isAdmin) => {
        const structureData = JSON.parse(JSON.stringify({ ...adminStructureTable }));
        response[0].data = { name: response[0].name, config: response[0].config };

        const { newResults, totalScore } = getFormattedAnswer(response)

        const structurePdf = generateStructurePdfAndExcel(newResults[1]?.code || "es", newResults)

        const [user] = structurePdf;
        const { name = "" } = user;
        const answers = structurePdf
        const final = getAdminFormat(answers, totalScore, isAdmin)
        structureData.content[0].table.body = structureData.content[0].table.body.concat(final)
        pdfMake.createPdf(structureData).download(`${name.split(' ').join("_")}_answers.pdf`)
    }

    const printManyAdmin = async (answers = []) => {
        return new Promise((resolve, reject) => {
            const structureData = JSON.parse(JSON.stringify({ ...adminStructureTable }));
            if (!answers)
                return reject(new Error("No answers provided"));
            const { newResults, totalScore } = getFormattedAnswer(answers);

            // const resultClear = newResults
            //     .filter((answer) => !answer.hasOwnProperty("code"))
            //     .map(({ identifierPdf, numberReactive, ...answer }) => {
            //         return {
            //             ...answer
            //         }
            //     })

            const resultClear = generateStructurePdfAndExcel(newResults[1]?.code || "es", newResults)

            const final = getAdminFormat(resultClear, totalScore)

            structureData.content[0].table.body = structureData.content[0].table.body.concat(final);
            const pdf = pdfMake.createPdf(structureData);
            pdf.getBase64((data) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = `data:application/pdf;base64,${data}`;
                downloadLink.download = "answers.pdf";
                downloadLink.click();
                resolve()
            });
        });

    }
    const printStudentResult = async (answers) => {
        return new Promise((resolve, reject) => {
            const structureData = JSON.parse(JSON.stringify({ ...studentStructureTable }));
            if (!answers) {
                reject(new Error("No answers provided"));
            } else {
                const { newResults } = getFormattedAnswer(answers);
                const final = formattedData(newResults);
                structureData.content[0].table.body = structureData.content[0].table.body.concat(final);
                const pdf = pdfMake.createPdf(structureData);
                pdf.getBase64((data) => {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = `data:application/pdf;base64,${data}`;
                    downloadLink.download = "answers.pdf";
                    downloadLink.click();
                    resolve();
                });
            }
        });
    }

    const createIntroduction = async () => {
        return new Promise((resolve, reject) => {
            const pdf = pdfMake.createPdf(introductionStructure);
            pdf.getBase64((data) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = `data:application/pdf;base64,${data}`;
                downloadLink.download = "fin_introduccion.pdf";
                downloadLink.click();
                resolve()
            });
        });

    }
    return { printOne, printManyAdmin, printStudentResult, createIntroduction };
}

export default usePDFAnswers;