import { IconBell } from "../assets";

const BellNotification = ({ isVisible }) => {
	if (!isVisible) return;
	return (
		<div className="w-[28px] h-[32px] lg:w-[33px] lg:h-[37px] relative pr-[10px]">
			<img src={IconBell} alt="Bell indicates if the message is checked" />
			<div className="w-[10px]  h-[10px] rounded-full absolute right-1  top-[-5px]" style={{ zIndex: 101, background: "red" }}></div>
		</div>
	);
};

export default BellNotification;
