import { Clock } from "three";

const clock = new Clock();

class Loop {
    constructor(scene, camera, renderer, updatables) {
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        this.updatables = updatables;
    }

    start() {
        this.renderer.setAnimationLoop(() => {
            this.animateActions();
            this.renderer.render(this.scene, this.camera);
        });
    }

    stop() {
        this.renderer.setAnimationLoop(null);
    }
    animateActions() {
        const delta = clock.getDelta();

        for (const object of this.updatables) {
            object.tick(delta);
        }
    }

    removeElement(objectName) {
        this.updatables = this.updatables.filter((object) => object.name !== objectName);
    }

    appendElement(object) {
        this.updatables.push(object);
    }
    getUpdatables() {
        return this.updatables;
    }
}

export default Loop;
