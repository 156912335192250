import { IconAdmin, } from "../../../assets";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

export const mail1 = {
    id: 1,
    person: {
        nameMail: getJsonTextLanguageDisplay("demo_inicio_experencia_recursos_humanos"),
        positionMail: getJsonTextLanguageDisplay("demo_inicio_experencia_banco_creciendos"),
        name: getJsonTextLanguageDisplay("demo_inicio_experencia_recursos_humanos"),
        position: "",
    },
    icon: IconAdmin,
    title: getJsonTextLanguageDisplay("demo_inicio_experencia_mail_title"),
    titleInside: getJsonTextLanguageDisplay("demo_inicio_experencia_mail_title_inside"),
    body: getJsonTextLanguageDisplay("demo_inicio_experencia_mail_body"),
    isChecked: false,
};

