import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    login,
    saveAnswer,
    saveRetro,
    selectCharacter,
    selectConcept,
    selectDocument,
    verifyConcept,
    saveDocument,
    selectAGoodClient,
    verifyGoodClient,
    changeSliceSelection,
    selectSlicesColletion,
    saveTime,
    cleanUser,
} from "../store/slices/user";

const useUserActions = () => {
    const dispatch = useDispatch();

    const verifyConceptdSelected = useCallback(() => {
        dispatch(verifyConcept());
    }, [dispatch]);

    const selectConceptId = useCallback(
        (id) => {
            dispatch(selectConcept({ id }));
        },
        [dispatch]
    );

    const saveUserAnswer = useCallback(
        ({ indexQuestion, value, correctAnswer, label, score }) => {
            const isCorrect = correctAnswer === value;
            dispatch(saveAnswer({ question: indexQuestion, answer: value, isCorrect, label, score }));
        },
        [dispatch]
    );

    const saveUserRetro = useCallback(
        (idTask, answer, answerLabel) => {
            dispatch(saveRetro({ taskId: idTask, answer, answerLabel }));
        },
        [dispatch]
    );

    const chooseCharacter = useCallback(
        (id) => {
            dispatch(selectCharacter(id));
        },
        [dispatch]
    );

    const setLogin = useCallback(
        (formData) => {
            dispatch(login(formData));
        },
        [dispatch]
    );

    const selectFile = useCallback(
        (document) => {
            dispatch(selectDocument(document));
        },
        [dispatch]
    );

    const saveFile = useCallback(() => {
        dispatch(saveDocument());
    }, [dispatch]);

    const selectClientWorkMate = useCallback(
        (indexClient, isRetro = false, label) => {
            dispatch(selectAGoodClient({ indexClient, isRetro, label }));
        },
        [dispatch]
    );

    const confirmWorkmateQuestion = useCallback((isCorrect, isRetro, label, answer) => {
        dispatch(verifyGoodClient({ isCorrect, isRetro, label, answer }));
    }, [dispatch]);

    const selectSliceMeeting = useCallback(
        (sliceId, indexSlice) => {
            dispatch(changeSliceSelection({ sliceId, indexSlice }));
        },
        [dispatch]
    );

    const changeCollectionMeeting = useCallback(() => {
        dispatch(selectSlicesColletion());
    }, [dispatch]);


    const saveTimeExperience = useCallback((time) => {
        dispatch(saveTime(time));
    }, [dispatch]);

    const finishUserData = useCallback(() => {
        dispatch(cleanUser());
    }, [dispatch])

    return {
        verifyConceptdSelected,
        selectConceptId,
        saveUserAnswer,
        saveUserRetro,
        chooseCharacter,
        setLogin,
        selectFile,
        saveFile,
        selectClientWorkMate,
        confirmWorkmateQuestion,
        selectSliceMeeting,
        changeCollectionMeeting,
        saveTimeExperience,
        finishUserData
    };
};

export default useUserActions;
