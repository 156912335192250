import { Close, CloseDisabled } from "../assets";

const CloseButton = ({ onClose, isVisible }) => {
    return (
        <div
            onClick={isVisible ? onClose : null}
            className={`absolute w-[24px] h-[24px] lg:w-[32px] lg:h-[32px]  top-[-28px] right-[-28px]  ${isVisible ? "cursor-pointer" : "cursor-not-allowed"} `}
            style={{ zIndex: 6000000 }}
        >
            <img src={isVisible ? Close : CloseDisabled} alt="Close computer view" />
        </div>
    );
};

export default CloseButton;
