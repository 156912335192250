import { configureStore } from "@reduxjs/toolkit";
import { User, Computer, General, Task, Introduction, BonusTime, Language } from "./slices";

export const store = configureStore({
    reducer: {
        user: User,
        computer: Computer,
        general: General,
        task: Task,
        introduction: Introduction,
        bonusTime: BonusTime,
        language: Language,
    },
});
