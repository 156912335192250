import Reactivo9_Grafica1 from './Reactivo9_Grafica1.png';
import Reactivo9_Grafica2 from './Reactivo9_Grafica2.png';
import Reactivo9_Grafica3 from './Reactivo9_Grafica3.png';
import Reactivo9_Grafica1En from './Reactivo9_Grafica1_En.png';
import Reactivo9_Grafica2En from './Reactivo9_Grafica2_En.png';
import Reactivo9_Grafica3En from './Reactivo9_Grafica3_En.png';

import Reactivo10_Grafica1 from './Reactivo10_Grafica1.png';
import Reactivo10_Grafica2 from './Reactivo10_Grafica2.png';
import Reactivo10_Grafica3 from './Reactivo10_Grafica3.png';
import Reactivo10_Grafica1En from './Reactivo10_Grafica1_En.png';
import Reactivo10_Grafica2En from './Reactivo10_Grafica2_En.png';
import Reactivo10_Grafica3En from './Reactivo10_Grafica3_En.png';

import Reactivo11_Grafica1 from './Reactivo11_Grafica1.png';
import Reactivo11_Grafica2 from './Reactivo11_Grafica2.png';
import Reactivo11_Grafica3 from './Reactivo11_Grafica3.png';
import Reactivo11_Grafica1En from './Reactivo11_Grafica1_En.png';
import Reactivo11_Grafica2En from './Reactivo11_Grafica2_En.png';
import Reactivo11_Grafica3En from './Reactivo11_Grafica3_En.png';

import Reactivo12_Grafica1 from './Reactivo12_Grafica1.png';
import Reactivo12_Grafica2 from './Reactivo12_Grafica2.png';
import Reactivo12_Grafica3 from './Reactivo12_Grafica3.png';
import Reactivo12_Grafica1En from './Reactivo12_Grafica1_En.png';
import Reactivo12_Grafica2En from './Reactivo12_Grafica2_En.png';
import Reactivo12_Grafica3En from './Reactivo12_Grafica3_En.png';

import Reactivo13_Grafica1 from './Reactivo13_Grafica1.png';
import Reactivo13_Grafica2 from './Reactivo13_Grafica2.png';
import Reactivo13_Grafica3 from './Reactivo13_Grafica3.png';
import Reactivo13_Grafica1En from './Reactivo13_Grafica1_En.png';
import Reactivo13_Grafica2En from './Reactivo13_Grafica2_En.png';
import Reactivo13_Grafica3En from './Reactivo13_Grafica3_En.png';

export const graphNew = {
    Reactivo9_Grafica1,
    Reactivo9_Grafica2,
    Reactivo9_Grafica3,
    Reactivo9_Grafica1En,
    Reactivo9_Grafica2En,
    Reactivo9_Grafica3En,
    Reactivo10_Grafica1,
    Reactivo10_Grafica2,
    Reactivo10_Grafica3,
    Reactivo10_Grafica1En,
    Reactivo10_Grafica2En,
    Reactivo10_Grafica3En,
    Reactivo11_Grafica1,
    Reactivo11_Grafica2,
    Reactivo11_Grafica3,
    Reactivo11_Grafica1En,
    Reactivo11_Grafica2En,
    Reactivo11_Grafica3En,
    Reactivo12_Grafica1,
    Reactivo12_Grafica2,
    Reactivo12_Grafica3,
    Reactivo12_Grafica1En,
    Reactivo12_Grafica2En,
    Reactivo12_Grafica3En,
    Reactivo13_Grafica1,
    Reactivo13_Grafica2,
    Reactivo13_Grafica3,
    Reactivo13_Grafica1En,
    Reactivo13_Grafica2En,
    Reactivo13_Grafica3En
}
