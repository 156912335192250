import {
    talk,
    talk2,
    talk3,
    talk5,
    talk6,
    talk7,
    optionalMessages1,
    optionalMessages1_2,
    optionalMessages1_3,
    optionalMessages1_4,
    optionalMessages4_1,
    optionalMessages4_2,
    talkThanksWorkMate,
    talkWithBoss,
    beginMeetingConversationDialogue,
    secondQuestionMeeting,
    finalConversationBoss,
    womanConversation,
    womanFinalConversation,
    womanWithOptionConversation,
    womanDealOptionalMessages,
    manConversation,
    manCreditConversation,
    manCreditOptionalConversation,
    manNewCreditConversation,
    thirdQuestionMeeting,
    fourthQuestionMeeting,
    fifthQuestionMeeting,
    leaveComputer,
} from "./talk1";
import {
    idTaskFirstQuestion,
    initStatusQuestions,
    selectSliceIds,
    talkWithBossTaskId,
    taskSliceMeeting1,
    taskSliceMeeting2,
    taskSliceMeeting3,
    taskSliceMeeting4,
    taskSliceMeeting5,
} from "../../../types";
import { meetingPresentationReact } from "./helper";
import { juanInformation } from "../../../data";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

const Task_1 = {
    id: 1,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_revisa_tu_computadora_na_escritorio_ambiente3D_boton_plegable_tarea_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};
const Task_1_1 = {
    id: 2,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_2_revisa_tu_correo_na_menu_interfaz2D_boton_plegable_tarea_1"),
    section: "Correo",
    action: "open_mails",
};
const Task_2 = {
    id: 3,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_conversacion_cliente_na_escritorio_ambiente3D_boton_plegable_tarea_1"),
    position: { x: 1, y: 1.5, z: 2.1 },
    name: "click-client",
    texture: "ClientCall",
    action: "click-user",
    isDoble: true,
};
const Task_2_1 = {
    id: 4,
    type: "talk",
    label: null,
    messages: talk,
    action: "finish",
    person: "client1",
};
const Task_2_2 = {
    id: 5,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_2_revisa_la_computadora_na_escritorio_ambiente3D_boton_plegable_tarea_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};
const Task_2_3 = {
    id: 6,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_3_ingresa_seccion_clientes_na_menu_boton_plegable_tarea_1"),
    section: "Clientes",
    action: "open_client",
};
const Task_2_4 = {
    id: idTaskFirstQuestion,
    type: "talk",
    label: getJsonTextLanguageDisplay("tarea_1_base_promocion_boton_plegable_tarea_1"),
    messages: talk2,
    wasAnswer: false,
    optionalMessages: {
        11: optionalMessages1,
        10: optionalMessages1_2,
        "00": optionalMessages1_3,
        "01": optionalMessages1_4,
    },
    hasRetro: false,
    action: "finish",
    addingEmail: true,
    addingTasks: true,
    person: "client1",
};
const Task_3_1 = {
    id: 11,
    type: "talk",
    label: null,
    messages: talk3,
    action: "finish",
    person: "client1",
};

const Task_3_2 = {
    id: 12,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_revisa_computadora_na_escritorio_ambiente3D_boton_plegable_tarea_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};
const Task_3_3 = {
    id: 13,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_2_ingresa_seccion_clientes_na_menu_interfaz2D_boton_plegable_tarea_1"),
    section: "Clientes",
    subSection: "Documentación",
    action: "open_mails",
};

const Task_4 = {
    id: 14,
    type: "talk",
    label: null,
    messages: talk5,
    action: "finish",
    person: "client1",
};
const Task_4_1 = {
    id: 15,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_realiza_analizar_historgrama_boton_plegable_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};

const Task_4_2 = {
    id: 16,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_2_ingresa_seccion_menu_boton_plegable_1"),
    section: "Clientes",
    subSection: "Histograma",
    action: "open_mails",
};

const Task_4_3 = {
    id: 17,
    type: "talk",
    label: initStatusQuestions.isClient ? getJsonTextLanguageDisplay("tarea_4_decidir_ofrecer_tarjeta_usuario_boton_plegable_2", { "[replace1]": juanInformation.score }) : getJsonTextLanguageDisplay("tarea_4_decidir_ofrecer_tarjeta_usuario_boton_plegable_1", { "[replace1]": juanInformation.age }),
    messages: talk6,
    wasAnswer: false,
    optionalMessages: {
        isClient: optionalMessages4_1,
        isNotClient: optionalMessages4_2,
    },
    hasRetro: false,
    action: "finish",
    person: "client1",
};
const Task_5 = {
    id: 18,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_revisa_computadora_agenda_escritorio_ambiente3D_boton_plegable_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};
const Task_5_1 = {
    id: 19,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_1_revisa_computadora_agenda_menu_interfaz2D_boton_plegable_tarea_1"),
    section: "Agenda",
    action: "ver_agenda",
};
const Task_5_2 = {
    id: 20,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_1_ingresar_seccion_documentos_menu_interfaz2D_boton_plegable_1"),
    section: "Documentos",
    action: "prev_meeting",
};

const Task_6 = {
    id: 21,
    type: "stand-up",
    label: getJsonTextLanguageDisplay("tarea_1_companero_ayuda_escritorio_boton_plegable_1"),
    texture: "ClientCall",
    name: "click-workmate",
    position: { x: 6.2, y: 1.4, z: 1.8 },
    isDoble: true,
};

const Task_6_1 = {
    id: 22,
    type: "talk",
    label: getJsonTextLanguageDisplay("tarea_2_revisa_computadora_companero_boton_plegable_1"),
    messages: talk7,
    action: "finish",
    person: "workmate",
};
const Task_6_2 = {
    id: 23,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_3_revisa_computadora_companero_boton_plegable_1"),
    position: { x: 5.2, y: 1.2, z: 1.9 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};

const Task_6_3 = {
    id: 24,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_boton_plegable_1"),
    section: "Clientes",
    action: "prev_meeting",
    person: "workmate",
    addingTasks: true,
};

const TaskThankingWorkMate = {
    id: 27,
    type: "talk",
    label: null,
    messages: talkThanksWorkMate,
    action: "finish",
    person: "workmate",
};
const TaskGoingtoBoss = {
    id: 28,
    type: "stand-up",
    label: getJsonTextLanguageDisplay("tarea_1_sala_juntas_escritorio_ambiente3D_boton_plegable_1"),
    texture: "ClientCall",
    name: "click-boss",
    position: { x: 6.6, y: 1.8, z: -3 },
    isDoble: true,
};

const TaskConversationBoss = {
    id: talkWithBossTaskId,
    type: "talk",
    label: null,
    messages: talkWithBoss,
    action: "finish",
    person: "boss",
};
const TaskOpenPresentation = {
    id: 30,
    type: "click",
    label: getJsonTextLanguageDisplay("tarea_1_abrir_presentacion_sala_juntas_boton_plegable_1"),
    position: { x: 5.2, y: 0.83, z: -2.8 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};

// inicio meeting
const TaskStartingMeeting = {
    id: 31,
    type: "talk",
    label: null,
    messages: beginMeetingConversationDialogue,
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    isQuestion: true,
    person: "boss",
};

const TaskSelectSlice = {
    id: selectSliceIds.slice1,
    type: "computer",
    label: meetingPresentationReact.copysTitleDialogBoss.slice1,
    section: "Clientes",
    meeting: true,
    slices: true,
};

const TaskRetroSlice = {
    id: taskSliceMeeting1,
    type: "talk",
    label: null,
    messages: [],
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    person: "boss",
};

const TaskStartingMeeting2 = {
    id: 34,
    type: "talk",
    label: null,
    messages: secondQuestionMeeting,
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    isQuestion: true,
    person: "boss",
};
const TaskSelectSlice2 = {
    id: selectSliceIds.slice2,
    type: "computer",
    label: meetingPresentationReact.copysTitleDialogBoss.slice2,
    section: "Clientes",
    meeting: true,
    slices: true,
};

const TaskRetroSlice2 = {
    id: taskSliceMeeting2,
    type: "talk",
    label: null,
    messages: [],
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    person: "boss",
};

const TaskStartingMeeting3 = {
    id: 37,
    type: "talk",
    label: null,
    messages: thirdQuestionMeeting,
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    isQuestion: true,
    person: "boss",
};
const TaskSelectSlice3 = {
    id: selectSliceIds.slice3,
    type: "computer",
    label: meetingPresentationReact.copysTitleDialogBoss.slice3,
    section: "Clientes",
    meeting: true,
    slices: true,
};
const TaskRetroSlice3 = {
    id: taskSliceMeeting3,
    type: "talk",
    label: null,
    messages: [],
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    person: "boss",
};
const TaskStartingMeeting4 = {
    id: 40,
    type: "talk",
    label: null,
    messages: fourthQuestionMeeting,
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    isQuestion: true,
    person: "boss",
};
const TaskSelectSlice4 = {
    id: selectSliceIds.slice4,
    type: "computer",
    label: meetingPresentationReact.copysTitleDialogBoss.slice4,
    section: "Clientes",
    meeting: true,
    slices: true,
};
const TaskRetroSlice4 = {
    id: taskSliceMeeting4,
    type: "talk",
    label: null,
    messages: [],
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    person: "boss",
};
const TaskStartingMeeting5 = {
    id: 43,
    type: "talk",
    label: null,
    messages: fifthQuestionMeeting,
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    isQuestion: true,
    person: "boss",
};
const TaskSelectSlice5 = {
    id: selectSliceIds.slice5,
    type: "computer",
    label: meetingPresentationReact.copysTitleDialogBoss.slice5,
    section: "Clientes",
    meeting: true,
    slices: true,
};
const TaskRetroSlice5 = {
    id: taskSliceMeeting5,
    type: "talk",
    label: null,
    messages: [leaveComputer],
    action: "finish",
    isAlsoComputer: true,
    meeting: true,
    person: "boss",
};

const TaskFinishMeetingConversation = {
    id: 46,
    type: "talk",
    label: null,
    messages: finalConversationBoss,
    action: "finish",
    person: "boss",
};

const TaskGoBackOffice = {
    id: 47,
    type: "going-zone",
    label: getJsonTextLanguageDisplay("sala_juntas_card_boton_plegable_1"),
    square: { gtX: 3.4, lsX: 7, gtZ: 2.7, lsZ: 6.1 },
};

const TaskCallCouple = {
    id: 48,
    type: "click",
    label: getJsonTextLanguageDisplay('tarea_llamar_pareja'),
    position: { x: 1, y: 1.5, z: 2.1 },
    name: "click-client",
    texture: "ClientCall",
    action: "click-user",
    isDoble: true,
};

const TaskWomanConversation = {
    id: 49,
    type: "talk",
    label: null,
    messages: womanConversation,
    action: "finish",
    person: "client3",
};

const TaskRegister = {
    id: 50,
    type: "computer",
    label: getJsonTextLanguageDisplay("tarea_computadora_clientes"),
    section: "Clientes",
    subSection: "Registro",
    glossaryType: "message",
    glossaryTitle: getJsonTextLanguageDisplay("label_requisito_promocion"),
    message: getJsonTextLanguageDisplay("label_descripcion_promocion")

};

const TaskFinalConversationWoman = {
    id: 51,
    type: "talk",
    label: null,
    messages: womanFinalConversation,
    action: "finish",
    person: "client3",
};

const TaskOfferingWoman = {
    id: 52,
    type: "talk",
    label: getJsonTextLanguageDisplay("task_decidir_mujer"),
    messages: womanWithOptionConversation,
    wasAnswer: false,
    optionalMessages: {
        answer: womanDealOptionalMessages,
    },
    hasRetro: false,
    person: "client3",
};

const TaskManConversation = {
    id: 53,
    type: "talk",
    label: null,
    messages: manConversation,
    action: "finish",
    person: "client2",
};
const TasksRegisterMan = {
    id: 54,
    type: "computer",
    label: getJsonTextLanguageDisplay("task_indicacion_computadora"),
    section: "Clientes",
    subSection: "Registro",
    glossaryType: "message",
    glossaryTitle: getJsonTextLanguageDisplay("task_promocion"),
    message: getJsonTextLanguageDisplay("task_promocion_oferta")
};
const TaskCreditMan = {
    id: 55,
    type: "talk",
    label: getJsonTextLanguageDisplay("task_promocion_decision"),
    messages: manCreditConversation,
    wasAnswer: false,
    optionalMessages: {
        answer: manCreditOptionalConversation,
    },
    hasRetro: false,
    person: "client2",
};
const TaskNewCreditMan = {
    id: 56,
    type: "talk",
    label: "Decide si ofrecer la nueva tarjeta de crédito que se habló en la junta.",
    messages: manNewCreditConversation,
    wasAnswer: false,
    optionalMessages: {
        answer: manCreditOptionalConversation,
    },
    hasRetro: false,
    person: "client2",
};

const TaskLeaveCouple = {
    id: 57,
    type: "breakfast",

}

const TaskBreakFast = {
    id: 58,
    type: "click",
    label: getJsonTextLanguageDisplay("task_aviso_salida"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};

const TaskFinal = {
    id: 59,
    type: "computer",
    label: getJsonTextLanguageDisplay("task_aviso_salida"),
    section: "Final",
};

const tasks = {
    Task_1,
    Task_1_1,
    Task_2,
    Task_2_1,
    Task_2_2,
    Task_2_3,
    Task_2_4,
    Task_3_1,
    Task_3_2,
    Task_3_3,
    Task_4,
    Task_4_1,
    Task_4_2,
    Task_4_3,
    Task_5,
    Task_5_1,
    Task_5_2,
    Task_6,
    Task_6_1,
    Task_6_2,
    Task_6_3,
    TaskThankingWorkMate,
    TaskGoingtoBoss,
    TaskConversationBoss,
    TaskOpenPresentation,
    TaskStartingMeeting,
    TaskSelectSlice,
    TaskRetroSlice,
    TaskStartingMeeting2,
    TaskSelectSlice2,
    TaskRetroSlice2,
    TaskStartingMeeting3,
    TaskSelectSlice3,
    TaskRetroSlice3,
    TaskStartingMeeting4,
    TaskSelectSlice4,
    TaskRetroSlice4,
    TaskStartingMeeting5,
    TaskSelectSlice5,
    TaskRetroSlice5,
    TaskFinishMeetingConversation,
    TaskGoBackOffice,
    TaskCallCouple,
    TaskWomanConversation,
    TaskRegister,
    TaskFinalConversationWoman,
    TaskOfferingWoman,
    TaskManConversation,
    TasksRegisterMan,
    TaskCreditMan,
    TaskNewCreditMan,
    TaskLeaveCouple,
    TaskBreakFast,
    TaskFinal,
};
export default tasks;
