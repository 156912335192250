import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setMovementStatus, selectCharacter } from "../store/slices/general";
import { selectCharacter as selectCharacterGeneral } from "../store/slices/user";
const useComputerActions = () => {
    const dispatch = useDispatch();

    const changeMovementStatus = useCallback(
        (status) => {
            dispatch(setMovementStatus(status));
        },
        [dispatch]
    );

    const chooseCharacter = useCallback((character) => {
        dispatch(selectCharacter(character))
        dispatch(selectCharacterGeneral(character))
    }, [dispatch])

    return { changeMovementStatus, chooseCharacter };
};

export default useComputerActions;
