import { useStoreComputer } from "../../hooks/store";
import { IconBell } from "../../assets";

const ComputerItem = ({ icon, text, action, isSubfolder = false, textNew = "" }) => {
    const { sectionRequired } = useStoreComputer();
    const isRequired = isSubfolder ? true : sectionRequired === text;

    return (
        <div
            className={` px-[10px] py-[24px] flex justify-center items-center relative  rounded-lg icon-hover-effect${!isRequired ? "-" : ""
                }  bg-strong-blue ${isRequired ? "opacity-100 cursor-pointer" : "opacity-60"}`}
            style={{ width: "23%" }}
            onClick={() => {
                if (!isRequired) return;
                action();
            }}
        >
            <div className="flex flex-col  w-[132px] h-[176px] lg:h-[202px]">
                <div className="  w-full  h-[132px] lg:h-[154px] mb-[24px]">
                    <div className="h-full  flex items-center justify-center">
                        <img src={icon} alt="It shows a part of the computer" className={` h-full`} style={{ objectFit: "contain" }} />
                    </div>
                </div>
                <div className="flex justify-center items-center grow">
                    <p className="icon-text text-center font-GraphikRegular text-[20px] lg:text-[24px] text-white ">{textNew} </p>
                </div>
                {isRequired && !isSubfolder && (
                    <div className="w-[28px] h-[32px] absolute top-3 left-3">
                        <img src={IconBell} alt="Showing you must check this section" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ComputerItem;
