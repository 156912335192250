import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, PrincipalLogo, WhiteCircle } from "../components";
import { logoInstitutefor } from "../assets";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";

const year = new Date().getFullYear()

const Home = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const typeName = localStorage.getItem("activeLanguage") || "no"
        if (typeName === "recarga") {
            localStorage.setItem("activeLanguage", "activo")
            window.location.reload(true);
        } else {
            setIsLoading(false)
        }
    }, [])

    if (isLoading) {
        return <></>
    }

    return (
        <Fragment>
            <div className="w-full h-screen flex flex-col pt-[72px] xl:pt-[120px] md:pb-[12px]">
                <div className="flex justify-center">
                    <div className="w-[232px]">
                        <PrincipalLogo />
                    </div>
                    <div className="w-[246px] lg:w-[246px] h-[46px] ml-[40px]">
                        <img src={logoInstitutefor} alt="Tec de monterrey logo" className="w-full object-cover" />
                    </div>
                </div>
                <div className="flex flex-col grow justify-center items-center ">
                    <div>
                        <h2 className="main-title text-center text-[32px] xl:text-[36px] font-GraphikBold ">
                            {getJsonTextLanguageDisplay("inicio_desktop_interfaz2D_recuadro_de_texto_1")}
                        </h2>
                        <p className="label text-center  mt-[16px] text-[18px] font-GraphikRegular mb-16  ">
                            {getJsonTextLanguageDisplay("inicio_desktop_interfaz2D_recuadro_de_texto_2")}
                        </p>
                    </div>
                    <div className="w-full flex justify-center home-button">
                        <Button onClick={() => navigate(`/home/register`)}>{getJsonTextLanguageDisplay("inicio_desktop_interfaz2D_boton")}</Button>
                    </div>
                </div>
                <div className="mt-auto self-center">
                    <p className="footer-home text-center font-GraphikRegular text-[14px] ">
                        {getJsonTextLanguageDisplay("inicio_desktop_interfaz2D_recuadro_de_texto_3")}<br />
                        {getJsonTextLanguageDisplay("inicio_desktop_interfaz2D_recuadro_de_texto_4", { "[replace1]": year })}
                    </p>
                </div>
            </div>
            <WhiteCircle />
        </Fragment>
    );
};

export default Home;
