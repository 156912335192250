import { useCallback, useEffect, useRef, useState } from "react";
import InstructionButtons from "../models/Task/InstructionButtons";
import { useGuide, useLabel } from "../hooks/introductionApp";
import IntroductionGuide from "./IntroductionGuide";
import { Introduction } from "../3D/introduction";
import { Loader, TaskLabel } from "../components";
import { useIntroduction } from "../hooks";
import { Screen } from "../models/introduction";
import { useStoreComputer } from "../hooks/store";
import Conversation from "../models/introduction/Conversation";
import { Curtain } from "../models/Container3D";

const IntroductionApp = () => {
    const [hasMovement, setHasMovement] = useState(false);
    const { state, changeTask } = useIntroduction();
    const [isLoading, setIsLoading] = useState(false);
    const [isCurtainVisible, setIsCurtainVisible] = useState(false);
    const { closeGuide, status, openGuide } = useGuide();
    const { selectedSection } = useStoreComputer();
    const { currentTask, selectedEmail, mails } = state;

    const { label } = useLabel({ currentTask, selectedSection, openedEmail: selectedEmail, mails })

    const appRef = useRef();
    const close = useCallback(() => {
        if (status === "init") {
            setIsLoading(true)
            const container = document.getElementById("introduction");
            const app = new Introduction(container, () => {
                if (currentTask.type === 'computer') return;
                setIsLoading(false)
            }, currentTask, changeTask);
            appRef.current = app;
            app.start()
        }
        if (appRef) appRef.current.start();
        closeGuide();

    }, [status, closeGuide, currentTask, changeTask]);

    const open = useCallback(() => {
        if (appRef.current)
            appRef.current.stop();
        openGuide();
    }, [openGuide]);

    useEffect(() => {
        if (!appRef.current) return;
        appRef.current.addSpriteByTask(currentTask);
    }, [currentTask])

    useEffect(() => {
        if (!appRef.current) return;
        if (currentTask.type !== 'click') {
            appRef.current.createClickSprite();
        }
        if (currentTask.type === 'walking')
            appRef.current.moveUser(currentTask, changeTask);
        setHasMovement(true);
        if (currentTask.type === "click") {
            appRef.current.createClickSprite(currentTask, () => {
                changeTask()
                appRef.current.createClickSprite()
            });
        }
        if (currentTask.type === 'going-zone') {
            appRef.current.setGoingZone(currentTask, () => {
                setIsCurtainVisible(true);
                setHasMovement(false);
                setTimeout(() => {
                    setIsCurtainVisible(false);
                    appRef.current.sitDownUser();
                    changeTask();

                }, 600);
            });
        }
    }, [currentTask, changeTask])

    return (
        <div id="introduction" className="w-full h-screen ">
            <Curtain isVisible={isCurtainVisible} />
            {
                appRef.current &&
                <TaskLabel label={label} isLoading={isLoading} />
            }
            <IntroductionGuide close={close} status={status} />
            <Loader isLoading={isLoading} />
            <InstructionButtons DoesHaveMovement={hasMovement} open={open} statusData={{ status, isLoading, currentTask }} />
            {
                currentTask.type === 'computer' &&
                <Screen isVisible={currentTask.type === 'computer'} currentTask={currentTask} />

            }
            {
                currentTask.type === 'talk' && <Conversation currentTask={currentTask} changeTask={changeTask} />
            }
        </div >
    );
}

export default IntroductionApp;