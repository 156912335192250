// import { useDevice } from "../hooks";
import data from "../../package.json";

const DeviceInformation = () => {
    // const { browserName, fullBrowserVersion, isMobile } = useDevice();

    return (
        <div className="fixed left-0 bottom-0 bg-task-color  overflow-clip text-right">
            <div>v{data.version}</div>
            {/* <div>{browserName}</div>
			<div>{fullBrowserVersion}</div>
			<div>{isMobile}</div> */}
        </div>
    );
};

export default DeviceInformation;
