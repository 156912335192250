import { getTalkTask4, meetingPresentationReact, menReact8, womanReact7 } from "./helper";
import { doesJuanHaveLowScore, isJuanAdult, juanInformation } from "../../../data";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";
import { initStatusQuestions } from "../../../types";
import { Icons } from "../../../assets";

export const WORKMATE_INDEX = 1;
export const BOSS_INDEX = 2;
const workMate = {
    name: getJsonTextLanguageDisplay("tarea_2_revisa_computadora_companero_card_text_titulo"),
    icon: Icons.WorkMate,
    index: WORKMATE_INDEX,
};
export const boss = {
    name: getJsonTextLanguageDisplay("tarea_1_sala_juntas_escritorio_gerente_card_titulo"),
    icon: Icons.Boss,
    index: BOSS_INDEX,
};
export const woman = {
    name: "Esther Lozano",
    icon: Icons.Woman,
    index: 3,
};
export const man = {
    name: "Pedro Hernández",
    icon: Icons.Man,
    index: 3,
};

export const talk = [
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_1_conversacion_cliente_na_juan_ambiente3D_card_texto_1"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_conversacion_cliente_na_usuario_ambiente3D_card_texto_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_1_conversacion_cliente_na_juan_ambiente3D_card_texto_2"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_conversacion_cliente_na_usuario_ambiente3D_card_texto_2"),
    },
];
export const talk2 = [
    {
        isMine: true,
        options: {
            11: [
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_1"), value: 1, score: 3 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_2"), value: 2, score: 1 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_3"), value: 3, score: 0 },
            ],
            10: [
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_1"), value: 1, score: 1 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_2"), value: 2, score: 3 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_3"), value: 3, score: 0 },
            ],
            "00": [
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_1"), value: 1, score: 0 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_2"), value: 2, score: 1 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_3"), value: 3, score: 3 },
            ],
            "01": [
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_1"), value: 1, score: 1 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_2"), value: 2, score: 0 },
                { label: getJsonTextLanguageDisplay("tarea_1_base_promocion_card_contenido_opc_3"), value: 3, score: 3 },
            ],
        },
        hasOptions: true,
        index: "question",
        hasMoreOptions: "type-case",
    },
];

const incorrectMessages = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_1_usuario_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_1_juan_1"),
    },
];
const incorrectMessages2 = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_3_usuario_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_3_juan_1"),
    },
];

const incorrectMessageMoney = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_2_usuario_1")
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_2_juan_1")
    }
]

export const optionalMessages1 = {
    correct: [
        {
            isMine: true,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_1_usuario_1"),
        },
        {
            isMine: false,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_1_juan_1"),
        },

    ],
    incorrect: {
        "2": incorrectMessageMoney,
        "3": incorrectMessages2
    },

};


export const optionalMessages1_2 = {
    correct: [
        {
            isMine: true,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_2_usuario_1")
        },

        {
            isMine: false,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_2_juan_1"),
        },
    ],
    incorrect: {
        "1": incorrectMessages,
        "3": incorrectMessages2
    },
};
export const optionalMessages1_3 = {
    correct: [
        {
            isMine: true,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_3_usuario_1"),
        },
        {
            isMine: false,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_3_juan_1"),
        }
    ],
    incorrect: {
        "1": incorrectMessages,
        "2": incorrectMessageMoney
    }
};
export const optionalMessages1_4 = {
    correct: [
        {
            isMine: true,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_3_usuario_1"),
        },
        {
            isMine: false,
            message: getJsonTextLanguageDisplay("tarea_1_base_promocion_opc_3_juan_1"),
        }
    ],
    incorrect: {
        "1": incorrectMessages,
        "2": incorrectMessageMoney
    },
};

const ahundredMoney = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_score_opc_1_usuario_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_score_opc_1_juan_1"),
    },
];

const fiveHundedMoney = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_score_opc_2_usuario_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_score_opc_2_juan_1"),
    },
]
export const optionalMessages4_1 = {
    correct: doesJuanHaveLowScore ? ahundredMoney : fiveHundedMoney,
    incorrect: doesJuanHaveLowScore ? fiveHundedMoney : ahundredMoney,
    retro: "Has elegido la opción incorrecta. <b>Este cliente supera el percentil 97.5</b>.",
};


const youngManOption = [

    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_opc_2_usuario_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_opc_2_juan_1"),
    },
]
const oldManOption = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_opc_1_usuario_1"),
    },
    {
        isMine: false,
        message: getJsonTextLanguageDisplay("tarea_4_card_contenido_opc_1_juan_1"),
    },
];

export const optionalMessages4_2 = {
    correct: isJuanAdult ? oldManOption : youngManOption,
    incorrect: isJuanAdult ? youngManOption : oldManOption,
    retro: `Has elegido la opción incorrecta. <b> Este cliente ${isJuanAdult ? "supera" : "no supera"} el percentil 16</b>.`,
};


export const talk3 = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_revisa_computadora_na_escritorio_ambiente3D_card_texto_contenido_1"),
    },
];

export const talk4 = [
    {
        isMine: true,
        message: "Listo. He verificado que su información haya sido llenada.",
    },
    {
        isMine: false,
        message: `Gracias. Disculpa, ¿qué tan probable es que me puedan autorizar la tarjeta de crédito si tengo ${juanInformation.age} años?`,
    },
];

export const talk5 = getTalkTask4(initStatusQuestions.isClient);
export const talk6 = [
    {
        isMine: true,
        options: {
            1: [
                { label: getJsonTextLanguageDisplay("tarea_4_decidir_ofrecer_score_usuario_card_texto_opc_1"), value: 1, score: 3 },
                { label: getJsonTextLanguageDisplay("tarea_4_decidir_ofrecer_score_usuario_card_texto_opc_2"), value: 0, score: 0 },
            ],
            0: [
                { label: getJsonTextLanguageDisplay("tarea_4_decidir_ofrecer_tarjeta_usuario_card_texto_opc_1"), value: 1, score: 0 },
                { label: getJsonTextLanguageDisplay("tarea_4_decidir_ofrecer_tarjeta_usuario_card_texto_opc_2"), value: 0, score: 3 },
            ],
        },
        hasOptions: true,
        index: "histograma",
        hasMoreOptions: "histogram",
    },
];
export const talk7 = [
    {
        isMine: false,
        person: workMate,
        message: getJsonTextLanguageDisplay("tarea_2_revisa_computadora_companero_card_text_contenido"),
    },
];

export const talk8 = [
    {
        isMine: false,
        person: workMate,
        message: getJsonTextLanguageDisplay("tarea_4_segunda_oportunidad_contenido_texto_1")
    },
];
export const talkThanksWorkMate = [
    {
        isMine: false,
        person: workMate,
        message: getJsonTextLanguageDisplay("companero_final_card_contenido"),
    },
];
export const talkWithBoss = [
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("tarea_1_sala_juntas_escritorio_gerente_card_contenido"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_1_sala_juntas_escritorio_usuario_card_contenido"),
    },
];
export const correctFilesBossDialogues = [
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("tarea_1_sala_juntas_escritorio_usuario_card_contenido_final"),
    },
];
export const inCorrectFilesBossDialogues = [
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("tarea_1_sala_juntas_escritorio_usuario_card_contenido_incorrecto"),
    },
];

export const beginMeetingConversationDialogue = [
    {
        isMine: false,
        person: boss,
        message: meetingPresentationReact.copysDialogBoss.slice1
        // message:
        //     `Respecto a la información personal de los clientes, ¿qué proporción de los clientes actuales podrían ser usuarios de la nueva tarjeta debido a que <b className="font-GraphikBold text-[16px] xl:text-[20px]">no tienen un trabajo renumerado?</b>`,
    },
];
export const secondQuestionMeeting = [
    {
        isMine: false,
        person: boss,
        message: meetingPresentationReact.copysDialogBoss.slice2
        // message:
        //     `Respecto a la información personal de los clientes, ¿cuál es <b className="font-GraphikBold text-[16px] xl:text-[20px]"> la proporción de clientes actuales </b> que realizan sus compras de productos básicos en <b className="font-GraphikBold text-[16px] xl:text-[20px]"> mercados y supermercados?</b>`
    },
];
export const thirdQuestionMeeting = [
    {
        isMine: false,
        person: boss,
        message: meetingPresentationReact.copysDialogBoss.slice3
        // message: `¿Cuál es  <b className="font-GraphikBold text-[16px] xl:text-[20px]">la proporción de clientes actuales</b> que realizan transacciones por  <b className="font-GraphikBold text-[16px] xl:text-[20px]">cantidades menores a $1,500 pesos al día?</b> `
    },
];
export const fourthQuestionMeeting = [
    {
        isMine: false,
        person: boss,
        message: meetingPresentationReact.copysDialogBoss.slice4
        // `Necesito evidencias de que <b className="font-GraphikBold text-[16px] xl:text-[20px]"> los clientes que son candidatos</b> para adquirir la nueva tarjeta tienen <b className="font-GraphikBold text-[16px] xl:text-[20px]">un historial crediticio favorable.</b> ¿Me puedes apoyar describiendo las siguiente graficas de la manera más acertada?`
    },
];
export const fifthQuestionMeeting = [
    {
        isMine: false,
        person: boss,
        message: meetingPresentationReact.copysDialogBoss.slice5
        // `Ahora me gustaría conocer <b className="font-GraphikBold text-[16px] xl:text-[20px]">la solvencia de los clientes que compran en el mercado y supermercado</b>, es un hecho que la capacidad de pago son criterios importantes para tomar esta decisión.`
    },
];

export const leaveComputer = [
    {
        isMine: false,
        person: boss,
        message:
            `Puedes dejar mi computadora.`
    },
]
export const finalConversationBoss = [
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("sala_juntas_card_contenido_1"),
    },
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("sala_juntas_card_contenido_2"),
    },
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("sala_juntas_card_contenido_3"),
    },
    {
        isMine: false,
        person: boss,
        message: getJsonTextLanguageDisplay("sala_juntas_card_contenido_4"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("sala_juntas_card_contenido_5"),
    },
];

export const womanConversation = [
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("tarea_saludar_pareja")
    },
    {
        isMine: false,
        person: woman,
        message: getJsonTextLanguageDisplay("conversacion_mujer_1")
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("conversacion_mujer_2"),
    },
];
export const womanFinalConversation = [
    {
        isMine: true,
        message: "Listo, su estado de cuenta ha sido enviado a su correo electrónico.",
    },
];

export const womanWithOptionConversation = [
    {
        isMine: true,
        options: [
            { label: getJsonTextLanguageDisplay("task_ofrecer_tarjeta_mujer"), value: true },
            { label: getJsonTextLanguageDisplay("task_no_ofrecer_tarjeta_mujer"), value: false },
        ],
        hasOptions: true,
        index: "womanQuestion",
    },
];


const newMessageWoman = {
    1: {
        correct: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_usuario"),
            },
            {
                isMine: false,
                message: getJsonTextLanguageDisplay("task_correcto_mujer"),
                person: woman,
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_despedida"),
            },
        ],
        incorrect: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_incorrecto_usuario"),
            },
            {
                isMine: false,
                message: getJsonTextLanguageDisplay("task_incorrecto_mujer"),
                person: woman,

            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_despedida"),
            },
        ],
    },
    2: {
        correct: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_incorrecto_usuario"),
            },
            {
                isMine: false,
                message: getJsonTextLanguageDisplay("task_incorrecto_mujer"),
                person: woman,

            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_despedida"),
            },
        ],
        incorrect: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_usuario"),
            },
            {
                isMine: false,
                message: getJsonTextLanguageDisplay("task_correcto_mujer"),
                person: woman,
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_despedida"),
            },
        ],
    },
    3: {
        correct: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_usuario"),
            },
            {
                isMine: false,
                message: getJsonTextLanguageDisplay("task_correcto_mujer"),
                person: woman,
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_despedida"),
            },
        ],
        incorrect: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_incorrecto_usuario"),
            },
            {
                isMine: false,
                message: getJsonTextLanguageDisplay("task_incorrecto_mujer"),
                person: woman,

            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_correcto_despedida"),
            },
        ],
    }
}


export const womanDealOptionalMessages = {
    correct: newMessageWoman[womanReact7.numberCase].correct,
    incorrect: newMessageWoman[womanReact7.numberCase].incorrect
};
export const manConversation = [

    {
        isMine: false,
        person: man,
        message: getJsonTextLanguageDisplay("conversacion_hombre_saludo"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("conversacion_usuario_saludo"),
    },
    {
        isMine: false,
        person: man,
        message: getJsonTextLanguageDisplay("conversacion_hombre_estatus"),
    },
    {
        isMine: true,
        message: getJsonTextLanguageDisplay("conversacion_usuario_despedida"),
    },
];
export const manCreditConversation = [
    {
        isMine: true,
        options: [
            { label: getJsonTextLanguageDisplay("task_promocion_opcion_1"), value: true },
            { label: getJsonTextLanguageDisplay("task_promocion_opcion_2"), value: false },
        ],
        hasOptions: true,
        index: "manCreditQuestion",
    },
];
export const manNewCreditConversation = [
    {
        isMine: true,
        options: [
            { label: "Ofrecer nueva tarjeta de crédito.", value: true },
            { label: "No ofrecer nueva tarjeta de crédito.", value: false },
        ],
        hasOptions: true,
        index: "manNewCreditQuestion",
    },
];

const newMessageMen = {
    1: {
        correct: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("tasK_promocion_tarjeta"),
            },
            {
                isMine: false,
                person: man,
                message: getJsonTextLanguageDisplay("task_promocion_tarjeta_agradecimiento"),
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("tasK_promocion_tarjeta_usuario"),
            },
        ],
        incorrect: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_promocion_aumento"),
            },
            {
                isMine: false,
                person: man,
                message: getJsonTextLanguageDisplay("task_promocion_aumento_agradecimiento"),
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("tasK_promocion_tarjeta_usuario"),
            },
        ],
    },
    2: {
        correct: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("task_promocion_aumento"),
            },
            {
                isMine: false,
                person: man,
                message: getJsonTextLanguageDisplay("task_promocion_aumento_agradecimiento"),
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("tasK_promocion_tarjeta_usuario"),
            },
        ],
        incorrect: [
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("tasK_promocion_tarjeta"),
            },
            {
                isMine: false,
                person: man,
                message: getJsonTextLanguageDisplay("task_promocion_tarjeta_agradecimiento"),
            },
            {
                isMine: true,
                message: getJsonTextLanguageDisplay("tasK_promocion_tarjeta_usuario"),
            },
        ],
    },
}

export const manCreditOptionalConversation = {
    correct: newMessageMen[menReact8.numberCase].correct,
    incorrect: newMessageMen[menReact8.numberCase].incorrect,
};
