export const initTaskBonusReactive = [
    {
        id: 7,
        finishId: [7],
        finishSecondId: 11,
        reactivo: "Reactivo 1",
        finishTime: "0:00",
        pointsGet: 3,
        haveSecond: true,
        response: false,
        isSpecial: false,
        isUsed: false
    },
    {
        id: 13,
        finishId: [14],
        finishSecondId: 14,
        reactivo: "Reactivo 2",
        finishTime: "0:00",
        pointsGet: 3,
        haveSecond: true,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 17,
        finishId: [17],
        finishSecondId: 18,
        reactivo: "Reactivo 3",
        finishTime: "0:00",
        pointsGet: 3,
        haveSecond: false,
        response: false,
        isSpecial: false,
        isUsed: false
    },
    {
        id: 20,
        finishId: [21],
        finishSecondId: 21,
        reactivo: "Reactivo 4",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: true,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 24,
        finishId: [25, 27],
        finishSecondId: 27,
        reactivo: "Reactivo 5",
        finishTime: "0:00",
        pointsGet: 3,
        haveSecond: true,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 32,
        finishId: [33],
        finishSecondId: 33,
        reactivo: "Reactivo 6.1",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: false,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 35,
        finishId: [36],
        finishSecondId: 36,
        reactivo: "Reactivo 6.2",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: false,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 38,
        finishId: [39],
        finishSecondId: 39,
        reactivo: "Reactivo 6.3",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: false,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 41,
        finishId: [42],
        finishSecondId: 42,
        reactivo: "Reactivo 6.4",
        finishTime: "0:00",
        pointsGet: 3,
        haveSecond: false,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 44,
        finishId: [45],
        finishSecondId: 45,
        reactivo: "Reactivo 6.5",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: false,
        response: false,
        isSpecial: true,
        isUsed: false
    },
    {
        id: 52,
        finishId: [52],
        finishSecondId: 53,
        reactivo: "Reactivo 7",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: false,
        response: false,
        isSpecial: false,
        isUsed: false
    },
    {
        id: 55,
        finishId: [55],
        finishSecondId: 56,
        reactivo: "Reactivo 8",
        finishTime: "0:00",
        pointsGet: 2,
        haveSecond: false,
        response: false,
        isSpecial: false,
        isUsed: false
    },
]


export const initTaskBonusReactiveSecondOportunity = [
    {
        id: 10,
        finishId: [11],
        reactivo: "Reactivo 1.1",
        finishTime: "0:00",
        nameQuestion: "question"
    },
    {
        id: 13,
        finishId: [14],
        reactivo: "Reactivo 2 Segunda",
        finishTime: "0:00",
        nameQuestion: ""
    },
    {
        id: 20,
        finishId: [21],
        reactivo: "Reactivo 4 segunda",
        finishTime: "0:00"
    },
    {
        id: 24,
        finishId: [25, 27],
        reactivo: "Reactivo 5 segunda",
        finishTime: "0:00"
    },
]