import { InfoModal, Modal } from "../../components";
import { initStatusQuestions } from "../../types";
import tasks from "../../store/slices/task/tasks";
import optionalTasks from "../../store/slices/task/optionalTasks";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";




const HelpText = ({ taskId, close }) => {

    return (
        <Modal>
            <div className=" w-11/12 flex-col">
                {
                    taskId === tasks.Task_2_3.id &&
                    <InfoModal close={close} title={getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_icon_ayuda_interfaz2D_modal_titulo_2")}  >
                        <div className="p-10 bg-white from-strong-retro   notification-body text-[16px] font-GraphikRegular  " dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_icon_ayuda_interfaz2D_modal_info") }}>
                        </div>
                    </InfoModal>
                }
                {
                    taskId === optionalTasks.Task_Optional_3.id && !initStatusQuestions.personRetro1.isWomanClient &&
                    <InfoModal close={close} title={getJsonTextLanguageDisplay("modal_modal_info_ayuda_titulo")}>
                        <p className="p-10 bg-white from-strong-retro   notification-body text-[16px] font-GraphikRegular  ">
                            {getJsonTextLanguageDisplay("modal_modal_info_ayuda_info", { "[replace1]": initStatusQuestions.personRetro1.name })}
                        </p>
                    </InfoModal>
                }
                {
                    taskId === optionalTasks.Task_Optional_3.id && initStatusQuestions.personRetro1.isWomanClient &&
                    <InfoModal close={close} title={getJsonTextLanguageDisplay("modal_modal_info_ayuda_titulo_mujer")}>
                        <p className="p-10 bg-white from-strong-retro   notification-body text-[16px] font-GraphikRegular  ">
                            {getJsonTextLanguageDisplay("modal_modal_info_ayuda_info_mujer", { "[replace1]": initStatusQuestions.personRetro1.name })}
                        </p>
                    </InfoModal>
                }
            </div>
        </Modal>

    )

}

export default HelpText;