import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Container3D, Register, SelectCharacter, Cms, Login, Administrator, Introduction, MainTraining, CharacterIntroduction, IntroductionApp, PageSelecionLanguage } from "./pages";
import { DeviceInformation } from "./models";

const Router = () => {
    return (
        <Fragment>
            <Routes>
                <Route path="/introduction" element={<Introduction />} >
                    <Route index element={<PageSelecionLanguage />} />
                    <Route path="/introduction/init" element={<MainTraining />} />
                    <Route path="/introduction/character" element={<CharacterIntroduction />} />
                    <Route path="/introduction/app" element={<IntroductionApp />} />
                </Route>
                <Route path="/" element={<PageSelecionLanguage />} />
                <Route path="/home/init" element={<Home />} />
                <Route path="/home/register" element={<Register />} />
                <Route path="/home/character" element={<SelectCharacter />} />
                <Route path="/app" element={<Container3D />} />
                <Route path="/administration" element={<Cms />} >
                    <Route index element={<Administrator />} />
                    <Route path="/administration/login" element={<Login />} />
                </Route>

                <Route path="*" element={<Home />} />
            </Routes>
            <DeviceInformation />
        </Fragment>
    );
};

export default Router;
