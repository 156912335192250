export const modulo4Seccion2SelecionarDocumentos = {
    //? tarea 1
    tarea_1_ingresar_seccion_documentos_menu_interfaz2D_boton_plegable_1: {
        es: "Ingresa a la sección de documentos.",
        en: "Enter the documents section.",
    },
    tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_seleciona_carpeta: {
        es: "Selecciona una carpeta:",
        en: "Select a folder:",
    },
    //? tarea 2
    tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_boton_plegable_1: {
        es: "Elige la carpeta del tema de la junta, recuerda que en la junta con el Gerente revisarán la propuesta de la nueva tarjeta de crédito del Banco Creciendo Juntos.",
        en: "Choose the folder for the meeting topic, remember that in the meeting with the Manager they will review the proposal for the new credit card from Banco Creciendo Juntos.",
    },
    tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_credito_hipotecario: {
        es: "Crédito hipotecario",
        en: "Mortgage Credit",
    },
    tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_credito_automotriz: {
        es: "Crédito automotriz",
        en: "Automotive Credit",
    },
    tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_tarjeta_credito: {
        es: "Tarjeta de crédito",
        en: "Credit Card",
    },
    tarea_2_elegir_carpeta_menu_carpeta_interfaz2D_card_seguro_vida: {
        es: "Seguro de vida",
        en: "Life Insurance",
    },
    //? tarea 3
    tarea_3_elegir_documentos_hipotecario_interfaz2D_boton_plegable_1: {
        es: "Elige los documentos que mostrarán las evidencias descriptivas de los clientes y guárdalas en la USB.",
        en: "Choose the documents that will provide descriptive evidence of the clients and save them to the USB.",
    },
    tarea_3_elegir_documentos_hipotecario_interfaz2D_card_1: {
        es: "Crédito hipotecario con letras de crédito.",
        en: "Mortgage credit with credit letters.",
    },
    tarea_3_elegir_documentos_hipotecario_interfaz2D_card_2: {
        es: "Mutuo hipotecario endosable.",
        en: "Endorsable mortgage loan.",
    },
    tarea_3_elegir_documentos_hipotecario_interfaz2D_card_3: {
        es: "Mutuo hipotecaria no endosable.",
        en: "Non-endorsable mortgage loan.",
    },
    tarea_3_elegir_documentos_hipotecario_interfaz2D_boton: {
        es: "Guardar en USB",
        en: "Save to USB",
    },

    tarea_3_elegir_documentos_automotriz_interfaz2D_card_1: {
        es: "Crédito convencional automotriz.",
        en: "Conventional automotive credit.",
    },
    tarea_3_elegir_documentos_automotriz_interfaz2D_card_2: {
        es: "Crédito inteligente.",
        en: "Smart credit.",
    },
    tarea_3_elegir_documentos_automotriz_interfaz2D_card_3: {
        es: "Crédito leasing o arrendamiento.",
        en: "Leasing or leasing credit.",
    },

    tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_1: {
        es: "Análisis gráfico y numérico que muestra las características de los clientes actuales",
        en: "Graphical and numerical analysis showing the characteristics of current clients.",
    },
    tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_2: {
        es: "Análisis distribucional de los estadísticos que provienen de la muestra.",
        en: "Distributional analysis of statistics from the sample.",
    },
    tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_3: {
        es: "Análisis inferencial que muestra las estimaciones de los parámetros de clientes futuros.",
        en: "Inferential analysis showing estimates of parameters for future clients.",
    },

    tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_1: {
        es: "Seguro de vida temporal.",
        en: "Term life insurance.",
    },
    tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_2: {
        es: "Seguro de ahorros para casos de supervivencia o jubilación.",
        en: "Savings insurance for survival or retirement.",
    },
    tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_3: {
        es: "Seguro de vida entera.",
        en: "Whole life insurance.",
    },

    //? tarea 4
    tarea_4_click_salir_menu_documento_interfaz2D_boton_plegable_1: {
        es: "Da click en “X” para salir.",
        en: "Click on “X” to exit."
    },
    tarea_4_segunda_oprotunidad_menu_documento_interfaz2D_boton_plegable_1: {
        es: "¡Espera! Vuelve a eligir la carpeta y/o los documentos del tema de la junta. Puedes apoyarte dando click en la flecha para regresar, si es necesario.",
        en: "Wait! Choose the folder and/or documents for the meeting topic again. You can go back by clicking the arrow if necessary."
    },
}