const MeetingGraph = ({ header, graph }) => {
	return (
		<div className="w-full h-full flex justify-center items-center rounded-2xl ">
			<div className="w-full h-full rounded-t-2xl flex flex-col ">
				<div className="w-full h-12 flex justify-center items-center bg-white rounded-t-2xl">
					<h3 className="font-GraphikBold text-[14px] xl:text-[16px] text-strong-blue">{header}</h3>
				</div>
				<div className="w-full grow h-full flex justify-center items-center rounded-b-2xl bg-strong-blue">
					<img src={graph} alt="Graph" className="h-3/4 w-11/12 object-contain" />
				</div>
			</div>
		</div>
	);
};

export default MeetingGraph;
