import { LogoEscuela } from "../assets";

const PrincipalLogo = () => {
	return (
		<div className="w-full h-[61px]  justify-center flex  ">
			<div className="w-[232px] lg:w-[232px] h-full ">
				<img src={LogoEscuela} alt="Tec de monterrey logo" className="w-full object-cover" />
			</div>
		</div>
	);
};

export default PrincipalLogo;
