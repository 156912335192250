import { useEffect, useRef } from "react";
import { InfoModal } from "../../components";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const SelectGlossary = (close, { title = getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_titulo"), type, message = '' }) => {
    const bodyRef = useRef();

    useEffect(() => {
        if (bodyRef.current) {
            bodyRef.current.innerText = "";
            bodyRef.current.insertAdjacentHTML("beforeend", message);
        }
    });
    const Body = type === 'message' ?
        <div className=" w-full h-full flex justify-center items-center lg:h-[220px]   px-8 border-b-strong-blue border-b-[1px] py-2">
            <p ref={bodyRef} className="font-GraphikMedium text-[16px] text-strong-blue ">
            </p>
        </div> : <>
            <div className=" px-8 border-b-strong-blue border-b-[1px] py-2">
                <div className="font-GraphikMedium text-[16px] text-strong-blue" dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_variable_cualitativa") }}>
                </div>
            </div>
            <div className=" px-8 border-b-strong-blue border-b-[1px] py-2">
                <div className="font-GraphikMedium text-[16px] text-strong-blue" dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_escala") }}>
                </div>
            </div>
            <div className=" px-8 border-b-strong-blue border-b-[1px] py-2">
                <div className="font-GraphikMedium text-[16px] text-strong-blue" dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_ordinal") }}>
                </div>
            </div>
            <div className=" px-8 border-b-strong-blue border-b-[1px] py-2">
                <div className="font-GraphikMedium text-[16px] text-strong-blue" dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_variable_cuantitativa") }}>
                </div>
            </div>
            <div className=" px-8 border-b-strong-blue border-b-[1px] py-2">
                <div className="font-GraphikMedium text-[16px] text-strong-blue" dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_intervalo") }}>
                </div>
            </div>
            <div className=" px-8 border-b-strong-blue border-b-[1px] py-2">
                <div className="font-GraphikMedium text-[16px] text-strong-blue" dangerouslySetInnerHTML={{ __html: getJsonTextLanguageDisplay("modal_glosario_interfaz2D_modal_razon") }}>
                </div>
            </div>
        </>

    return (
        <InfoModal Body={Body} close={close} title={title} children={Body} />
    )

}

export default SelectGlossary;