import { useCallback, useState } from "react";

const useGuide = () => {
    const [status, setStatus] = useState("init");

    const closeGuide = useCallback(() => {
        setStatus("running");
    }, [])

    const openGuide = useCallback(() => {
        setStatus("pause");
    }, [])

    return {
        openGuide, closeGuide, status
    }
}

export default useGuide;