/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { popTask } from "../store/slices/task";

const useChangeTask = () => {
	const dispatch = useDispatch();
	const changeTask = useCallback(() => {
		dispatch(popTask());
	}, []);
	return { changeTask };
};

export default useChangeTask;
