import { useCallback } from "react";
import { sendAnswers } from "../api";
import { juanInformation } from "../data";
import { tableConceptsQuestion } from '../data/concepts';
import { informationQuestionMeeting } from "../data/retro";
import { initStatusQuestions } from "../types";
import { useStoreBonusTime, useStoreUser } from "./store";
import { meetingPresentationReact, menReact8, womanReact7, workmateReact5 } from "../store/slices/task/helper";


const withOutRetro = "Sin retroalimentación"

const useSaveAnswer = () => {

    const { questions, time, answerId } = useStoreUser();
    const { taskBonusReactive } = useStoreBonusTime()



    const generateCSV = useCallback(() => {
        const entries = []
        const questionEntries = Object.entries(questions);
        questionEntries.forEach(([, value]) => {
            let { name, isCorrect, retro, answerLabel, score } = value;
            if (name === "Reactivo 6") {
                const { presentation = [] } = value;
                presentation.forEach((slice, index) => {
                    const { correctAnswer, sliceId, selectedSlice } = slice
                    const { isCorrect, id } = selectedSlice
                    const { title } = selectedSlice
                    const messageRetro = informationQuestionMeeting[`Reactivo 6.${index + 1}`][id];
                    entries.push({ questionName: `Reactivo 6.${index + 1}`, isCorrect, retroQuestionName: withOutRetro, retroStatus: false, wasRetroUsed: false, answerLabel: title, score: messageRetro[0], answer: selectedSlice, correctAnswer, sliceId });
                })
                return
            }
            if (name === "Reactivo 2") {
                const { answer } = retro;
                const wasUsed = answer === 0 ? true : answer === null || answer === undefined ? false : true;

                const answerData = value.answer || [];
                const { concepts, correctAnswer, id } = tableConceptsQuestion

                const title = answerData.map(concept => concepts[concept].field);
                const corrects = answerData.reduce((prev, current) => {
                    const isCorrect = correctAnswer.find(answer => answer === current);
                    return isCorrect === 0 ? prev + 1 : isCorrect ? prev + 1 : prev;
                }, 0);
                const retroCorrects = answer ? answer.reduce((prev, current) => {
                    const isCorrect = correctAnswer.find(answer => answer === current);
                    return isCorrect === 0 ? prev + 1 : isCorrect ? prev + 1 : prev;
                }, 0) : 0;
                const isComplete = corrects === 8
                const isRetroComplete = retroCorrects === 8
                entries.push(
                    {
                        questionName: name,
                        isCorrect: isComplete,
                        retroQuestionName: wasUsed ? name + " Segunda oportunidad" : withOutRetro,
                        retroStatus: wasUsed,
                        answerLabel: title,
                        score: isComplete ? 3 : corrects > 0 ? 1 : 0,
                        retroScore: isRetroComplete ? 1 : retroCorrects > 0 ? 0 : 0,
                        retroAnswerOptions: retroCorrects === 0 ? "Falla en todas" : isRetroComplete ? "Acierta en todas" : "Falla en por lo menos una",
                        table: id,
                        type: "concepts",
                        retroLabel: answer ? answer.map(concept => concepts[concept].field) : []
                    })
                return;
            }
            if (name === "Reactivo 4") {
                const { correctAnswer, file, retro } = value
                const isCorrect = correctAnswer === file.id;
                entries.push(
                    {
                        questionName: name,
                        isCorrect,
                        retroQuestionName: retro.file ? retro.name + " Segunda oportunidad" : withOutRetro,
                        retroStatus: retro.file ? true : false,
                        answerLabel: file.label,
                        retroLabel: retro.file ? retro.file.label : "",
                        score: isCorrect ? 2 : 0,
                        retroScore: retro.file ? correctAnswer === retro.file.id ? 1 : 0 : 0,
                        retroFile: retro.file,
                        file
                    })
                return;

            }
            if (name === "Reactivo 5") {
                const { retro } = value;
                const wasUsed = retro.answer === 0 ? true : retro.answer === null || retro.answer === undefined ? false : true;
                entries.push(
                    {
                        questionName: name,
                        isCorrect,
                        retroQuestionName: wasUsed ? name + " Segunda oportunidad" : withOutRetro,
                        retroStatus: wasUsed,
                        answerLabel,
                        retroLabel: retro.answerLabel,
                        score: isCorrect ? 3 : 0,
                        retroScore: retro.isCorrect ? 2 : 0,
                    })
                return;
            }
            if (name === "Reactivo 3") {
                entries.push({ questionName: name, isCorrect, retroQuestionName: withOutRetro, retroStatus: false, wasRetroUsed: false, answerLabel: answerLabel, score: isCorrect ? 3 : 0 });

                return;
            }

            if (!retro || retro === undefined) {
                entries.push({ questionName: name, isCorrect, retroQuestionName: withOutRetro, retroStatus: false, wasRetroUsed: false, answerLabel, score, });
                return
            }

            const { score: scoreRetro = 0 } = retro;
            const retroStatus = retro.answer === 0 ? true : retro.answer === null || retro.answer === undefined ? false : true;

            const retroName = retroStatus ? retro.name : withOutRetro;
            if (!retroStatus) {
                entries.push({ questionName: name, isCorrect, retroQuestionName: withOutRetro, retroStatus: false, answerLabel, score })
                return;
            }
            const correctRetroScore = scoreRetro === 1 ? 1 : 0
            entries.push({ questionName: name, isCorrect, retroQuestionName: retroName, retroStatus, answerLabel: answerLabel, score, retroScore: correctRetroScore, retroLabel: retro.answerLabel })
        })
        return entries;
    }, [questions])



    const updateAnswers = useCallback(async () => {
        const questionInformation = generateCSV();
        const { DoesParticipate, isClient, personRetro1, } = initStatusQuestions
        if (questionInformation && questionInformation.length > 0) {
            return await sendAnswers({
                time,
                answerId,
                answers: questionInformation,
                bonusTime: taskBonusReactive,
                config: {
                    hasParticipated: DoesParticipate,
                    isClient: isClient,
                    retroClient: personRetro1.name,
                    isRetroWoman: personRetro1.isWomanClient,
                    retroHasParticipated: personRetro1.hasParticipated,
                    retroIsClient: personRetro1.isClient,
                    tableId: initStatusQuestions.tableConceptsQuestion.id,
                    tableAnswers: tableConceptsQuestion.correctAnswer,
                    typeBorrow: initStatusQuestions.isClient ? "score" : "edad",
                    score: juanInformation.score,
                    age: juanInformation.age,
                    casesReactive7And8: {
                        woman: womanReact7,
                        men: menReact8
                    },
                    casesReactiveMeetingSlice: {
                        slice1: meetingPresentationReact.casesType.slice1,
                        slice2: meetingPresentationReact.casesType.slice2,
                        slice3: meetingPresentationReact.casesType.slice3,
                        slice4: meetingPresentationReact.casesType.slice4,
                        slice5: meetingPresentationReact.casesType.slice5,
                    },
                    casesReactive5WorkMate: {
                        workmate: workmateReact5
                    }
                }
            })
        }
    }, [answerId, generateCSV, time, taskBonusReactive])

    return { updateAnswers }
}

export default useSaveAnswer;