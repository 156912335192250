import { createSlice } from "@reduxjs/toolkit";
import { initMesssages, initExtraMails } from "./state";

const MAX_EMAILS_PERR_SCREEN = 3;

const initialState = {
    isActive: false,
    mail: initMesssages,
    extraMails: initExtraMails,
    selectedMail: null,
    selectedSection: null,
    sectionRequired: null,
    isSubFolder: false,
    isGlossaryActive: false,
};

export const computerSlice = createSlice({
    name: "computer",
    initialState,
    reducers: {
        selectMail: (state, action) => {
            const { payload } = action;
            const index = state.mail.findIndex((element) => element.id === payload);
            if (index === -1) {
                state.selectedMail = null;
                return;
            }
            const element = state.mail[index];
            element.isChecked = true;
            state.selectedMail = state.mail[index];
            if (state.sectionRequired === "Correo") {
                const isActiveTask = state.mail.reduce((prevValue, current) => {
                    if (!prevValue) return false;
                    if (!current.isChecked) return false;
                    return true;
                }, true);
                if (isActiveTask) state.sectionRequired = null;
            }
        },
        setRequiredSection(state, action) {
            const { payload } = action;
            state.sectionRequired = payload;
        },
        selectSection: (state, action) => {
            const { payload = null } = action;
            if (!payload) {
                state.sectionRequired = payload;
                state.isSubFolder = false;
                return;
            }
            const { sectionName, isSubFolder = false } = payload;
            state.selectedSection = sectionName;
            state.isSubFolder = isSubFolder;
        },
        setActiveComputer: (state, action) => {
            const {
                payload: { status },
            } = action;
            state.isActive = status;
            if (!status) {
                state.selectedMail = null;
                state.selectedSection = null;
                state.sectionRequired = null;
            }
        },
        addingEmailMessages: (state, action) => {
            const {
                payload: { idTask },
            } = action;
            let wasFounded = false;
            let index = 0;
            for (index = 0; index < state.extraMails.length; index++) {
                const element = state.extraMails[index];
                if (element.idTask === idTask) {
                    wasFounded = true;
                    break;
                }
            }
            if (!wasFounded) new Error("Must be extra messages here");
            const isTooLong = state.mail.length + state.extraMails[index].mails.length > MAX_EMAILS_PERR_SCREEN;
            if (isTooLong) {
                const numSavedMails = MAX_EMAILS_PERR_SCREEN - state.mail.length;
                if (numSavedMails < 0) new Error("Too many messages");
                let savedMails = [];
                for (let index = 0; index < numSavedMails; index++) {
                    savedMails.push(state.mail[index]);
                }
                state.mail = state.extraMails[index].mails.concat(...numSavedMails);
            } else {
                state.mail = state.extraMails[index].mails.concat(...state.mail);
            }

            if (index + 1 > state.extraMails.length) state.extraMails = [];
            else state.extraMails.slice(index + 1);
        },
        setGlossaryStatus: (state, action) => {
            const { payload } = action;
            state.isGlossaryActive = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { selectMail, selectSection, setActiveComputer, setRequiredSection, addingEmailMessages, setGlossaryStatus } = computerSlice.actions;

export default computerSlice.reducer;
