import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";
import ClienUserInfo from "../../models/Task/ClientUserInfo";
import { menReact8, womanReact7 } from "../../store/slices/task/helper";
import tasks from "../../store/slices/task/tasks";

const SelectUserTable = (taskId, { name, age, city, isClient }) => {
    if (taskId === tasks.TaskRegister.id) {
        return (
            <>
                <ClienUserInfo title={getJsonTextLanguageDisplay("label_woman_name")} value={name} />
                <ClienUserInfo title={getJsonTextLanguageDisplay("label_woman_credito")} value={getJsonTextLanguageDisplay("label_mujer_credito_valor")} />
                <ClienUserInfo title={getJsonTextLanguageDisplay("label_mujer_contrato")} value={getJsonTextLanguageDisplay("label_mujer_contrato_valor")} />
                {/* <ClienUserInfo title="Saldo promedio mensual" value={'$35,499.00 MXN'} /> */}
                <ClienUserInfo title={getJsonTextLanguageDisplay("label_mujer_saldo")} value={`$${womanReact7.averageMonthlyBalance}MXN`} />
            </>
        )
    } else if (taskId === tasks.TasksRegisterMan.id) {
        return (
            <>
                <ClienUserInfo title={getJsonTextLanguageDisplay("informacion_pedro_nombre")} value={"Pedro Hernández García"} />
                <ClienUserInfo title={getJsonTextLanguageDisplay("informacion_pedro_estatus")} value={getJsonTextLanguageDisplay("informacion_pedro_estatus_valor")} />
                <ClienUserInfo title={getJsonTextLanguageDisplay("informacion_pedro_contrato")} value={getJsonTextLanguageDisplay("informacion_pedro_contrato_valor")} />
                {/* <ClienUserInfo title="Saldo promedio mensual" value={'$18,124.00 MXN'} /> */}
                <ClienUserInfo title={getJsonTextLanguageDisplay("informacion_pedro_saldo")} value={`$${menReact8.averageMonthlyBalance}${getJsonTextLanguageDisplay("informacion_pedro_modena")}`} />
            </>
        )
    }
    return (
        <>
            <ClienUserInfo title={getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_1")} value={name} />
            <ClienUserInfo title={getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_3")} value={age} />
            <ClienUserInfo title={getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_5")} value={city} />
            <ClienUserInfo
                title={getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_7")}
                value={
                    isClient
                        ? getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_8_registrado")
                        : getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_8_no_registrado")
                }
            />
        </>
    );
}

export default SelectUserTable;