import useChangeTask from "../useChangeTask";
import { ArrowBlue } from "../../assets";

const useArrow = (messages, index) => {
	const { changeTask } = useChangeTask();
	if (!messages) return { nextFuntion: null };
	if (index === undefined || index === null) return { nextFuntion: null };
	if (!messages[index]) return { nextFuntion: null };
	const isOptionItem = messages[index].hasOptions;
	const fnTask = () => {
		changeTask();
	};
	const nextFuntion = (onClickHandler, hasNext) => {
		if (isOptionItem) return;
		return (
			<div
				onClick={hasNext ? onClickHandler : fnTask}
				className="absolute top-0 scale-[-1] right-0  w-[26px] flex items-center h-full mr-[15px]"
			>
				<button>
					<img src={ArrowBlue} alt="Change slide to next" style={{ transform: "rotate(180deg)" }} />
				</button>
			</div>
		);
	};
	return { nextFuntion };
};

export default useArrow;
