import { createSlice } from "@reduxjs/toolkit";
import { imgs } from "../../assets";

const initialState = {
    loading: true,
    isInstructionsActive: true,
    hasInstructionsBeenShown: false,
    DoesHaveMovement: false,
    character: null
};

export const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setInstructionsStatus: (state, { payload }) => {
            state.isInstructionsActive = payload;
            if (state.hasInstructionsBeenShown) return;
            if (!payload) state.hasInstructionsBeenShown = true;
        },
        setMovementStatus: (state, { payload: status }) => {
            state.DoesHaveMovement = status;
        },
        selectCharacter: (state, { payload }) => {
            let indexCharacter = payload < 0 || payload > 1 ? 0 : payload
            const img = indexCharacter === 0 ? imgs.H01 : imgs.M01;
            state.character = {
                index: indexCharacter,
                img
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { setLoading, setInstructionsStatus, setMovementStatus, selectCharacter } = generalSlice.actions;

export default generalSlice.reducer;
