/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import optionalTasks from "../../store/slices/task/optionalTasks";
import tasks from "../../store/slices/task/tasks";
import { initStatusQuestions } from "../../types";
import useTableInfo from "./useTableInfo";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const useTableClient = (isDoneAction, taskId) => {
    const isTableQuestion = [tasks.TaskRegister.id, tasks.TasksRegisterMan.id].findIndex(id => id === taskId) > -1
    const { getCase, getEmpyValue, getUser, userList, getTable, getUserBenefitPercent } = useTableInfo(isTableQuestion);
    return useMemo(() => {
        if (taskId === optionalTasks.Task_Optional_3.id) {
            const list = getTable(taskId, initStatusQuestions.personRetro1.isClient && initStatusQuestions.personRetro1.id !== 100);
            const getNewlist = list.filter((lit) => {
                const userIsFernando = initStatusQuestions.personRetro1.name === "Fernando Murillo Luján"
                if (userIsFernando && lit.name === "Fernando Murillo Luján") {
                    return false
                }
                return true
            })
            return getNewlist.map((user) => {
                const element = getUserBenefitPercent(parseInt(taskId), user, isDoneAction);
                const emptyElements = getEmpyValue(element, isDoneAction, taskId);
                return (
                    <div
                        key={user.name + user.city + user.isTarget}
                        className={`w-full flex justify-start items-start px-1 xl:px-[10px]`}
                        style={{ background: "#0577BE", margin: "1px 0" }}
                    >
                        <p className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white w-fit xl:mr-[40px]  grow">
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_9")} </b>
                            <span className="font-GraphikRegular">
                                {element.name}
                            </span>
                        </p>
                        <p
                            className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white xl:mr-[10px] "
                            style={{ width: "80px" }}
                        >
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_10")} </b>
                            <span className="font-GraphikRegular">
                                {emptyElements.age}
                            </span>
                        </p>
                        <p className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white " style={{ width: "20%" }}>
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_11")} </b>
                            <span className="font-GraphikRegular">
                                {emptyElements.city}
                            </span>
                        </p>
                        <p className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white " style={{ width: "20%" }}>
                            {getCase(taskId, element)}
                        </p>
                    </div>
                );
            });
        } else if (isTableQuestion) {
            return userList.map((user) => {
                return (
                    <div
                        key={user.name + user.city + user.isTarget}
                        className="w-full flex justify-evenly items-start px-1 xl:px-[10px]  grow"
                        style={{ background: "#0577BE", margin: "1px 0" }}
                    >
                        <p className="item-table-client text-[10px] lg:text-[11px] xl:text-[11px] font-GraphikRegular text-white xl:mr-[16px]  grow">
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("label_woman_name")}: </b>
                            <span className="font-GraphikRegular">
                                {user.name}
                            </span>
                        </p>
                        <p className="item-table-client text-[11px] lg:text-[11px] xl:text-[11px] font-GraphikRegular text-white mr-[8px] lg:mr-5  ">
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("label_valor_compra")}: </b>
                            <span className="font-GraphikRegular">
                                ${user.value} MXN
                            </span>
                        </p>
                        <p className="item-table-client text-[10px] lg:text-[11px] xl:text-[11px] font-GraphikRegular text-white w-1/3" >
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("label_lugar_compra")}: </b>
                            <span className="font-GraphikRegular">
                                {user.place}
                            </span>
                        </p>

                    </div>
                );
            })
        } else {
            const list = getTable(taskId, initStatusQuestions.isClient);
            return list.map((user) => {
                const element = getUser(parseInt(taskId), user, isDoneAction);
                const emptyElements = getEmpyValue(element, isDoneAction, taskId);
                const showHighlight = element.isTarget && isDoneAction;
                return (
                    <div
                        key={user.name + user.city + user.isTarget}
                        className={`w-full flex justify-start items-start px-1 xl:px-[10px] ${showHighlight && "bg-organge-task"}`}
                        style={{ background: "#0577BE", margin: "1px 0" }}
                    >
                        <p className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white w-fit xl:mr-[40px]  grow">
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_9")} </b>
                            <span className="font-GraphikRegular">
                                {element.name}
                            </span>
                        </p>
                        <p
                            className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white xl:mr-[10px] "
                            style={{ width: "80px" }}
                        >
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_10")} </b>
                            <span className="font-GraphikRegular">
                                {emptyElements.age}
                            </span>
                        </p>
                        <p className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white " style={{ width: "20%" }}>
                            <b className="font-GraphikMedium">{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_11")} </b>
                            <span className="font-GraphikRegular">
                                {emptyElements.city}
                            </span>
                        </p>
                        <p className="item-table-client text-[10px] md:text-[11px] xl:text-[14px] font-GraphikRegular text-white " style={{ width: "20%" }}>
                            {getCase(taskId, element)}
                        </p>
                    </div>
                );
            });
        }
    }, [isDoneAction, taskId]);
};

export default useTableClient;
