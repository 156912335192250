import { useEffect, useState } from "react";
import { imgs, LogoEscuela } from "../assets";
import { FormContainer } from "../models/cms";
import information from '../../package.json'

const Login = () => {
    const [, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)
    }, [])




    return (
        <div className="w-full h-full flex justify-center items-center">
            <div className="max-w-[1280px]  max-h-[720px] w-full h-full  ">
                <div className="w-full h-full flex shadow-login ">
                    <div className="w-1/2 h-full flex justify-center items-center relative">
                        <div className=" w-[456px] h-[450px] flex flex-col   ">
                            <img src={LogoEscuela} alt="Logo con el nombre de la universidad" className="h-[74px] object-cover mx-auto mb-8" />
                            <div className="grow ">
                                <p className="text-center text-base text-white w-[338px] mx-auto">Ingresa tu correo electrónico y contraseña para iniciar sesión.</p>
                                <div className="h-full pt-9  ">
                                    <FormContainer setLoading={setIsLoading} />
                                </div>
                            </div>

                        </div>
                        <p className="mt-auto absolute bottom-4 text-base text-version font-medium text-center">Versión {information.version}</p>

                    </div>
                    <div className="w-1/2 h-full ">
                        <img src={imgs.schoolBackground} alt="Edificio de un campus perteneciente al Tec de Monterrey" className="object-cover w-full h-full" />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login;