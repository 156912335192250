import { useMemo } from "react";
import DialogueItemWithOption from "./MessageItemWithOption";
import { useMessageOptions } from "../../hooks/conversation";
import { getTypeCase } from "../../types";

const ConversationWithOption = ({ item, correctAnswer, selectOption }) => {
    const { options = [] } = useMessageOptions(item, getTypeCase());
    const optionItems = useMemo(
        () =>
            options.map((option, index, arr) => (
                <DialogueItemWithOption
                    index={index}
                    key={option.label + option.value}
                    correctAnswer={correctAnswer}
                    value={option.value}
                    label={option.label}
                    indexQuestion={item.index}
                    hasLine={index < arr.length - 1}
                    score={option.score}
                    selectOption={selectOption}
                />
            )),
        [correctAnswer, item.index, options, selectOption]
    );
    return <div className="flex flex-col items-start flex-1  h-full">{optionItems}</div>;
};

export default ConversationWithOption;
