import styled from "styled-components";


const ButtonCms = styled.button`
    width: 104px;
    height: 32px;
    cursor: pointer;
    background-color: ${props => props.isPrimary ? "white" : "#F28E14"};
    color: ${props => props.isPrimary ? "#F28E14" : "white"};
    border: 1px solid ${props => props.isPrimary ? "#F28E14" : "white"};
    border-radius: 8px;
    transition: 400ms ease-out all;
    :active {
        transform: scale(1.1);
        background-color: #DA8115;
    }
    :hover {
        background-color: #D17300;
    color: ${props => props.isPrimary ? "white" : "white"};

    }
`

export default ButtonCms