import * as Yup from "yup";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";

export const validationLogin = Yup.object().shape({
    id: Yup.string().required(getJsonTextLanguageDisplay("registro_desktop_interfaz2D_mensaje_aviso")),
    name: Yup.string().required(getJsonTextLanguageDisplay("registro_desktop_interfaz2D_mensaje_aviso")),
    crn: Yup.string().required(getJsonTextLanguageDisplay("registro_desktop_interfaz2D_mensaje_aviso")),
    campus: Yup.string().required(getJsonTextLanguageDisplay("registro_desktop_interfaz2D_mensaje_aviso")),
});



export const validateLoginDate = Yup.object().shape({
    email: Yup.string().required("Ingresa tu correo electrónico").email("Correo electrónico no valido"),
    password: Yup.string().required("Ingresa tu contraseña").min(8, "Mínimo 8 caracteres")
})

