export const studentHeaders = [
    { label: "Matrícula del alumno", key: "name" },
    { label: "Reactivos", key: "question" },
    { label: "Valores de entrada (cliente)", key: "context" },
    { label: "Respuesta", key: "answerLabel" },
    { label: "Estatus ", key: "status" },
    { label: "Puntos ", key: "score" },
];


export const teacherHeaders = [
    { label: "Matrícula del alumno", key: "name" },
    { label: "Reactivos", key: "question" },
    { label: "Valores de entrada (cliente)", key: "context" },
    { label: "Respuesta", key: "answerLabel" },
    { label: "Estatus ", key: "status" },
    { label: "Puntos ", key: "score" },
    { label: "Retroalimentación para el alumno", key: "retro" },
    { label: "Nivel en la competencia", key: "training" },
];
