import TransactionPerDay from "./TransactionPerDay.png";
import SuperMarketCake from "./SuperMarketCake.png";
import GenderClients from "./GenderClients.png";
import GenderSlice2 from "./GenderSlice2.png";
import WorkClients from "./WorkClients.png";
import Transaction from "./Transaction.png";
import ClientCake from "./ClientsCake.png";
import A4SuperMarket from "./4a-Supermarket.png";
import B4SuperMarket from "./4b-regularSupermarket.png";
import C4SuperMarket from "./4c-Credit.png";
import A5RegularClients from "./5a-RegularClients.png";
import B5RegularClients from "./5b-RegularClients.png";
import C5Morosos from "./5c-Morosos.png";
import Products from "./Products.png";
import State from "./State.png";
import { graphNew } from "./nuevas";

const Graphs = {
    ClientCake,
    GenderClients,
    WorkClients,
    SuperMarketCake,
    GenderSlice2,
    State,
    TransactionPerDay,
    Transaction,
    Products,
    A4SuperMarket,
    B4SuperMarket,
    C4SuperMarket,
    A5RegularClients,
    B5RegularClients,
    C5Morosos,
    ...graphNew
};

export default Graphs;
