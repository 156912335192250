import { initStatusQuestions, slicesTaskIds, talkWithBossTaskId } from "../../types";
import { useChangeTask, useComputerActions, useUserActions } from "../../hooks";
import { useStoreComputer, useStoreUser } from "../../hooks/store";
import ClientButtonSection from "./ClientButtonSection";
import { useTableContext } from "../../context/table";
import { useGetSection } from "../../hooks/computer";
import { boss } from "../../store/slices/task/talk1";
import { useTaskActions } from "../../hooks/";
import { Button } from "../../components";
import BackButton from "./BackButton";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const FooterButtons = ({ selectedSection, idTask = 6 }) => {
    const { changeTask } = useChangeTask();
    const { sectionRequired } = useStoreComputer();
    const { doAction, isDoneAction } = useTableContext();
    const { selectComputerSection } = useComputerActions();
    const [section, subSection] = useGetSection(selectedSection);
    const { appendOptionalTasks, appendDialoguesBoss, appendDialoguesMeeting } = useTaskActions();
    const { verifyConceptdSelected, saveUserRetro, saveFile, confirmWorkmateQuestion } = useUserActions();
    const {
        questions: { questionConcept, meetingDocuments, workmateQuestion, sliceQuestion },
    } = useStoreUser();
    const { retro } = questionConcept;
    const hasFinished = questionConcept.isCorrect || retro.times > 1;

    if (!selectedSection && idTask < 24) return <></>;
    const isFirstTaks = idTask === 6 && selectedSection !== "Correo";
    const isFirtRetroTaks = idTask === 10 && selectedSection === "Registro";
    return (
        <>
            {selectedSection === "Correo" && (
                <div className=" h-[48px] w-[208px] py-[5px]">
                    <BackButton className="h-full w-full rounded-full text-[20px]" />
                </div>
            )}
            {isFirstTaks && (
                <ClientButtonSection
                    actionIsDone={isDoneAction}
                    leftButton={{
                        label: initStatusQuestions.isClient
                            ? getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_boton_registrar")
                            : getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_boton_actualizar"),
                        action: () => { }
                    }}
                    rigthButton={{
                        label: initStatusQuestions.isClient
                            ? getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_boton_actualizar")
                            : getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_boton_registrar")
                        , action: doAction
                    }}
                />
            )}
            {isFirtRetroTaks && (
                <div className="flex">
                    <Button
                        isOption={true}
                        disabled={!isDoneAction && sectionRequired === null}
                        isDisabled={!isDoneAction && sectionRequired === null}
                        onClick={() => {
                            const message = initStatusQuestions.personRetro1.isWomanClient ? " la promoción de la feria de mujeres" : "la tasa preferencial"
                            saveUserRetro(idTask, false, `No autorizó ${message}`)
                        }}
                        className="w-[208px] h-[48px] rounded-full border-4 "
                    >
                        {getJsonTextLanguageDisplay("tarea_7_ver_info_botones_1")}
                    </Button>
                    <Button
                        disabled={!isDoneAction && sectionRequired === null}
                        isDisabled={!isDoneAction && sectionRequired === null}
                        onClick={() => {
                            const message = initStatusQuestions.personRetro1.isWomanClient ? " la promoción de la feria de mujeres" : "la tasa preferencial"
                            saveUserRetro(idTask, true, `Autorizó ${message}`)
                        }}
                        className="w-[208px] h-[48px] rounded-full ml-[24px]"
                    >
                        {getJsonTextLanguageDisplay("tarea_7_ver_info_botones_2")}
                    </Button>
                </div>
            )}

            {idTask === 13 && (
                <Button
                    onClick={verifyConceptdSelected}
                    disabled={questionConcept.selectedConcept.length !== 8 || hasFinished}
                    isDisabled={questionConcept.selectedConcept.length !== 8 || hasFinished}
                    className="h-[36px] w-[200px] rounded-full"
                >
                    {getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_boton_verificar")}
                </Button>
            )}
            {selectedSection === "Agenda" && idTask === 19 && (
                <div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            onClick={() => {
                                selectComputerSection(null);
                                changeTask();
                            }}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("tarea_2_recordatorio_reunion_agenda_interfaz2D_boton")}
                        </Button>
                    </div>
                </div>
            )}
            {section === "Documentos" && subSection && idTask === 20 && (
                <div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            isDisabled={!meetingDocuments.selected || meetingDocuments.isCorrect || meetingDocuments.retro.times > 1}
                            disabled={!meetingDocuments.selected || meetingDocuments.isCorrect || meetingDocuments.retro.times > 1}
                            onClick={() => {

                                const { label } = meetingDocuments.selected;
                                if (meetingDocuments.selected.id === meetingDocuments.correctAnswer) {
                                    appendDialoguesBoss(true, talkWithBossTaskId, label);
                                } else if (meetingDocuments.retro.times) {
                                    appendDialoguesBoss(false, talkWithBossTaskId, label);
                                }
                                saveFile();
                            }}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("tarea_3_elegir_documentos_hipotecario_interfaz2D_boton")}
                        </Button>
                    </div>
                </div>
            )}
            {idTask === 24 && (
                <div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            isDisabled={!workmateQuestion.answer}
                            disabled={!workmateQuestion.answer}
                            onClick={() => {
                                const { answer, correctAnswer } = workmateQuestion;
                                if (answer !== correctAnswer) {
                                    appendOptionalTasks();
                                }
                                confirmWorkmateQuestion(answer === correctAnswer, false, `Cliente ${answer}`, answer);
                                changeTask();
                            }}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("tarea_4_casos_1_boton_1")}
                        </Button>
                    </div>
                </div>
            )}
            {idTask === 26 && (
                <div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            isDisabled={!workmateQuestion.answer}
                            disabled={!workmateQuestion.answer}
                            onClick={() => {
                                const { answer, retro } = workmateQuestion;
                                confirmWorkmateQuestion(answer === retro.correctAnswer, true, `Cliente ${answer}`, answer);
                                changeTask();
                            }}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("tarea_4_segunda_oportunidad_boton")}
                        </Button>
                    </div>
                </div>
            )}
            {slicesTaskIds.includes(idTask) && (
                <div className=" h-[48px] w-[208px] py-[5px]">
                    <div className="w-full flex justify-end h-full">
                        <Button
                            onClick={() => {
                                const { presentation, currentIndex } = sliceQuestion;
                                const currentSliceCollection = presentation[currentIndex]; // sliceone
                                const { answer } = currentSliceCollection;
                                const currentSlice = currentSliceCollection.slices[answer - 1];
                                const dialogue = currentSlice.dialoguesBoss.map((text) => {
                                    return {
                                        isMine: false,
                                        person: boss,
                                        message: text,
                                    };
                                });
                                const argument = { dialogue, taskId: idTask + 1 };
                                appendDialoguesMeeting(argument);
                                changeTask();
                            }}
                            className="rounded-full w-[208px] h-full"
                        >
                            {getJsonTextLanguageDisplay("graficas_1_1_caso_1_boton_selecionar_grafica_1")}
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
};

export default FooterButtons;
