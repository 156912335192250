import { useDispatch } from "react-redux";
import { memo, useRef, useEffect } from "react";
import { addingOptionalMessages } from "../../store/slices/task";
import { useComputerActions, useUserActions } from "../../hooks";
import { useStoreTask } from "../../hooks/store";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const MessageItemWithOption = (props) => {
    const { correctAnswer, value, index, label, indexQuestion, hasLine, score, selectOption } = props;
    const dispatch = useDispatch();
    const optionRef = useRef(null);
    const { tasks } = useStoreTask();
    const { saveUserAnswer } = useUserActions();
    const { addingEmails } = useComputerActions();

    const handlerClick = () => {
        const isCorrect = correctAnswer === value;
        saveUserAnswer({ indexQuestion, value, correctAnswer, label, score });
        dispatch(addingOptionalMessages({ isCorrect, value }));
        const mustAddEmail = tasks[0] ? (tasks[0].addingEmail ? tasks[0].addingEmail : false) : false;
        if (!isCorrect && mustAddEmail) addingEmails(tasks[0].id);
        selectOption()
    };

    useEffect(() => {
        if (optionRef.current) {
            const finalLabel = `<b className=" font-GraphikBold text-[16px] xl:text-[20px]">${getJsonTextLanguageDisplay("tarea_1_base_promocion_text_opcion_1")} ${index + 1}</b>. ${label}`;
            optionRef.current.innerText = "";
            optionRef.current.insertAdjacentHTML("beforeend", finalLabel);
        }
    });

    return (
        <>
            <div
                onClick={handlerClick}
                className="my-[2px] w-full rounded-xl   h-full flex items-center  hover:bg-hover-talk active:bg-active-talk cursor-pointer "
            >
                <p ref={optionRef}></p>
            </div>
            {hasLine && <div className="h-[1px] w-full border-task-color" style={{ border: "1px solid " }} onClick={(event) => event.stopPropagation()}></div>}
        </>
    );
};

export default memo(MessageItemWithOption);
