/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { initStatusQuestions } from "../../types";

const useMessageOptions = (item, typeCase) => {
	const getOptionsByTypeCase = (options, typeCase) => {
		return options[typeCase];
	};

	const getOptionsByClient = (options, isClient) => {
		const type = isClient ? 1 : 0;
		return options[type];
	};

	const getOptions = (options, hasMoreOptions) => {
		switch (hasMoreOptions) {
			case "type-case": {
				return getOptionsByTypeCase(options, typeCase);
			}
			case "histogram": {
				return getOptionsByClient(options, initStatusQuestions.isClient);
			}
			default:
				new Error("Type Error");
		}
	};

	const options = useMemo(() => {
		if (!item.hasMoreOptions) return item.options;
		return getOptions(item.options, item.hasMoreOptions);
	}, [item]);
	return { options };
};

export default useMessageOptions;
