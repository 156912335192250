
const tokenName = "login-token";

export const setToken = (value) => {
    localStorage.setItem(tokenName, value);
}


export const getToken = () => {
    return localStorage.getItem(tokenName)
}

export const cleanToken = () => {
    localStorage.removeItem(tokenName)
}


