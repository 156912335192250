import { Ellipse } from "../assets";

const WhiteCircle = () => {
	return (
		<>
			<div id="ellipse-top" className="w-[313px] h-[313px]  top-[-90px] right-[-156px]">
				<img src={Ellipse} className="w-full h-full absolute" alt="S/D" />
			</div>
			<div id="ellipse-bottom" className="w-[415px] h-[415px] left-[-116px] bottom-[-149px]">
				<img src={Ellipse} className="w-full h-full absolute" alt="S/D" />
			</div>
		</>
	);
};

export default WhiteCircle;
