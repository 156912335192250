import { Tooltip } from 'antd';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { teacherHeaders } from '../../data/headers';
import { useExcelAnswers } from '../../hooks';

const ActionIcon = ({ icon, title, answers }) => {
    const { excelData, getAnswers } = useExcelAnswers();

    useEffect(() => {
        if (!answers) return;
        getAnswers(answers)
    }, [getAnswers, answers])
    return (
        <Tooltip placement="left" title={title} className='text-black' >
            <CSVLink data={excelData} headers={teacherHeaders} filename={`group_answers_${new Date().toISOString()}.csv`} >
                <img src={icon} alt="exporting excel" className='w-full object-contain mx-auto cursor-pointer' />
            </CSVLink>
        </Tooltip >
    );
}

export default ActionIcon;