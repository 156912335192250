import Router from "./routes";
import "./assets/fonts/GraphikSemibold.otf";
import "./assets/fonts/GraphikRegular.otf";
import "./assets/fonts/GraphikMedium.otf";
import "./assets/fonts/GraphikBold.otf";
import "./style.desktop.css";
import "./style.css";
// "homepage": "https://tec-web3d.online/app",

function App() {
    return <Router />;
}

export default App;
