import { useDispatch } from "react-redux";
import { setRequiredSection } from "../../store/slices/computer";
import { setNotificationStatus } from "../../store/slices/task";
import { useStoreTask } from "../../hooks/store";
import { Notification } from "../../components";

const NotificationModel = () => {
	const dispatch = useDispatch();
	const { notification, tasks } = useStoreTask();
	const { isActive, title, body, textButton } = notification;
	const action = () => {
		if (!tasks) return;
		if (tasks.length === 0) return;
		const [task] = tasks;
		if (task.id !== 13) dispatch(setRequiredSection(null));
		dispatch(setNotificationStatus(false));
	};
	if (!isActive) return <></>;

	return <Notification body={body} title={title} action={action} textButton={textButton} />;
};

export default NotificationModel;
