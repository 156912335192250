import { useCallback, useRef, useState } from "react";
import { getAllAnswer } from "../../api";

const useResults = () => {
    const [results, setResults] = useState([]);
    const resultsRef = useRef([])

    const updateResults = useCallback(async () => {
        const { payload } = await getAllAnswer();
        const newResults = payload.answers.map(answer => ({
            ...answer,
            key: answer._id
        }))
        resultsRef.current = newResults;
        setResults(newResults);
    }, []);

    const reset = () => {
        setResults(resultsRef.current)
    }

    const filter = (dates) => {
        if (!dates) return;
        const [init, last] = dates;

        const filteredResults = resultsRef.current.filter(answer => {
            const { createdAt } = answer;
            const start = new Date(`${init.$d.getFullYear()}/${init.$d.getMonth()}/${init.$d.getDate()}`)
            const end = new Date(last.$d);

            const custom = new Date(createdAt)
            const date = new Date(`${custom.getFullYear()}/${custom.getMonth()}/${custom.getDate()}`)
            return start <= date && date <= end
        })
        setResults(filteredResults)
    }

    return { results, updateResults, reset, filter }
}

export default useResults;