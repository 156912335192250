import { useStoreTask, useStoreUser } from "../../hooks/store";
import { useUserActions } from "../../hooks";
import { workmateReact5 } from "../../store/slices/task/helper";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const WorkMateTask = () => {
    const {
        questions: { workmateQuestion },
    } = useStoreUser();
    const { tasks } = useStoreTask();
    const currentTask = tasks[0];
    const { selectClientWorkMate } = useUserActions();
    const currentQuestion = workmateQuestion;
    const { answer, wasUsed } = currentQuestion;
    const isActiveElement = currentTask.id === 25;

    const handlerSelection = (indexClient, label) => {
        if (isActiveElement) return;
        selectClientWorkMate(indexClient, wasUsed, label);
    };

    return (
        <div className=" w-full h-full flex items-end flex-col  ">
            <div className="w-4/5 h-10 rounded-t-xl  border-white" style={{ border: "1px solid" }}>
                <div className="w-full h-full flex">
                    <div className="w-2/4 flex justify-center items-center border-white" style={{ borderRight: "2px solid" }}>
                        <p className="font-GraphikSemibold text-white xl:text-[16px]">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_1_col_1")}</p>
                    </div>
                    <div className="w-2/4 flex justify-center items-center">
                        <p className="font-GraphikSemibold text-white xl:text-[16px]">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_1_col_2")}</p>
                    </div>
                </div>
            </div>
            <div className="w-full h-full flex flex-col bg-gray-dark rounded-b-xl rounded-tl-xl">
                <div className="w-full h-9 flex rounded-tl-xl bg-white">
                    <div className="w-1/5 h-full flex items-center justify-center" style={{ borderRight: ' "0px solid #0577BE" ' }}>
                        <p className="font-GraphikSemibold xl:text-[14px] text-strong-blue">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_2_col_1")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_2_col_2")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_2_col_3")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_2_col_4")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("tarea_4_casos_1_tabla_fila_2_col_5")}</p>
                    </div>
                </div>
                <div className="w-full grow  rounded-b-xl">
                    <div
                        onClick={() => handlerSelection(1, 'Cliente 1')}
                        className={`w-full h-1/3 flex  ${answer === 1 ? "bg-task-color" : "bg-strong-blue hover:bg-blue-table-histogram cursor-pointer"
                            } `}
                    >
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">1</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$5,800.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$485.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$5,080.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$125.00</p>
                        </div>
                    </div>
                    <div
                        onClick={() => handlerSelection(2, 'Cliente 2')}
                        className={`w-full h-1/3 flex  ${answer === 2 ? "bg-task-color" : "bg-strong-blue hover:bg-blue-table-histogram cursor-pointer"
                            } `}
                    >
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">2</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            {/* <p className="font-GraphikRegular xl:text-[18px] text-white">$4,200.00</p> */}
                            <p className="font-GraphikRegular xl:text-[18px] text-white">${workmateReact5.priceRandomFormatted}</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$150.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$3,800.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$540.00</p>
                        </div>
                    </div>
                    <div
                        onClick={() => handlerSelection(3, 'Cliente 3')}
                        className={`w-full h-1/3 flex rounded-b-xl  ${answer === 3 ? "bg-task-color" : "bg-strong-blue hover:bg-blue-table-histogram cursor-pointer"
                            } `}
                    >
                        <div className="w-1/5 h-full flex items-center justify-center border-white rounded-bl-xl" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">3</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$7,140.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$280.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$5,820.00</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white rounded-br-xl" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">$1,860.00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkMateTask;
