export const modulo5EntregaDocumentacion = {
    tarea_1_sala_juntas_escritorio_ambiente3D_boton_plegable_1: {
        es: "¡Todavía estás a tiempo para tu reunión! Dirígete a la sala de juntas que se encuentra a tu lado izquierdo.",
        en: "You're still on time for your meeting! Head to the boardroom located on your left side."
    },
    tarea_1_sala_juntas_escritorio_gerente_card_titulo: {
        es: "Gerente",
        en: "Manager"
    },
    tarea_1_sala_juntas_escritorio_gerente_card_contenido: {
        es: "Muy buenos días, justo a tiempo, ¿tiene los documentos?",
        en: "Good morning, just in time. Do you have the documents?"
    },
    tarea_1_sala_juntas_escritorio_usuario_card_contenido: {
        es: "Si, estos son los documentos que decidí traer para utilizarlos en la junta.",
        en: "Yes, these are the documents I decided to bring to use in the meeting."
    },
    tarea_1_sala_juntas_escritorio_usuario_card_contenido_final: {
        es: "¡Excelente! veamos de que se tratará el tema de esta nueva tarjeta para nuestro banco.",
        en: "Great! Let's see what the topic of this new card for our bank will be."
    },
    tarea_1_sala_juntas_escritorio_usuario_card_contenido_incorrecto: {
        es: "De acuerdo, veamos de que tratará el tema de esta nueva tarjeta para nuestro banco",
        en: "Alright, let's see what the topic of this new card for our bank will be."
    },
    tarea_1_abrir_presentacion_sala_juntas_boton_plegable_1: {
        es: "Abre tu presentación para comenzar la reunión.",
        en: "Open your presentation to start the meeting."
    },
}