export const demoTraduction = {
    demo_home_1: {
        es: "¡Bienvenido al Banco Creciendo Juntos!",
        en: "Welcome to Banco Creciendo Juntos!"
    },
    demo_home_2: {
        es: " ¿Listo para empezar? Da click en 'Comenzar'",
        en: "Ready to begin? Click on 'Start'"
    },
    demo_home_3: {
        es: "Por: Dra. Gabriela Monforte García",
        en: "By: Dr. Gabriela Monforte García"
    },
    demo_home_4: {
        es: "D.R. Instituto Tecnológico y de Estudios Superiores de Monterrey, México, [replace1]",
        en: "All rights reserved. Monterrey Institute of Technology and Higher Education, Mexico, [replace1]"
    },
    demo_home_button: {
        es: "Comenzar",
        en: "Start"
    },
    // selecionar personajes
    demo_home_selecionar_perosnaje_1: {
        "es": "¡Bienvenido al Banco Creciendo Juntos!",
        "en": "Welcome to Banco Creciendo Juntos!"
    },
    demo_home_selecionar_perosnaje_2: {
        "es": "Selecciona tu avatar para comenzar.",
        "en": "Select your avatar to begin."
    },
    demo_home_selecionar_perosnaje_3: {
        "es": "Iniciar",
        "en": "Start"
    },
    // guia
    demo_home_guia_introduccion_1: {
        "es": "Guía de navegación.",
        "en": "Navigation guide"
    },
    demo_home_guia_introduccion_titulo_1: {
        "es": "Conoce la interfaz",
        "en": "Get to know the interface."
    },
    demo_home_guia_introduccion_titulo_2: {
        "es": "Conoce las tareas.",
        "en": "Get to know the tasks."
    },
    demo_home_guia_introduccion_titulo_3: {
        "es": "¿Cómo interactuar?",
        "en": "How to interact?"
    },
    demo_home_guia_introduccion_titulo_4: {
        "es": "¿Cómo caminar?",
        "en": "How to walk?"
    },
    demo_home_guia_introduccion_button_1: {
        "es": "Comenzar",
        "en": "Start"
    },
    demo_home_guia_introduccion_button_2: {
        "es": "Saltar",
        "en": "Skip"
    },
    // inicio de la experencia
    demo_inicio_experencia_task_1: {
        es: "Tarea",
        en: "Task"
    },
    demo_inicio_experencia_task_1_1: {
        es: "Revisa tu computadora.",
        en: "Check your computer."
    },
    demo_inicio_experencia_task_2_1: {
        es: "Revisa tu correo.",
        en: "Check your email."
    },
    demo_inicio_experencia_task_3_1: {
        es: "Revisa los correos pendientes",
        en: "Check pending emails"
    },
    demo_inicio_experencia_task_4_1: {
        es: "Da click en 'X' para salir",
        en: "Click on 'X' to exit"
    },
    demo_inicio_experencia_recursos_humanos: {
        es: "Recursos Humanos",
        en: "Human Resources"
    },
    demo_inicio_experencia_banco_creciendos: {
        es: "Banco Creciendo Juntos",
        en: "Banco Creciendo Juntos"
    },
    demo_inicio_experencia_mail_title: {
        es: "Agenda de fechas para la capacitación anual.",
        en: "Annual training schedule."
    },
    demo_inicio_experencia_mail_title_inside: {
        es: "Recursos Humanos. Banco Creciendo Juntos.",
        en: "Human Resources | Banco Creciendo Juntos."
    },
    demo_inicio_experencia_mail_body: {
        es: `
        <ul style="list-style: inside; padding: 0px 56px;">
            <div style="display: flex; width: 100%; justify-content: space-between;">
            <li>Junio.- Fechas disponibles: 5 al 9 y 12 al 16.</li>
            <li> Junio.- Fechas disponibles: 19 al 23 y 26 al 30.</li>
            </div>
            <li>Julio.- Fechas disponibles: 3 al 7 y 10 al 14.</li>
        </ul>
        <p style="margin-top: 16px">
        <span  class="strong-text font-GraphikBold" style="font-weight: bolder;">Nota</span>: esperamos su confirmación.
        </p>
        `,
        en: `
        <ul style="list-style: inside; padding: 0px 56px;">
            <div style="display: flex; width: 100%; justify-content: space-between;">
            <li>June.- Available dates: 5th to 9th and 12th to 16th.</li>
            <li>June.- Available dates: 19th to 23rd and 26th to 30th.</li>
            </div>
            <li>July.- Available dates: 3rd to 7th and 10th to 14th.</li>
        </ul>
        <p style="margin-top: 16px"> 
        <span  class="strong-text font-GraphikBold" style="font-weight: bolder;">Note</span>: We await your confirmation.
        </p>
        `
    },
    // compañero
    demo_inicio_experencia_task_companero_1: {
        es: "¡Pregúntale a tu compañero sobre la fecha de la capacitación! Dirígete a su oficina que se encuentra a tu lado derecho.",
        en: "Ask your colleague about the training date! Head to their office located on your right."
    },
    demo_inicio_experencia_task_companero_2: {
        es: "Dialoga con tu compañero",
        en: "Engage in dialogue with your colleague"
    },
    demo_inicio_experencia_task_companero_2_name: {
        es: "Ejecutivo",
        en: "Executive"
    },
    demo_inicio_experencia_task_companero_2_msg_1: {
        es: "Hola recibiste el correo con las fechas para la capacitación. Yo agendé la primera y segunda semana de junio.",
        en: "Hi, did you receive the email with the training dates? I scheduled for the first and second week of June."
    },
    demo_inicio_experencia_task_companero_2_msg_2: {
        es: "Suena bien! Probablemente yo también vaya en esa fecha.",
        en: "Sounds good! I'll probably go on those dates too."
    },
    demo_inicio_experencia_task_companero_2_msg_3: {
        es: "Si gustas puedes agendar las fechas en mi computadora.",
        en: "If you'd like, you can schedule the dates on my computer."
    },
    demo_inicio_experencia_task_companero_3: {
        es: "Ingresa a la computadora de tu compañero para reservar la fecha.",
        en: "Access your colleague's computer to reserve the date."
    },
    // tabla
    demo_inicio_experencia_task_companero_tabla_1: {
        es: "Selecciona la fecha deseada.",
        en: "Select the desired date."
    },
    demo_inicio_experencia_task_companero_tabla_week_1: {
        es: "Semana 1",
        en: "Week 1"
    },
    demo_inicio_experencia_task_companero_tabla_week_2: {
        es: "Semana 2",
        en: "Week 2"
    },
    demo_inicio_experencia_task_companero_tabla_fechas: {
        es: "Fechas",
        en: "Dates"
    },
    demo_inicio_experencia_task_companero_tabla_inicio: {
        es: "Inicio",
        en: "Start"
    },
    demo_inicio_experencia_task_companero_tabla_fin: {
        es: "Fin",
        en: "End"
    },
    demo_inicio_experencia_task_companero_tabla_junio: {
        es: "Junio",
        en: "June"
    },
    demo_inicio_experencia_task_companero_tabla_julio: {
        es: "Julio",
        en: "July   "
    },
    demo_inicio_experencia_task_companero_tabla_lunes: {
        es: "Lunes",
        en: "Monday"
    },
    demo_inicio_experencia_task_companero_tabla_viernes: {
        es: "Viernes",
        en: "Friday"
    },
    demo_inicio_experencia_task_companero_tabla_boton: {
        es: "Elegir",
        en: "Choose"
    },
    //regresar
    demo_inicio_experencia_task_final_1: {
        es: "Te despides para regresar a tu oficina.",
        en: "Bid farewell as you return to your office."
    },
    demo_inicio_experencia_task_final_msg_1: {
        es: "Muchas gracias, ya agendé la fecha.",
        en: "Thank you very much, I've already scheduled the date."
    },
    demo_inicio_experencia_task_final_msg_2: {
        es: "De nada, nos vemos al rato para ir a comer.",
        en: "You're welcome, see you in a bit for lunch."
    },
    demo_inicio_experencia_task_final_2: {
        es: "Dirígete a tu oficina.",
        en: "Head back to your office."
    },
    demo_inicio_experencia_task_final_3: {
        es: "¡Ya casi es la hora de salir! Firma tu hora de salida en tu computadora.",
        en: "It's almost time to leave! Sign out on your computer."
    },
    demo_inicio_experencia_pdf_1: {
        es: "Muchas gracias por haber participado",
        en: "Thank you very much for participating"
    },
}