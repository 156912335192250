import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { LoaderCMS, MainContent, Menu } from "../components";
import { cleanToken, getToken } from "../helpers/loginToken";
import { MenuItem, TableStudents } from "../models/cms";
import { Icons, LogoEscuela } from "../assets";



const Administrator = () => {
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const logOut = useCallback(() => {
        cleanToken();
        localStorage.removeItem("email")
        navigation("/administration/login")
    }, [navigation])

    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigation("/administration/login")
        } else {
            setIsLoading(false)
        }
    }, [navigation])


    if (isLoading) {
        return < LoaderCMS />
    }
    return (
        <div className="w-full h-full flex">
            <Menu>
                <div className="bg-background-screen">
                    <div className="">
                        <div className="flex justify-center px-4">
                            <img src={LogoEscuela} alt="Logo con el nombre de la universidad" className="w-full object-cover  mb-8" />

                        </div>
                        <ul>
                            <MenuItem isSelected icon={Icons.table} text="Tabla" alt="table section" />
                        </ul>

                    </div>
                </div>
                <div >
                    <ul className="flex flex-col gap-4">
                        <MenuItem icon={Icons.user} alt="user profile section" text={localStorage.getItem("email")} />
                        <MenuItem icon={Icons.question} alt="help section" text="Ayuda" />
                        <MenuItem handleClick={logOut} icon={Icons.closeSession} alt="close section" text="Cerrar sesión" />
                    </ul>
                </div>
            </Menu>
            <MainContent >
                <TableStudents />
            </MainContent>
        </div>
    );
}

export default Administrator;