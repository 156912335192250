import { useDispatch } from "react-redux";
import { selectMail } from "../../store/slices/computer";
import { useStoreComputer } from "../../hooks/store";
import { useComputerActions } from "../../hooks";
import { Button } from "../../components";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const BackButton = () => {
    const dispatch = useDispatch();
    const { selectedMail } = useStoreComputer();
    const { selectComputerSection } = useComputerActions();
    const backAction = () => {
        if (selectedMail) {
            dispatch(selectMail(null));
            return;
        }
        selectComputerSection(null);
    };

    return (
        <div className="w-full flex justify-end h-full">
            <Button onClick={backAction} className="rounded-full w-[208px] h-full">
                {getJsonTextLanguageDisplay("tarea_3_revisa_los_correos_pendientes_na_pantalla_menu_de_correo_interfaz2D_boton")}
            </Button>
        </div>
    );
};

export default BackButton;
