import { useEffect, useState } from "react";
import { usePersonClientSection, useSelectUserTable, useTableClient } from "../../hooks/computer";
import { useTableContext } from "../../context/table";
import tasks from "../../store/slices/task/tasks";
import { useComputerActions } from "../../hooks/";
import { InfoButton } from "../../components";
import HelpText from "../computer/HelpText";
import optionalTasks from "../../store/slices/task/optionalTasks";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const helpTasks = [tasks.Task_2_3.id, optionalTasks.Task_Optional_3.id]

const TableUserQuestion = ({ taskId }) => {
    const { isDoneAction } = useTableContext();
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const { user } = usePersonClientSection(taskId);
    const rowUsers = useTableClient(isDoneAction, taskId);
    const { changeGlossaryStatus, updateRequiredSection } = useComputerActions();
    const openGlossary = () => changeGlossaryStatus(true);
    const isWomanClientTask = taskId === tasks.TaskRegister.id;
    const isManClientTask = taskId === tasks.TasksRegisterMan.id;
    const TaskField = useSelectUserTable(taskId, user)

    useEffect(() => {
        if (isManClientTask)
            updateRequiredSection(null)
    }, [isManClientTask, updateRequiredSection])

    return (
        <section className="w-full h-full  flex items-center justify-center flex-col     rounded-lg  ">
            {isInfoOpen && <HelpText taskId={taskId} close={() => setIsInfoOpen(false)} />}

            {(isWomanClientTask || isManClientTask) && <InfoButton action={openGlossary} />}
            {helpTasks.includes(taskId) && <InfoButton action={() => setIsInfoOpen(true)} label={getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_modal_icon_ayuda_interfaz2D_modal_titulo_1")} />}
            <div className="w-full grow rounded-xl bg-white mt-1">
                <table className="w-full rounded-3xl h-full">
                    <thead className="bg-blue">
                        <tr>
                            <td className="py-2 xl:py-[11px] px-[24px] xl:px-[36px]  item-table-client text-[12px] xl:text-[18px]  font-GraphikRegular text-white rounded-tl-lg bg-strong-blue text-left">
                                {getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_columna1_titulo")}
                            </td>
                            <td className="py-[5px] px-[41px] th-client text-strong-blue text-[12px] xl:text-[18px] font-GraphikBold rounded-tr-lg bg-white text-left">
                                {getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_columna2_titulo")}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="h-full   rounded-lg px-[15px] py-[5px] xl:py-[30px]">
                                <div className="h-full flex flex-col justify-start items-start overflow-y-scroll">
                                    {TaskField}
                                </div>
                            </td>
                            <td className="h-full flex items-start flex-col overflow-scroll rounded-br-lg">{rowUsers}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default TableUserQuestion;
