import { Outlet } from "react-router";
import "../cms.css"
import { LanguageActions } from "../hooks/LanguageActions";
import { useEffect, useState } from "react";

const Cms = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { updateCodeLanguage } = LanguageActions()

    useEffect(() => {
        updateCodeLanguage("es")
        setIsLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (isLoading) {
        return <></>
    }

    return (
        <div className="w-full h-screen">
            <Outlet />
        </div >

    );
}

export default Cms;