/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { useStoreComputer, useStoreTask } from "../../hooks/store";
import { setActiveComputer } from "../../store/slices/computer";
import { useActiveComputer } from "../../hooks/computer";
import { TableProvider } from "../../context/table";
import { useComputerActions } from "../../hooks";
import Footer from "./Footer";
import Header from "./Header";
import Screen from "./Screen";
import Body from "./Body";

const Computer = () => {
    const dispath = useDispatch();
    const { tasks } = useStoreTask();
    const { isActive, sectionRequired, selectedSection } = useStoreComputer();
    const { showComputer, closeComputer } = useActiveComputer(isActive, tasks);
    const { updateRequiredSection, selectComputerSection } = useComputerActions();
    const currentTask = tasks[0];
    const isChangeSection = currentTask.action === "change-section";

    useEffect(() => {
        if (!tasks) {
            updateRequiredSection(null);
            return;
        }
        if (tasks.length === 0) {
            updateRequiredSection(null);
            return;
        }

        if (currentTask.type !== "computer" && !currentTask.isAlsoComputer) {
            updateRequiredSection(null);
            return;
        }
        if (currentTask.type === "computer" || currentTask.isAlsoComputer) updateRequiredSection(currentTask.section);
    }, [tasks.length]);

    useEffect(() => {
        if (!showComputer) {
            selectComputerSection(null);
        }
        dispath(setActiveComputer({ status: showComputer }));
    }, [showComputer]);

    if (!showComputer) return;
    if (tasks.length === 0) return;
    return (
        <TableProvider>
            <Screen
                task={currentTask}
                closeComputer={closeComputer}
                sectionRequired={sectionRequired}
                isChangeSection={isChangeSection}
                selectedSection={selectedSection}
            >
                <Header />
                <Body currentTask={currentTask} />
                <Footer />
            </Screen>
        </TableProvider>
    );
};

export default memo(Computer);
