import { useEffect, useState } from "react";
import tasks from "../../store/slices/task/tasks";
import optionalTasks from "../../store/slices/task/optionalTasks";

const useCloseButton = (sectionRequired, isChangeSection, task, questions, selectedSection) => {
    const { action: taskAction, id: taskId, isAlsoComputer, type } = task;
    const [isVisible, setIsVisible] = useState(false);
    const { questionConcept, meetingDocuments } = questions;
    useEffect(() => {
        if (!taskId) return;
        if (type === 'talk' && isAlsoComputer) {
            setIsVisible(false);
            return
        }
        if (taskId === optionalTasks.Task_Optional_6_1.id) {
            setIsVisible(false)
            return
        }
        if (taskId === 10) {
            setIsVisible(sectionRequired === null && !isChangeSection && taskAction !== "change-section");
            return;
        } else if ((taskId === 16) && selectedSection === "Histograma") {
            setIsVisible(true);
            return;
        } else if (taskId === 13) {
            const { retro, isCorrect } = questionConcept;
            if (isCorrect || retro.times > 1) {
                setIsVisible(true);
                return;
            }
            if (!sectionRequired) {
                setIsVisible(true);
                return;
            }
            setIsVisible(false);
        }
        if (taskId === 20) {
            const { retro } = meetingDocuments;
            if (meetingDocuments.isCorrect) {
                setIsVisible(true);
                return;
            }
            if (!meetingDocuments.isCorrect && retro.times > 1) {
                setIsVisible(true);
            }
        } else {
            if (!sectionRequired) {
                setIsVisible(true);
                return;
            }
            if (isAlsoComputer) return;
            setIsVisible(false);
        }
        if (taskId === tasks.TaskRegister.id && selectedSection === 'Registro') {
            setIsVisible(true)
            return;
        }

    }, [isChangeSection, sectionRequired, selectedSection, questionConcept, meetingDocuments, taskId, taskAction, isAlsoComputer, type]);

    return { isVisible };
};

export default useCloseButton;
