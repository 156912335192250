import { ActiveInterrogation, ActiveLock, Lock } from "../../assets";

const InstructionButtons = ({ open, DoesHaveMovement = true, statusData }) => {
    const { status, isLoading, currentTask } = statusData;
    if (status !== 'running' || isLoading || currentTask.type === 'computer') return <></>
    return (
        <div className="absolute top-[52px] right-[47px]" style={{ zIndex: 100000 }}>
            <div onClick={open} className="w-[54px] h-[54px] mb-[36px] cursor-pointer color-after ">
                <img src={ActiveInterrogation} alt="When this icon is blue the user cannot use controls, now it is blue" />
            </div>
            <div className={`w-[54px] h-[54px] ${DoesHaveMovement ? "cursor-pointer" : "cursor-not-allowed"} `}>
                <img src={DoesHaveMovement ? ActiveLock : Lock} alt="When this icon is blue the user cannot use controls, now it is blue" />
            </div>
        </div>
    );
}

export default InstructionButtons;