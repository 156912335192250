export const modulo4Seccion1RevisarAgenda = {
    tarea_1_revisa_computadora_agenda_escritorio_ambiente3D_boton_plegable_1: {
        es: "Revisa tu computadora para abrir tu agenda.",
        en: "Check your computer to open your agenda.",
    },
    //menu
    tarea_1_revisa_computadora_agenda_menu_interfaz2D_boton_plegable_tarea_1: {
        es: "Ingresa a la sección de agenda.",
        en: "Enter the agenda section.",
    },
    //agenda
    tarea_2_recordatorio_reunion_agenda_ambiente3D_boton_plegable_tarea_1: {
        es: "Recuerda que tienes una reunión importante con el Gerente General a las 11 AM. Da click en ‘’Regresar’’ para preparar los documentos necesarios.",
        en: "Remember you have an important meeting with the General Manager at 11 AM. Click on ‘’Back’’ to prepare the necessary documents.",
    },
    tarea_2_recordatorio_reunion_agenda_interfaz2D_card_titulo: {
        es: "Hoy",
        en: "Today"
    },
    tarea_2_recordatorio_reunion_agenda_interfaz2D_boton: {
        es: "Regresar",
        en: "Back"
    },
}