import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addingEmailMessages, selectSection, setGlossaryStatus, setRequiredSection, selectMail } from "../store/slices/computer";

const useComputerActions = () => {
	const dispatch = useDispatch();

	const changeGlossaryStatus = useCallback(
		(state) => {
			dispatch(setGlossaryStatus(state));
		},
		[dispatch]
	);
	const selectComputerSection = useCallback(
		(sectionName, isSubFolder = false) => {
			dispatch(selectSection({ sectionName, isSubFolder }));
		},
		[dispatch]
	);

	const updateRequiredSection = useCallback(
		(section) => {
			dispatch(setRequiredSection(section));
		},
		[dispatch]
	);

	const addingEmails = useCallback(
		(idTask) => {
			dispatch(addingEmailMessages({ idTask }));
		},
		[dispatch]
	);

	const openMail = (id) => dispatch(selectMail(id));

	return { changeGlossaryStatus, selectComputerSection, updateRequiredSection, addingEmails, openMail };
};

export default useComputerActions;
