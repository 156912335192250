import { Button } from "../../components";

const ClientButtonSection = ({ leftButton, rigthButton, actionIsDone }) => {
	return (
		<div className="h-[48px] py-[5px] flex ">
			<div className="w-[208px] rounded-full ">
				<Button
					disabled={actionIsDone}
					isDisabled={true}
					onClick={leftButton.action}
					className={`rounded-full h-[48px] border-gray border-4 "cursor-not-allowed" `}
					style={{ background: "transparent", color: "gray" }}
					isFull
				>
					{leftButton.label}
				</Button>
			</div>
			<div className="w-[208px] rounded-full ml-[24px]">
				<Button disabled={actionIsDone} isDisabled={actionIsDone} onClick={rigthButton.action} className="rounded-full h-[48px]" isFull>
					{rigthButton.label}
				</Button>
			</div>
		</div>
	);
};
export default ClientButtonSection;
