import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button, WhiteCircle } from "../components";
import { useStoreGeneral } from "../hooks/store";
import { useGeneralActions } from "../hooks";
import { imgs } from "../assets";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";

const CharacterIntroduction = () => {
    const navigate = useNavigate()
    const { chooseCharacter } = useGeneralActions()
    const { character } = useStoreGeneral()

    return (
        <Fragment>
            <div className="w-full h-screen flex  flex-col text-center  pt-[36px] lg:pt-[80px] xl:pt-[176px] overflow-scroll">
                <h3 className="title-mobile text-center text-[16px] xl:text-[24px] font-GraphikMedium mb-[34px] lg:mb-[46px]">
                    {getJsonTextLanguageDisplay("demo_home_selecionar_perosnaje_1")}
                </h3>
                <p className="label-character text-center text-[20px] xl:text-[24px] font-GraphikSemibold lg:mb-[36px]">
                    {getJsonTextLanguageDisplay("demo_home_selecionar_perosnaje_2")}
                </p>
                <div className="flex flex-col lg:flex-row  items-center lg:justify-center mt-[20px] p-1">
                    <div
                        className={` w-[178px] xl:w-[214px] h-[178px] xl:h-[214px]  mb-[28px] rounded-full bg-white lg:mr-[24px] border-[3px]  ${character ? character.index === 0 ? "avatar-active" : "border-computer" : "border-computer"
                            }`}
                        onClick={() => chooseCharacter(0)}
                    >
                        <img src={imgs.H01} alt="Man" />
                    </div>
                    <div
                        className={` w-[178px] xl:w-[214px] h-[178px] xl:h-[214px]  mb-[28px] rounded-full bg-white  border-[3px] ${character ? character.index === 1 ? "avatar-active" : "border-computer" : "border-computer"
                            }`}
                        onClick={() => chooseCharacter(1)}
                    >
                        <img src={imgs.M01} alt="woman" />
                    </div>
                </div>
                <div className="mt-[24px]lg:mt-[40px] mb-[37px] flex w-full justify-center">
                    <Button
                        style={{ background: character ? (character.index === null ? "gray" : "") : "gray" }}
                        disabled={character ? character.index === null : true}
                        onClick={() => navigate("/introduction/app")}
                    >
                        {getJsonTextLanguageDisplay("demo_home_selecionar_perosnaje_3")}
                    </Button>
                </div>
            </div>
            <WhiteCircle />
        </Fragment>
    );
}

export default CharacterIntroduction;