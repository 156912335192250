import { IconDocuments, IconMessage, IconPeople, IconAgenda } from "../../assets";
import { ComputerSections } from "../../store/slices/computer/state";
import { useComputerActions } from "../../hooks";
import ComputerItem from "./ComputerItem";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const ComputerHome = () => {
    const { selectComputerSection } = useComputerActions();

    return (
        <div className="w-full  flex justify-between items-center h-[301px] ">
            <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_documentos_1")} action={() => selectComputerSection(ComputerSections.Documents.name)} icon={IconDocuments} text={"Documentos"} />
            <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_correo_1")} action={() => selectComputerSection(ComputerSections.Email.name)} icon={IconMessage} text={"Correo"} />
            <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_clientes_1")} action={() => selectComputerSection(ComputerSections.Clients.name)} icon={IconPeople} text={"Clientes"} />
            <ComputerItem textNew={getJsonTextLanguageDisplay("tarea_2_revisa_tu_correo_na_menu_interfaz2D_card_de_agenda_1")} action={() => selectComputerSection(ComputerSections.Agenda.name)} icon={IconAgenda} text={"Agenda"} />
        </div>
    );
};

export default ComputerHome;
