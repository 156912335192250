import { Fragment, useEffect } from "react";
import { useStoreComputer } from "../../hooks/store";
import { useComputerActions, useIntroduction } from "../../hooks";
import Computer from "./Computer";
import { Header } from "../Task";
import Footer from "./Footer";
import Body from "./Body";
import { useCloseButton } from "../../hooks/introductionApp";

const Screen = ({ isVisible = false, currentTask }) => {
    const { state, changeTask } = useIntroduction();
    const { mails, selectedEmail } = state;
    const { updateRequiredSection } = useComputerActions();
    const { selectedSection } = useStoreComputer();
    const { isVisible: isCloseButtonVisible } = useCloseButton({ actualTask: currentTask, section: selectedSection, emails: mails, selectedEmail });
    const section = currentTask.section === 'workmate' || currentTask.section === 'final' ? currentTask.section : selectedSection;

    useEffect(() => {
        updateRequiredSection("Correo")
    }, [updateRequiredSection]);

    if (!isVisible) return <Fragment />
    return (
        <Computer handlerCloseComputer={changeTask} isVisible={isCloseButtonVisible} >
            <Header />
            <Body section={section} />
            <Footer currentTask={currentTask} />
        </Computer>
    );
}

export default Screen;