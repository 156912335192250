export const modulo6Pareja = {
    tarea_llamar_pareja: {
        es: "Tienes clientes en espera, habla con ellos.",
        en: "You have waiting customers, talk to them."
    },
    tarea_saludar_pareja: {
        es: "¡Hola, buenos días! Bienvenidos al Banco Creciendo Juntos, ¿en qué puedo ayudarlos?",
        en: "Hello, good morning! Welcome to Banco Creciendo Juntos, how can I assist you?"
    },
    conversacion_mujer_1: {
        es: "Buenos días, quiero que me ayude generando mi estado de cuenta del mes pasado.",
        en: "Good morning, I would like you to help me generate my last month's statement."
    },
    conversacion_mujer_2: {
        es: "Claro, ingresaré sus datos al sistema y con gusto se lo facilito.",
        en: "Of course, I'll enter your data into the system and gladly provide it for you."
    },
    tarea_computadora_clientes: {
        es: "Ingresa a la sección de clientes.",
        en: "Go to the clients section."
    },
    tarea_registro_hombre: {
        es: "Ingresa a la sección de registro.",
        en: "Go to the registration section."
    },
    tarea_goback: {
        es: "Regresar",
        en: "Back"
    },
    tarea_mujer_revisar: {
        es: "Revisa la información de Esther Lozano para ver si es candidata a la nueva tarjeta. Puedes ver los requisitos dando click en el ícono de ‘’requisitos’’ Al terminar, da click en ‘’X’’ para salir.",
        en: "Review Esther Lozano's information to see if she qualifies for the new card. You can view the requirements by clicking on the ‘’requirements’’  icon. When you're done, click on ‘’X’’ to exit."
    },
    boton_flotantes_falta_ingresar: {
        es: "Ingresa a la sección de registro.",
        en: "Go to the registration section."
    },
    label_woman_credito: {
        es: "Crédito",
        en: "Credit"
    },
    label_mujer_credito_valor: {
        es: 'Estatus regular',
        en: "Regular Status"
    },
    label_woman_name: {
        es: "Nombre completo",
        en: "Full Name"
    },
    label_mujer_contrato: {
        es: "Contrato laboral",
        en: "Employment Contract"
    },
    label_mujer_contrato_valor: {
        es: 'Trabajo renumerado',
        en: "Paid Job"
    },
    label_mujer_saldo: {
        es: "Saldo promedio mensual",
        en: "Average Monthly Balance"
    },
    label_valor_compra: {
        es: "Valor promedio de compras",
        en: "Average Purchase Value:"
    },
    label_lugar_compra: {
        es: "Lugar de compras",
        en: "Shopping Place"
    },
    label_mercado: {
        es: "Mercado",
        en: "Market"
    },
    label_online: {
        es: "Online",
        en: "Online"
    },
    label_super_market: {
        es: "Supermercado",
        en: "Supermarket"
    },
    label_requisito_promocion: {
        es: "REQUISITOS DE PROMOCIÓN DE LA NUEVA TARJETA",
        en: "PROMOTION REQUIREMENTS FOR THE NEW CARD"
    },
    label_descripcion_promocion: {
        es: `La promoción aplicará para clientes que: tengan un contrato laboral temporal o trabajo no renumerado, un valor promedio de compras menor a $1,500 pesos y realizar sus compras en mercados o supermercados. Los clientes con al menos una de las características anteriores deberá de cumplir para ser candidato con: ser un cliente solvente (tener un estatus regular) y tener saldo promedio mensual superior a $15,000 pesos.`,
        en: `The promotion will apply to customers who: have a temporary employment contract or unpaid work, an average purchase value of less than $1,500 pesos, and make their purchases in markets or supermarkets. Customers with at least one of the above characteristics must meet the following requirements to be eligible: be a solvent customer (have a regular status) and have an average monthly balance greater than $15,000 pesos.`
    },
    task_decidir_mujer: {
        es: "Decide la mejor solución para el cliente.",
        en: "Decide the best solution for the customer."
    },
    task_ofrecer_tarjeta_mujer: {
        es: "Ofrecer la nueva tarjeta de crédito.",
        en: "Offer the new credit card."
    },
    task_no_ofrecer_tarjeta_mujer: {
        es: "No ofrecer la nueva tarjeta de crédito.",
        en: "Do not offer the new credit card."
    },
    task_incorrecto_usuario: {
        es: "Listo, he enviado su estado de cuenta a su correo. Además, revisando sus datos usted es candidata a nuestra nueva tarjeta de crédito la cual tiene mayores beneficios ¿desea que inicie con el trámite?",
        en: "Great, I have sent your statement. Additionally, upon reviewing your information, you are eligible for our new credit card, which offers greater benefits. Would you like me to start the application process?"
    },
    task_incorrecto_mujer: {
        es: "Justo lo que necesitamos, muchas gracias. Llenaré la solicitud.",
        en: "That's exactly what we need, thank you very much. I will fill out the application."
    },
    task_correcto_usuario: {
        es: "Listo, he enviado su estado de cuenta a su correo. ¿Puedo ayudarle en algo más?",
        en: "Perfect, I have sent your statement to your email. Is there anything else I can assist you with?"
    },
    task_correcto_mujer: {
        es: "Muchas gracias, por el momento es todo.",
        en: "Thank you very much, but for now, that's all."
    },
    task_correcto_despedida: {
        es: "Es un placer haberle ayudado. Gracias por su preferencia. En Banco Creciendo Juntos estamos para servirle.",
        en: "Thank you very much, but for now, that's all."
    },
    conversacion_hombre_saludo: {
        es: "Buenos días, yo quiero saber si hay una posibilidad de aumentar el límite de crédito de mi tarjeta.",
        en: "Good morning, I want to know if there is a possibility of increasing the credit limit on my card."
    },
    conversacion_usuario_saludo: {
        es: `Claro, esto dependerá de si su estatus crediticio es regular ¿me podrá proporcionar su nombre completo y sus datos de referencia?`,
        en: `Certainly, this will depend on whether your credit status is regular. Could you please provide me with your full name and your reference information?`
    },
    conversacion_hombre_estatus: {
        es: `Claro, mi nombre completo es Pedro Hernández García, tengo 40 años de edad, soy del estado de Puebla...`,
        en: "Of course, my full name is Pedro Hernández García, I am 40 years old, and I am from the state of Puebla…"
    },
    conversacion_usuario_despedida: {
        es: "Gracias, ingresaré sus datos en el sistema y con gusto lo revisamos.",
        en: "Thank you, I will enter your information into the system and we will gladly review it."
    },
    task_indicacion_computadora: {
        es: "Ingresa a la sección de clientes.",
        en: "Access the clients section."
    },
    task_revisar_pedro: {
        es: `Revisa la información de Pedro Hernández para tomar una decisión. Puedes ver los requisitos dando click en el ícono de ‘’requisitos’’ Al terminar, da click en ‘’X’’ para salir.`,
        en: `Check Pedro Hernandez's information to make a decision. You can view the requirements by clicking on the "requirements" icon. When finished, click on 'X' to exit.`
    },
    informacion_pedro_nombre: {
        es: "Nombre completo",
        en: "Full Name"
    },
    informacion_pedro_estatus: {
        es: "Crédito",
        en: "Credit"
    },
    informacion_pedro_estatus_valor: {
        es: "Estatus regular",
        en: "Regular Status"
    },
    informacion_pedro_contrato: {
        es: "Contrato laboral:",
        en: "Employment Contract:"
    },
    informacion_pedro_contrato_valor: {
        es: 'Temporal',
        en: "Temporary"
    },
    informacion_pedro_saldo: {
        es: "Saldo promedio mensual",
        en: "Average Monthly Balance:"
    },
    informacion_pedro_modena: {
        es: "MXN",
        en: "MXN"
    },
    task_promocion: {
        es: "REQUISITOS DE PROMOCIÓN DE LA NUEVA TARJETA",
        en: "PROMOTION REQUIREMENTS FOR THE NEW CARD"
    },
    task_promocion_oferta: {
        es: `La promoción aplicará para clientes que: tengan un contrato laboral temporal o trabajo no renumerado, un valor promedio de compras menor a $1,500 pesos y realizar sus compras en mercados o supermercados. Los clientes con al menos una de las características anteriores deberá de cumplir para ser candidato con: ser un cliente solvente (tener un estatus regular) y tener saldo promedio mensual superior a $15,000 pesos.`,
        en: `The promotion applies to clients who: have a temporary employment contract or unpaid work, have an average purchase value less than $1,500 pesos, and make their purchases in markets or supermarkets. Clients with at least one of the above characteristics must meet the following criteria to be eligible: be a solvent customer (have a regular status) and have an average monthly balance greater than $15,000 pesos.`
    },
    task_promocion_decision: {
        es: "Decide la mejor solución para el cliente.",
        en: "Decide the best solution for the client."
    },
    task_promocion_opcion_1: {
        es: "No ofrecer la nueva tarjeta de crédito.",
        en: "Do not offer the new credit card."
    },
    task_promocion_opcion_2: {
        es: "Ofrecer la nueva tarjeta de crédito.",
        en: 'Offer the new credit card.'
    },
    task_promocion_aumento: {
        es: "Muy bien, revisando sus datos, sí puede aumentar su límite de crédito. En unos minutos se ajustará automáticamente su crédito.",
        en: "Very well, upon reviewing your data, you can indeed increase your credit limit. Your credit will be adjusted automatically in a few minutes."
    },
    task_promocion_aumento_agradecimiento: {
        es: "Excelente, muchas gracias por su ayuda.",
        en: "Excellent, thank you very much for your help."
    },
    tasK_promocion_tarjeta: {
        es: "Revisando sus datos, usted es candidato para la nueva tarjeta de crédito la cual tiene mayores beneficios ¿desea que inicie con el trámite?",
        en: "Upon reviewing your data, you are eligible for our new credit card, which offers greater benefits. Would you like to proceed with the application?"
    },
    task_promocion_tarjeta_agradecimiento: {
        es: "Gracias, me servirá mucho, llenaré la solicitud.",
        en: "Thank you, that will be very helpful. I'll fill out the application."
    },
    tasK_promocion_tarjeta_usuario: {
        es: "Es un placer haberle ayudado. Gracias por su preferencia. En Banco Creciendo Juntos estamos para servirle.",
        en: "It's been a pleasure assisting you. Thank you for choosing us. At Banco Creciendo Juntos, we're here to serve you."
    },
    task_aviso_salida: {
        es: "¡Ya casi es tu hora de comida! Firma tu hora de salida en tu computadora.",
        en: "It's almost your lunchtime! Sign out on your computer."
    },
    despedida: {
        es: "¡Gracias por ser parte del Banco Creciendo Juntos!",
        en: "Thank you for being part of Banco Creciendo Juntos!"
    },
    task_despedida: {
        es: "Da click para finalizar la experiencia.",
        en: "Click to finish the session."
    },
    firmar_salida: {
        es: "Firmar salida",
        en: "Sign out"
    }

}