import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";
import { useIntroduction } from "../../hooks";

const PreferedDate = () => {
    // const [answer, setAnswer] = useState(null);
    const { selectDate, state: { selectedDate: answer } } = useIntroduction()


    const handlerSelection = (selected) => {
        selectDate(selected);
    }
    return (
        <div className=" w-full h-full flex items-end flex-col  ">
            <div className="w-4/5 h-10 rounded-t-xl  border-white" style={{ border: "1px solid" }}>
                <div className="w-full h-full flex">
                    <div className="w-2/4 flex justify-center items-center border-white" style={{ borderRight: "2px solid" }}>
                        <p className="font-GraphikSemibold text-white xl:text-[16px]">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_week_1")}</p>
                    </div>
                    <div className="w-2/4 flex justify-center items-center">
                        <p className="font-GraphikSemibold text-white xl:text-[16px]">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_week_2")}</p>
                    </div>
                </div>
            </div>
            <div className="w-full h-full flex flex-col bg-gray-dark rounded-b-xl rounded-tl-xl">
                <div className="w-full h-9 flex rounded-tl-xl bg-white">
                    <div className="w-1/5 h-full flex items-center justify-center" style={{ borderRight: ' "0px solid #0577BE" ' }}>
                        <p className="font-GraphikSemibold xl:text-[14px] text-strong-blue">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_fechas")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_inicio")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_fin")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_inicio")}</p>
                    </div>
                    <div
                        className="w-1/5 h-full flex items-center justify-center"
                        style={{ borderLeft: "2px solid #0577BE", borderRight: ' "0px solid #0577BE" ' }}
                    >
                        <p className="font-GraphikSemibold xl:text-[16px] text-strong-blue">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_fin")}</p>
                    </div>
                </div>
                <div className="w-full grow  rounded-b-xl">
                    <div
                        onClick={() => handlerSelection(1, 'Cliente 1')}
                        className={`w-full h-1/3 flex  ${answer === 1 ? "bg-task-color" : "bg-strong-blue hover:bg-blue-table-histogram cursor-pointer"
                            } `}
                    >
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_junio")}</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_lunes")} 5</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_viernes")} 9</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_lunes")} 12</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_viernes")} 16</p>
                        </div>
                    </div>
                    <div
                        onClick={() => handlerSelection(2, 'Cliente 2')}
                        className={`w-full h-1/3 flex  ${answer === 2 ? "bg-task-color" : "bg-strong-blue hover:bg-blue-table-histogram cursor-pointer"
                            } `}
                    >
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_junio")}</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_lunes")} 19</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_viernes")} 23</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_lunes")} 26</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_viernes")} 30</p>
                        </div>
                    </div>
                    <div
                        onClick={() => handlerSelection(3, 'Cliente 3')}
                        className={`w-full h-1/3 flex rounded-b-xl  ${answer === 3 ? "bg-task-color" : "bg-strong-blue hover:bg-blue-table-histogram cursor-pointer"
                            } `}
                    >
                        <div className="w-1/5 h-full flex items-center justify-center border-white rounded-bl-xl" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_julio")}</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_lunes")} 3</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_viernes")} 7</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_lunes")} 10</p>
                        </div>
                        <div className="w-1/5 h-full flex items-center justify-center border-white rounded-br-xl" style={{ border: "1px solid" }}>
                            <p className="font-GraphikRegular xl:text-[18px] text-white">{getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_viernes")} 14</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreferedDate;