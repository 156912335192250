export const modulo3TarjetaCredito = {
    usuario_card_texto_1: {
        es: "Listo. He verificado que su información haya sido llenada.",
        en: "All set. I've verified that your information has been filled out."
    },
    // no es cliente
    juan_no_cliente_card_texto_1: {
        es: "Gracias. Disculpa, ¿qué tan probable es que me puedan autorizar la tarjeta de crédito si tengo [replace1] años?",
        en: "Thank you. Excuse me, how likely is it that I can be approved for the credit card if I'm [replace1] years old?"
    },
    usuario_no_cliente_card_texto_2: {
        es: "Permítame analizar los datos disponibles en el sistema con respecto a las tarjetas de crédito y tendré una respuesta.",
        en: "Let me analyze the data available in the system regarding credit cards, and I'll have an answer for you."
    },
    // es cliente
    juan_es_cliente_card_texto_1: {
        es: "Gracias. Disculpa, ¿qué tan probable es que me puedan otorgar un préstamo si mi score crediticio es de [replace1] puntos?",
        en: "Thank you. Excuse me, how likely is it that I can be granted a loan if my credit score is [replace1] points?"
    },
    usuario_es_cliente_card_texto_2: {
        es: "Permítame analizar los datos disponibles en el sistema con respecto a los requerimientos para un prestamo y tendré una respuesta.",
        en: "Let me analyze the data available in the system regarding loan requirements, and I'll have an answer for you."
    },
    // ---
    tarea_1_realiza_analizar_historgrama_boton_plegable_1: {
        es: "Revisa tu computadora para analizar el histograma.",
        en: "Check your computer to analyze the histogram."
    },
    tarea_2_ingresa_seccion_menu_boton_plegable_1: {
        es: "Ingresa a la sección de clientes.",
        en: "Enter the clients section."
    },
    tarea_2_ingresa_seccion_menu_boton_plegable_2: {
        es: "Ingresa a la sección de Histograma.",
        en: "Enter the Histogram section."
    },
    // edad
    tarea_3_revisa_histograma_edad_boton_plegable_1: {
        es: "Si la edad del cliente supera el percentil 2.5 de la variable edad de los clientes se otorgará la tarjeta de crédito. De lo contrario se otorgará una cuenta de ahorro teniendo como aval un tutor. Da click en ‘X’ cuando termines.",
        en: "If the client's age exceeds the 2.5th percentile of the age variable of the clients, the credit card will be granted. Otherwise, a savings account will be granted with a tutor as guarantor. Click on 'X' when you're done."
    },
    // score
    tarea_3_revisa_histograma_score_boton_plegable_1: {
        es: "Si el score crediticio del cliente es superior al percentil 84 e inferior al percentil 97.5, se ofrece un préstamo por $100,000 pesos. En caso de ser superior al percentil 97.5, se ofrece un préstamo por $500,000 pesos. Da click en ‘‘x’’ cuando termines.",
        en: "If the client's credit score is higher than the 84th percentile and lower than the 97.5th percentile, a loan of $100,000 pesos is offered. If it is higher than the 97.5th percentile, a loan of $500,000 pesos is offered. Click on 'x' when you're done."
    },

    // tabla edad
    tarea_3_revisa_histograma_edad_grafica_card_titulo: {
        es: "Histograma (con curva normal) de edad en años.",
        en: "Histogram (with normal distribution) of age in years."
    },
    // tabla score
    tarea_3_revisa_histograma_score_grafica_card_titulo: {
        es: "Histograma de score crediticio.",
        en: "Histogram of credit score."
    },
    // tablas
    tarea_3_tabla_1_media: {
        es: "Media",
        en: "Mean",
    },
    tarea_3_tabla_1_dev_est: {
        es: "Desv. Est",
        en: "Standard Deviation",
    },
    tarea_3_tabla_1_n: {
        es: "N",
        en: "N",
    },
    // preguntas creidto
    tarea_4_decidir_ofrecer_tarjeta_usuario_boton_plegable_1: {
        es: "Decide si ofrecerás la tarjeta de crédito o la cuenta de ahorro con aval. Recuerda que la edad del cliente es de [replace1] años.",
        en: "Decide whether to offer the credit card or the savings account with a guarantor. Remember that the client's age is [replace1] years."
    },
    // preguntas score
    tarea_4_decidir_ofrecer_tarjeta_usuario_boton_plegable_2: {
        es: "Decide cual préstamo ofrecer. Recuerda que el cliente tiene un score crediticio de [replace1].",
        en: "Decide which loan to offer. Remember that the client has a credit score of [replace1]."
    },
    // opc credito
    tarea_4_decidir_ofrecer_tarjeta_usuario_card_texto_opc_1: {
        es: "Ofrecer la tarjeta crédito.",
        en: "Offer the credit card."
    },
    tarea_4_decidir_ofrecer_tarjeta_usuario_card_texto_opc_2: {
        es: "Ofrecer una cuenta de ahorro con aval.",
        en: "Offer a savings account with a guarantor."
    },
    // opc score
    tarea_4_decidir_ofrecer_score_usuario_card_texto_opc_1: {
        es: "Ofrecer un préstamo de $100,000 pesos.",
        en: "Offer a loan of $100,000 pesos."
    },
    tarea_4_decidir_ofrecer_score_usuario_card_texto_opc_2: {
        es: "Ofrecer un préstamo de $500,000 pesos.",
        en: "Offer a loan of $500,000 pesos."
    },
    // mensajes credito
    tarea_4_card_contenido_opc_1_usuario_1: {
        es: "¡Felicidades! Usted es apto para recibir una tarjeta de crédito. Puede pasar a la caja a recogerla.",
        en: "Congratulations! You are eligible to receive a credit card. You can proceed to the cashier to pick it up."
    },
    tarea_4_card_contenido_opc_1_juan_1: {
        es: "¡Muchísimas gracias! Pasaré a caja ahora mismo, gracias por su tiempo. Hasta pronto.",
        en: "Thank you very much! I'll head to the cashier right away. Thanks for your time. See you soon."
    },
    tarea_4_card_contenido_opc_2_usuario_1: {
        es: "La única opción que podemos ofrecerle por lo pronto es una cuenta de ahorro teniendo un tutor como aval.",
        en: "The only option we can offer you for now is a savings account with a tutor as guarantor."
    },
    tarea_4_card_contenido_opc_2_juan_1: {
        es: "Por lo pronto, no es la opción que estaba buscando. Gracias de todas maneras. Hasta pronto.",
        en: "For now, it's not the option I was looking for. Thank you anyway. See you soon."
    },
    // mensajes score
    tarea_4_card_contenido_score_opc_1_usuario_1: {
        es: "¡Felicidades! Dado el excelente manejo de su cuenta, le ofrecemos un préstamo por: $100,000 pesos. Puede pasar a la caja a recoger la autorización de su préstamo.",
        en: "Congratulations! Given your excellent account management, we offer you a loan of: $100,000 pesos. You can proceed to the cashier to collect the authorization for your loan."
    },
    tarea_4_card_contenido_score_opc_1_juan_1: {
        es: "¡Muchísimas gracias! Pasaré a caja ahora mismo, gracias por su tiempo. Hasta pronto.",
        en: "Thank you very much! I'll head to the cashier right away. Thanks for your time. See you soon."
    },
    tarea_4_card_contenido_score_opc_2_usuario_1: {
        es: "¡Felicidades! Dado el excelente manejo de su cuenta le ofrecemos un préstamo por: $500,000 pesos. Puede pasar a la caja a recoger la autorización de su préstamo.",
        en: "Congratulations! Given your excellent account management, we offer you a loan of: $500,000 pesos. You can proceed to the cashier to collect the authorization for your loan."
    },
    tarea_4_card_contenido_score_opc_2_juan_1: {
        es: "¡Muchísimas gracias! Pasaré a caja ahora mismo, gracias por su tiempo. Hasta pronto.",
        en: "Thank you very much! I'll head to the cashier right away. Thanks for your time. See you soon."
    },

}
