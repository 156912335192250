import { CloseButton } from "../../components";
import NotificationModel from "../Task/NotificationModel";

const Computer = ({ children, handlerCloseComputer, isVisible }) => {
    const [Header, Body, Footer] = children;
    return (
        <section
            id="screen"
            className={`w-full h-screen absolute top-0 flex justify-center items-center bg-background-screen  z-30`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.stopPropagation();
            }}
        >
            <div
                className="w-full max-w-[1280px]  bg-background-screen flex flex-col relative  border-[24px]  "
                style={{ border: "black solid", height: "90%" }}
            >
                <div className="lg:w-full pt-[51px] pb-10 pr-[14px] lg:pr-[23px]  flex flex-col items-center justify-end ">
                    <div className="h-full w-full ">{Header}</div>
                </div>
                <div className="w-full flex items-center grow">
                    <div className="my-auto w-full lg:px-[23px] h-full flex items-center bg-strong-lab ">{Body}</div>
                </div>
                <div className="w-full h-[100px] xl:h-[120px] flex items-center  py-0  pl-[9px]   xl:pl-[24px] pr-[14px] xl:pr-[23px]    ">
                    <div className=" w-full h-full ">{Footer}</div>
                </div>
                <CloseButton onClose={handlerCloseComputer} isVisible={isVisible} />
                <NotificationModel />
            </div>
        </section>
    );
}

export default Computer;