import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage"


// getJsonTextLanguageDisplay("")
export const informationQuestionClientParticipate = {
    3: [getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_si_contesto_encuesta_entregar_electrodoméstico_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_si_contesto_encuesta_entregar_electrodoméstico_nivel")],
    1: [getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_si_contesto_encuesta_entrega_tarjeta_200_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_si_contesto_encuesta_entrega_tarjeta_200_nivel")],
    0: [getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_si_contesto_encuesta_participa_futuras_promociones_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_si_contesto_encuesta_participa_futuras_promociones_nivel")]
}

export const informationQuestionClient = {
    3: [getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_no_contesto_encuesta_entrega_tarjeta_200_nivel"), getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_no_contesto_encuesta_participa_futuras_promociones_retro")],
    1: [getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_no_contesto_encuesta_entregar_electrodoméstico_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_no_contesto_encuesta_entregar_electrodoméstico_nivel")],
    0: [getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_no_contesto_encuesta_participa_futuras_promociones_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_registrado_no_contesto_encuesta_participa_futuras_promociones_nivel")]
}

export const informationQuestionParticipate = {
    3: [getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_si_contesto_encuesta_participa_futuras_promociones_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_si_contesto_encuesta_participa_futuras_promociones_nivel")],
    1: [getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_si_contesto_encuesta_entregar_electrodoméstico_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_si_contesto_encuesta_entregar_electrodoméstico_nivel")],
    0: [getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_si_contesto_encuesta_entrega_tarjeta_200_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_si_contesto_encuesta_entrega_tarjeta_200_nivel")]
}

export const informationQuestion1 = {
    "3": [getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_no_contesto_encuesta_participa_futuras_promociones_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_no_contesto_encuesta_participa_futuras_promociones_nivel")],
    1: [getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_no_contesto_encuesta_entrega_tarjeta_200_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_no_contesto_encuesta_entrega_tarjeta_200_nivel")],
    0: [getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_no_contesto_encuesta_entregar_electrodoméstico_retro"), getJsonTextLanguageDisplay("reactivo_1_cliente_no_registrado_no_contesto_encuesta_entregar_electrodoméstico_nivel")]
}

export const informmationRetro1Event = {
    "11": [getJsonTextLanguageDisplay("reactivo_1_1_si_asistio_evento_autoriza_promocion_feria_mujer_retro"), getJsonTextLanguageDisplay("reactivo_1_1_si_asistio_evento_autoriza_promocion_feria_mujer_nivel")],
    "10": [getJsonTextLanguageDisplay("reactivo_1_1_si_asistio_evento_denegar_promocion_feria_mujer_retro"), getJsonTextLanguageDisplay("reactivo_1_1_si_asistio_evento_denegar_promocion_feria_mujer_nivel")],
    "01": [getJsonTextLanguageDisplay("reactivo_1_1_no_asistio_evento_denegar_promocion_feria_mujer_retro"), getJsonTextLanguageDisplay("reactivo_1_1_no_asistio_evento_denegar_promocion_feria_mujer_nivel")],
    "00": [getJsonTextLanguageDisplay("reactivo_1_1_no_asistio_evento_autoriza_promocion_feria_mujer_retro"), getJsonTextLanguageDisplay("reactivo_1_1_no_asistio_evento_autoriza_promocion_feria_mujer_nivel")]
}
export const informmationRetro1_1Registered = {
    "11": [getJsonTextLanguageDisplay("reactivo_1_1_cliente_registrado_autorizar_retro"), getJsonTextLanguageDisplay("reactivo_1_1_cliente_registrado_autorizar_nivel")],
    "10": [getJsonTextLanguageDisplay("reactivo_1_1_no_asistio_evento_autoriza_promocion_feria_mujer_retro"), getJsonTextLanguageDisplay("reactivo_1_1_no_asistio_evento_autoriza_promocion_feria_mujer_nivel")],
    "01": [getJsonTextLanguageDisplay("reactivo_1_1_cliente_no_registrado_denegar_retro"), getJsonTextLanguageDisplay("reactivo_1_1_cliente_no_registrado_denegar_nivel")],
    "00": [getJsonTextLanguageDisplay("reactivo_1_1_cliente_no_registrado_autorizar_retro"), getJsonTextLanguageDisplay("reactivo_1_1_cliente_no_registrado_autorizar_nivel")]
}

export const informationQuestion2 = {
    3: [getJsonTextLanguageDisplay("reactivo_2_tabla1_acierta_todas_retro"), getJsonTextLanguageDisplay("reactivo_2_tabla1_acierta_todas_nivel")],
    1: [getJsonTextLanguageDisplay("reactivo_2_tabla1_falla_en_una_retro"), getJsonTextLanguageDisplay("reactivo_2_tabla1_falla_en_una_nivel")],
    0: [getJsonTextLanguageDisplay("reactivo_2_tabla1_no_acierta_todas_retro"), getJsonTextLanguageDisplay("reactivo_2_tabla1_no_acierta_todas_nivel")]
}

export const retroConcepts = {
    1: [getJsonTextLanguageDisplay("reactivo_2_1_tabla1_acierta_todas_retro"), getJsonTextLanguageDisplay("reactivo_2_1_tabla1_acierta_todas_nivel")],
    0: [getJsonTextLanguageDisplay("reactivo_2_1_tabla1_falla_en_una_retro"), getJsonTextLanguageDisplay("reactivo_2_1_tabla1_falla_en_una_nivel")]
}
// export const informationRetro2 = {
//     3: ["Respuesta correcta. ", ""],
//     1: ["Te recomiendo que revises detalladamente los conceptos de tipos de variables y escalas de medición.", "El estudiante cometió al menos un error identificando los errores en la designación de variables y/o cometió al menos un error identificando los errores en la designación de escalas de medición. Requiere un repaso del tema de variables y escalas de medición. "],
//     0: ["Te recomiendo que revises detalladamente los conceptos de tipos de variables y escalas de medición.", ""]
// }

export const informationQuestion3Age = {
    "03": [getJsonTextLanguageDisplay("reactivo3_cliente_menor_18_ofrece_cuenta_ahorro_retro"), getJsonTextLanguageDisplay("reactivo3_cliente_menor_18_ofrece_cuenta_ahorro_nivel")],
    "00": [getJsonTextLanguageDisplay("reactivo3_cliente_menor_18_ofrece_tarjeta_credito_retro"), getJsonTextLanguageDisplay("reactivo3_cliente_menor_18_ofrece_tarjeta_credito_nivel")],
    "13": [getJsonTextLanguageDisplay("reactivo3_cliente_mayor_18_ofrece_tarjeta_credito_retro"), getJsonTextLanguageDisplay("reactivo3_cliente_mayor_18_ofrece_tarjeta_credito_nivel")],
    "10": [getJsonTextLanguageDisplay("reactivo3_cliente_mayor_18_ofrece_cuenta_ahorro_retro"), getJsonTextLanguageDisplay("reactivo3_cliente_mayor_18_ofrece_cuenta_ahorro_nivel")]
}

export const informationQuestion3Score = {
    "03": [getJsonTextLanguageDisplay("reactivo3_score_entre_ofrece_prestamo_1000_retro"), getJsonTextLanguageDisplay("reactivo3_score_entre_ofrece_prestamo_1000_nivel")],
    "00": [getJsonTextLanguageDisplay("reactivo3_score_entre_ofrece_prestamo_5000_retro"), getJsonTextLanguageDisplay("reactivo3_score_entre_ofrece_prestamo_5000_nivel")],
    "13": [getJsonTextLanguageDisplay("reactivo3_score_mayor_ofrece_prestamo_5000_retro"), getJsonTextLanguageDisplay("reactivo3_score_mayor_ofrece_prestamo_5000_nivel")],
    "10": [getJsonTextLanguageDisplay("reactivo3_score_mayor_ofrece_prestamo_1000_retro"), getJsonTextLanguageDisplay("reactivo3_score_mayor_ofrece_prestamo_1000_nivel")],
}

export const informationQuestion4 = {
    "0": ["El estudiante tomó una mala carpeta", getJsonTextLanguageDisplay("reactivo4_student_nive_competencia_multiple")],
    "2": ["Selacción correcta", "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas. "]
}
export const informationQuestionRetro4 = {
    "0": ["El estudiante tomó una mala carpeta", getJsonTextLanguageDisplay("reactivo4_student_nive_competencia_multiple")],
    "1": ["Selección correcta", "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas. "]
}
export const informationQuestion5 = {
    "0": ["La respuesta correcta es el cliente 1 debido a que es el único que muestra una probabilidad mayor al 97.5 de tener un ingreso semanal mayor al gato semanal, lo que aumenta su probabilidad de cumplir con el pago de la tarjeta de crédito.", "El estudiante no tomó una decisión adecuada debido a que no comprendió o no aplicó alguno de los siguientes razonamientos: La relación entre la distribución normal y la regla empírica ya que, a partir de la regla empírica, que establece los percentiles para determinadas probabilidades, podría estimar los valores de las variables para evaluar hasta qué valor de probabilidad el ingreso sería superior al gasto, considerando la independencia entre las variables. "],
    "3": [getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4000_cliente_1_retro"), getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4000_cliente_1_nivel")]
}

export const informationQuestion5Cases = {
    1: {
        correct: {
            retro: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4000_cliente_1_retro"),
            nivelComp: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4000_cliente_1_nivel")
        },
        incorrect: {
            retro: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4000_cliente_2_retro"),
            nivelComp: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4000_cliente_2_nivel")
        }
    },
    2: {
        correct: {
            retro: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4580_cliente_2_retro"),
            nivelComp: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4580_cliente_2_nivel")
        },
        incorrect: {
            retro: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4580_cliente_3_retro"),
            nivelComp: getJsonTextLanguageDisplay("reactivo5_mayor_cuplir_pagos_4580_cliente_3_nivel")
        }
    },
}



export const informationRetro5 = {
    "0": [getJsonTextLanguageDisplay("reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_1_2_retro"), getJsonTextLanguageDisplay("reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_1_2_nivel")],
    "2": [getJsonTextLanguageDisplay("reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_3_retro"), getJsonTextLanguageDisplay("reactivo5_5_menor_probabilidad_cumplir_pagos_cliente_3_nivel")]
}

// export const informationQuestions6 = {
//     "0": ["Respuesta incorrecta.", "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable, en vez de elegir la gráfica de pastel que muestra la proporción marginal."],
//     "2": ["Respuesta correcta.", "El estudiante fue capaz de tomar decisiones empleando la gráfica correcta, para este caso la gráfica de pastel, que refleja el valor de la probabilidad marginal de la variable analizada y distingue adecuadamente entre las gráficas de barras que muestran relaciones condicionales entre variables entre la variable de estudio y otras variables. "]
// }

export const informationQuestionMeeting = {
    "Reactivo 6.1": {
        1: [2, "Respuesta correcta.", "El estudiante fue capaz de tomar decisiones empleando la gráfica correcta, para este caso la gráfica de pastel, que refleja el valor de la probabilidad marginal de la variable analizada y distingue adecuadamente entre las gráficas que muestran relaciones condicionales entre variables o relaciones de intersección entre variables."],
        2: [0, "Aunque la interpretación de esta gráfica es correcta, el Gerente no pide la proporción atendida por cada ejecutivo condicionada a que los clientes tengan trabajo no remunerado.", "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional o una proporción de intersección en vez de elegir la gráfica que muestra la proporción marginal."],
        3: [0, "Aunque la interpretación de esta gráfica es correcta, el Gerente no pide la proporción que representa la intersección entre el sexo del cliente y tiene trabajo no remunerado.", "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional o una proporción de intersección en vez de elegir la gráfica que muestra la proporción marginal. "],
    },
    "Reactivo 6.2": {
        1: [1, " Respuesta correcta.", "El estudiante fue capaz de tomar decisiones empleando la gráfica correcta, para este caso la gráfica de pastel, que refleja el valor de la probabilidad marginal de la variable analizada y distingue adecuadamente entre las gráficas de barras que muestran relaciones condicionales entre variables entre la variable de estudio y otras variables."],
        2: [0, " Aunque la interpretación de esta gráfica es correcta, el Gerente no solicita la proporción de hombres de los clientes que compran en mercados y supermercados", "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable, en vez de elegir la gráfica de pastel que muestra la proporción marginal. "],
        3: [0, "Aunque la interpretación de esta gráfica es correcta, el Gerente no solicita que fracción de clientes compra en el mercado del total de clientes que compran en mercados y supermercados", "El estudiante no tomó la decisión adecuada debido a que eligió una gráfica que mostró una proporción condicional de la variable de interés respecto a otra variable, en vez de elegir la gráfica de pastel que muestra la proporción marginal. "]
    },
    "Reactivo 6.3": {
        1: [2, "Respuesta correcta.", "El estudiante tomó la decisión correcta porque fue capaz de distinguir en el contexto del análisis a las variables que son clave para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros"],
        2: [0, "Aunque la interpretación de esta gráfica es correcta, el Gerente no está interesado en la cantidad de transacciones al día que realizan los clientes", "El estudiante no tomó la decisión correcta porque no fue capaz de distinguir en el contexto del análisis a las variables que son clave para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros."],
        3: [0, "Aunque la interpretación de esta gráfica es correcta, el Gerente no está interesado en la cantidad de productos financieros que utilizan los clientes. ", "El estudiante no tomó la decisión correcta porque no fue capaz de distinguir en el contexto del análisis a las variables que son clave para elegir a los clientes candidatos para la nueva tarjeta de crédito que el Banco pretende incorporar a su cartera de productos financieros."]
    },
    "Reactivo 6.4": {
        1: [3, "Respuesta correcta.", "El estudiante tomó la decisión correcta al reconocer en el contexto del problema y estimar la probabilidad condicionada de que los clientes puedan cumplir con su compromiso financiero en función de presentar alguna de las condiciones establecidas para ofrecer la nueva tarjeta de crédito."],
        2: [0, "Este resultado indica la fracción de clientes que compran en mercados y supermercados del total de clientes regulares, lo que el Gerente necesita saber es la probabilidad de ser cliente regular dado que compra en mercados y supermercados.", "El estudiante no tomó la decisión correcta debido a que no eligió la probabilidad condicionada de que los clientes puedan cumplir con su compromiso financiero en función de presentar alguna de las condiciones establecidas para ofrecer la nueva tarjeta de crédito. "],
        3: [0, "Este resultado indica la fracción de clientes regulares del total de clientes, es una probabilidad marginal, lo que el Gerente necesita saber es la probabilidad de ser cliente regular dado que compra en mercados y supermercados", "El estudiante no tomó la decisión correcta debido a que no eligió la probabilidad condicionada de que los clientes puedan cumplir con su compromiso financiero en función de presentar alguna de las condiciones establecidas para ofrecer la nueva tarjeta de crédito. "]
    },
    "Reactivo 6.5": {
        1: [3, " Respuesta correcta", "El estudiante tomo la decisión correcta, al entender y tener claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago."],
        2: [0, "Aunque esta interpretación de la gráfica es correcta, los clientes que compran en otros establecimientos no son clientes que estén considerados en la promoción de la nueva tarjeta", "El estudiante no tomo la decisión correcta dando evidencia de no entender claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago."],
        3: [0, "Esta interpretación de la gráfica es correcta; sin embargo, aunque los clientes morosos tienen un saldo promedio alto prácticamente en el límite requerido, una condición es que los clientes candidatos a la tarjeta sean regulares.", "El estudiante no tomo la decisión correcta dando evidencia de no entender claro cuáles son las características que deben cumplir los clientes para ser elegidos por sus condiciones sociodemográficas y por su capacidad de pago."],
    },
}

export const informationQuestionMeetingCases = {
    "Reactivo 6.1": {
        1: {
            1: {
                score: 2,
                retro: getJsonTextLanguageDisplay("reactivo_6_1_caso_1_tipo_actividad_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_1_caso_1_tipo_actividad_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_1_caso_1_genero_clientes_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_1_caso_1_genero_clientes_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_1_caso_1_clientes_genero_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_1_caso_1_clientes_genero_nivel")
            },
        },
        2: {
            1: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_1_caso_2_tipo_actividad_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_1_caso_2_tipo_actividad_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_1_caso_2_genero_clientes_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_1_caso_2_genero_clientes_nivel")
            },
            3: {
                score: 2,
                retro: getJsonTextLanguageDisplay("reactivo_6_1_caso_2_clientes_genero_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_1_caso_2_clientes_genero_nivel")
            },
        }
    },
    "Reactivo 6.2": {
        1: {
            1: {
                score: 1,
                retro: getJsonTextLanguageDisplay("reactivo_6_2_caso_1_proporcion_clientes_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_2_caso_1_proporcion_clientes_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_2_caso_1_por_sexo_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_2_caso_1_por_sexo_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_2_caso_1_por_entidad_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_2_caso_1_por_entidad_nivel")
            },
        },
        2: {
            1: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_2_caso_2_proporcion_clientes_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_2_caso_2_proporcion_clientes_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_2_caso_2_por_sexo_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_2_caso_2_por_sexo_nivel")
            },
            3: {
                score: 1,
                retro: getJsonTextLanguageDisplay("reactivo_6_2_caso_2_por_entidad_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_2_caso_2_por_entidad_nivel")
            },
        }
    },
    "Reactivo 6.3": {
        1: {
            1: {
                score: 2,
                retro: getJsonTextLanguageDisplay("reactivo_6_3_caso_1_menor_1500_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_3_caso_1_menor_1500_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_3_caso_1_5_transaccion_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_3_caso_1_5_transaccion_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_3_caso_1_productos_financieros_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_3_caso_1_productos_financieros_nivel")
            },
        },
        2: {
            1: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_3_caso_2_menor_1500_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_3_caso_2_menor_1500_nivel")
            },
            2: {
                score: 2,
                retro: getJsonTextLanguageDisplay("reactivo_6_3_caso_2_5_transaccion_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_3_caso_2_5_transaccion_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_3_caso_2_productos_financieros_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_3_caso_2_productos_financieros_nivel")
            },
        }
    },
    "Reactivo 6.4": {
        1: {
            1: {
                score: 3,
                retro: getJsonTextLanguageDisplay("reactivo_6_4_caso_1_correcto_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_4_caso_1_correcto_1_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_4_caso_1_falla_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_4_caso_1_falla_1_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_4_caso_1_falla_2_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_4_caso_1_falla_2_nivel")
            },
        },
        2: {
            1: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_4_caso_2_falla_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_4_caso_2_falla_1_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_4_caso_2_falla_2_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_4_caso_2_falla_2_nivel")
            },
            3: {
                score: 3,
                retro: getJsonTextLanguageDisplay("reactivo_6_4_caso_2_correcto_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_4_caso_2_correcto_1_nivel")
            },
        }
    },
    "Reactivo 6.5": {
        1: {
            1: {
                score: 3,
                retro: getJsonTextLanguageDisplay("reactivo_6_5_caso_1_correcto_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_5_caso_1_correcto_1_nivel")
            },
            2: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_5_caso_1_falla_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_5_caso_1_falla_1_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_5_caso_1_falla_2_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_5_caso_1_falla_2_nivel")
            },
        },
        2: {
            1: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_5_caso_2_falla_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_5_caso_2_falla_1_nivel")
            },
            2: {
                score: 3,
                retro: getJsonTextLanguageDisplay("reactivo_6_5_caso_2_correcto_1_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_5_caso_2_correcto_1_nivel")
            },
            3: {
                score: 0,
                retro: getJsonTextLanguageDisplay("reactivo_6_5_caso_2_falla_2_retro"),
                nivelComp: getJsonTextLanguageDisplay("reactivo_6_5_caso_2_falla_2_nivel")
            },
        }
    },
}


export const informationQuestionsCouple = {
    "0": ["Aunque Esther Lozano cumple con ser cliente regular y un saldo promedio mayor a $15,000, no cumple con ninguna de las características de trabajo temporal o no remunerado, compras en mercados y supermercados, compras por menos de $1,500 pesos. Por tanto, no es candidata para la nueva tarjeta de crédito.", "El estudiante no fue capaz de tomar la decisión correcta, ofreció la nueva tarjeta de crédito a la cliente que no cumple con las condiciones definidas para los usuarios de la nueva tarjeta de crédito."],
    "2": ["Respuesta correcta. ", "El estudiante fue capaz de tomar la decisión correcta al no ofrecer la tarjeta a la cliente yaque, aunque es un cliente regular y con capacidad de pago no cumple con ningula de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito."]
}

export const informationQuestionsCoupleWomanCases = {
    "1": {
        retro: {
            correct: getJsonTextLanguageDisplay("reactivo_7_1_caso_1_no_ofrece_retro"),
            incorrect: getJsonTextLanguageDisplay("reactivo_7_1_caso_1_ofrece_retro")
        },
        nivelComp: {
            correct: getJsonTextLanguageDisplay("reactivo_7_1_caso_1_no_ofrece_nivel"),
            incorrect: getJsonTextLanguageDisplay("reactivo_7_1_caso_1_ofrece_nivel")
        }
    },
    "2": {
        retro: {
            correct: getJsonTextLanguageDisplay("reactivo_7_2_caso_1_ofrece_retro"),
            incorrect: getJsonTextLanguageDisplay("reactivo_7_2_caso_1_no_ofrece_retro")
        },
        nivelComp: {
            correct: getJsonTextLanguageDisplay("reactivo_7_2_caso_1_ofrece_nivel"),
            incorrect: getJsonTextLanguageDisplay("reactivo_7_2_caso_1_no_ofrece_nivel")
        }
    },
    "3": {
        retro: {
            correct: getJsonTextLanguageDisplay("reactivo_7_3_caso_1_no_ofrece_retro"),
            incorrect: getJsonTextLanguageDisplay("reactivo_7_3_caso_1_ofrece_retro")
        },
        nivelComp: {
            correct: getJsonTextLanguageDisplay("reactivo_7_3_caso_1_no_ofrece_nivel"),
            incorrect: getJsonTextLanguageDisplay("reactivo_7_3_caso_1_ofrece_nivel")
        }
    },
}


export const informationQuestions9 = {
    "0": ["Pedro Hernández tiene trabajo temporal y comprar en mercados, por lo que cumple con al menos una de las condiciones señaladas además cumple con la condición de ser cliente regular y tener saldo promedio mayor a $15,000 pesos. Sí es candidato a la nueva tarjeta de crédito. ", "El estudiante no fue capaz de tomar la decisión correcta, no ofreció la nueva tarjeta de crédito al cliente que cumple con dos de las tres condiciones definidas para los usuarios de la nueva tarjeta de crédito además de ser un cliente regular y con capacidad de pago."],
    "2": ["Selección correcta. ", "El estudiante fue capaz de tomar la decisión correcta al ofrecer la tarjeta al cliente ya que cumple con dos de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito además de ser un cliente regular y con capacidad de pago. "]
}

export const informationQuestions9MenCases = {
    "1": {
        retro: {
            correct: getJsonTextLanguageDisplay("reactivo_8_1_caso_1_no_ofrece_retro"),
            incorrect: getJsonTextLanguageDisplay("reactivo_8_1_caso_1_ofrece_retro")
        },
        nivelComp: {
            correct: getJsonTextLanguageDisplay("reactivo_8_1_caso_1_no_ofrece_nivel"),
            incorrect: getJsonTextLanguageDisplay("reactivo_8_1_caso_1_ofrece_nivel")
        }
    },
    "2": {
        retro: {
            correct: getJsonTextLanguageDisplay("reactivo_8_2_caso_1_ofrece_retro"),
            incorrect: getJsonTextLanguageDisplay("reactivo_8_2_caso_1_no_ofrece_retro")
        },
        nivelComp: {
            correct: getJsonTextLanguageDisplay("reactivo_8_2_caso_1_ofrece_nivel"),
            incorrect: getJsonTextLanguageDisplay("reactivo_8_2_caso_1_no_ofrece_nivel")
        }
    },
}

export const informationQuestions7 = {
    "0": ["Aunque Esther Lozano cumple con ser cliente regular y un saldo promedio mayor a $15,000, no cumple con ninguna de las características de trabajo temporal o no remunerado, compras en mercados y supermercados, compras por menos de $1,500 pesos. Por tanto, no es candidata para la nueva tarjeta de crédito", "El estudiante no fue capaz de tomar la decisión correcta, ofreció la nueva tarjeta de crédito a la cliente que no cumple con las condiciones definidas para los usuarios de la nueva tarjeta de crédito."],
    "2": ["Respuesta correcta. ", "El estudiante fue capaz de tomar la decisión correcta al no ofrecer la tarjeta a la cliente yaque, aunque es un cliente regular y con capacidad de pago no cumple con ningula de las condiciones que el Banco definió para los usuarios de la nueva tarjeta de crédito."]
}