import { createSlice } from "@reduxjs/toolkit";
import { Task_1, Task_1_1, selectComputer, workMateTalk, workMateTalk2, workmateComputer, workmateTask, TaskGoBackOffice, ComputerFood, FinalSection } from "./tasks";
import { mail1 } from "./mails";


const initialState = {
    character: null,
    currentTask: Task_1,
    tasks: [Task_1_1, workmateTask, workMateTalk, selectComputer, workmateComputer, workMateTalk2, TaskGoBackOffice, ComputerFood, FinalSection],
    mails: [mail1],
    selectedEmail: null,
    selectedDate: null
}


export const introductionlSlice = createSlice({
    name: "introduction",
    initialState,
    reducers: {
        selectCharacter: (state, action) => {
            const { payload: characterIndex } = action;
            state.character = { index: characterIndex };
        },
        changeTask: (state) => {
            if (state.tasks.length === 0) return;
            state.currentTask = state.tasks[0];
            state.tasks.shift();
            return;
        },
        openEmail: (state, action) => {
            const { payload: emailId } = action;
            const selectedEmail = state.mails.find(mail => mail.id === emailId);
            if (!selectedEmail) {
                state.selectedEmail = null;
                return;
            }
            selectedEmail.isChecked = true;
            state.selectedEmail = selectedEmail;
        },
        selectate: (state, action) => {
            const { payload: date } = action;
            state.selectedDate = date;
        }
    }
})

export const introductionActions = introductionlSlice.actions
export default introductionlSlice.reducer