import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";
import { workmateOne, workmateTwo } from "./messages";

export const Task_1 = {
    id: 1,
    type: "click",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_1_1"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};


export const Task_1_1 = {
    id: 2,
    type: "computer",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_2_1"),
    section: "Correo",
    action: "open_mails",
};

export const workmateTask = {
    id: 3,
    type: "walking",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_1"),
    texture: "ClientCall",
    name: "click-workmate",
    position: { x: 6.2, y: 1.4, z: 1.8 },
    isDoble: true,
    action: "click",
}


export const workMateTalk = {
    id: 4,
    type: "talk",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_2"),
    person: "workmate",
    messages: workmateOne
}

export const selectComputer = {
    id: 5,
    type: "click",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_3"),
    position: { x: 5.2, y: 1.2, z: 1.9 },
    name: "click-computer",
    texture: "Click",
    action: "click",
}


export const workmateComputer = {
    id: 6,
    type: "computer",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_companero_tabla_1"),
    section: "workmate",
}
export const workMateTalk2 = {
    id: 7,
    type: "talk",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_final_1"),
    person: "workmate",
    messages: workmateTwo
}

export const TaskGoBackOffice = {
    id: 8,
    type: "going-zone",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_final_2"),
    square: { gtX: 3.4, lsX: 7, gtZ: 2.7, lsZ: 6.1 },
};


export const ComputerFood = {
    id: 9,
    type: "click",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_final_3"),
    position: { x: 5.7, y: 1.11, z: 5.15 },
    name: "click-computer",
    texture: "Click",
    action: "click",
};


export const FinalSection = {
    id: 10,
    type: "computer",
    label: getJsonTextLanguageDisplay("demo_inicio_experencia_task_final_3"),
    section: "final",
}