import { usePDFAnswers } from "../../hooks/cms";
import { getAnswerById } from "../../api";
import { Icons } from "../../assets";

const PDFIcon = ({ results }) => {
    const { printOne } = usePDFAnswers();

    return (
        <div onClick={async () => {
            const { payload: { answer } } = await getAnswerById(results[0]._id);
            await printOne(answer, true);
        }} className='w-full flex justify-center items-center cursor-pointer active:scale-105'>
            <img className='w-5' src={Icons.document} alt="info" />
        </div>
    );
}

export default PDFIcon;