import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { introductionActions } from "../store/slices/introduction";

const useIntroduction = () => {
    const state = useSelector(state => state.introduction);
    const dispatch = useDispatch()

    const selectCharacter = useCallback((character) => {
        dispatch(introductionActions.selectCharacter(character))
    }, [dispatch]);

    const changeTask = useCallback(() => {
        dispatch(introductionActions.changeTask());
    }, [dispatch]);

    const openEmail = useCallback((mailId) => {
        dispatch(introductionActions.openEmail(mailId))
    }, [dispatch]);

    const selectDate = useCallback((dateId) => {
        dispatch(introductionActions.selectate(dateId));
    }, [dispatch]);

    return { state, selectCharacter, changeTask, openEmail, selectDate }
}

export default useIntroduction;