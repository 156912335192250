import closedEye from './closed-eye.png'
import WorkMate from "./Workmate.png";
import Register from './Register.png'
import Woman from "./Woman.png";
import Boss from "./Boss.png";
import Man from "./Man.png";
import table from './table.png'
import question from './question.png'
import closeSession from './closeSession.png'
import user from './user.png';
import exportExcel from './exportExcel.png'
import exportPdf from './exportPdf.png'
import openedEye from './opened-eye.png'
import info from './Info.png';
import document from './document.png';
import remove from './delete.png';

import icon_mexico from './icon_mexico.svg';
import icon_usa from './icon_usa.svg';

const Icons = {
    closedEye,
    WorkMate,
    Register,
    closeSession,
    exportExcel,
    exportPdf,
    table,
    question,
    user,
    Woman,
    Boss,
    Man,
    openedEye,
    info,
    document,
    remove,
    icon_mexico,
    icon_usa
};
export default Icons;
