import { falseUser, userList, invoiceUserList, userListWomen } from "../../data";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";
import { womanReact7 } from "../../store/slices/task/helper";
import { optionalTask } from "../../store/slices/task/state";
import { initStatusQuestions } from "../../types";

const newinvoiceUserList = invoiceUserList.map((invoice) => {
    if (invoice.name === 'Esther Lozano Muñoz') {
        return {
            ...invoice,
            value: womanReact7.shoppingForLess
        }
    }
    return invoice
})

const useTableInfo = (isWomanTask) => {

    const getHasParticipated = (hasParticipated, taskId = -1) => {
        if (taskId === 10) {
            return hasParticipated
                ? getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_asistio")
                : getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_no_asistio");
        }
        return hasParticipated ? getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_participo") : getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_noparticipo");
    };

    const getUser = (taskId, user, isDoneAction) => {
        if (taskId === 10 && user.isWomanClient) {
            user.isTarget = true;
            return user;
        }

        return user.isTarget ? (initStatusQuestions.isClient || isDoneAction ? user : falseUser) : user;
    };
    const getUserBenefitPercent = (taskId, user, isDoneAction) => {
        if (taskId === 10 && user.isWomanClient) {
            user.isTarget = true;
            return user;
        }

        return user.isClient === undefined ? falseUser : user
    };

    const getEmpyValue = (user, isDoneAction, taskId) => {
        if (user.isTarget && !isDoneAction && taskId !== 10) {
            return { age: "------", city: "------" };
        }
        return { age: user.age, city: user.city };
    };

    const getCase = (taskId, element) => {
        if (taskId !== 10) {
            return (
                <>
                    <b>{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_12")} </b>
                    {element.isTarget ? getHasParticipated(initStatusQuestions.DoesParticipate) : getHasParticipated(element.hasParticipated)}
                </>
            );
        }
        if (initStatusQuestions.personRetro1.isWomanClient) {
            return (
                <>
                    <b>{getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_13")}</b>
                    {getHasParticipated(element.hasParticipated, 10)}
                </>
            );
        }
        return null;
    };

    const getTable = (taskId, isClient = false) => {
        if (isWomanTask) {
            // return [...invoiceUserList];
            return [...newinvoiceUserList];
        }
        if (taskId === optionalTask.Task_Optional_3.id && isClient) {
            return [...userListWomen];
        }
        return [...userList];
    };
    return {
        getUser,
        getEmpyValue,
        getCase,
        userList: isWomanTask ? newinvoiceUserList : userList,
        // userList: isWomanTask ? invoiceUserList : userList,
        getTable,
        getUserBenefitPercent,
    };
};

export default useTableInfo;
