import { Icons } from "../assets";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";


const InforButton = ({ action, label = getJsonTextLanguageDisplay("modal_modal_info_ayuda_requisitos_titulo") }) => {
    return (
        <div className="w-full h-10 lg:h-14 flex justify-end  ">
            <div className="w-full flex justify-end items-center relative">
                <img src={Icons.Register} alt="glossary" className="h-full object-cover cursor-pointer helping-button " onClick={action} />
                <p className="absolute bg-white from-strong-retro  h-2/3  items-center px-4 rounded-3xl right-12 helping-text">{label}</p>
            </div>
        </div>
    );
}

export default InforButton;