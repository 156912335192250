import { useEffect, useState } from "react";
import { juanInformation } from "../../data";
import tasks from "../../store/slices/task/tasks";
import { initStatusQuestions, womanTableInformation } from "../../types";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const usePersonClientSection = (taskId) => {
    const [user, setUser] = useState({
        name: getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_2"),
        age: getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_4", { "[replace1]": juanInformation.age }),
        city: getJsonTextLanguageDisplay("tarea_1_ver_datos_cliente_caso_1_pantalla_cliente_desactualizado_info_columna_6"),
        isClient: initStatusQuestions.isClient,
        hasParticipated: true,
    });
    useEffect(() => {
        switch (taskId) {
            case 10: {
                const { age, city, name, hasParticipated, isClient = false } = initStatusQuestions.personRetro1;
                setUser({ name, city, age, hasParticipated, isClient });
                break;
            }
            case tasks.TaskRegister.id: {
                const { age, city, name, job } = womanTableInformation;
                setUser({ name, city, age, job });
                break;
            }
            default:
        }
    }, [taskId]);
    return { user };
};

export default usePersonClientSection;
