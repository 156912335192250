import { useCallback, useState } from "react";
import { getFormattedAnswer } from "../helpers/answerStructure";
import { generateStructurePdfAndExcel } from "./cms/fileStructure";

const useExcelAnswers = () => {
    const [excelData, setExcelData] = useState([])

    const getAnswers = useCallback((results) => {
        const { newResults } = getFormattedAnswer(results);

        const structurePdf = generateStructurePdfAndExcel(newResults[1]?.code || "es", newResults)

        const data = structurePdf.map((result) => {
            const resultEntries = Object.entries(result);
            const isTotal = resultEntries.find(([key]) => key === "total");
            if (!isTotal) return result;
            return { ...result, score: `${result.score}`, retro: result.range }
        })
        setExcelData(data)
    }, [])
    return { getAnswers, excelData, };
}

export default useExcelAnswers;