import { Graph1, Graph1En, Graph2, Graph2En } from "../assets";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";

const Data1 = [
    { title: getJsonTextLanguageDisplay("tarea_3_tabla_1_media"), data: 43.4 },
    { title: getJsonTextLanguageDisplay("tarea_3_tabla_1_dev_est"), data: 12.35 },
    { title: getJsonTextLanguageDisplay("tarea_3_tabla_1_n"), data: 2248 },
];

const Data2 = [
    { title: getJsonTextLanguageDisplay("tarea_3_tabla_1_media"), data: 623 },
    { title: getJsonTextLanguageDisplay("tarea_3_tabla_1_dev_est"), data: 93 },
];

const graph1 = {
    img: localStorage.getItem("language") === "es" ? Graph1 : Graph1En,
    arrData: Data1,
};
const graph2 = {
    img: localStorage.getItem("language") === "es" ? Graph2 : Graph2En,
    arrData: Data2,
};

const getClientGrapth = (isClient = true) => {
    return !isClient ? graph1 : graph2;
};
export { getClientGrapth };
