export const modulo2Seccion1IngresarVistaTabla = {
    tarea_1_revisa_computadora_na_escritorio_ambiente3D_card_texto_titulo_1: {
        es: "Tú",
        en: "You"
    },
    tarea_1_revisa_computadora_na_escritorio_ambiente3D_card_texto_contenido_1: {
        es: "Con respecto a su información, ha sido verificada en el sistema. Ahora revisaré si ha sido llenada correctamente.",
        en: "With regards to your information, it has been verified in the system. Now, I will check if it has been filled out correctly."
    },
    tarea_1_revisa_computadora_na_escritorio_ambiente3D_boton_plegable_tarea_1: {
        es: "Revisa tu computadora.",
        en: "Check your computer."
    },
    // menu
    tarea_2_ingresa_seccion_clientes_na_menu_interfaz2D_boton_plegable_tarea_1: {
        es: "Ingresa a la sección de clientes.",
        en: "Enter the clients section."
    },
    tarea_2_ingresa_seccion_clientes_na_menu_interfaz2D_boton_plegable_tarea_2: {
        es: "Ingresa a la sección de registro.",
        en: "Enter the registration section."
    },
    tarea_2_ingresa_seccion_clientes_na_menu_interfaz2D_boton_1: {
        es: "Regresar",
        en: "Back"
    },

    // menu 2
    tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_boton_plegable_tarea_1: {
        es: "Ingresa a la sección de documentación.",
        en: "Enter the documentation section."
    },
    tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_card_documentacion_1: {
        es: "Documentación",
        en: "Documentation"
    },
    tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_card_registro_1: {
        es: "Registro",
        en: "Registration"
    },
    tarea_3_ingresa_seccion_registro_na_menu2_interfaz2D_card_histograma_1: {
        es: "Histograma",
        en: "Histogram"
    },
}
