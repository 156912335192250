import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { validateLoginDate } from "../../form/register";
import { getErrorMessage } from "../../api/helpers";
import { setToken } from "../../helpers/loginToken";
import { Button } from "../../components";
import { verifyLogin } from "../../api";
import { Icons, } from "../../assets";

const FormContainer = ({ setLoading }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(true);
    const navigate = useNavigate();


    const onSubmit = async (formData, { setErrors }) => {
        setLoading(true)
        try {
            const { email, password } = formData;
            const response = await verifyLogin(email, password);
            if (!response || !response.payload) {
                setErrors("Usuario/Contraseña incorrectos")
            }
            const { payload: { token } } = response;
            localStorage.setItem("email", email);
            setToken(token);
            navigate("/administration");
        } catch (error) {
            const message = getErrorMessage(error);
            setErrors(message)
        } finally {
            setLoading(false)
        }
    };

    const showHidePassword = () => {
        setIsPasswordVisible(value => !value);
    }

    return (
        <Formik

            initialValues={{
                email: "",
                password: ""
            }}
            validationSchema={validateLoginDate}
            onSubmit={onSubmit}

        >
            {({ handleSubmit, handleChange, values, errors, setErrors }) => {
                const isError = !errors || typeof errors !== "object";
                return (
                    <Form
                        className=" flex flex-col gap-2 home-button   grow"
                        onSubmit={handleSubmit}
                    >
                        <div className="w-full  flex flex-col gap-2">
                            <div className="h-14">
                                <Field
                                    className="w-full h-8  bg-background-screen border-b-2 text-white border-white focus:border-t-0 focus:border-x-0 placeholder:text-white    "
                                    type="text"
                                    placeholder={"Correo electrónico"}
                                    name={"email"}
                                    onChange={handleChange}
                                    value={values.email}
                                    autoComplete="none"
                                />
                                <ErrorMessage name={"email"}>
                                    {(msg) => <p style={{ color: "red" }} >{msg}</p>}
                                </ErrorMessage>
                            </div>
                            <div className="h-14 relative mb-14">

                                <Field
                                    className="w-full h-8  bg-background-screen border-b-2 text-white border-white focus:border-t-0 focus:border-x-0 placeholder:text-white"
                                    type={isPasswordVisible ? "password" : "text"}
                                    placeholder={"Contraseña"}
                                    name={"password"}
                                    onChange={handleChange}
                                    value={values.password}
                                    autoComplete="none"
                                />
                                <ErrorMessage name={"password"}>
                                    {(msg) => <p style={{ color: "red" }} >{msg}</p>}
                                </ErrorMessage>
                                <img onClick={showHidePassword} src={isPasswordVisible ? Icons.closedEye : Icons.openedEye} alt="Mostrar/Ocultar contraseña" className="w-6 h-6 absolute right-0 top-0 cursor-pointer transition-all   active:scale-[1.1]" />
                                {isError && <p style={{ color: "red" }}>{errors}</p>}

                            </div>

                            <div className="w-full flex justify-center ">
                                <Button onClick={(event) => {
                                    event.preventDefault();
                                    onSubmit(values, { setErrors })

                                }} type="submit"  >Iniciar sesión</Button>

                            </div>
                        </div>


                    </Form>
                )
            }}
        </Formik>
    );
}

export default FormContainer;