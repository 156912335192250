const reactiveNumber1 = {
    "1": {
        answerLabel: [
            {
                textValidateByTraduction: ["Entregar electrodoméstico.", "Deliver home appliance."],
                keyByJsonTranslate: "tarea_1_base_promocion_card_contenido_opc_1"
            },
            {
                textValidateByTraduction: ["Entregar tarjeta de $200 pesos.", "Deliver $200 pesos gift card."],
                keyByJsonTranslate: "tarea_1_base_promocion_card_contenido_opc_2"
            },
            {
                textValidateByTraduction: ["Participará en futuras promociones.", "Participate in future promotions."],
                keyByJsonTranslate: "tarea_1_base_promocion_card_contenido_opc_3"
            },
        ]
    }
}

const reactiveNumber1_1 = {
    "1.1": {
        answerLabel: [
            {
                textValidateByTraduction: ["Autorizó la tasa preferencial", "No autorizó la tasa preferencial"],
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Autorizó la tasa preferencial"],
                            codeTranslate: {
                                es: "Autorizó la tasa preferencial",
                                en: ""
                            }
                        },
                        {
                            textValidateByTraduction: ["No autorizó la tasa preferencial"],
                            codeTranslate: {
                                es: "No autorizó la tasa preferencial",
                                en: ""
                            }
                        },
                    ]
                }
            },
            {
                textValidateByTraduction: ["Autorizó  la promoción de la feria de mujeres", "No autorizó  la promoción de la feria de mujeres"],
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Autorizó  la promoción de la feria de mujeres"],
                            codeTranslate: {
                                es: "Autorizó  la promoción de la feria de mujeres",
                                en: ""
                            }
                        },
                        {
                            textValidateByTraduction: ["No autorizó  la promoción de la feria de mujeres"],
                            codeTranslate: {
                                es: "No autorizó  la promoción de la feria de mujeres",
                                en: ""
                            }
                        },
                    ]
                }
            },
        ]
    }
}


const array2 = [
    "Imp-Txn, Sdo_vigente_visa, Num_prod_cte, Calificación_cte, Score Ced, Est_compra, Edad_años, Etapa_vida",
    "Cust_id, Cve_emp, Imp_Txn, Sdo_vigente_vista, Num_prod_cte, Score Cred, Edad_años, Cve_actividad"
]

const reactiveNumber2 = {
    "2": {
        answerLabel: [
            {
                textValidateByTraduction: array2,
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Imp-Txn, Sdo_vigente_visa, Num_prod_cte, Calificación_cte, Score Ced, Est_compra, Edad_años, Etapa_vida"],
                            codeTranslate: {
                                es: "Imp-Txn, Sdo_vigente_visa, Num_prod_cte, Calificación_cte, Score Ced, Est_compra, Edad_años, Etapa_vida",
                                en: ""
                            }
                        },
                        {
                            textValidateByTraduction: ["Cust_id, Cve_emp, Imp_Txn, Sdo_vigente_vista, Num_prod_cte, Score Cred, Edad_años, Cve_actividad"],
                            codeTranslate: {
                                es: "Cust_id, Cve_emp, Imp_Txn, Sdo_vigente_vista, Num_prod_cte, Score Cred, Edad_años, Cve_actividad",
                                en: ""
                            }
                        },
                    ]
                }
            },
        ]
    }
}

const array2_1 = ["Num_prod_cte, Calificación_cte, Score Ced, Edad_años, Etapa_vida, Cve_emp, Cve_sexo, Cve_actividad"]

const reactiveNumber2_1 = {
    "2.1": {
        answerLabel: [
            {
                textValidateByTraduction: array2_1,
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Imp-Txn, Sdo_vigente_visa, Num_prod_cte, Calificación_cte, Score Ced, Est_compra, Edad_años, Etapa_vida"],
                            codeTranslate: {
                                es: "Imp-Txn, Sdo_vigente_visa, Num_prod_cte, Calificación_cte, Score Ced, Est_compra, Edad_años, Etapa_vida",
                                en: ""
                            }
                        },
                    ]
                }
            },
        ],
    }
}



const reactiveNumber3 = {
    "3": {
        answerLabel: [
            {
                textValidateByTraduction: ["Ofrecer la tarjeta crédito.", "Offer the credit card."],
                keyByJsonTranslate: "tarea_4_decidir_ofrecer_tarjeta_usuario_card_texto_opc_1"
            },
            {
                textValidateByTraduction: ["Ofrecer una cuenta de ahorro con aval.", "Offer a savings account with a guarantor."],
                keyByJsonTranslate: "tarea_4_decidir_ofrecer_tarjeta_usuario_card_texto_opc_2"
            },
            {
                textValidateByTraduction: ["Ofrecer un préstamo de $100,000 pesos.", "Offer a loan of $100,000 pesos."],
                keyByJsonTranslate: "tarea_4_decidir_ofrecer_score_usuario_card_texto_opc_1"
            },
            {
                textValidateByTraduction: ["Ofrecer un préstamo de $500,000 pesos.", "Offer a loan of $500,000 pesos."],
                keyByJsonTranslate: "tarea_4_decidir_ofrecer_score_usuario_card_texto_opc_2"
            },
        ]
    },
}

const reactiveNumber4 = {
    "4": {
        answerLabel: [
            {
                textValidateByTraduction: ["Crédito hipotecario con letras de crédito.", "Mortgage credit with credit letters."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_hipotecario_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Mutuo hipotecario endosable.", "Endorsable mortgage loan."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_hipotecario_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Mutuo hipotecaria no endosable.", "Non-endorsable mortgage loan."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_hipotecario_interfaz2D_card_3"
            },
            {
                textValidateByTraduction: ["Crédito convencional automotriz.", "Conventional automotive credit."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_automotriz_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Crédito inteligente.", "Smart credit."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_automotriz_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Crédito leasing o arrendamiento.", "Leasing or leasing credit."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_automotriz_interfaz2D_card_3"
            },
            {
                textValidateByTraduction: ["Análisis gráfico y numérico que muestra las características de los clientes actuales", "Graphical and numerical analysis showing the characteristics of current clients."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Análisis distribucional de los estadísticos que provienen de la muestra.", "Distributional analysis of statistics from the sample."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Análisis inferencial que muestra las estimaciones de los parámetros de clientes futuros.", "Inferential analysis showing estimates of parameters for future clients."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_3"
            },
            {
                textValidateByTraduction: ["Seguro de vida temporal.", "Term life insurance."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Seguro de ahorros para casos de supervivencia o jubilación.", "Savings insurance for survival or retirement."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Seguro de vida entera.", "Whole life insurance."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_3"
            },
        ],
        training: [
            {
                textValidateByTraduction: [
                    "El estudiante no comprendió el caso de estudio",
                    "The student did not understand the case study"
                ],
                keyByJsonTranslate: "reactivo4_student_nive_competencia_multiple"
            },
            {
                textValidateByTraduction: [
                    "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas.",
                    "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas. ",
                    "The student understands the case study because they chose the correct folder and understand that the descriptive analysis of customer information is performed using graphical and numerical tools."
                ],
                keyByJsonTranslate: "reactivo4_analisis_grafico_numerico_nivel"
            },
        ],
        retro: [
            {
                textValidateByTraduction: [
                    "El tema de la junta es la posibilidad de lanzar una nueva tarjeta de crédito con beneficios especiales, así como se indicó en el laboratorio de práctica",
                    "The topic of the meeting is the possibility of launching a new credit card with special benefits, as indicated in the practice lab."
                ],
                keyByJsonTranslate: "reactivo4_student_retroalientacion_multiple"
            },
            {
                textValidateByTraduction: ["Selección correcta", "Selacción correcta", "Correct answer"],
                keyByJsonTranslate: "reactivo4_analisis_grafico_numerico_retro"
            },
        ],
    },
}

const reactiveNumber4_1 = {
    "4.1": {
        answerLabel: [
            {
                textValidateByTraduction: ["Crédito hipotecario con letras de crédito.", "Mortgage credit with credit letters."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_hipotecario_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Mutuo hipotecario endosable.", "Endorsable mortgage loan."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_hipotecario_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Mutuo hipotecaria no endosable.", "Non-endorsable mortgage loan."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_hipotecario_interfaz2D_card_3"
            },
            {
                textValidateByTraduction: ["Crédito convencional automotriz.", "Conventional automotive credit."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_automotriz_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Crédito inteligente.", "Smart credit."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_automotriz_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Crédito leasing o arrendamiento.", "Leasing or leasing credit."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_automotriz_interfaz2D_card_3"
            },
            {
                textValidateByTraduction: ["Análisis gráfico y numérico que muestra las características de los clientes actuales", "Graphical and numerical analysis showing the characteristics of current clients."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Análisis distribucional de los estadísticos que provienen de la muestra.", "Distributional analysis of statistics from the sample."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Análisis inferencial que muestra las estimaciones de los parámetros de clientes futuros.", "Inferential analysis showing estimates of parameters for future clients."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_tarjeta_credito_interfaz2D_card_3"
            },
            {
                textValidateByTraduction: ["Seguro de vida temporal.", "Term life insurance."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_1"
            },
            {
                textValidateByTraduction: ["Seguro de ahorros para casos de supervivencia o jubilación.", "Savings insurance for survival or retirement."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_2"
            },
            {
                textValidateByTraduction: ["Seguro de vida entera.", "Whole life insurance."],
                keyByJsonTranslate: "tarea_3_elegir_documentos_seguro_vida_interfaz2D_card_3"
            },
        ],
        training: [
            {
                textValidateByTraduction: [
                    "El estudiante no comprendió el caso de estudio",
                    "The student did not understand the case study"
                ],
                keyByJsonTranslate: "reactivo4_student_nive_competencia_multiple"
            },
            {
                textValidateByTraduction: [
                    "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas.",
                    "El estudiante comprende el caso de estudio porque eligió la carpeta correcta y comprende que el análisis descriptivo de la información de los clientes se realiza utilizando herramientas gráficas y numéricas. ",
                    "The student understands the case study because they chose the correct folder and understand that the descriptive analysis of customer information is performed using graphical and numerical tools."
                ],
                keyByJsonTranslate: "reactivo4_analisis_grafico_numerico_nivel"
            },
        ],
        retro: [
            {
                textValidateByTraduction: [
                    "El tema de la junta es la posibilidad de lanzar una nueva tarjeta de crédito con beneficios especiales, así como se indicó en el laboratorio de práctica",
                    "The topic of the meeting is the possibility of launching a new credit card with special benefits, as indicated in the practice lab."
                ],
                keyByJsonTranslate: "reactivo4_student_retroalientacion_multiple"
            },
            {
                textValidateByTraduction: ["Selección correcta", "Selacción correcta", "Correct answer"],
                keyByJsonTranslate: "reactivo4_analisis_grafico_numerico_retro"
            },
        ],
    }
}

const reactiveNumber5 = {
    "5": {
        answerLabel: [
            {
                textValidateByTraduction: ["Cliente 1", "Cliente 2", "Cliente 3"],
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Cliente 1"],
                            codeTranslate: {
                                es: "Cliente 1",
                                en: "Client 1"
                            }
                        },
                        {
                            textValidateByTraduction: ["Cliente 2"],
                            codeTranslate: {
                                es: "Cliente 2",
                                en: "Client 2"
                            }
                        },
                        {
                            textValidateByTraduction: ["Cliente 3"],
                            codeTranslate: {
                                es: "Cliente 3",
                                en: "Client 3"
                            }
                        },
                    ]
                }
            },
        ]
    }
}

const reactiveNumber5_1 = {
    "5.1": {
        answerLabel: [
            {
                textValidateByTraduction: ["Cliente 1", "Cliente 2", "Cliente 3"],
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Cliente 1"],
                            codeTranslate: {
                                es: "Cliente 1",
                                en: "Client 1"
                            }
                        },
                        {
                            textValidateByTraduction: ["Cliente 2"],
                            codeTranslate: {
                                es: "Cliente 2",
                                en: "Client 2"
                            }
                        },
                        {
                            textValidateByTraduction: ["Cliente 3"],
                            codeTranslate: {
                                es: "Cliente 3",
                                en: "Client 3"
                            }
                        },
                    ]
                }
            },
        ]
    }
}

const array6_1 = [
    "Proporción de clientes con trabajo no renumerado.",
    "Cantidad de clientes para cada tipo de actividad por sexo.",
]

const reactiveNumber6_1 = {
    "6.1": {
        answerLabel: [
            {
                textValidateByTraduction: array6_1,
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Proporción de clientes con trabajo no renumerado."],
                            codeTranslate: {
                                es: "Proporción de clientes con trabajo no renumerado.",
                                en: ""
                            }
                        },
                        {
                            textValidateByTraduction: ["Cantidad de clientes para cada tipo de actividad por sexo."],
                            codeTranslate: {
                                es: "Cantidad de clientes para cada tipo de actividad por sexo.",
                                en: ""
                            }
                        },
                    ]
                },
            },
            {
                textValidateByTraduction: ["Tipo de actividad de los clientes actuales", "Type of activity of current clients"],
                keyByJsonTranslate: "graficas_1_1_caso_1_2_card_titulo_grafica_1"
            },
            {
                textValidateByTraduction: ["Género de los clientes actuales", "Gender of current clients"],
                keyByJsonTranslate: "graficas_1_2_caso_1_2_card_titulo_grafica_1"
            },
            {
                textValidateByTraduction: ["Clientes por género dado su tipo de actividad", "Clients by gender given their type of activity"],
                keyByJsonTranslate: "graficas_1_3_caso_1_2_card_titulo_grafica_1"
            },
        ]
    }
}


const array6_2 = [
    "Proporción de clientes que realizan sus compras en mercados, supermercados, tiendas de convivencia, etc.",
]

const reactiveNumber6_2 = {
    "6.2": {
        answerLabel: [
            {
                textValidateByTraduction: array6_2,
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Proporción de clientes que realizan sus compras en mercados, supermercados, tiendas de convivencia, etc."],
                            codeTranslate: {
                                es: "Proporción de clientes que realizan sus compras en mercados, supermercados, tiendas de convivencia, etc.",
                                en: ""
                            }
                        }
                    ]
                }
            },
            {
                textValidateByTraduction: [
                    "Cantidad de personas que realiza sus compras en cada establecimiento por entidad",
                    "Number of people who shop at each establishment by state."
                ],
                keyByJsonTranslate: "graficas_2_1_caso_1_2_card_titulo_grafica_2"
            },
            {
                textValidateByTraduction: [
                    "Proporción de clientes que realizan sus compras en los diferentes establecimientos",
                    "Proportion of customers who shop at different establishments"
                ],
                keyByJsonTranslate: "graficas_2_2_caso_1_2_card_titulo_grafica_2"
            },
            {
                textValidateByTraduction: [
                    "Cantidad de personas que realiza sus compras en cada establecimiento por sexo",
                    "Number of people shopping at each establishment by gender."
                ],
                keyByJsonTranslate: "graficas_2_3_caso_1_2_card_titulo_grafica_2"
            },
        ]
    }
}

const array6_3 = [
    "Cantidad de productos financieros por clientes.",
]

const reactiveNumber6_3 = {
    "6.3": {
        answerLabel: [
            {
                textValidateByTraduction: array6_3,
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Cantidad de productos financieros por clientes."],
                            codeTranslate: {
                                es: "Cantidad de productos financieros por clientes.",
                                en: ""
                            }
                        },
                    ]
                }
            },
            {
                textValidateByTraduction: [
                    "Cantidad de productos financieros utilizados por cliente.",
                    "Number of financial products used per customer."
                ],
                keyByJsonTranslate: "graficas_3_3_caso_1_2_card_titulo_grafica_3"
            },
            {
                textValidateByTraduction: [
                    "Cantidad de clientes que realizan transacciones por cantidades menores a $1500 pesos por día.",
                    "Number of customers who make transactions for amounts less than $1500 pesos per day."
                ],
                keyByJsonTranslate: "graficas_3_1_caso_1_2_card_titulo_grafica_3"
            },
            {
                textValidateByTraduction: [
                    "Cantidad de clientes que realizan desde 1 hasta más de 5 transacciones al día.",
                    "Number of customers who make from 1 to more than 5 transactions per day."
                ],
                keyByJsonTranslate: "graficas_3_2_caso_1_2_card_titulo_grafica_3"
            },
        ]
    }
}


const reactiveNumber6_4 = {
    "6.4": {
        answerLabel: [
            {
                textValidateByTraduction: [
                    "Cantidad de clientes que compran en los diferentes establecimientos y su calificación crediticia.",
                    "Number of customers shopping at different establishments and their credit score."
                ],
                keyByJsonTranslate: "graficas_4_1_caso_1_2_card_titulo_grafica_4"
            },
        ]
    }
}

const reactiveNumber6_5 = {
    "6.5": {
        answerLabel: [
            {
                textValidateByTraduction: [
                    "Saldo vigente promedio por calificación crediticia VS donde compran.",
                ],
                keyByJsonTranslate: null,
                substituteTextTranslation: {
                    answerLabel: [
                        {
                            textValidateByTraduction: ["Saldo vigente promedio por calificación crediticia VS donde compran."],
                            codeTranslate: {
                                es: "Saldo vigente promedio por calificación crediticia VS donde compran.",
                                en: ""
                            }
                        },
                    ]
                }
            },
            {
                textValidateByTraduction: [
                    "Saldo vigente promedio por calificación crediticia vs donde compran ",
                    "Average current balance by credit rating vs where they shop"
                ],
                keyByJsonTranslate: "graficas_5_1_caso_1_2_card_titulo_grafica_4"
            },
        ]
    }
}


const reactiveNumber7 = {
    "7": {
        answerLabel: [
            {
                textValidateByTraduction: [
                    "Ofrecer la nueva tarjeta de crédito.",
                    "Offer the new credit card."
                ],
                keyByJsonTranslate: "task_ofrecer_tarjeta_mujer"
            },
            {
                textValidateByTraduction: [
                    "No ofrecer la nueva tarjeta de crédito.",
                    "Do not offer the new credit card."
                ],
                keyByJsonTranslate: "task_no_ofrecer_tarjeta_mujer"
            },
        ]
    }
}


const reactiveNumber8 = {
    "8": {
        answerLabel: [
            {
                textValidateByTraduction: [
                    "No ofrecer la nueva tarjeta de crédito.",
                    "Do not offer the new credit card."
                ],
                keyByJsonTranslate: "task_promocion_opcion_1"
            },
            {
                textValidateByTraduction: [
                    "Ofrecer la nueva tarjeta de crédito.",
                    "Offer the new credit card."
                ],
                keyByJsonTranslate: "task_promocion_opcion_2"
            },
        ]
    }
}



export const keysReactive = {
    ...reactiveNumber1,
    ...reactiveNumber1_1,
    ...reactiveNumber2,
    ...reactiveNumber2_1,
    ...reactiveNumber3,
    ...reactiveNumber4,
    ...reactiveNumber4_1,
    ...reactiveNumber5,
    ...reactiveNumber5_1,
    ...reactiveNumber6_1,
    ...reactiveNumber6_2,
    ...reactiveNumber6_3,
    ...reactiveNumber6_4,
    ...reactiveNumber6_5,
    ...reactiveNumber7,
    ...reactiveNumber8,
}


const regexText = {
    "Table": {
        es: `Table cambiar`,
        en: ``,
    },
    "Reactivo6": {
        es: "Reactivo cambiar",
        en: "Reagent cambiar"
    },
    "Reagent6": {
        es: "Reactivo cambiar",
        en: "Reagent cambiar"
    }
}


export const replaceTextToTranslate = (key = "", codeLanguage = "es", replaceText = "") => {
    let text = ""
    text = regexText[key][codeLanguage]
    text = text.replace(`cambiar`, replaceText)
    return text
}