import { LogoEscuela, Logos } from "../assets";

const LogoIntroduction = () => {
    return (
        <div className="flex justify-center items-center gap-[42px]">
            <div className="w-[232px] lg:w-[232px] h-full ">
                <img src={LogoEscuela} alt="Tec de monterrey logo" className="w-full object-cover" />
            </div>
            <img src={Logos.NovusLogo} alt="novus loco" className="w-[214px]" />
        </div>
    );
}

export default LogoIntroduction;