import { memo } from "react";
import { useDispatch } from "react-redux";
import { useStoreComputer, useStoreGeneral } from "../../hooks/store";
import { ActiveLock, ActiveInterrogation, Lock } from "../../assets";
import { setInstructionsStatus } from "../../store/slices/general";

const ModelButtons = () => {
    const dispatch = useDispatch();
    const { isActive } = useStoreComputer();
    const { DoesHaveMovement, loading } = useStoreGeneral();

    if (loading || isActive) return;
    return (
        <>
            <div className="absolute top-[52px] right-[47px] flex flex-col items-center">
                <div className="w-[84px] h-[84px] mb-[12px]"></div>
                <div onClick={() => dispatch(setInstructionsStatus(true))} className="w-[54px] h-[54px] mb-[36px] cursor-pointer color-after">
                    <img src={ActiveInterrogation} alt="When this icon is blue the user cannot use controls, now it is blue" />
                </div>
                <div className={`w-[54px] h-[54px] ${DoesHaveMovement ? "cursor-pointer" : "cursor-not-allowed"} `}>
                    <img src={DoesHaveMovement ? ActiveLock : Lock} alt="When this icon is blue the user cannot use controls, now it is blue" />
                </div>
            </div>
        </>
    );
};

export default memo(ModelButtons);
