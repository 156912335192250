import { textsToTranslate } from "../../language";
import { keysReactive, replaceTextToTranslate } from "./typeReactiveReport";


const getTextByJson = (jsonText = {}, keyByJsonTranslate = "", codeLanguage = "") => {
    return jsonText[keyByJsonTranslate][codeLanguage]
}

const getSubstituteTextByLanguage = (codeLanguage = "es", keyArrayName = "", dataReactiveText = {}, textAnswer = "") => {
    let text = ""
    dataReactiveText[keyArrayName].forEach((element) => {
        if (!element.textValidateByTraduction.includes(textAnswer)) {
            return
        }
        text = getTextByJson(element, "codeTranslate", codeLanguage)
    })
    return text
}

const getJsonTextByLanguage = (codeLanguage = "es", keyArrayName = "", dataReactiveText = {}, textAnswer = "") => {
    let text = `${textAnswer}`
    dataReactiveText[keyArrayName].forEach((element) => {
        if (!element.textValidateByTraduction.includes(textAnswer)) {
            return
        }
        //* Key
        if (element.keyByJsonTranslate) {
            text = getTextByJson(textsToTranslate, element.keyByJsonTranslate, codeLanguage)
        }
        //* No Key
        else {
            text = getSubstituteTextByLanguage(codeLanguage, keyArrayName, element.substituteTextTranslation, textAnswer)
        }
    })
    return text
}


const matchTextByTextsToTranslate = (codeLanguage = "es", textAnswer = "", keyArrayName = "") => {
    let text = `${textAnswer}`
    let isRegex = {
        context: false,
        question: false
    }
    let arraysEquals = []

    if (["question"].includes(keyArrayName)) {
        const splitTextMatchRegex = textAnswer.split(" ")[1] || ""
        isRegex.question = ["6.1", "6.2", "6.3", "6.4", "6.5"].includes(splitTextMatchRegex)
    }

    if (isRegex.question) {
        if (codeLanguage === "es") {
            text = replaceTextToTranslate("Reactivo6", codeLanguage, textAnswer.split(" ")[1] || "")

        } else {
            text = replaceTextToTranslate("Reagent6", codeLanguage, textAnswer.split(" ")[1] || "")
        }
        return text
    }

    if (["context"].includes(keyArrayName)) {
        const splitTextMatchRegex = textAnswer.split(" ")[0] || ""
        isRegex.context = ["Tabla"].includes(splitTextMatchRegex)
    }

    if (isRegex.context) {
        text = replaceTextToTranslate("Table", codeLanguage, textAnswer.split(" ")[1] || "3")
        return text
    }

    Object.entries(textsToTranslate).forEach(([key, value]) => {
        const findTextByCode = value["es"] === textAnswer

        if (!findTextByCode) {
            return
        }

        arraysEquals.push({
            data: {
                key,
                text: value[codeLanguage],
                value
            }
        })
    })

    if (arraysEquals.length === 0) {
        return text
    }

    // if (arraysEquals.length > 1) {
    //     console.log('son mas de 1', arraysEquals);
    // }

    return arraysEquals[0].data.text
}

const getTextCorrectByLanguage = (codeLanguage = "es", findReactiveNumber = {}, answer = {}) => {

    const usedKeys = []

    let textCheck = {}

    //? Codigo
    if (findReactiveNumber.answerLabel) {
        const getTextAnswerLabel = getJsonTextByLanguage(codeLanguage, "answerLabel", findReactiveNumber, answer["answerLabel"])
        textCheck["answerLabel"] = getTextAnswerLabel
    }

    if (findReactiveNumber.retro) {
        const getTextRetro = getJsonTextByLanguage(codeLanguage, "retro", findReactiveNumber, answer["retro"])
        textCheck["retro"] = getTextRetro
        usedKeys.push("retro")
    }

    if (findReactiveNumber.training) {
        const getTextTraining = getJsonTextByLanguage(codeLanguage, "training", findReactiveNumber, answer["training"])
        textCheck["training"] = getTextTraining
        usedKeys.push("training")
    }

    if (findReactiveNumber.status) {
        const getTextStatus = getJsonTextByLanguage(codeLanguage, "status", findReactiveNumber, answer["status"])
        textCheck["status"] = getTextStatus
        usedKeys.push("status")
    }

    //? Normales

    const getTextJsonByQuestionName = matchTextByTextsToTranslate(codeLanguage, answer["question"], "question")
    textCheck["question"] = getTextJsonByQuestionName

    // const getTextJsonByContext = matchTextByTextsToTranslate(codeLanguage, answer["context"], "context")
    // textCheck["context"] = getTextJsonByContext
    textCheck["context"] = ""

    if (!usedKeys.includes("status")) {
        const getTextJsonByStatus = matchTextByTextsToTranslate(codeLanguage, answer["status"], "status")
        textCheck["status"] = getTextJsonByStatus
    }

    if (!usedKeys.includes("retro")) {
        const getTextJsonByRetro = matchTextByTextsToTranslate(codeLanguage, answer["retro"], "retro")
        textCheck["retro"] = getTextJsonByRetro
    }

    if (!usedKeys.includes("training")) {
        const getTextJsonByTraining = matchTextByTextsToTranslate(codeLanguage, answer["training"], "training")
        textCheck["training"] = getTextJsonByTraining
    }

    return textCheck
}

const getExtraTextToTranslate = (codeLanguage = "es", identifier = "", answer = {}) => {
    let extraJson = {}
    const dataChangue = {
        "total": {
            range: {
                textValidate: [
                    textsToTranslate["reactivo_total_calificaion_incipiente"],
                    textsToTranslate["reactivo_total_calificaion_basico"],
                    textsToTranslate["reactivo_total_calificaion_solido"],
                    textsToTranslate["reactivo_total_calificaion_destacado"]
                ]
            },
        },
    }

    if (["bonus"].includes(identifier)) {
        const bonusTranslate = {
            es: "Resultado final",
            en: "Final result"
        }
        return {
            ...extraJson,
            retro: `${bonusTranslate[codeLanguage]}:${answer.retro.split(":")[1]}`
        }
    }

    if (["columnExtraData"].includes(identifier)) {
        const resultClear = {
            resultados: {
                es: "Resultados.-",
                en: "Results.-"
            },
            resultadoFinal: {
                es: "Resultado final: ",
                en: "Final result: "
            },
            matricula: {
                es: "Matricula:",
                en: "Registration:"
            },
            bonus: {
                es: "Bonus:",
                en: "Bonus:"
            },
            total: {
                es: "Total:",
                en: "Total:"
            },
            estatus: {
                es: "Estatus:",
                en: "Status:"
            }
        }

        return {
            ...extraJson,
            context: `${resultClear["resultados"][codeLanguage]}`,
            answerLabel: `${resultClear["matricula"][codeLanguage]}`,
            status: `${resultClear["bonus"][codeLanguage]}`,
            score: `${resultClear["total"][codeLanguage]}`,
            retro: `${resultClear["resultadoFinal"][codeLanguage]}`,
            training: `${resultClear["estatus"][codeLanguage]}`
        }
    }

    if (["extraData"].includes(identifier)) {
        const dataMatch = dataChangue["total"]["range"]
        let valueTranslate = answer.training
        const isExistValidate = Object.values(dataMatch.textValidate).map((text) => text.es).includes(valueTranslate)
        if (isExistValidate) {
            const textTranslate = Object.values(dataMatch.textValidate).find((text) => text.es === valueTranslate)
            valueTranslate = textTranslate[codeLanguage]
        }
        return {
            ...extraJson,
            training: valueTranslate
        }
    }




    // total

    Object.entries(answer).forEach(([key, value]) => {
        const dataMatch = dataChangue[identifier][key]
        if (!dataMatch) {
            return
        }

        // checar el validador

        const isExistValidate = Object.values(dataMatch.textValidate).map((text) => text.es).includes(value)

        if (!isExistValidate) {
            return
        }


        const textTranslate = Object.values(dataMatch.textValidate).find((text) => text.es === value)

        extraJson = {
            ...extraJson,
            [key]: textTranslate[codeLanguage]
        }
    })

    return extraJson
}

export const generateStructurePdfAndExcel = (codeLanguage = "es", answers = []) => {
    return answers
        .filter((answer) => !answer.hasOwnProperty("code"))
        .map(({ identifierPdf, numberReactive, ...answer }) => {
            const extraIdentifier = identifierPdf

            if (extraIdentifier) {
                let extraJson = getExtraTextToTranslate(codeLanguage, extraIdentifier, answer)
                return {
                    ...answer,
                    ...extraJson
                }
            }

            const reactiveIdentifier = numberReactive

            if (!reactiveIdentifier) {
                return {
                    ...answer
                }
            }

            const findReactiveNumber = keysReactive[reactiveIdentifier]

            const textForReport = getTextCorrectByLanguage(codeLanguage, findReactiveNumber, answer)

            return {
                ...answer,
                ...textForReport
            }
        })

}