import { createSlice } from "@reduxjs/toolkit";
import { imgs } from "../../../assets";
import { getInitState } from "./utils";

export const userSlice = createSlice({
    name: "user",
    initialState: getInitState(),
    reducers: {
        saveDocument: (state) => {
            const { meetingDocuments } = state.questions;
            const { correctAnswer, retro } = meetingDocuments;
            if (retro.times > 1) return;
            const isCorrect = meetingDocuments.selected.id === correctAnswer;
            meetingDocuments.answer = meetingDocuments.selected;
            meetingDocuments.isCorrect = isCorrect;
            const { label } = meetingDocuments.selected;
            meetingDocuments.answerLabel = label

            if (retro.times === 0) meetingDocuments.file = meetingDocuments.selected
            else meetingDocuments.retro.file = meetingDocuments.selected
            if (!isCorrect) {
                retro.wasUsed = true;
                meetingDocuments.selected = null;
            }
            retro.times++;
        },
        verifyConcept: (state) => {
            const { questionConcept } = state.questions;
            const { selectedConcept, correctAnswer, retro } = questionConcept;
            let isCorrect = true;
            retro.times++;

            for (let index = 0; index < selectedConcept.length; index++) {
                const result = correctAnswer.find((item) => item === selectedConcept[index]);
                if (result === undefined) {
                    isCorrect = false;
                    break;
                }
            }


            questionConcept.isCorrect = isCorrect;
            questionConcept.answerLabel = `${retro.times} intentos`
            if (!retro.wasUsed && retro.times > 1) {
                retro.answer = [...selectedConcept];
                retro.wasUsed = true;
            } else {
                state.questions.questionConcept.answer = [...selectedConcept];

            }
            state.questions.questionConcept.retro = retro;
            // Clean incorrect options
            if (!isCorrect && retro.times < 2) {
                questionConcept.selectedConcept = selectedConcept.filter((selected) => {
                    const isCorrect = correctAnswer.find((correct) => correct === selected ? true : false);
                    return isCorrect === 0 ? true : isCorrect
                })

            }
        },
        login: (state, action) => {
            const { payload } = action;
            const { id, name, crn, campus, answerId } = payload;
            state.information = { id, name, crn, campus };
            state.answerId = answerId;
        },
        saveRetro: (state, action) => {
            const { payload } = action;
            const { taskId, answer, answerLabel } = payload;
            let keyElement;
            for (const key in state.questions) {
                const element = state.questions[key];
                if (element.retro.taskId === taskId) {
                    keyElement = key;
                    break;
                }
            }


            state.questions[keyElement].retro.answer = answer;
            state.questions[keyElement].retro.wasUsed = true;
            state.questions[keyElement].retro.answerLabel = answerLabel;
            state.questions[keyElement].retro.isCorrect = answer === state.questions[keyElement].retro.correctAnswer;
            state.questions[keyElement].retro.score = state.questions[keyElement].retro.isCorrect ? 1 : 0
        },
        saveAnswer: (state, action) => {
            const {
                payload: { question, answer, retro = false, label, score },
            } = action;
            if (retro) {
                const { retro } = state.questions[question];
                if (!retro.wasUsed) return;
                retro.answer = answer;
                retro.answerLabel = label;
                state.questions[question].retro = retro;
            } else {
                state.questions[question].answer = answer;
                state.questions[question].answerLabel = label;
                state.questions[question].score = score;
                const { questions } = state;
                if (questions[question].correctAnswer === answer) {
                    questions[question].isCorrect = true;
                    return;
                }
                if (!questions[question].retro) return;
                questions[question].retro.wasUsed = true;
            }
        },
        selectConcept: (state, action) => {
            const {
                payload: { id },
            } = action;
            const { selectedConcept } = state.questions.questionConcept;
            const element = selectedConcept.find((item) => Number(item) === Number(id));
            if (!element && element !== 0) {
                if (selectedConcept.length >= 8) {
                    window.alert("Máximo 8 elementos");
                    return;
                }
                const data = selectedConcept.concat([id]);
                state.questions.questionConcept.selectedConcept = data;
            } else {
                state.questions.questionConcept.selectedConcept = selectedConcept.filter((item) => item !== id);
            }
        },
        selectDocument: (state, action) => {
            const { meetingDocuments } = state.questions;
            const { retro } = meetingDocuments;
            if (retro.times > 1) return;
            const { payload: document } = action;
            meetingDocuments.selected = document;
        },


        selectAGoodClient: (state, action) => {
            const {
                payload: { indexClient, isRetro, label },
            } = action;

            const currentQuestion = state.questions.workmateQuestion;

            if (isRetro) {
                currentQuestion.answer = indexClient;
                currentQuestion.retro.answer = indexClient;
                currentQuestion.retro.wasUsed = true;
                currentQuestion.retro.answerLabel = label
                currentQuestion.retro.isCorrect = indexClient === currentQuestion.correctAnswer;
                return;
            } else {
                currentQuestion.answerLabel = label
                currentQuestion.answer = indexClient;
                currentQuestion.isCorrect = indexClient === currentQuestion.correctAnswer;
            }
        },
        verifyGoodClient: (state, action) => {
            const { payload: { isRetro, isCorrect, label, answer } } = action;
            const currentQuestion = state.questions.workmateQuestion;


            if (isRetro) {
                currentQuestion.retro.answerLabel = label
                currentQuestion.retro.answer = answer;
                currentQuestion.retro.wasUsed = true;
                currentQuestion.retro.isCorrect = isCorrect
            } else {
                currentQuestion.answerLabel = label;
                currentQuestion.wasUsed = true;
                currentQuestion.answer = answer
                currentQuestion.isCorrect = isCorrect
            }

        },
        changeSliceSelection: (state, action) => {
            const { payload: { sliceId, indexSlice } } = action;
            const currentQuestion = state.questions.sliceQuestion;
            const { presentation, currentIndex } = currentQuestion;
            const currentSlice = presentation[currentIndex];
            const selectedSlice = currentSlice.slices.find(data => data.id === sliceId);
            currentSlice.answer = indexSlice;
            currentSlice.selectedSlice = selectedSlice;
            currentSlice.sliceId = sliceId
            currentSlice.isCorrect = selectedSlice.isCorrect ? true : false;
        },
        selectSlicesColletion: (state) => {
            const { sliceQuestion } = state.questions;
            sliceQuestion.currentIndex++;
        },
        selectCharacter: (state, { payload }) => {
            let indexCharacter = payload < 0 || payload > 1 ? 0 : payload
            const img = indexCharacter === 0 ? imgs.H01 : imgs.M01;
            state.character = {
                index: indexCharacter,
                img
            }
        },
        saveTime: (state, { payload }) => {
            state.time = payload
        },
        cleanUser: (state) => {
            state.information = null;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    login,
    saveAnswer,
    saveRetro,
    selectConcept,
    verifyConcept,
    selectDocument,
    saveDocument,
    selectAGoodClient,
    verifyGoodClient,
    changeSliceSelection,
    selectSlicesColletion,
    selectCharacter,
    saveTime,
    cleanUser
} = userSlice.actions;

export default userSlice.reducer;
