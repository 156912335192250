import { Carousel } from "react-responsive-carousel";
import { ActiveInterrogation, Arrow, Interrogation, ActiveLock, Lock, TaskBanner, ComputerGuide, PersonSymbol, Controls, TaskBannerEn } from "../assets";
import { Guide } from "../assets/controls";
import SymbolGuide from "./SymbolGuide";
import { arrowStyles } from "../utils";
import { IconGuide } from "./Guide";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";

const InstructionsCrousell = ({ changeSlice }) => {
    return (
        <div className=" w-full h-full lg:h-[452px] flex justify-center self-center justify-self-center lg:pb-[38px] ">
            <div className="w-full flex h-full justify-center ">
                <Carousel
                    className=" w-full  h-full   "
                    onChange={changeSlice}
                    renderArrowNext={(onClickHandler, hasNext, label) => (
                        <>
                            {hasNext ? (
                                <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles }}>
                                    <img src={Arrow} alt="Change slide to next" />
                                </button>
                            ) : null}
                        </>
                    )}
                    renderArrowPrev={(onClickHandler, hasPrev, label) => (
                        <>
                            {hasPrev ? (
                                <button
                                    type="button"
                                    onClick={onClickHandler}
                                    title={label}
                                    style={{ ...arrowStyles, left: 0, transform: "scale(1)" }}
                                >
                                    <img src={Arrow} alt="Change slide to next" />
                                </button>
                            ) : null}
                        </>
                    )}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    autoFocus
                >
                    <Item>
                        <div className={` w-full h-full flex justify-center flex-col lg:justify-around  `}>
                            <p
                                className="guide-body pb-[41px] xl:pb-[48px] font-GraphikMedium text-[16px] lg:text-[20px]"
                                style={{ textAlign: "left" }}
                            >
                                {getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_3")}
                            </p>
                            <div className="w-full h-[240px] flex justify-center items-center">
                                <SymbolGuide
                                    title={getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_4")}
                                    items={[
                                        {
                                            text: getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_5")
                                        },
                                        {
                                            text: getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_6")
                                        }
                                    ]}
                                    isPrincipal
                                    hasCouple
                                    img1={<IconGuide icon={Interrogation} description="Symbol thah indicate when the experience is inactive" />}
                                    img2={<IconGuide icon={ActiveInterrogation} description="Symbol thah indicate when the experience is active" />}
                                />
                                <SymbolGuide
                                    title={getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_7")}
                                    items={[
                                        {
                                            text: getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_8")
                                        },
                                        {
                                            text: getJsonTextLanguageDisplay("guia_de_navegacion_1_desktop_interfas2D_Recuadro_de_texto_9")
                                        }
                                    ]}
                                    hasCouple
                                    img1={<IconGuide icon={Lock} description="Symbol thah indicate when the experience is inactive" />}
                                    img2={<IconGuide icon={ActiveLock} description="Symbol thah indicate when the experience is active" />}
                                />
                            </div>
                        </div>
                    </Item>
                    <Item>
                        <div className="w-full h-full flex flex-col items-center px-[45px]">
                            <div className="w-full mb-[72px] flex justify-start">
                                <p className="guide-body text-left font-GraphikMedium text-[16px] lg:text-[20px]">
                                    {getJsonTextLanguageDisplay("guia_de_navegacion_2_desktop_interfas2D_Recuadro_de_texto_3")}
                                </p>
                            </div>
                            <div className="h-[32px] lg:h-[80px]">
                                <img className="object-contain lg:w-[720px] lg:h-[80px]" src={
                                    localStorage.getItem("language") === "es"
                                        ? TaskBanner
                                        : TaskBannerEn
                                } alt="Example of the task indicator" />
                            </div>
                        </div>
                    </Item>
                    <Item>
                        <div className="w-full h-full  flex flex-col items-center">
                            <div className="w-full  flex justify-start">
                                <p className="guide-body mt-[33px] mb-[44px] text-left font-GraphikMedium text-[16px] lg:text-[20px]">
                                    {getJsonTextLanguageDisplay("guia_de_navegacion_3_desktop_interfas2D_Recuadro_de_texto_3")}
                                </p>
                            </div>
                            <div className="w-full flex items-center justify-center lg:overflow-hidden xl:h-full ">
                                <div className="w-1/2 flex justify-center items-center border-r-2 border-r-white h-full">
                                    <div className=" flex justify-center items-center h-full">
                                        <img
                                            style={{ height: "90%" }}
                                            className="object-contain"
                                            src={ComputerGuide}
                                            alt="Example of the task indicator"
                                        />
                                    </div>
                                </div>
                                <div className="w-2/5 flex justify-center items-center h-full">
                                    <div className="flex justify-center items-center h-full">
                                        <img style={{ height: "90%" }} src={PersonSymbol} alt="Example of the task indicator" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Item>
                    <Item>
                        <div className="w-full h-fit lg:h-full flex justify-center items-center">
                            <SymbolGuide
                                title={getJsonTextLanguageDisplay("guia_de_navegacion_4_desktop_interfas2D_Recuadro_de_texto_3")}
                                text={getJsonTextLanguageDisplay("guia_de_navegacion_4_desktop_interfas2D_Recuadro_de_texto_4")}
                                items={[{ image: Controls, text: "" }]}
                                isPrincipal
                                isLast
                                img1={
                                    <div className="w-[130px] xl:w-[320px]">
                                        <img src={Controls} alt="Symbol thah indicate when the experience is active" />
                                    </div>
                                }
                            />
                            <SymbolGuide
                                title={getJsonTextLanguageDisplay("guia_de_navegacion_4_desktop_interfas2D_Recuadro_de_texto_5")}
                                text={getJsonTextLanguageDisplay("guia_de_navegacion_4_desktop_interfas2D_Recuadro_de_texto_6")}
                                items={[{ image: Controls }]}
                                isLast
                                img1={
                                    <div className=" flex flex-col items-center">
                                        <div className="w-[130px]  xl:w-[320px]">
                                            <img src={Guide.Mouse} alt="Symbol thah indicate when the experience is active" />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </Item>
                </Carousel>
            </div>
        </div>
    );
};
const Item = ({ children }) => {
    return (
        <div className="w-full h-full lg:flex lg:items-center py-3">
            <div className=" w-full h-full lg:px-[87px] py-1  ">
                <div className="h-full flex items-center public-main-back lg:pt-[40px] px-[34px]">{children}</div>
            </div>
        </div>
    );
};

export default InstructionsCrousell;
