import { useIntroduction } from "../../hooks";
import ClientSection from "../Task/ClientSection";
import ComputerHome from "../Task/ComputerHome";
import Final from "../Task/Final";
import { Agenda } from "../Task/task5";
import MailSection from "./MailSection";
import PreferedDate from "./PreferedDate";



const Body = ({ section }) => {
    const { state, openEmail } = useIntroduction()
    const { mails, selectedEmail } = state;
    switch (section) {


        case "final": {
            return <Final isIntroduction={true} />
        }
        case "workmate": {
            return <PreferedDate />
        }
        case "Correo": {
            return <MailSection mails={mails} selectMail={openEmail} selectedMail={selectedEmail} />;
        }
        case "Clientes": {
            return <ClientSection />;
        }
        case "Agenda": {
            return <Agenda />;
        }

        default: {
            return <ComputerHome />;
        }
    }
}


export default Body;