import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";
import { getRandomNumber } from "../utils";



export const juanInformation = {
    age: getRandomNumber(16, 50),
    score: getRandomNumber(716, 901)
}
export const doesJuanHaveLowScore = 716 <= juanInformation.score && juanInformation.score <= 809;
export const isJuanAdult = juanInformation.age >= 18;

export const userList = [
    { name: "Juan Garcia Solis", age: 40, city: "CDMX", hasParticipated: true, isClient: true, responseAnswer: false },
    { name: "Margarita Duarte Bejarano", age: 32, city: "Tlaxcala", hasParticipated: false, isClient: true, responseAnswer: false },
    { name: "Reneé Lopéz Padilla", age: 26, city: "Chihuahua", hasParticipated: false, isClient: true, responseAnswer: false },
    { name: "Diego Alexandro Áviala", age: 67, city: "Durango", hasParticipated: false, isClient: true, responseAnswer: false },
    { name: "Juan Enrique Reyes González", age: juanInformation.age, city: "Jalisco", hasParticipated: true, isTarget: true, responseAnswer: false },
    { name: "Fernando Murillo Luján", age: 40, city: "Sonora", hasParticipated: false, isClient: false, responseAnswer: false },
    { name: "Juan Venzór Mendoza", age: 29, city: "Puebla", hasParticipated: true, isClient: true, responseAnswer: false },
    { name: "Paul Molina Ramírez", age: 23, city: "Veracruz", hasParticipated: false, isClient: true, responseAnswer: false },
    { name: "Alejandro Valenzuela Holguín", age: 29, city: "CDMX", hasParticipated: true, isClient: true, responseAnswer: true, id: 100 },
    { name: "Fernando Salinas Guerrero", age: 50, city: "Chihuahua", hasParticipated: true, isClient: true, responseAnswer: false },
    { name: "Sara Fernández Sanchez", age: 31, city: "CDMX", hasParticipated: false, isClient: true, responseAnswer: false },
];
export const userListWomen = [
    { name: "Ali Romero Aguirre", age: 40, city: "CDMX", hasParticipated: true, isClient: true, responseAnswer: false, isWomanClient: true },
    { name: "Karla Lopez Padilla", age: 26, city: "Chihuahua", hasParticipated: true, isClient: true, responseAnswer: true, isWomanClient: true },
    { name: "Margarita Duarte Bejarano", age: 32, city: "Tlaxcala", hasParticipated: false, isClient: true, responseAnswer: false, isWomanClient: true },
    { name: "Alejandra Picazarri Murillo", age: 26, city: "Chihuahua", hasParticipated: false, isClient: true, responseAnswer: false, isWomanClient: true },
    { name: "Ana Maria Delgado", age: 67, city: "Durango", hasParticipated: false, isClient: true, responseAnswer: false, isWomanClient: true },
    { name: "Maria Romero Casas", age: 20, city: "Jalisco", hasParticipated: true, isWomanClient: true, responseAnswer: false },
    { name: "Alicia Padilla Sánchez", age: 35, city: "Sonora", hasParticipated: false, isClient: true, responseAnswer: false, isWomanClient: true },
    { name: "Cassandra Ontiveros Luna", age: 23, city: "Veracruz", hasParticipated: false, isClient: true, responseAnswer: false, isWomanClient: true },
];

export const invoiceUserList = [
    { name: "Pedro Hernández García", value: '6,765', place: getJsonTextLanguageDisplay("label_mercado") },
    { name: "Esther Lozano Muñoz", value: '5,104', place: getJsonTextLanguageDisplay("label_online") },
    { name: "Reneé Lopez Padilla", value: '4,567', place: getJsonTextLanguageDisplay("label_mercado") },
    { name: "Diego Alexandro Ávila", value: '345', place: getJsonTextLanguageDisplay("label_super_market") },
    { name: "Juan Enrique Reyes González", value: '5,221', place: getJsonTextLanguageDisplay("label_online") },
    { name: "Fernando Murillo Luján", value: '1,986', place: getJsonTextLanguageDisplay("label_mercado") },
    { name: "Juan Venzór Mendoza", value: '1,342', place: getJsonTextLanguageDisplay("label_super_market") },
    { name: "Paul Molina Ramírez", value: '6,432', place: getJsonTextLanguageDisplay("label_online") },
];
export const falseUser = { name: "Hector Solis Montiel", age: 31, city: "Hidalgo", hasParticipated: true };
export const inexistentUser = { name: "Abraham Acosta Ramírez", age: 40, city: "Durango", isTarget: true };
