import { Arrow } from "./assets";

export const getRandomNumber = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const getRandomBoolean = () => {
	return getRandomNumber(0, 2) === 0;
};

export const arrowStyles = {
	position: "absolute",
	zIndex: 2,
	top: "calc(50% - 27px)",
	width: 32,
	height: 54,
	right: 0,
	transform: "scale(-1)",
	cursor: "pointer",
};

export const getPrevOrangeArrow = (onClickHandler, hasPrev, label, isConversationTask) => {
	if (!hasPrev) return;
	if (isConversationTask) return;

	return (
		<button
			type="button"
			onClick={(index) => {
				if (isConversationTask) return;
				onClickHandler(index);
			}}
			title={label}
			style={{ ...arrowStyles, left: 0, transform: "scale(1)" }}
			className={`w-[16px] h-[27px] ml-4 ${isConversationTask && "cursor-not-allowed"}  ${
				!isConversationTask && " hover:opacity-60 duration-300 transition-opacity"
			}`}
		>
			<img src={Arrow} alt="Change slide to next" />
		</button>
	);
};

export const getNextOrangeArrow = (onClickHandler, hasNext, label, isConversationTask) => {
	if (!hasNext) return;
	if (isConversationTask) return;
	return (
		<button
			type="button"
			onClick={(index) => {
				if (isConversationTask) return;
				onClickHandler(index);
			}}
			title={label}
			style={{ ...arrowStyles }}
			className={`w-[16px] h-[27px] mr-4 ${isConversationTask && "cursor-not-allowed"}  ${
				!isConversationTask && " hover:opacity-60 duration-300 transition-opacity"
			}`}
		>
			<img src={Arrow} alt="Change slide to next" />
		</button>
	);
};
