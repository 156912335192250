import { useMemo } from "react";
import { useStoreComputer } from "../../hooks/store";
import MailBox from "./MailBox";
import Message from "./Message";

const getCorrectSection = (selectedMail, MailBox, Message) => {
    return selectedMail === null ? MailBox : Message;
};

const MailSection = () => {
    const { selectedMail, mail: messages } = useStoreComputer();

    const ListOfMessages = useMemo(
        () =>
            messages.map((element) => (
                <MailBox.Message key={element.id} id={element.id} isChecked={element.isChecked} person={element.person} title={element.title} />
            )),
        [messages]
    );

    if (selectedMail === null) {
        return <MailBox>{ListOfMessages}</MailBox>;
    }
    const MailBoxSection = <MailBox>{ListOfMessages}</MailBox>;
    const Section = getCorrectSection(selectedMail, MailBoxSection, <Message />);
    return (
        <div className=" flex items-center  flex-col justify-center h-full w-full    ">
            <div className=" grow w-full h-full flex items-center justify-center">{Section}</div>
        </div>
    );
};

export default MailSection;
