import { memo } from "react";

const MenuItem = ({ icon, alt = "section", text, isSelected, handleClick }) => {
    return (
        <li onClick={handleClick} className={`flex items-center gap-2 cursor-pointer px-4 py-2 ${isSelected && "bg-[#0577BE]"} hover:bg-[#0577BE] active:bg-[#3366FF]`}>
            <img src={icon} alt={alt} className="w-6 h-6 object-contain" />
            <p className="text-sm font-GraphikRegular text-white">{text}</p>
        </li>
    );
}

export default memo(MenuItem);