import { useStoreComputer, useStoreTask } from "../../hooks/store";
import FooterButtons from "./FooterButtons";
import { BankBCJ } from "../../assets";
// import useComputerActions from "../../hooks/generalActions";

const Footer = () => {
    const { tasks } = useStoreTask();
    const { selectedSection } = useStoreComputer();


    return (
        <div className="  w-full h-full flex items-center  justify-between   ">
            <div className="w-[106px] flex items-center h-full">
                <img src={BankBCJ} alt="TMY" />
            </div>
            <FooterButtons selectedSection={selectedSection} idTask={tasks[0].id} />
        </div>
    );
};

export default Footer;
