import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";
import { initStatusQuestions } from "../types";
import { getRandomBoolean } from "../utils";


export const concepts1 = [
    {
        id: "Nominal-Cust_id",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_1"),
        value: "1",
    },

    {
        id: "Razón-Cust_emp",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_razon"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_2"),
        value: "001",
    },

    {
        id: "Nominal-Cust_sexo",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_3"),
        value: "H",
    },

    {
        id: "Intervalo-Cust_actividad",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_intervalo"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_4"),
        value: "1",
    },

    {
        id: "Razón-Tot_txn_6_mes",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_razon"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_5"),
        value: "780",
    },


    {
        id: "Intervalo-Estado",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_6"),
        value: "CDMX",
    },

    {
        id: "Ordinal-Imp",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_7"),
        value: "800",
    },

    {
        id: "Intervalo-Sdo_vgente_visa",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_intervalo"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_8"),
        value: "23826.59258",
    },

    {
        id: "Ordinal-Num_prod_cte",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_9"),
        value: "12",
    },

    {
        id: "Ordinal-Cal_cte",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_10"),
        value: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_1"),
    },

    {
        id: "Razón-Score",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_razon"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_11"),
        value: "775",
    },

    {
        id: "Nominal-Est_compra",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_12"),
        value: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_2"),
    },

    {
        id: "Intervalo-Edad_años",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_intervalo"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_13"),
        value: 65,
    },

    {
        id: "Razón-Etapa_vida",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_14"),
        value: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_3"),
    },
];
export const concepts2 = [
    {
        id: "Nominal-Cust_id",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_1"),
        value: "1",
    },

    {
        id: "Razón-Cust_emp",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_intervalo"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_2"),
        value: "001",
    },

    {
        id: "Nominal-Cve_sexo",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_3"),
        value: "H",
    },

    {
        id: "Intervalo-Cve_actividad",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_4"),
        value: "1",
    },

    {
        id: "Razón-Tot_txn_6_mes",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_intervalo"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_5"),
        value: "780",
    },

    {
        id: "Intervalo-Estado",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_6"),
        value: "CDMX",
    },

    {
        id: "Ordinal-Imp",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_razon"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_7"),
        value: "800",
    },

    {
        id: "Intervalo-Sdo_vgente_visa",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_razon"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_8"),
        value: "23826.59258",
    },

    {
        id: "Ordinal-Num_prod_cte",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_9"),
        value: "12",
    },

    {
        id: "Ordinal-Cal_cte",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_10"),
        value: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_1"),
    },

    {
        id: "Razón-Score",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_11"),
        value: "775",
    },

    {
        id: "Nominal-Est_compra",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_12"),
        value: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_2"),
    },

    {
        id: "Intervalo-Edad_años",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_ordinal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_13"),
        value: 65,
    },

    {
        id: "Razón-Etapa_vida",
        title: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_1ra_fila_nominal"),
        field: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombres_2ra_fila_14"),
        value: getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_interfaz2D_nombre_3ra_fila_3"),
    },
];

export const dataConcept = {
    id: 2,
    correctAnswer: [0, 1, 3, 6, 7, 8, 10, 12],
    concepts: concepts1,
};

export const dataConcept1 = {
    id: 1,
    correctAnswer: [1, 3, 4, 8, 9, 10, 12, 13],
    concepts: concepts2,
};


export const tableConceptsQuestion = getRandomBoolean() ? dataConcept : dataConcept1;

initStatusQuestions.tableConceptsQuestion = tableConceptsQuestion;
