/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";

const useActiveComputer = (isActive, tasks) => {
    const [showComputer, setShowComputer] = useState(isActive);
    const currenTask = tasks[0];

    const closeComputer = useCallback(() => {
        setShowComputer(false);
    }, []);

    useEffect(() => {
        if (!tasks) {
            closeComputer();
            return;
        }
        if (tasks.length === 0) {
            closeComputer();
            return;
        }
        if (currenTask.type !== "computer" && !currenTask.isAlsoComputer) {
            closeComputer();
            return;
        }
        if ((currenTask.type === "computer" && !showComputer) || currenTask.isAlsoComputer) setShowComputer(true);
    }, [showComputer, tasks.length, closeComputer]);

    return { showComputer, closeComputer };
};

export default useActiveComputer;
