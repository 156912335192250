import { RotatePhone } from "../assets";

const MovePhone = () => {
	return (
		<div className="public-main  flex flex-col h-screen justify-center items-center">
			<h2 className="text-center main-title w-[279px] h-[40px] label-title mb-[100px]">Gira tu celular para poder continuar.</h2>
			<img src={RotatePhone} alt="Rotate your phone to see the experience" className="w-[193px] h-[144px]" />
		</div>
	);
};

export default MovePhone;
