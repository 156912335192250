import { memo } from "react";
import { getJsonTextLanguageDisplay } from "../../helpers/helpersLanguage";

const getCorrectName = (isMine, person) => {
    if (isMine) return getJsonTextLanguageDisplay("tarea_1_revisa_computadora_na_escritorio_ambiente3D_card_texto_titulo_1");
    if (!person) return "Juan Enrique Reyes González";
    return person.name;
};

const HeaderName = ({ isOptionItem, isMine, person }) => {
    const personSpeaking = getCorrectName(isMine, person);

    if (isOptionItem) return;
    return (
        <div className=" absolute w-[331px] h-[38px] bg-task-color rounded-lg flex items-center justify-center pl-12 text-white" style={{ zIndex: 100 }}>
            <p className="header-dialogue text-white font-GraphikMedium text-sm xl:text-lg">{personSpeaking}</p>
        </div>
    );
};

export default memo(HeaderName);
