import { memo } from "react";
import { Glossary, GlossaryClose, IconInfo } from "../../../assets";
import { getJsonTextLanguageDisplay } from "../../../helpers/helpersLanguage";

const Concepts = ({ children, changeGlossaryStatus, itemsSelected, attemps, hasFinished }) => {
    const hasTried = attemps > 0;
    return (
        <div className="w-full mx-auto h-full flex flex-col  rounded-sm items-center">
            <div className="w-full h-[56px] flex justify-between items-end ">
                <div className="flex">
                    {itemsSelected !== 8 && !hasFinished && (
                        <>
                            <img src={IconInfo} alt="information" className="w-[24px] h-[24px] " />
                            <div className="ml-[8px]">
                                <p className="text-instruction font-GraphikMedium text-[16px] xl:text-[20px]">
                                    {8 - itemsSelected === 1
                                        ? getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_primera_oportunidad_interfaz2D_intrucciones_selecion_2")
                                        : getJsonTextLanguageDisplay("tarea_1_instrucciones_selecion_columnas_caso1y2_tabla_primera_oportunidad_interfaz2D_intrucciones_selecion_1", { "[replace1]": `${8 - itemsSelected}` })
                                    }
                                </p>
                            </div>
                        </>
                    )}
                </div>
                <div
                    onClick={() => {
                        if (hasTried) changeGlossaryStatus(true);
                    }}
                    className={`w-[56px] h-[56px] ${hasTried ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                    <img className="w-[56px] h-[56px] " src={hasTried ? Glossary : GlossaryClose} alt="Glossary" />
                </div>
            </div>
            <div className="w-full grow pt-[22px] flex justify-center ">
                <div className="w-full h-full flex justify-center">{children}</div>
            </div>
        </div>
    );
};

const Item = ({ title = "", field = "", value = "", concepts = [], handlerClick, index, hasFinished, isTheFirstElement, isTheLastElement }) => {
    const isSelected = concepts.find((item) => Number(item) === Number(index));
    return (
        <div
            onClick={() => {
                if (hasFinished) return;
                handlerClick(index);
            }}
            className={`h-fit w-[55px] rounded-lg lg:w-[65px] xl:w-[80px] ${!hasFinished && "hover:bg-blue-table-histogram"} ${isSelected || isSelected === 0 ? "bg-blue-table-histogram " : "bg-strong-blue "
                } `}
        >
            <div
                className={`${isTheFirstElement && "rounded-tl-xl"} ${isTheLastElement && "rounded-tr-xl"
                    } w-[55px] lg:w-[65px] xl:w-[80px] text-strong-blue  text-[10px] lg:text-[14px] py-[15px] px-[0px] xl:px-[4px] overflow-hidden   ${isSelected || isSelected === 0 ? "bg-organge-task " : "bg-white"
                    } `}
                style={{
                    border: "1px solid #0577BE",
                }}
            >
                <p
                    className={`text-center  font-GraphikBold text-[12px] xl:text-[16px]  ${isSelected || isSelected === 0 ? "text-white" : "text-strong-blue"
                        }`}
                >
                    {title}
                </p>
            </div>
            <div
                className=" w-[55px] lg:w-[65px] xl:w-[80px]  h-[80px]  flex justify-center items-center py-[25px] px-0 xl:px-[5px] text-white"
                style={{ overflowWrap: " anywhere", border: "0.5px solid #FFFFFF" }}
            >
                <p className="font-GraphikRegular text-[12px] xl:text-[12px] text-white text-center">{field}</p>
            </div>
            <div
                className={`flex justify-center items-center h-[80px] text-[14px] py-[25px] px-0 xl:px-[5px] ${isTheFirstElement && "rounded-bl-xl"
                    } ${isTheLastElement && "rounded-br-xl"}`}
                style={{ border: "0.5px solid #FFFFFF" }}
            >
                <p className="font-GraphikRegular text-[12px] xl:text-[12px] text-white text-justify">{value} </p>
            </div>
        </div>
    );
};

Concepts.Item = memo(Item);

export default Concepts;
