import { LoadingItem } from "../assets";
import { getJsonTextLanguageDisplay } from "../helpers/helpersLanguage";
import LogoIntroduction from "./LogoIntroduction";
const year = new Date().getFullYear()

const Loader = ({ isLoading }) => {
    if (!isLoading) return;

    return (
        <div className="w-full h-full flex items-center flex-col public-main pt-[89px] absolute top-0">

            <LogoIntroduction />
            <div className="self-center content-center justify-self-center mt-[103px]">
                <p className="text-center text-white  mt-[16px]  label font-GraphikRegular mb-7 text-[18px]">{getJsonTextLanguageDisplay("loading_desktop_interfaz2D_recuadro_de_texto_1")}</p>
                <div>
                    <img id="loading" src={LoadingItem} alt="loading item" className="w-[119px] h-[119px]" />
                </div>
            </div>
            <div className="mt-auto self-center">
                <p className="footer-home text-center font-GraphikRegular text-[14px] ">
                    {getJsonTextLanguageDisplay("loading_desktop_interfaz2D_recuadro_de_texto_2")}<br />
                    {getJsonTextLanguageDisplay("loading_desktop_interfaz2D_recuadro_de_texto_3", { "[replace1]": year })}
                </p>
            </div>
        </div>
    );
};

export default Loader;
