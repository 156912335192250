import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { AudioLoader, LoadingManager, TextureLoader } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";



// export const path3D = "/"; //* develop and local
export const path3D = process.env.NODE_ENV === "development" ? "/" : "/app/"; //* prod
export const loadingManager = new LoadingManager();
export const textureLoader = new TextureLoader(loadingManager).setPath(`${path3D}textures/`);
export const audioLoader = new AudioLoader();
export const loader = new GLTFLoader(loadingManager);
export const dracoloader = new DRACOLoader();
dracoloader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.5.5/"
);
loader.setDRACOLoader(dracoloader);



