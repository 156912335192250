/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback } from "react";
import { useCloseButton } from "../../hooks/computer";
import NotificationModel from "./NotificationModel";
import { useStoreUser } from "../../hooks/store";
import { CloseButton } from "../../components";
import { useChangeTask } from "../../hooks";
import Glossary from "./task3/Glossary";

const Screen = ({ children, task, closeComputer, sectionRequired, isChangeSection, selectedSection }) => {
    const { questions } = useStoreUser();
    const { changeTask } = useChangeTask();
    const [Header, Body, Footer] = children;
    const { isVisible } = useCloseButton(sectionRequired, isChangeSection, task, questions, selectedSection);

    useEffect(() => {
        if (children.length !== 3) return new Error("Children must have just 3 elements. [header, body, footer]");
    }, [children.length]);

    useEffect(() => {
        if (task.action === "change-section" && sectionRequired === null) {
            changeTask();
        }
    }, [sectionRequired, task]);

    const handlerCloseComputer = useCallback(() => {
        closeComputer();
        changeTask();
    }, []);

    return (
        <section
            id="screen"
            className={`w-full h-screen absolute top-0 flex justify-center items-center bg-background-screen  z-30`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.stopPropagation();
            }}
        >
            <div
                className="w-full max-w-[1280px]  bg-background-screen flex flex-col relative  border-[24px]  "
                style={{ border: "black solid", height: "90%" }}
            >
                <div className="lg:w-full pt-[51px] pb-10 pr-[14px] lg:pr-[23px]  flex flex-col items-center justify-end ">
                    <div className="h-full w-full ">{Header}</div>
                </div>
                <div className="w-full flex items-center grow   overflow-scroll">
                    <div className="my-auto w-full lg:px-[23px] h-full flex items-center bg-strong-lab ">{Body}</div>
                </div>
                <div className="w-full h-[100px] xl:h-[120px] flex items-center  py-0  pl-[9px]   xl:pl-[24px] pr-[14px] xl:pr-[23px]    ">
                    <div className=" w-full h-full ">{Footer}</div>
                </div>
                <CloseButton onClose={handlerCloseComputer} isVisible={isVisible} />
                <NotificationModel />
                <Glossary currentTask={task} />
            </div>
        </section>
    );
};

export default Screen;
